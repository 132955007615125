import { useMutation } from "@tanstack/react-query";

import { ErrorResponse } from "services/ErrorResponse";
import httpClient from "services/httpClient";

const ADMIN_ORDER = "admin/orders/";

type PostEditSuborderFields = {
  suborderId: string;
  snapshotId: string;
};

const postEditSuborder = async (form: PostEditSuborderFields) => {
  return await httpClient.put(`${ADMIN_ORDER}${form.suborderId}/edit`, {
    snapshotId: form.snapshotId,
  });
};

export const usePostEditSuborder = (
  onSuccess: () => void,
  onError: (data: ErrorResponse) => void,
) =>
  useMutation(postEditSuborder, {
    onSuccess,
    onError,
  });
