import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { AdminCountryMultiplierType } from "./useGetMSRPMultipliers";
import { useUpdateBatchMultipliers } from "./useUpdateBatchMultipliers";
import { useUpdateIndividualMultiplier } from "./useUpdateIndividualMultiplier";
import { Country } from "hooks/useGetRetailerCountries";
import { ErrorResponse } from "services/ErrorResponse";
import { getErrorCodes } from "services/httpClient";

// TODO: Update for Phase 2 and 3.
export type MSRPMultiplierType = "COUNTRY";

export type MultiplierField = {
  multiplier: number | null;
  type: MSRPMultiplierType;
  countryCode: string;
  categoryId?: string;
  brandId?: string;
};

const createDefaultMultiplier = (
  countries?: Country[],
  existingMultipliers?: AdminCountryMultiplierType[],
): MultiplierField[] => {
  if (!countries || !existingMultipliers) return [];
  const multiplierList: MultiplierField[] = existingMultipliers.map(
    (multiplier) => ({
      multiplier: multiplier.multiplier,
      type: multiplier.type,
      countryCode: multiplier.country.countryCode,
    }),
  );

  countries
    .filter(
      (country) =>
        !multiplierList.find(
          (multiplier) => multiplier.countryCode === country.countryCode,
        ),
    )
    .forEach((country) =>
      multiplierList.push({
        multiplier: 1,
        type: "COUNTRY",
        countryCode: country.countryCode,
      }),
    );
  return multiplierList.sort((a, b) =>
    a.countryCode.localeCompare(b.countryCode),
  );
};

export type MultiplierFormFields = { multipliers: MultiplierField[] };
export type UseMultiplierFormProps = {
  existingMultipliers?: AdminCountryMultiplierType[];
  retailerCountries?: Country[];
};

export const useMultiplierForm = ({
  existingMultipliers,
  retailerCountries,
}: UseMultiplierFormProps) => {
  const { t } = useTranslation();
  const {
    register,
    control,
    getValues,
    setValue,
    unregister,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm<MultiplierFormFields>({
    mode: "onChange",
    defaultValues: {
      multipliers: createDefaultMultiplier(
        retailerCountries,
        existingMultipliers,
      ),
    },
  });

  useEffect(() => {
    if (existingMultipliers && retailerCountries) {
      reset(() => ({
        multipliers: createDefaultMultiplier(
          retailerCountries,
          existingMultipliers,
        ),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingMultipliers, retailerCountries]);

  const { fields: multiplierFields } = useFieldArray({
    control,
    name: "multipliers",
  });
  const toast = useToast();
  const onSuccess = () => {
    toast({
      description: "Successfully updated multipliers",
      duration: 3000,
      position: "top",
    });
  };

  const onError = (errorResponse: ErrorResponse) => {
    const [errorCode] = getErrorCodes(errorResponse);

    toast({
      status: "error",
      description:
        errorCode !== "COUNTRY_001"
          ? "Failed to update multipliers"
          : t("settings.msrp_settings.COUNTRY_001"),
      duration: 3000,
      position: "top",
    });
  };
  const { mutateAsync: updateBatchMultipliers } = useUpdateBatchMultipliers(
    onSuccess,
    onError,
  );

  const { mutateAsync: updateIndividualMultiplier } =
    useUpdateIndividualMultiplier(onSuccess, onError);

  const onSubmit = handleSubmit(async () => {
    await updateBatchMultipliers(getValues());
  });

  const onSubmitIndividual = (index: number) =>
    handleSubmit(async () => {
      await updateIndividualMultiplier(getValues().multipliers[index]);
    });

  return {
    register,
    control,
    getValues,
    setValue,
    unregister,
    onSubmit,
    multiplierFields,
    createDefaultMultiplier,
    errors,
    isDirty,
    handleSubmit,
    onSubmitIndividual,
  };
};
