/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import { AxiosResponse } from "axios";
import { format, parseISO } from "date-fns";
import jwtDecode from "jwt-decode";
import { debounce } from "lodash";
import { ChangeEventHandler } from "react";

import { AccessToken } from "services/httpClient";

export const formatDate = (date: string) =>
  format(parseISO(date), "dd-MM-yyyy");

export const downloadCSV = (serverResponse: AxiosResponse<string>) => {
  const blob = new Blob([serverResponse.data], {
    type: "text/csv;charset=utf-8",
  });

  const url = window.URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  link.download = serverResponse.headers["content-disposition"]!.replace(
    "attachment; filename=",
    "",
  ).replace(/"/g, "");

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export const isAbleToImpersonate = () => {
  const accessToken = localStorage.getItem("access-token");

  if (accessToken) {
    const decodedToken = jwtDecode(accessToken) as AccessToken;
    const cognitoGroups = decodedToken["cognito:groups"];

    return (
      cognitoGroups.includes("AccountAdmin") ||
      cognitoGroups.includes("AccountEdit")
    );
  }

  return false;
};

const SEARCH_DEBOUNCE_TIMER = 350;

export const DEBOUNCED_SEARCH = (
  func: () => void,
  time = SEARCH_DEBOUNCE_TIMER,
) => debounce(func, time) as ChangeEventHandler<unknown>;
