import { SVGProps } from "react";

const SvgLightEye = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.99997 10.3833C8.75552 10.3833 9.39708 10.1193 9.92463 9.59133C10.4526 9.06378 10.7166 8.42222 10.7166 7.66667C10.7166 6.91111 10.4526 6.26956 9.92463 5.742C9.39708 5.214 8.75552 4.95 7.99997 4.95C7.24441 4.95 6.60286 5.214 6.0753 5.742C5.5473 6.26956 5.2833 6.91111 5.2833 7.66667C5.2833 8.42222 5.5473 9.06378 6.0753 9.59133C6.60286 10.1193 7.24441 10.3833 7.99997 10.3833ZM7.99997 9.46667C7.49997 9.46667 7.07508 9.29155 6.7253 8.94133C6.37508 8.59156 6.19997 8.16667 6.19997 7.66667C6.19997 7.16667 6.37508 6.74156 6.7253 6.39133C7.07508 6.04156 7.49997 5.86667 7.99997 5.86667C8.49997 5.86667 8.92508 6.04156 9.2753 6.39133C9.62508 6.74156 9.79997 7.16667 9.79997 7.66667C9.79997 8.16667 9.62508 8.59156 9.2753 8.94133C8.92508 9.29155 8.49997 9.46667 7.99997 9.46667ZM7.99997 12.3333C6.54441 12.3333 5.20819 11.95 3.9913 11.1833C2.77486 10.4167 1.84441 9.37778 1.19997 8.06667C1.17775 8.02222 1.16108 7.96378 1.14997 7.89133C1.13886 7.81933 1.1333 7.74444 1.1333 7.66667C1.1333 7.58889 1.13886 7.51378 1.14997 7.44133C1.16108 7.36933 1.17775 7.31111 1.19997 7.26667C1.84441 5.95556 2.77486 4.91667 3.9913 4.15C5.20819 3.38333 6.54441 3 7.99997 3C9.45552 3 10.7915 3.38333 12.008 4.15C13.2249 4.91667 14.1555 5.95556 14.8 7.26667C14.8222 7.31111 14.8389 7.36933 14.85 7.44133C14.8611 7.51378 14.8666 7.58889 14.8666 7.66667C14.8666 7.74444 14.8611 7.81933 14.85 7.89133C14.8389 7.96378 14.8222 8.02222 14.8 8.06667C14.1555 9.37778 13.2249 10.4167 12.008 11.1833C10.7915 11.95 9.45552 12.3333 7.99997 12.3333ZM7.99997 11.3333C9.25552 11.3333 10.4084 11.0027 11.4586 10.3413C12.5084 9.68044 13.3111 8.78889 13.8666 7.66667C13.3111 6.54444 12.5084 5.65267 11.4586 4.99133C10.4084 4.33044 9.25552 4 7.99997 4C6.74441 4 5.59152 4.33044 4.5413 4.99133C3.49152 5.65267 2.68886 6.54444 2.1333 7.66667C2.68886 8.78889 3.49152 9.68044 4.5413 10.3413C5.59152 11.0027 6.74441 11.3333 7.99997 11.3333Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgLightEye;
