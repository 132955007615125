import { useMutation } from "@tanstack/react-query";

import { Product } from "./useGetReturnRequestDetails";
import httpClient from "services/httpClient";

export const PUT_RESOLVE_REQUEST = "/admin/return-requests/";
type PutResolveRequestParams = {
  id: string;
  form: Product[];
};
const putResolveRequest = async ({ form, id }: PutResolveRequestParams) => {
  return await httpClient.put(`${PUT_RESOLVE_REQUEST}${id}`, {
    products: form.map((product) => {
      return {
        sku: product.sku,
        unitQuantity: Number(product.approvedUnitQuantity),
        comment: product.adminComment,
      };
    }),
  });
};

export const usePutResolveRequest = (onSuccess: () => void) =>
  useMutation(putResolveRequest, {
    onSuccess,
  });
