/* eslint-disable @tanstack/query/exhaustive-deps */
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { ReturnRequest } from "features/ReturnRequests/Submitted/hooks/useGetSubmittedRequests";
import { READY_FOR_REVIEW_REQUESTS_KEY } from "services/ReactQueryKeys";
import httpClient from "services/httpClient";

export const GET_READY_FOR_REVIEW_REQUESTS =
  "/admin/return-requests/ready-for-review";

export const useGetReadyForReviewRequests = (): UseQueryResult<
  ReturnRequest[]
> =>
  useQuery({
    queryKey: [READY_FOR_REVIEW_REQUESTS_KEY],
    queryFn: async () => {
      const { data: requests } = await httpClient.get<ReturnRequest[]>(
        GET_READY_FOR_REVIEW_REQUESTS,
      );
      return requests;
    },
  });
