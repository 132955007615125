import { headingSizes } from "styles/foundations";

export const Heading = {
  baseStyle: {
    fontWeight: "400",
  },
  sizes: {
    ...headingSizes,
  },
};
