import { Box, BoxProps, Circle, Flex, Text } from "@chakra-ui/react";

import {
  circleContainerStyles,
  circleStyles,
  statusContainerStyles,
  statusTextStyles,
} from "./Status.styles";

export type StatusVariant = "error" | "pending" | "done" | "info" | "neutral";

type StatusProps = {
  variant: StatusVariant;
  text?: string;
} & BoxProps;

export const Status = ({ variant, text, ...rest }: StatusProps) => {
  return (
    <Box {...statusContainerStyles(variant, text)} {...rest}>
      <Flex {...circleContainerStyles}>
        <Circle {...circleStyles(variant)} />
      </Flex>
      <Text {...statusTextStyles}>{text}</Text>
    </Box>
  );
};
