/* eslint-disable @typescript-eslint/no-unnecessary-condition */

/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { API_BASE_URL } from "env";

import httpClient from "services/httpClient";

export const GET_BRAND = `/brands/brand-page/preview/{brandKey}`;

export type BrandProfile = {
  brandName: string;
  brandLocation: string;
  logoRef: string;
  coverRef: string;
  description: string;
  videoName?: string;
  videoDescription?: string;
  videoRef?: string;
  imgRef?: string[];
  isRequest?: boolean;
  brandKey: string;
  minOrderAmount?: number;
  isFavourite?: boolean;
  shopStatus: boolean;
  isPreview?: boolean;
  brandProducts?: ProductResult;
  product_types?: string;
  values?: string;
  style?: string;
  highlights?: string;
};

export type Product = {
  id?: string;
  isBestSeller?: boolean;
  keyParam: string;
  name: string;
  image: string;
  category: {
    id: string;
    name: string;
    key: string;
    parentCategory?: {
      id: string;
      name: string;
      key: string;
    };
  };
  brand?: {
    id: string;
    name: string | null;
    location: string | null;
  };
  isNew?: boolean;
  minimumOrderQuantity: string;
  favorite?: boolean;
  wholeSalePrice: string | null;
  discountedPrice: string | null;
  msrp: string;
  promotionName?: string;
  size?: "md" | "auto";
  isLoading?: boolean;
  originalPrice?: boolean;
};

export type ProductResult = {
  results: Product[];
  total: number;
  priceRange: { from: string; to: string }[];
  madeInCountries: string[];
  count: number;
};

export const useBrandProfile = (
  brandKey: string,
  lang: string,
  currency: string,
): UseQueryResult<BrandProfile> =>
  useQuery({
    queryKey: [GET_BRAND, brandKey, API_BASE_URL, lang, currency],
    queryFn: async () => {
      const { data } = await httpClient.get<BrandProfile>(
        GET_BRAND.replace("{brandKey}", brandKey),
      );

      const { data: products } = await httpClient.get<ProductResult>(
        `${API_BASE_URL ?? ""}/admin/products/search`,
        {
          headers: {
            "x-products-search": `{"offset": 0,"limit":500,"fullTextSearch":"","brandKey":["${brandKey}"]}`,
            "x-locale": lang,
            "x-currency": currency,
            sessionid: "markato-ui",
          },
        },
      );

      let product_types = "";
      let values = "";
      let style = "";
      let highlights = "";
      const response = await fetch(
        "https://docs.google.com/spreadsheets/d/1ZEj_MK3sEaRWxml2yT7m9qdMU2Yk6cEbxJgILEPV0II/gviz/tq?tqx=out:json&tq&gid=0",
      );
      const text = await response.text();
      const results = text.match(/(?<="table":).*(?=}\);)/g);
      results?.forEach((element) => {
        const json = JSON.parse(element);
        json?.rows?.forEach(
          (ele: {
            c: [
              { v: string },
              { v: string },
              { v: string },
              { v: string },
              { v: string },
            ];
          }) => {
            const row = ele.c;
            const brand_key = row[0]?.v;
            if (brandKey === brand_key) {
              product_types = row[1]?.v;
              values = row[2]?.v;
              style = row[3]?.v;
              highlights = row[4]?.v;
            }
          },
        );
      });

      return {
        ...data,
        brandProducts: products,
        product_types,
        values,
        style,
        highlights,
      } as BrandProfile;
    },
    staleTime: 10,
  });
