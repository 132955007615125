import { Box, Icon, Tooltip, TooltipProps } from "@chakra-ui/react";

import { iconStyle, tooltipStyle } from "./InfoTooltip.styles.ts";
import { SolidHelp } from "styles/icons/solid";

export const InfoTooltip = ({ children, ...rest }: TooltipProps) => (
  <Tooltip {...tooltipStyle} label={children} {...rest}>
    <Box display="flex">
      <Icon as={SolidHelp} {...iconStyle} />
    </Box>
  </Tooltip>
);
