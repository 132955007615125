/* eslint-disable @tanstack/query/exhaustive-deps */
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import httpClient from "services/httpClient";

export const GET_RETAILER_REQEUSTS = "/admin/payment-terms-requests";
export const GET_RETAILER_REQEUSTS_QK = "RETAILER_REQEUSTS_QK";

export type RetailerRequest = {
  id: string;
  name: string;
  email: string;
  creationDate: string;
  category: string;
  country: string;
};

export const useGetRetailerRequests = (): UseQueryResult<RetailerRequest[]> =>
  useQuery({
    queryKey: [GET_RETAILER_REQEUSTS_QK],
    queryFn: async () => {
      const { data: adminAccounts } = await httpClient.get<RetailerRequest>(
        GET_RETAILER_REQEUSTS,
      );
      return adminAccounts;
    },
  });
