/* eslint-disable @tanstack/query/exhaustive-deps */
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { BRAND_FINANCIAL_KEY } from "../../../../../services/ReactQueryKeys";
import httpClient from "services/httpClient";

export type Settings = {
  firstOrderCommission: number;
  commission: number;
};

export const useGetSettings = (brandId: string): UseQueryResult<Settings> =>
  useQuery({
    queryKey: [BRAND_FINANCIAL_KEY, brandId],
    queryFn: async () => {
      const { data: settings } = await httpClient.get<Settings>(
        `/admin/brands/${brandId}/financials`,
      );
      return settings;
    },
    retry: false,
  });
