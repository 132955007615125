import { Flex, Image, Text } from "@chakra-ui/react";

type OrderAvatarProps = {
  image: string;
  sku: string;
  name: string;
  variant?: string;
};
export const OrderAvatar = ({
  image,
  sku,
  name,
  variant = "",
}: OrderAvatarProps) => {
  return (
    <Flex>
      <Image src={image} alt={name} w={9} h={9} />
      <Flex textStyle="bodyS" flexDir="column" ml={3}>
        <Text color="grey.700">{name}</Text>
        <Text color="grey.600">{sku}</Text>
        {variant && <Text color="grey.600">{`Variant: ${variant}`}</Text>}
      </Flex>
    </Flex>
  );
};
