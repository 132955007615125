import { useMutation } from "@tanstack/react-query";

import httpClient from "services/httpClient";

export const ADMIN_BRAND = "/admin/brands";

type PostNodeParams = {
  id: string;
  content: string;
};

const postNote = async ({ id, content }: PostNodeParams) => {
  return await httpClient.post(`${ADMIN_BRAND}/${id}/notes`, {
    content,
  });
};

export const usePostNewBrandNote = (onSuccess: () => void) =>
  useMutation(postNote, {
    onSuccess,
  });
