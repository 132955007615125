const textEn = {
  page1: {
    text1: "Introducing Markato.",
    text2:
      "An Online Wholesale Marketplace for Asia Retailers to source from curated brands around the world.",
  },
  page2: {
    text1: "A new way to buy wholesale, digitally, easily",
    text2: "Brands in Beauty & Wellness, Home & Living, Accessories",
    text3: "Savings on international shipping and payments",
    text4: "Low MOQ",
    text5: "Low minimum orders, low commitment",
    text6: "So you can earn more, try more, sell more",
    text7: "Retail to Wholesale Margin. Earn more on every product you sell",
    text8: "Risk-Free Returns",
    text9: "For 60 days on any order as an add-on service",
    text10: "Buy Now, Pay Later",
    text11:
      "Interest-free, 60 days. Pay $0 at checkout. Sell more with no extra budget",
  },
  page3: {
    text1:
      "We empower Asia retailers and global independent brands by making discovering new products and cross-border wholesale easy and accessible.",
    text2:
      "The following section is a selection of brands curated specifically for you →",
  },
  page4: {
    text1: "MARKATO BRAND PROFILE",
    text2: "Product types:",
    text3: "Brand values:",
    text4: "Style:",
    text5: "Brand highlights:",
    text6: "Wholesale Price:",
    text7: "Suggested Retail:",
  },
  page5: {
    text1: "Markato is amongst the most prominent startups in Asia",
    text2:
      "Investing in long-term partnerships is a cornerstone of our growth strategy",
    text3: "Our Founders are veteran marketplace builders",
    text4: "Brian Lo",
    text5: "Ex-GM of Deliveroo",
    text6:
      "HK and Taiwan. Launched the regional business in Asia and scaled it from 0 to $0.8bn USD in sales, representing 15% of Deliveroo's global P&L",
    text7: "Martin Li",
    text8: "Uber’s #1 employee in China.",
    text9:
      "Launched Uber’s business in Shanghai, Hangzhou, Shenzhen, HK, and New Zealand; After leaving Uber, Martin launched recruitment marketplace Hired.com across Asia",
    text10: "Our investors and team",
    text11: "Raised US$5 Million in funding led by Lightspeed",
    text12: "Our team brings experience from",
    text13: "Markato in the press",
  },
};

const textZh = {
  page1: {
    text1: "隆重介紹 Markato.",
    text2: "專為亞洲零售商而設的網上批發平台，輕鬆發掘數百家全球頂尖品牌",
  },
  page2: {
    text1: "全新網上批發體驗隨時隨地輕鬆選購入貨",
    text2: "精選品牌，涵蓋美容護理、家居擺設、時尚飾物品類",
    text3: "節省跨國物流及付款成本，一站式網上入貨",
    text4: "低MOQ",
    text5: "支持少量採購入貨，無負擔，更靈活",
    text6: "讓你以同樣的 budget 賺更多、試更多、賣更多",
    text7: "零售利潤率，繞過代理商，直接以最優惠批發價向品牌入貨",
    text8: "退貨保障",
    text9: "可加購60天零風險退貨服務，彈性選擇保障每張訂單",
    text10: "先買後付",
    text11: "即日$0出貨，享受零利息60天靈活付款期限，同樣入貨 budget 賣得更多",
  },
  page3: {
    text1:
      "我們致力為亞洲零售商戶搜羅全球最受歡迎的小眾品牌，並應用創新科技讓中小企也能輕鬆拓展跨國批發、採購商機。",
    text2: "Markato 團隊為您精心挑選一系列人氣品牌商品 →",
  },
  page4: {
    text1: "Markato 品牌概覽",
    text2: "商品類別:",
    text3: "品牌價值:",
    text4: "品牌風格:",
    text5: "簡介:",
    text6: "批發價:",
    text7: "建議零售價:",
  },
  page5: {
    text1: "Markato is amongst the most prominent startups in Asia",
    text2:
      "Investing in long-term partnerships is a cornerstone of our growth strategy",
    text3: "Our Founders are veteran marketplace builders",
    text4: "Brian Lo",
    text5: "Ex-GM of Deliveroo",
    text6:
      "HK and Taiwan. Launched the regional business in Asia and scaled it from 0 to $0.8bn USD in sales, representing 15% of Deliveroo's global P&L",
    text7: "Martin Li",
    text8: "Uber’s #1 employee in China.",
    text9:
      "Launched Uber’s business in Shanghai, Hangzhou, Shenzhen, HK, and New Zealand; After leaving Uber, Martin launched recruitment marketplace Hired.com across Asia",
    text10: "Our investors and team",
    text11: "Raised US$5 Million in funding led by Lightspeed",
    text12: "Our team brings experience from",
    text13: "Markato in the press",
  },
};

const textSc = {
  page1: {
    text1: "隆重介绍 Markato.",
    text2: "专为亚洲零售商而设的网上批发平台，轻松发掘数百家全球顶尖品牌",
  },
  page2: {
    text1: "全新网上批发体验随时随地轻松选购入货",
    text2: "精选品牌，涵盖美容护理、家居摆设、时尚饰物品类",
    text3: "节省跨国物流及付款成本，一站式网上入货",
    text4: "低MOQ",
    text5: "支持少量采购入货，无负担，更灵活",
    text6: "让你以同样的 budget 赚更多、试更多、卖更多",
    text7: "零售利润率，绕过代理商，直接以最优惠批发价向品牌入货",
    text8: "退货保障",
    text9: "可加购60天零风险退货服务，弹性选择保障每张订单",
    text10: "先买后付",
    text11: "即日$0出货，享受零利息60天灵活付款期限，同样入货 budget 卖得更多",
  },
  page3: {
    text1:
      "我们致力为亚洲零售商户搜罗全球最受欢迎的小众品牌，并应用创新科技让中小企也能轻松拓展跨国批发、采购商机。",
    text2: "Markato 团队为您精心挑选一系列人气品牌商品 →",
  },
  page4: {
    text1: "Markato 品牌概览",
    text2: "商品类别:",
    text3: "品牌价值:",
    text4: "品牌风格:",
    text5: "简介:",
    text6: "批发价:",
    text7: "建议零售价:",
  },
  page5: {
    text1: "Markato is amongst the most prominent startups in Asia",
    text2:
      "Investing in long-term partnerships is a cornerstone of our growth strategy",
    text3: "Our Founders are veteran marketplace builders",
    text4: "Brian Lo",
    text5: "Ex-GM of Deliveroo",
    text6:
      "HK and Taiwan. Launched the regional business in Asia and scaled it from 0 to $0.8bn USD in sales, representing 15% of Deliveroo's global P&L",
    text7: "Martin Li",
    text8: "Uber’s #1 employee in China.",
    text9:
      "Launched Uber’s business in Shanghai, Hangzhou, Shenzhen, HK, and New Zealand; After leaving Uber, Martin launched recruitment marketplace Hired.com across Asia",
    text10: "Our investors and team",
    text11: "Raised US$5 Million in funding led by Lightspeed",
    text12: "Our team brings experience from",
    text13: "Markato in the press",
  },
};

type Text = {
  page1: {
    text1: string;
    text2: string;
  };
  page2: {
    text1: string;
    text2: string;
    text3: string;
    text4: string;
    text5: string;
    text6: string;
    text7: string;
    text8: string;
    text9: string;
    text10: string;
    text11: string;
  };
  page3: {
    text1: string;
    text2: string;
  };
  page4: {
    text1: string;
    text2: string;
    text3: string;
    text4: string;
    text5: string;
    text6: string;
    text7: string;
  };
  page5: {
    text1: string;
    text2: string;
    text3: string;
    text4: string;
    text5: string;
    text6: string;
    text7: string;
    text8: string;
    text9: string;
    text10: string;
    text11: string;
    text12: string;
    text13: string;
  };
};

type Settings = Record<
  string,
  {
    currency: "HKD" | "SGD" | "MYR";
    text: Text;
  }
>;

export const settings: Settings = {
  "en-hk": {
    currency: "HKD",
    text: textEn,
  },
  "zh-hk": {
    currency: "HKD",
    text: textZh,
  },
  "en-sg": {
    currency: "SGD",
    text: textEn,
  },
  "zh-sg": {
    currency: "SGD",
    text: textSc,
  },
  "en-my": {
    currency: "MYR",
    text: textEn,
  },
  "zh-my": {
    currency: "MYR",
    text: textSc,
  },
};
