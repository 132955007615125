import {
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { TableWrapper } from "components/Table/TableWrapper";
import { Voucher } from "features/UserManagement/types";
import { SolidSearch } from "styles/icons/solid";
import { formatDate } from "utils/functions";

type VoucherTableType = {
  action: string;
} & Voucher;

type VoucherProps = {
  vouchers: Voucher[];
  isLoading: boolean;
};

export const Expired = ({ vouchers, isLoading }: VoucherProps) => {
  const [globalFilter, setGlobalFilter] = useState("");

  const { t } = useTranslation();

  const columnHelper = createColumnHelper<VoucherTableType>();

  const columns = [
    columnHelper.accessor((row) => `${row.code}`, {
      cell: ({ row }) => (
        <Text>
          <b>{row.original.code}</b>
        </Text>
      ),
      header: t("vouchers.code"),
      size: 20,
    }),
    columnHelper.accessor((row) => formatDate(row.expiryDate), {
      id: "expiryDate",
      cell: ({ row }) => <Text>{formatDate(row.original.expiryDate)}</Text>,
      header: t("vouchers.expired_date"),
      size: 10,
      sortingFn: (a, b) => {
        return (
          new Date(a.original.expiryDate).getTime() -
          new Date(b.original.expiryDate).getTime()
        );
      },
    }),
    columnHelper.accessor((row) => `${row.applicableBrand}`, {
      cell: ({ row }) => <Text>{row.original.applicableBrand}</Text>,
      header: t("vouchers.brands"),
      size: 10,
    }),
    columnHelper.accessor((row) => `${row.firstOrderOnly}`, {
      cell: ({ row }) => <Text>{row.original.firstOrderOnly}</Text>,
      header: t("vouchers.first_order"),
      size: 10,
    }),
    columnHelper.accessor((row) => `${row.appliedTo}`, {
      cell: ({ row }) => <Text>{row.original.appliedTo}</Text>,
      header: t("vouchers.applied_to"),
      size: 10,
    }),
    columnHelper.accessor((row) => `${row.discountType}`, {
      cell: ({ row }) => <Text>{row.original.discountType}</Text>,
      header: t("vouchers.discount_type"),
      size: 10,
    }),
    columnHelper.accessor((row) => `${row.discountValue}`, {
      cell: ({ row }) => <Text>{row.original.discountValue}</Text>,
      header: t("vouchers.discount_value"),
      size: 10,
    }),
    columnHelper.accessor((row) => `${row.usageQuantity}`, {
      cell: ({ row }) => <Text>{row.original.usageQuantity}</Text>,
      header: t("vouchers.usage_quantity"),
      size: 10,
    }),
    columnHelper.accessor((row) => `${row.maxUsagePerUser}`, {
      cell: ({ row }) => <Text>{row.original.maxUsagePerUser}</Text>,
      header: t("vouchers.max_usage_per_user"),
      size: 10,
    }),
    columnHelper.accessor((row) => `${row.minAmount}`, {
      cell: ({ row }) => <Text>{row.original.minAmount}</Text>,
      header: t("vouchers.min_amount"),
      size: 10,
    }),
  ];

  if (isLoading) {
    return <Spinner position="absolute" top="50%" left="50%" />;
  }

  return (
    <Flex display="column" width="100%">
      <Flex
        width="100%"
        justify="space-between"
        align="center"
        paddingRight={4}
      >
        <InputGroup mb={5} mr={5} mt={5} width="xs">
          <InputLeftElement
            pointerEvents="none"
            // eslint-disable-next-line react/no-children-prop
            children={<SolidSearch color="#B3B3B8" width={16} height={16} />}
          />
          <Input
            pl={7}
            placeholder={t("vouchers.search")}
            value={globalFilter}
            onChange={(event) => setGlobalFilter(event.target.value)}
          />
        </InputGroup>
      </Flex>
      <TableWrapper
        tableData={vouchers}
        columns={columns}
        globalFilter={globalFilter}
      />
    </Flex>
  );
};
