import { useMutation } from "@tanstack/react-query";

import { MultiplierField } from "./useMultiplierForm";
import { ErrorResponse } from "services/ErrorResponse";
import httpClient from "services/httpClient";

export const POST_MULTIPLIERS_INDIVIDUAL = "admin/msrp-multiplier";

const updateIndividualMultiplier = async (multiplierField: MultiplierField) => {
  return await httpClient.post<MultiplierField>(
    POST_MULTIPLIERS_INDIVIDUAL,
    multiplierField,
  );
};

export const useUpdateIndividualMultiplier = (
  onSuccess: () => void,
  onError: (error: ErrorResponse) => void,
) => useMutation(updateIndividualMultiplier, { onSuccess, onError });
