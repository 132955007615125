/* eslint-disable @typescript-eslint/no-misused-promises */
import { Button, Flex, Spinner, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ForcePushForm, useForcePush } from "./hooks/useForcePush";
import { useGetFinancialDetails } from "./hooks/useGetFinancialDetails";
import { useSetupDetailsTable } from "./hooks/useSetupDetailsTable";
import { useSetupTermsTable } from "./hooks/useSetupTermsTable";
import { ModalWrapper } from "components/Modal/ModalWrapper";
import { TableWrapper } from "components/Table/TableWrapper";
import { TextAreaField } from "components/TextAreaField/TextAreaField";
import { useToast } from "hooks/useToast";

type FinancialDetailsProps = {
  orderId: string;
  orderNumber: string;
};
export const FinancialDetails = ({
  orderId,
  orderNumber,
}: FinancialDetailsProps) => {
  const { t } = useTranslation();
  const toast = useToast();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    register,
    getValues,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<ForcePushForm>();

  setValue("id", orderId);

  const handleSuccess = () => {
    toast({ status: "success", title: "Order has been force pushed" });
  };

  const { data: financials, isLoading } = useGetFinancialDetails(orderId);
  const { mutateAsync: forcePush, isLoading: isMutating } =
    useForcePush(handleSuccess);

  const { detailsColumns } = useSetupDetailsTable(() => setIsModalOpen(true));
  const { termColumns, termRows } = useSetupTermsTable({
    termsData: financials?.paymentTermTransactions ?? [],
    outstanding: financials?.outstanding ?? 0,
  });

  const handleForcePush = handleSubmit(
    async () => {
      await forcePush({ id: orderId, note: getValues("note") });
      setIsModalOpen(false);
    },
    (error) => {
      console.error(error);
    },
  );

  if (isLoading) {
    return <Spinner position="absolute" top="50%" left="50%" />;
  }

  return (
    <Flex flexDirection="column" pt={5}>
      <Text
        textStyle="bodyL"
        fontWeight="bold"
        textTransform="uppercase"
        mb={5}
      >
        {t("financials.payment_details")}
      </Text>
      <TableWrapper
        columns={detailsColumns}
        tableData={financials?.transactions}
        tableProps={{ variant: "order", size: "sm" }}
        disablePagination
      />
      {termRows.length > 0 && (
        <>
          <Text
            textStyle="bodyL"
            fontWeight="bold"
            textTransform="uppercase"
            mb={5}
          >
            {t("financials.payment_terms")}
          </Text>
          <TableWrapper
            columns={termColumns}
            tableData={termRows}
            tableProps={{ variant: "order", size: "sm" }}
            showFooter
            disablePagination
          />
        </>
      )}
      <ModalWrapper
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        showHeaderDivider={true}
        modalHeader={`Force Push Payment for #${orderNumber}`}
        modalBody={
          <TextAreaField
            {...register("note", { required: "This field is required" })}
            errors={errors}
            label="Add Comment*"
            placeholder="Please provide a reason"
            size="lg"
          />
        }
        modalFooter={
          <Button size="lg" onClick={handleForcePush} disabled={isMutating}>
            Apply Force Push
          </Button>
        }
      />
    </Flex>
  );
};
