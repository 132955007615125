/* eslint-disable @tanstack/query/exhaustive-deps */
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { ReturnRequest } from "features/ReturnRequests/Submitted/hooks/useGetSubmittedRequests";
import { HISTORY_REQUESTS_KEY } from "services/ReactQueryKeys";
import httpClient from "services/httpClient";

export type HistoryRequest = {
  resolutionDate: string;
} & ReturnRequest;
export const GET_HISTORY_REQUESTS = "/admin/return-requests/history";

export const useGetHistoryRequests = (): UseQueryResult<HistoryRequest[]> =>
  useQuery({
    queryKey: [HISTORY_REQUESTS_KEY],
    queryFn: async () => {
      const { data: requests } = await httpClient.get<HistoryRequest[]>(
        GET_HISTORY_REQUESTS,
      );
      return requests;
    },
  });
