/* eslint-disable @tanstack/query/exhaustive-deps */
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { BrandRequest } from "../types";
import { BRAND_REGISTRATION_REQUESTS_KEY } from "services/ReactQueryKeys";
import httpClient from "services/httpClient";

export const GET_ALL_BRAND_REGISTRATION_REQUESTS_URL = "/admin/brand-requests";

export const useGetAllBrandRegistrationRequests = (): UseQueryResult<
  BrandRequest[]
> =>
  useQuery({
    queryKey: [BRAND_REGISTRATION_REQUESTS_KEY],
    queryFn: async () => {
      const { data: brandRequests } = await httpClient.get<BrandRequest>(
        GET_ALL_BRAND_REGISTRATION_REQUESTS_URL,
      );
      return brandRequests;
    },
  });
