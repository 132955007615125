import { useMutation } from "@tanstack/react-query";

import httpClient from "services/httpClient";

export const POST_ADMIN_IMPERSONATE = "/admin/auth/impersonate";

type AdminImpersonate = {
  id: string;
  group: string;
  name: string;
};
const postAdminImpersonate = async ({ id, group, name }: AdminImpersonate) => {
  return await httpClient.post(
    `${POST_ADMIN_IMPERSONATE}`,
    {
      idToImpersonate: id,
      role: group,
      name: name,
    },
    { withCredentials: true },
  );
};

export const usePostAdminImpersonate = () => useMutation(postAdminImpersonate);
