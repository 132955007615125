import {
  Divider,
  Flex,
  Heading,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { BrandOverview } from "./BrandOverview";
import { DiscardChangesModal } from "./DiscardChangesModal";
import { HeaderActions } from "./HeaderActions";
import { useGetReturnRequestDetails } from "./hooks/useGetReturnRequestDetails";
import { useReturnRequestDetails } from "./hooks/useReturnRequestDetails";
import { Button } from "components/Button/Button";
import { Status } from "components/Status/Status";
import {
  capitalizeFirstLetter,
  convertToStatusVariant,
} from "features/UserManagement/utils";
import { LightArrowLeft } from "styles/icons/light";

export const ReturnRequestDetails = () => {
  const { orderId } = useParams();
  const { data: returnRequest, isLoading } = useGetReturnRequestDetails(
    orderId ?? "",
  );

  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();

  const { fields, register, errors, handleApply, resetModal, isChanged } =
    useReturnRequestDetails({ onCloseModal, returnRequest });

  const {
    onOpen: openGoBackModal,
    onClose: closeGoBackModal,
    isOpen: isOpenGoBackModal,
  } = useDisclosure();

  if (isLoading || returnRequest === undefined) {
    return <Spinner position="absolute" top="50%" left="50%" />;
  }

  return (
    <Flex flexDir="column">
      <Flex mb={7} mt={8} ml={8} justifyContent="space-between">
        <Flex>
          <Button
            variant="ghost"
            colorScheme="grey"
            mainIcon={LightArrowLeft}
            aria-label="backButton"
            data-test-id="backToPayouts"
            alignSelf="center"
            mr={4}
            onClick={() => {
              if (isChanged) {
                openGoBackModal();
              } else {
                window.history.back();
              }
            }}
          />
          <Heading as="h1" size="h1" fontWeight="bold">
            {`#${returnRequest.orderIdName}`}
          </Heading>
          <Status
            display="flex"
            variant={convertToStatusVariant(returnRequest.status)}
            text={capitalizeFirstLetter(
              returnRequest.status === "PARTIAL_APPROVED"
                ? "PARTIALLY_APPROVED"
                : returnRequest.status,
            )}
            ml={3.5}
            h="22px"
            alignSelf="center"
          />
        </Flex>
        <HeaderActions returnRequest={returnRequest} fields={fields} />
      </Flex>
      <Divider h="2px" />
      <BrandOverview
        fields={fields}
        register={register}
        errors={errors}
        handleApply={handleApply}
        returnRequest={returnRequest}
        isOpenModal={isOpenModal}
        onOpenModal={onOpenModal}
        onCloseModal={onCloseModal}
        resetModal={resetModal}
      />
      <DiscardChangesModal
        onCloseModal={closeGoBackModal}
        isOpenModal={isOpenGoBackModal}
      />
    </Flex>
  );
};
