import { useMutation } from "@tanstack/react-query";

import httpClient from "services/httpClient";

export const DELETE_ADMIN_BY_ID_URL = "/admin/accounts/";

const deleteAdminAccount = async (id: string) => {
  return await httpClient.delete(`${DELETE_ADMIN_BY_ID_URL}${id}`);
};
export const useDeleteAdminById = () => useMutation(deleteAdminAccount);
