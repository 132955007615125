/* eslint-disable @typescript-eslint/no-misused-promises */
import { Flex, useDisclosure } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { BrandDetailsType } from "../types";
import BrandNameUpdateForm from "./components/BrandNameUpdateForm/BrandNameUpdateForm";
import { useChangeEmailForm } from "./hooks/email/useChangeEmailForm";
import { useBrandFinancialSettingsForm } from "./hooks/financials/useBrandFinancialSettingsForm";
import { useChangePasswordForm } from "./hooks/useChangePasswordForm";
import { Button } from "components/Button/Button";
import { InputField } from "components/InputField/InputField";
import { ModalWrapper } from "components/Modal/ModalWrapper";
import { PanelForm } from "components/PanelForm/PanelForm";

type FinancialSettingsFormProps = {
  brandId: string;
};

type BrandSettingsProps = {
  brandAccount: BrandDetailsType;
};

// Returns null or a number
const setCommissionValue = (v: string) =>
  v === "" ? null : parseInt(String(v));

const FinancialSettingsForm = ({ brandId }: FinancialSettingsFormProps) => {
  const { t } = useTranslation();
  const { register, errors, onSubmit } = useBrandFinancialSettingsForm({
    brandId,
  });

  return (
    <PanelForm title={t("settings.order_commissions")}>
      <InputField
        errors={errors}
        label={t("settings.order_commission_placeholder")}
        placeholder={""}
        rightAddon="%"
        type="number"
        w="450px"
        {...register("firstOrderCommission", {
          required: false,
          min: -100,
          max: 100,
          setValueAs: setCommissionValue,
        })}
        mb={5}
      />
      <InputField
        errors={errors}
        label={t("settings.commission")}
        placeholder={""}
        rightAddon="%"
        type="number"
        w="450px"
        {...register("commission", {
          required: false,
          min: -100,
          max: 100,
          setValueAs: setCommissionValue,
        })}
      />

      <Button size="lg" w="fit-content" mt={5} onClick={onSubmit}>
        {t("Save changes")}
      </Button>
    </PanelForm>
  );
};

export const BrandSettings = ({ brandAccount }: BrandSettingsProps) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const brandId = brandAccount.id;
  const { register, errors, onSubmit } = useChangePasswordForm({ brandId });
  const {
    register: email_register,
    errors: email_errors,
    onSubmit: email_onSubmit,
    isValid: email_isValid,
    getValues: getEmailFormValues,
  } = useChangeEmailForm({ brandId, onClose });

  const requiredMessage = "This field is required";
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const emailMismatchPattern = "Please input a valid email address";

  return (
    <>
      <Flex flexDir="column" padding={8} gap={5} width="100%">
        <BrandNameUpdateForm brandAccount={brandAccount} />
        <PanelForm title={t("brand_settings.email_update")} w="100%">
          <Flex mt={2} mb={5} w="496px">
            <InputField
              errors={email_errors}
              label={t("brand_settings.new_email")}
              placeholder={t("brand_settings.new_email")}
              type="email"
              {...email_register("newEmail", {
                required: requiredMessage,
                pattern: {
                  value: emailRegex,
                  message: emailMismatchPattern,
                },
              })}
            />
          </Flex>
          <Flex w="496px">
            <InputField
              errors={email_errors}
              label={t("brand_settings.confirm_new_email")}
              placeholder={t("brand_settings.confirm_new_email")}
              type="email"
              {...email_register("confirmEmail", {
                required: requiredMessage,
                pattern: {
                  value: emailRegex,
                  message: emailMismatchPattern,
                },
                validate: (value) => {
                  return (
                    value === getEmailFormValues("newEmail") ||
                    t("brand_settings.confirm_new_brand_email_error")
                  );
                },
              })}
            />
          </Flex>
          <Button
            size="lg"
            w="fit-content"
            mt={5}
            onClick={onOpen}
            disabled={!email_isValid}
          >
            {t("brand_settings.change_email")}
          </Button>
        </PanelForm>
        <PanelForm title={t("brand_settings.password_update")} w="100%">
          <Flex mt={2} mb={5} w="496px">
            <InputField
              errors={errors}
              label={t("brand_settings.new_password")}
              placeholder={t("brand_settings.create_password")}
              type="password"
              canToggleVisibility
              {...register("newPassword", {
                required: requiredMessage,
              })}
            />
          </Flex>
          <Flex w="496px">
            <InputField
              errors={errors}
              label={t("brand_settings.confirm_new_password")}
              placeholder={t("brand_settings.repeat_password")}
              type="password"
              canToggleVisibility
              {...register("confirmPassword", {
                required: requiredMessage,
              })}
            />
          </Flex>
          <Button size="lg" w="fit-content" mt={5} onClick={onSubmit}>
            {t("brand_settings.change_password")}
          </Button>
        </PanelForm>
        <FinancialSettingsForm brandId={brandId} />
      </Flex>
      <ModalWrapper
        modalHeader={t("brand_settings.confirm_change_email")}
        modalBody={
          <Flex gap={5}>
            <Button w={100} onClick={email_onSubmit}>
              {t("confirm")}
            </Button>
            <Button w={100} onClick={onClose}>
              {t("cancel")}
            </Button>
          </Flex>
        }
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};
