/* eslint-disable @tanstack/query/exhaustive-deps */
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { Market } from "./useSettingsForm";
import { SETTINGS_KEY } from "services/ReactQueryKeys";
import httpClient from "services/httpClient";

const GET_SETTINGS_URL = "/admin/settings";

export type CompanyDetails = {
  companyName: string;
  companyCountry: string;
  companyAddress: string;
  accountName: string;
  bankAccountNumber: string;
  bankCode: string;
  swiftCode: string;
};

export type Settings = {
  minimumOrderValue: number;
  firstOrderCommission: number;
  commission: number;
  bestsellerProductLimit: number;
  paymentTermsDelay: number;
  newProductDurationInDays: number;
  companyDetails: CompanyDetails;
  retailerCountrySettings: Market[];
  payoutCommissionRate: number;
  delayPayoutDays: number;
  returnEligibilityDays: number;
};

export const useGetSettings = (): UseQueryResult<Settings> =>
  useQuery({
    queryKey: [SETTINGS_KEY],
    queryFn: async () => {
      const { data: settings } = await httpClient.get<Settings>(
        GET_SETTINGS_URL,
      );
      return settings;
    },
    retry: false,
  });
