import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { buttonStyle, formStyle, headingStyle } from "../ForgotPassword.style";
import { useForgotPasswordForm } from "../hooks/useForgotPasswordForm";
import { EnterUsernameProps } from "../types";
import { Button } from "components/Button/Button";
import { InputField } from "components/InputField/InputField";

export const EnterUsername = ({
  username,
  setStep,
  setUsername,
}: EnterUsernameProps) => {
  const { t } = useTranslation();

  const { register, getValues, onSubmit, errors, isValid, isLoading } =
    useForgotPasswordForm(username);

  const handleSubmit = () => {
    void onSubmit();
    setUsername(getValues().username.trim());
    setStep((prevStep) => prevStep + 1);
  };

  return (
    <>
      <Box>
        <Heading {...headingStyle}>{t("Enter your username")}</Heading>
        <Text lineHeight="22px" pb={5}>
          {t(
            "Please enter your email or phone number and we will send you instructions to reset your password.",
          )}
        </Text>
      </Box>
      <form style={{ height: "100%" }} onSubmit={handleSubmit}>
        <Flex {...formStyle} justifyContent="space-between">
          <InputField
            label={t("Username")}
            placeholder={t("Enter your username")}
            errors={errors}
            {...register("username", {
              required: "Username is required",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format",
              },
            })}
          />
          <Button
            type="submit"
            disabled={!isValid}
            isLoading={isLoading}
            {...buttonStyle}
          >
            {t("Next")}
          </Button>
        </Flex>
      </form>
    </>
  );
};
