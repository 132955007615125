import { useMutation } from "@tanstack/react-query";

import { GET_RETAILER_REQEUSTS_QK } from "features/RetailerRequests/hooks/useGetRetailerRequests";
import httpClient from "services/httpClient";
import { queryClient } from "services/queryClient";

export const POST_REJECT_RETAILER_REQUEST =
  "/admin/payment-terms-requests/reject/";
export const POST_APPROVE_RETAILER_REQUEST =
  "/admin/payment-terms-requests/approve/";

const putApproveRequest = async (requestId: string) =>
  httpClient.put(POST_APPROVE_RETAILER_REQUEST + requestId);

export const useApprovePaymentTermsRequest = (
  id: string,
  onSuccess: () => void,
) =>
  useMutation(() => putApproveRequest(id), {
    onSuccess: () => {
      void queryClient.fetchQuery({
        queryKey: [GET_RETAILER_REQEUSTS_QK],
      });
      onSuccess();
    },
  });

const putRejectRequest = async ({
  requestId,
  reason,
}: {
  requestId: string;
  reason: string;
}) =>
  httpClient.put(POST_REJECT_RETAILER_REQUEST + requestId, {
    rejectionReason: reason,
  });

export const useRejectPaymentTermsRequest = (onSuccess: () => void) =>
  useMutation(putRejectRequest, {
    onSuccess: () => {
      void queryClient.fetchQuery({
        queryKey: [GET_RETAILER_REQEUSTS_QK],
      });
      onSuccess();
    },
  });
