import { UseQueryResult, useQuery } from "@tanstack/react-query";

import httpClient from "services/httpClient";

export const GET_COUNTRIES = "/retailers/countries";

export type Country = {
  id: string;
  name: string;
  countryCode: string;
  currencyCode: string;
};

export const useRetailerCountries = (): UseQueryResult<Country[]> =>
  useQuery({
    queryKey: [GET_COUNTRIES],
    queryFn: async () => {
      const { data: countries } = await httpClient.get<Country>(GET_COUNTRIES);
      return countries;
    },
  });
