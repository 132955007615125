import { BoxProps, FormLabelProps, IconProps } from "@chakra-ui/react";

export const boxWrapperStyle: BoxProps = {
  display: "flex",
  alignItems: "center",
};
export const formLabelStyle: FormLabelProps = {
  textStyle: "bodyS",
  color: "grey.900",
  fontWeight: "medium",
  margin: 0,
};
export const iconStyle: IconProps = {
  color: "red.500",
  marginBottom: 1,
  marginLeft: 1,
  width: 1.5,
  height: 1.5,
};
