import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { usePutChangePassword } from "./usePutChangePassword";
import { useToast } from "hooks/useToast";
import { ErrorResponse } from "services/ErrorResponse";
import { getErrorCodes } from "services/httpClient";

export type ChangePasswordFields = {
  newPassword: string;
  confirmPassword: string;
  brandId: string;
};

type UseChangePasswordFormProps = {
  brandId: string;
};
export const useChangePasswordForm = ({
  brandId,
}: UseChangePasswordFormProps) => {
  const { t } = useTranslation();
  const {
    control,
    register,
    formState: { errors, isDirty, isValid },
    getValues,
    handleSubmit,
    reset,
  } = useForm<ChangePasswordFields>({
    defaultValues: {
      brandId: brandId,
    },
  });
  const toast = useToast();

  const onSuccess = () => {
    toast({ description: t("brand_settings.password_success_change") });
    reset({
      brandId: brandId,
      newPassword: "",
      confirmPassword: "",
    });
  };

  const onError = (error: ErrorResponse) =>
    toast({ status: "error", description: t(getErrorCodes(error)) });

  const { mutateAsync: putBrandPassword } = usePutChangePassword(
    onSuccess,
    onError,
  );

  const isFormValid = useMemo(() => {
    return !isDirty || !isValid;
  }, [isDirty, isValid]);

  const onSubmit = handleSubmit(async () => {
    await putBrandPassword(getValues());
  });

  return {
    control,
    register,
    errors,
    isFormValid,
    getValues,
    onSubmit,
  };
};
