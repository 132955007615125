/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Button, Flex, Text } from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useForceCompleteSubOrder } from "../hooks/useForceCompleteSubOrder";
import { GET_ORDER_DETAILS_QK, SubOrder } from "../hooks/useGetOrderById";
import { ModalWrapper } from "components/Modal/ModalWrapper";
import { useSimpleToast } from "hooks/useSimpleToast";
import { ErrorResponse } from "services/ErrorResponse";
import { getErrorCodes } from "services/httpClient";
import { queryClient } from "services/queryClient";

type ForceCompleteSubOrderModalProps = {
  isOpen: boolean;
  onClose: () => void;
  subOrder: SubOrder;
  onLoading?: (isLoading: boolean) => void;
};

export const ForceCompleteSubOrderModal = ({
  isOpen,
  onClose,
  subOrder,
  onLoading,
}: ForceCompleteSubOrderModalProps) => {
  const { t } = useTranslation();
  const toast = useSimpleToast();
  const [isLoading, setIsLoading] = useState(false);
  const refetchCount = useRef(0);
  const refetchIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const onSuccess = () => {
    // The endpoint has returned a success response, but there might be a delay in updating the suborder status.
    // Therefore, we need to implement the refetch logic and consider it as a success when the suborder status changes to "COMPLETED".
    refetchSubOrder();
  };

  const onError = (error: ErrorResponse) => {
    const errorCodes = getErrorCodes(error);
    toast({ status: "error", description: t(errorCodes[0]) });
    setIsLoading(false);
    onClose();
  };

  const { mutateAsync: forceCompleteSubOrder, isLoading: isMutating } =
    useForceCompleteSubOrder(onSuccess, onError);

  const resetRefetchInterval = () => {
    if (refetchIntervalRef.current) {
      clearInterval(refetchIntervalRef.current);
    }
    refetchCount.current = 0;
  };

  const refetchSubOrder = (maxRefetchCount = 10) => {
    refetchIntervalRef.current = setInterval(() => {
      refetchCount.current = refetchCount.current + 1;

      void queryClient.invalidateQueries({
        queryKey: [GET_ORDER_DETAILS_QK],
      });

      if (refetchCount.current >= maxRefetchCount) {
        resetRefetchInterval();
      }
    }, 1000);
  };

  const onForceCompleteSubOrder = async () => {
    await forceCompleteSubOrder(subOrder.id);
    setIsLoading(true);
  };

  const onOrderCompleted = useCallback(() => {
    toast({
      position: "top",
      description: `${t("order.force_complete_suborder_success", {
        suborderNumber: subOrder.orderNumber,
      })}`,
    });
    resetRefetchInterval();
    setIsLoading(false);
    onClose();
  }, [onClose, subOrder.orderNumber, t, toast]);

  useEffect(() => {
    onLoading?.(isLoading);
  }, [isLoading, onLoading]);

  useEffect(() => {
    return () => {
      if (refetchIntervalRef.current) {
        clearInterval(refetchIntervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (subOrder.status === "COMPLETED" && refetchIntervalRef.current) {
      onOrderCompleted();
    }
  }, [subOrder.status, onOrderCompleted]);

  return (
    <ModalWrapper
      showCloseButton={!isLoading}
      modalProps={{
        size: "l",
        isOpen,
        onClose,
        children: undefined,
        closeOnOverlayClick: !isLoading,
      }}
      isOpen={isOpen}
      onClose={onClose}
      modalHeader={
        <Flex flexDir="column" textStyle="h3" fontWeight="bold">
          <Text>{t("order.force_complete_suborder")}</Text>
          <Text>{`#${subOrder.orderNumber}`}</Text>
        </Flex>
      }
      modalFooter={
        <Button
          isLoading={isLoading}
          type="submit"
          variant="solid"
          size="lg"
          ml={3}
          disabled={isMutating}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={onForceCompleteSubOrder}
        >
          {t("order.force_complete_suborder")}
        </Button>
      }
    />
  );
};
