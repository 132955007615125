import { useMutation } from "@tanstack/react-query";

import { ChangePasswordFields } from "./useChangePasswordForm";
import { ErrorResponse } from "services/ErrorResponse";
import httpClient from "services/httpClient";

export const PUT_CHANGE_BRAND_PASSWORD = "/admin/brands/";

const putBrandPassword = async (form: ChangePasswordFields) => {
  return await httpClient.put(
    `${PUT_CHANGE_BRAND_PASSWORD}${form.brandId}/change-password`,
    {
      newPassword: form.newPassword,
      confirmPassword: form.confirmPassword,
    },
  );
};

export const usePutChangePassword = (
  onSuccess: () => void,
  onError: (axiosError: ErrorResponse) => void,
) =>
  useMutation(putBrandPassword, {
    onSuccess,
    onError,
  });
