import { OrderTable } from "./OrderTable";
import { useGetOrderHistory } from "./hooks/useGetOrders";

export const OrderHistory = () => {
  const { data: orderHistory, isLoading } = useGetOrderHistory();

  return (
    <OrderTable orders={orderHistory} isHistory={true} isLoading={isLoading} />
  );
};
