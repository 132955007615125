import { useMutation } from "@tanstack/react-query";

import {
  ExportProductFormFields,
  ExportProductReturnType,
} from "./usePostExportProductsForm";
import { ErrorResponse } from "services/ErrorResponse";
import httpClient from "services/httpClient";

export const POST_EXPORT_PRODUCTS = "/admin/support/export-products.csv";

const postProductExport = async (form: ExportProductFormFields) => {
  return await httpClient.post(POST_EXPORT_PRODUCTS, {
    brands: form.brands,
    categories: form.categories,
    keywords: form.keywords,
    minWholesalePrice: Number(form.minWholesalePrice),
    maxWholesalePrice: Number(form.minWholesalePrice),
    minRetailPrice: Number(form.minRetailPrice),
    maxRetailPrice: Number(form.maxRetailPrice),
    margin: Number(form.margin),
  });
};

export const usePostProductExport = (
  onSuccess: (response: ExportProductReturnType) => void,
  onError: (data: ErrorResponse) => void,
) =>
  useMutation(postProductExport, {
    onSuccess,
    onError,
  });
