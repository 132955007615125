/* eslint-disable @typescript-eslint/restrict-plus-operands */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/non-nullable-type-assertion-style */
// TO-DO: Refactor to make code sharable with ui repo
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

import { RetailerDetails, SubOrder } from "../hooks/useGetOrderById";
import { markato_logo_uri } from "./constants";

export function downloadInvoice(
  order: SubOrder,
  retailer: RetailerDetails,
  creationDate: string,
) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const doc = new jsPDF();

  // Styles
  const styles = {
    color: {
      primaryColor: "#80A4B4",
      black: "#202020",
      grey: "#808080",
    },
    fontSize: {
      h1: 20,
      h2: 12,
      p: 10,
    },
  };

  const content = {
    key: {
      markato_company_name: "Markato HK Limited",
      markato_address:
        "20/F Metro South Tower 1,\n39 Wong Chuk Hang Road,\nHong Kong",
      packing_list: "Packing List",
      packing_list_issued: "Packing List issued for and on behalf of:",
      date: "Date",
      suborder_no: "Suborder No.",
      billing_address: "Billing Address",
      shipping_address: "Shipping Address",
      additional_notes: "Additional Notes",
    },
    value: {
      brand_name: order.brand.storeName,
      creation_date: creationDate.slice(0, 10),
      suborder_number: order.orderNumber,
      shipping_address:
        `${retailer.firstName} ${retailer.lastName}\n` +
        `${retailer.deliveryAddress.address}` +
        `\n` +
        `${retailer.deliveryAddress.address2 ?? ""}` +
        `\n` +
        `${retailer.deliveryAddress.city}, ${retailer.deliveryAddress.state} ${retailer.deliveryAddress.postCode}`,
      billing_address:
        `${retailer.firstName} ${retailer.lastName}\n` +
        `${retailer.billingAddress.address}` +
        `\n` +
        `${retailer.billingAddress.address2 ?? ""}` +
        `\n` +
        `${retailer.billingAddress.city}, ${retailer.billingAddress.state} ${retailer.billingAddress.postCode}`,
      additional_notes: order.additionalNotes,
    },
  };

  // generate main table headers and content
  const tableHeaders = [
    "SKU ID",
    "Product Name",
    "Units per Case",
    "Ordered Quantity",
    "Total Units",
  ];
  const orderContents = () => {
    const orderArr: (string | number)[][] = [];

    const products = order.products;
    // eslint-disable-next-line array-callback-return
    products.map((product) => {
      orderArr.push([
        product.sku,
        `${product.name} ${
          product.variantName ? `- ${product.variantName}` : ""
        }`,
        product.caseQty,
        product.qty,
        product.caseQty * product.qty,
      ]);
    });

    return orderArr;
  };

  // Markato header
  autoTable(doc, {
    didDrawCell: function (data) {
      if (data.row.index === 0) {
        doc.setDrawColor(128, 164, 180);
        doc.setLineWidth(0.8);
        doc.line(
          data.cell.x + data.cell.width,
          data.cell.y,
          data.cell.x,
          data.cell.y,
        );
      }

      if (
        data.section === "body" &&
        data.column.index === 2 &&
        data.row.index === 0
      ) {
        const xOffset = 44.2;
        const yOffset = 5;
        const image = {
          width: 50,
          height: 15,
          URI: markato_logo_uri,
        };

        doc.addImage(
          image.URI,
          "PNG",
          data.cell.x - xOffset,
          data.cell.y + yOffset,
          image.width,
          image.height,
        );
      }
    },
    body: [
      [
        {
          content: content.key.markato_company_name,
          rowSpan: 1,
          styles: {
            fontSize: styles.fontSize.h2,
            textColor: styles.color.primaryColor,
            minCellHeight: 10,
            valign: "bottom",
          },
        },
        { content: "" },
        { content: "" },
      ],
      [
        {
          content: content.key.markato_address,
          rowSpan: 1,
          styles: {
            fontSize: styles.fontSize.p,
            textColor: styles.color.grey,
          },
        },
      ],
    ],
    theme: "plain",
  });

  // Shipping details
  autoTable(doc, {
    body: [
      [
        {
          content: content.key.packing_list,
          styles: {
            fontSize: styles.fontSize.h1,
            textColor: styles.color.primaryColor,
          },
        },
        { content: "" },
        { content: "" },
      ],
      [
        {
          content: content.key.packing_list_issued,
        },
        {
          content: content.key.date,
        },
        {
          content: content.key.suborder_no,
        },
      ],
      [
        {
          content: content.value.brand_name,
          styles: {
            fontStyle: "normal",
          },
        },
        {
          content: content.value.creation_date,
          styles: {
            fontStyle: "normal",
          },
        },
        {
          content: content.value.suborder_number,
          styles: {
            fontStyle: "normal",
          },
        },
      ],
      [
        {
          content: content.key.billing_address,
          styles: {
            textColor: styles.color.black,
          },
        },
        {
          content: content.key.shipping_address,
          styles: {
            textColor: styles.color.black,
          },
        },
      ],
      [
        {
          content: content.value.billing_address,
          styles: {
            fontStyle: "normal",
            textColor: styles.color.grey,
          },
        },
        {
          content: content.value.shipping_address,
          styles: {
            fontStyle: "normal",
            textColor: styles.color.grey,
          },
        },
      ],
    ],
    styles: {
      fontStyle: "bold",
      fontSize: styles.fontSize.p,
    },
    theme: "plain",
  });

  const bodyData = orderContents();
  autoTable(doc, {
    willDrawCell: function (data) {
      if (data.row.section === "head") {
        doc.setDrawColor(128, 164, 180);
        doc.setLineWidth(0.5);
        doc.line(
          data.cell.x + data.cell.width,
          data.cell.y,
          data.cell.x,
          data.cell.y,
        );
      }
    },
    head: [tableHeaders],
    headStyles: { minCellHeight: 10, valign: "bottom" },
    body: bodyData,
    bodyStyles: { textColor: styles.color.grey },
    theme: "plain",
  });

  order.additionalNotes &&
    autoTable(doc, {
      body: [
        [
          {
            content: content.key.additional_notes,
            styles: {
              fontSize: styles.fontSize.h2,
              fontStyle: "bold",
              textColor: styles.color.primaryColor,
            },
          },
          { content: "" },
        ],
        [{ content: content.value.additional_notes }, { content: "" }],
      ],
      bodyStyles: { minCellHeight: 20, valign: "bottom" },
      theme: "plain",
    });

  return setTimeout(() => {
    doc.save(`invoice-${order.orderNumber}`);
  }, 1);
}
