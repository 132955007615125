import { Box, Flex, Heading } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { BusinessRegistrationTableOverview } from "./BusinessRegistrationTableOverview";
import { PaymentTermsTableOverview } from "./PaymentTermsTableOverview/PaymentTermsTableOverview";
import { TabData, TabWrapper } from "components/Tabs/TabWrapper";

export const RetailerRequests = () => {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState<number>(
    parseInt(window.location.hash.replace("#", "") || "0"),
  );

  const tabsData: TabData[] = [
    {
      title: t("retailer_requests.payment_terms"),
      content: <PaymentTermsTableOverview />,
    },
    {
      title: t("retailer_requests.business_registration"),
      content: <BusinessRegistrationTableOverview />,
    },
  ];

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
    window.location.hash = `${index}`;
  };
  return (
    <Box margin="auto">
      <Flex display="column">
        <Heading as="h1" size="h1" fontWeight="bold" mb={2} mt={5} ml={10}>
          {t("Retailer Requests")}
        </Heading>
        <TabWrapper
          tabListProps={{ pl: 10 }}
          tabPanelsProps={{ pl: 2 }}
          tabsData={tabsData}
          tabIndex={tabIndex}
          handleTabsChange={handleTabsChange}
        />
      </Flex>
    </Box>
  );
};
