import { Flex, Spinner } from "@chakra-ui/react";
import { Control, FieldErrorsImpl, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { AddOptionFormFields } from "./useAddOptionForm";
import { AutocompleteField } from "components/AutocompleteField/AutocompleteField";
import { InputField } from "components/InputField/InputField";
import { TextAreaField } from "components/TextAreaField/TextAreaField";

export type FinancialOption = {
  label: string;
  value: string;
};

type AddOptionFormProps = {
  control: Control<AddOptionFormFields>;
  register: UseFormRegister<AddOptionFormFields>;
  errors: Partial<
    FieldErrorsImpl<{
      type: FinancialOption;
      comment: string;
      amount: string;
    }>
  >;
  isAddLoading: boolean;
  currency: string;
};

const options: FinancialOption[] = [
  { label: "Correction", value: "correction" },
  { label: "Referral", value: "referral" },
  { label: "Fine", value: "fine" },
  { label: "Warranty", value: "warranty" },
];
export const AddOptionForm = ({
  control,
  register,
  errors,
  isAddLoading,
  currency,
}: AddOptionFormProps) => {
  const { t } = useTranslation();
  const requiredMessage = t("order.required");
  return (
    <Flex flexDir="column">
      <Flex gap={5} mb={5}>
        <AutocompleteField
          name="type"
          label={t("order.select_type")}
          placeholder={t("order.select_option_type")}
          control={control as unknown as Control}
          rules={{ required: requiredMessage }}
          options={options}
          closeMenuOnSelect
        />
        <InputField
          size="md"
          type="number"
          errors={errors}
          {...register("amount", {
            required: requiredMessage,
            validate: (value) => !!value.trim(),
          })}
          isRequired
          label={t("order.add_amount")}
          placeholder={Intl.NumberFormat("en-US", {
            style: "currency",
            currency,
          }).format(0)}
          style={{ height: "32px" }}
        />
      </Flex>

      <TextAreaField
        label={t("order.add_comment")}
        placeholder={t("order.add_description")}
        isRequired
        {...register("comment", {
          required: requiredMessage,
          validate: (value) => !!value.trim(),
        })}
        errors={errors}
        minH="132px"
        resize="none"
      />
      {isAddLoading && <Spinner position="absolute" top="50%" left="50%" />}
    </Flex>
  );
};
