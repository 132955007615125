/* eslint-disable @tanstack/query/exhaustive-deps */
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import httpClient from "services/httpClient";

export const GET_PAYMENT_TERMS_REQUEST = "/admin/payment-terms-requests/";

type RetailerDetails = {
  name: string;
  email: string;
  website: string;
  country: string;
  phoneNumber: string;
};

type RequestDetails = {
  limit: string;
  additionalNotes: string;
};

type Document = {
  url: string;
  name: string;
  creationDate: string;
};

export type SingleRetailerRequest = {
  id: string;
  name: string;
  retailerDetails: RetailerDetails;
  requestDetails: RequestDetails;
  uploadedDocuments: Document[];
  previousDocuments: Document[];
};

export const useGetRetailerRequestById = (
  id: string,
): UseQueryResult<SingleRetailerRequest> =>
  useQuery({
    queryKey: [GET_PAYMENT_TERMS_REQUEST],
    queryFn: async () => {
      const { data: retailerRequest } =
        await httpClient.get<SingleRetailerRequest>(
          GET_PAYMENT_TERMS_REQUEST + id,
        );
      return retailerRequest;
    },
  });
