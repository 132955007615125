import { StatusVariant } from "components/Status/Status";

export const convertToAccessFormat = (
  property: string,
  access: string | null,
) => {
  switch (access) {
    case "admin":
      return `${property}Admin`;
    case "edit":
      return `${property}Edit`;
    case "view":
      return `${property}View`;
    default:
      return null;
  }
};

export const convertFromAccessFormat = (property: string) => {
  if (property.includes("View")) {
    return "view";
  } else if (property.includes("Edit")) {
    return "edit";
  } else if (property.includes("Admin")) {
    return "admin";
  }
  return "none";
};

export const convertToStatusVariant = (status: string) => {
  switch (status) {
    case "PENDING":
    case "PENDING_REVIEW":
    case "PARTIALLY_APPROVED":
    case "PARTIAL_APPROVED":
      return "pending" as StatusVariant;
    case "INACTIVE":
    case "REJECTED":
    case "CANCELLED":
    case "LOST":
    case "DECLINED":
      return "error" as StatusVariant;
    case "ACTIVE":
    case "COMPLETED":
    case "APPROVED":
    case "IN_PROGRESS":
    case "IN_DELIVERY":
    default:
      return "done" as StatusVariant;
  }
};

export const capitalizeFirstLetter = (word: string): string => {
  const words = word.split("_").join(" ").toLocaleLowerCase().split(" ");
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }
  return words.join(" ");
};

export const normalizeText = (text: string): string => {
  return text.trim().toLowerCase();
};
