/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable @typescript-eslint/no-unnecessary-condition */

/* eslint-disable @typescript-eslint/restrict-template-expressions */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-misused-promises */

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Card,
  CardBody,
  Flex,
  Spinner,
  Text,
  Textarea,
} from "@chakra-ui/react";
import axios from "axios";
import { useState } from "react";

import KeywordParser from "../Parser/keyword";
import SqlParser from "../Parser/sql";
import { keyword_search_prompt } from "./prompts";
import "./style.scss";

export const Recommendation = () => {
  const [result, setResult] = useState<React.ReactElement>();
  const [isLoading, setIsLoading] = useState<boolean>();

  const talkToLlm = async (input: string): Promise<string> => {
    // TO-DO: Move this to Lambda to hide the key
    const talkToOpenAI = async (input: string) => {
      try {
        const data = JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "system",
              content: keyword_search_prompt,
            },
            {
              role: "user",
              content: input,
            },
          ],
        });

        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: "https://api.openai.com/v1/chat/completions",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer sk-${atob(
              "cWlQS0FVdlFBQzEyZUFZT2FPVkxUM0JsYmtGSlBPaVY0QVRyNnQwUUVXaE5UbVI=",
            )}p`,
          },
          data: data,
        };

        const response = await axios.request(config);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };

    const output = await talkToOpenAI(input);

    return new Promise((resolve) => {
      // TO-DO: Remove SQL queries once GPT can handle them
      resolve(`${output.choices[0].message.content}`);
      // resolve(`
      // ${output.choices[0].message.content}

      // \`\`\`SELECT * FROM public."Products" WHERE "Best Seller" = true LIMIT 10;\`\`\`

      // `);
    });
  };

  const removeMarkdownSyntax = (text: string): string => {
    const markdownRegex = /```([\s\S]+?)```/g;
    return text.replace(markdownRegex, "");
  };

  const handleLlmResult = (llmResult: string) => {
    const markdownRegex = /```([\s\S]+?)```/g;
    const markdownArray = llmResult.match(markdownRegex) ?? [];

    const sqlOutput = markdownArray.map((query, index) => (
      <SqlParser
        query={query
          .replaceAll("`", "")
          .replaceAll("sql", "")
          .replaceAll("\n", " ")
          .replaceAll("Products", `Products`)}
        key={index}
      ></SqlParser>
    ));

    const keywordRegex = /\*\*\*([\s\S]+?)\*\*\*/g;
    const keywordArray = llmResult.match(keywordRegex) ?? [];
    const keywordOutput = keywordArray.map((keyword, index) => (
      <KeywordParser
        query={keyword
          .replaceAll("`", "")
          .replaceAll("*", "")
          .replaceAll("\n", " ")
          .replaceAll("Products", `Products`)}
        key={index}
      ></KeywordParser>
    ));
    const output = (
      <div>
        {llmResult.trim() && (
          <Box mb={3}>{removeMarkdownSyntax(llmResult)}</Box>
        )}
        {keywordOutput.map((keyword, index) => (
          <Box key={index}>{keyword}</Box>
        ))}
      </div>
    );
    setResult(output);
  };

  return (
    <Box>
      {result && !isLoading && (
        <Card mt={3} mb={8} className="card-ai">
          <CardBody className="card-body-ai">
            <Box>
              <Text
                textStyle="bodyL"
                fontWeight="semibold"
                style={{ whiteSpace: "pre-line" }}
              >
                {result}
              </Text>
            </Box>
          </CardBody>
        </Card>
      )}

      {isLoading && (
        <Card mt={3} mb={8} className="card-ai">
          <CardBody className="card-body-ai">
            <Box>
              <Flex alignItems={"center"}>
                <Spinner />
              </Flex>
            </Box>
          </CardBody>
        </Card>
      )}

      <Card mt={3} mb={8} className="card-ai">
        <CardBody className="card-body-ai">
          <Box>
            <Flex gap={1} mb={3} alignItems={"center"}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                height="24px"
                width="24px"
                name="miscAIFill"
              >
                <path
                  fill="#5d82a4"
                  d="M6.159 7.143 9.15 1.997h1.729l2.992 5.146 5.146 2.993v1.729l-5.146 2.992-2.992 5.146H9.15L6.16 14.857l-5.146-2.992v-1.73l5.146-2.992ZM20.797 18.194l-1.28-2.203-1.282 2.203-2.202 1.28 2.202 1.282 1.281 2.203 1.281-2.203L23 19.475l-2.203-1.281Z"
                ></path>
              </svg>
              <Text textStyle="h5" fontWeight="semibold">
                What product or brand would you like?
              </Text>
            </Flex>
            <Textarea
              onKeyDown={async (ev) => {
                if (ev.key === "Enter") {
                  ev.preventDefault();
                  const input = (ev.target as HTMLTextAreaElement).value;
                  setIsLoading(true);
                  const llmResult = await talkToLlm(input);
                  setIsLoading(false);
                  handleLlmResult(llmResult);
                  (ev.target as HTMLTextAreaElement).value = "";
                }
              }}
              mb={3}
            />
            <Text textStyle="bodyS">
              Example: My client is opening a gym room, suggest me some products
            </Text>
          </Box>
        </CardBody>
      </Card>
    </Box>
  );
};
