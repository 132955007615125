import { SVGProps } from "react";

const SvgRegularLink = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 17c-1.383 0-2.562-.488-3.537-1.463C2.488 14.562 2 13.383 2 12s.488-2.563 1.463-3.538C4.438 7.487 5.617 7 7 7h3a.97.97 0 0 1 .713.287A.97.97 0 0 1 11 8c0 .283-.096.52-.287.712A.968.968 0 0 1 10 9H7c-.833 0-1.542.292-2.125.875A2.893 2.893 0 0 0 4 12c0 .833.292 1.542.875 2.125A2.893 2.893 0 0 0 7 15h3a.97.97 0 0 1 .713.287A.97.97 0 0 1 11 16c0 .283-.096.52-.287.712A.968.968 0 0 1 10 17H7Zm2-4a.965.965 0 0 1-.712-.288A.965.965 0 0 1 8 12c0-.283.096-.521.288-.713A.967.967 0 0 1 9 11h6a.97.97 0 0 1 .713.287A.97.97 0 0 1 16 12c0 .283-.096.52-.287.712A.968.968 0 0 1 15 13H9Zm5 4a.965.965 0 0 1-.712-.288A.965.965 0 0 1 13 16c0-.283.096-.521.288-.713A.967.967 0 0 1 14 15h3c.833 0 1.542-.292 2.125-.875A2.893 2.893 0 0 0 20 12c0-.833-.292-1.542-.875-2.125A2.893 2.893 0 0 0 17 9h-3a.965.965 0 0 1-.712-.288A.965.965 0 0 1 13 8c0-.283.096-.521.288-.713A.967.967 0 0 1 14 7h3c1.383 0 2.563.487 3.538 1.462C21.513 9.437 22 10.617 22 12s-.487 2.562-1.462 3.537C19.563 16.512 18.383 17 17 17h-3Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgRegularLink;
