import { useMutation } from "@tanstack/react-query";

import { RETAILERS_KEY } from "services/ReactQueryKeys";
import httpClient from "services/httpClient";
import { queryClient } from "services/queryClient";

export const ACTIVATE_RETAILER_ACCOUNT_BY_ID = "/admin/retailer";

const activateRetailerAccount = async ({ id }: { id: string }) => {
  return await httpClient.put(
    `${ACTIVATE_RETAILER_ACCOUNT_BY_ID}/${id}/unblock`,
  );
};
export const useActivateRetailerAccountById = (id: string) =>
  useMutation(activateRetailerAccount, {
    onSuccess: () => {
      void queryClient.fetchQuery({
        queryKey: [RETAILERS_KEY, id],
      });
    },
  });
