import { useMutation } from "@tanstack/react-query";

import httpClient from "services/httpClient";

export const POST_UPLOAD = "/upload";

export type UploadImage = {
  key: string;
  extension: string;
};

export type UploadResponse = {
  id: string;
  previewUrl: string;
  uploadUrl: string;
};

const uploadImage = async (requestBody: UploadImage) =>
  httpClient.post<UploadResponse>(`${POST_UPLOAD}`, requestBody);

export const useUpload = () => useMutation(uploadImage);
