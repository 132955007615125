/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { usePostExternalTrackingUrl } from "./usePostExternalTrackingUrl";
import { usePostTrackingUrl } from "./usePostTrackingUrl";
import { useSimpleToast } from "hooks/useSimpleToast";
import { ErrorResponse } from "services/ErrorResponse";
import { getErrorCodes } from "services/httpClient";

export type TrackingUrlFields = {
  subOrderId: string;
  shipmentId: string;
};

export type TrackingUrlFormProps = {
  onClose: () => void;
  suborderId: string;
  subOrderNumber: string;
};

export const useTrackingUrlForm = ({
  onClose,
  suborderId,
  subOrderNumber,
}: TrackingUrlFormProps) => {
  const {
    control,
    register,
    getValues,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isDirty, isValid },
  } = useForm<TrackingUrlFields>({
    defaultValues: {
      subOrderId: suborderId,
    },
  });

  const { t } = useTranslation();

  const toast = useSimpleToast();

  const onSuccess = () => {
    toast({
      position: "top",
      description: t("orders.tracking_success", {
        subOrderNumber: subOrderNumber,
      }),
    });
    onClose();
  };

  const onError = (error: ErrorResponse) => {
    const errorCodes = getErrorCodes(error);
    toast({
      status: "error",
      position: "top",
      description: t(errorCodes[0]),
    });
  };

  const { mutateAsync: postTrackingUrl } = usePostTrackingUrl(
    onSuccess,
    onError,
  );

  const onSubmit = handleSubmit(async () => {
    await postTrackingUrl(getValues());
  });

  const onSuccessExternal = () => {
    toast({
      position: "top",
      description: t("orders.tracking_url_success", {
        subOrderNumber: subOrderNumber,
      }),
    });
    onClose();
  };

  const { mutateAsync: postExternalTrackingUrl } = usePostExternalTrackingUrl(
    onSuccessExternal,
    onError,
  );

  const onSubmitExternal = handleSubmit(async () => {
    await postExternalTrackingUrl(getValues());
  });

  const trackingUrl = watch("shipmentId");
  return {
    control,
    register,
    onSubmit,
    getValues,
    reset,
    errors,
    isDirty,
    isValid,
    trackingUrl,
    onSubmitExternal,
  };
};
