import { useMutation } from "@tanstack/react-query";

import { ChangeBrandBrandNameFields } from "./useChangeBrandNameForm";
import { ErrorResponse } from "services/ErrorResponse";
import httpClient from "services/httpClient";

const CHANGE_BRAND_NAME = "/admin/brands/";

const putBrandBrandName = async ({
  brandId,
  newBrandName,
  confirmNewBrandName,
}: ChangeBrandBrandNameFields) => {
  return await httpClient.put(
    `${CHANGE_BRAND_NAME}${brandId}/change-brand-name`,
    {
      newBrandName,
      confirmNewBrandName,
    },
  );
};

export const usePutBrandBrandName = (
  onSuccess: () => void,
  onError: (error: ErrorResponse) => void,
) =>
  useMutation(putBrandBrandName, {
    onSuccess,
    onError,
  });
