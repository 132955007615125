import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { GET_WALLET_CREDIT } from "./useGetWalletCredit";
import { usePostWalletCredit } from "./usePostWalletCredit";
import { useSimpleToast } from "hooks/useSimpleToast";
import { ErrorResponse } from "services/ErrorResponse";
import { getErrorCodes } from "services/httpClient";
import { queryClient } from "services/queryClient";

export type AddWalletCreditFields = {
  id: string;
  amount: number;
  reason: string;
};

export const useAddWalletCredit = (
  id: string,
  closeModal: () => void,
  isOpenModal: boolean,
) => {
  const {
    control,
    register,
    formState: { errors, isDirty, isValid },
    getValues,
    handleSubmit,
    reset,
  } = useForm<AddWalletCreditFields>({ defaultValues: { id: id } });
  const { t } = useTranslation();
  const toast = useSimpleToast();

  const onSuccess = () => {
    void queryClient.fetchQuery({ queryKey: [GET_WALLET_CREDIT] });
    toast({
      position: "top",
      description: "Wallet credit added successfully",
    });
    closeModal();
  };

  const onError = (error: ErrorResponse) =>
    toast({ status: "error", description: t(getErrorCodes(error)) });

  const { mutateAsync: postWalletCredit, isLoading } = usePostWalletCredit(
    onSuccess,
    onError,
  );

  const handleAddWallet = handleSubmit(async () => {
    await postWalletCredit(getValues());
  });

  const isFormValid = useMemo(() => {
    return !isDirty || !isValid;
  }, [isDirty, isValid]);

  useEffect(() => {
    if (isOpenModal) {
      return;
    }
    reset({
      id: id,
      amount: undefined,
      reason: "",
    });
  }, [id, isOpenModal, reset]);

  return {
    control,
    register,
    errors,
    isFormValid,
    getValues,
    isLoading,
    handleAddWallet,
  };
};
