import { Flex, Spinner } from "@chakra-ui/react";

import { useGetRequestsByOrder } from "./hooks/useGetRequestsByOrder";
import { useSetupReturnRequestsTable } from "./hooks/useSetupReturnRequestsTable";
import { TableWrapper } from "components/Table/TableWrapper";

type FinancialDetailsProps = {
  orderId: string;
};
export const ReturnDetails = ({ orderId }: FinancialDetailsProps) => {
  const { data: actions, isLoading } = useGetRequestsByOrder(orderId);

  const { columns } = useSetupReturnRequestsTable();

  if (isLoading) {
    return <Spinner position="absolute" top="50%" left="50%" />;
  }

  return (
    <Flex flexDirection="column" pt={5}>
      <TableWrapper
        columns={columns}
        tableData={actions}
        tableProps={{ size: "md" }}
        disablePagination
      />
    </Flex>
  );
};
