import { useMutation } from "@tanstack/react-query";

import { TrackingUrlFields } from "./useTrackingUrlForm";
import { ErrorResponse } from "services/ErrorResponse";
import httpClient from "services/httpClient";

const POST_TRACKING_URL = "admin/orders/tracking/";
const postTrackingUrl = async (form: TrackingUrlFields) => {
  return await httpClient.put(`${POST_TRACKING_URL}${form.subOrderId}`, {
    shipmentId: form.shipmentId,
  });
};

export const usePostTrackingUrl = (
  onSuccess: () => void,
  onError: (data: ErrorResponse) => void,
) =>
  useMutation(postTrackingUrl, {
    onSuccess,
    onError,
  });
