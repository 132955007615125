import { format, isSameMonth, isSameYear } from "date-fns";

export const useFormatDateRange = (startDate: Date, endDate: Date) => {
  const fullDate = "MMMM dd, yyyy";

  let formattedDate = "";
  if (isSameMonth(startDate, endDate)) {
    formattedDate = `${format(startDate, "MMMM dd")} to ${format(
      endDate,
      "dd, yyyy",
    )}`;
  } else {
    if (isSameYear(startDate, endDate)) {
      formattedDate = `${format(startDate, "MMMM dd")} to ${format(
        endDate,
        fullDate,
      )}`;
    } else {
      formattedDate = `${format(startDate, fullDate)} to ${format(
        endDate,
        fullDate,
      )}`;
    }
  }

  return formattedDate;
};
