export const leftSideModalStyle = {
  w: "45%",
  flexDirection: "column" as const,
  alignItems: "center",
  backgroundColor: "#FAFAFD",
};

export const textStyle = {
  mt: 6,
  fontSize: "3xl",
  textTransform: "capitalize" as const,
};

export const rightSideModalStyle = {
  w: "55%",
  px: "60px",
  flexDirection: "column" as const,
};

export const headingStyle = {
  color: "grey.700",
  fontWeight: 600,
  size: "2xl",
  lineHeight: "48px",
  mb: 5,
  mt: 10,
  textTransform: "capitalize" as const,
};

export const formStyle = {
  flexDirection: "column" as const,
  h: "100%",
  position: "relative" as const,
};

export const resendWrapperStyle = {
  alignItems: "center",
  mt: 4,
  mb: 8,
};

export const resendStyle = {
  variant: "ghost",
  _hover: { backgroundColor: "transparent" },
  px: 2,
  py: 0,
  h: "initial",
};

export const buttonStyle = {
  w: "100%",
  fontSize: "2xl",
  h: "initial",
  py: "10px",
  lineHeight: "24px",
  textTransform: "capitalize" as const,
  position: "absolute" as const,
  colorScheme: "grey",
  bottom: 12,
};

export const messageStyle = {
  lineHeight: "22px",
  mt: 10,
  mb: 6,
  pr: 5,
};

export const titleStyle = {
  textTransform: "capitalize" as const,
  fontWeight: 600,
  mb: 4,
  fontSize: "xl",
};

export const thankYouWrapperStyle = {
  h: "100%",
  position: "relative" as const,
  flexDirection: "column" as const,
  justifyContent: "center",
  alignItems: "center",
  pb: 15,
};

export const thankYouTitleStyle = {
  fontSize: "5xl",
  fontWeight: 600,
  lineHeight: "48px",
  textTransform: "capitalize" as const,
};

export const errorWrapper = {
  backgroundColor: "#fff1f1",
  border: "1px solid",
  borderColor: "#ffd5d6",
  borderRadius: "4px",
  display: "flex",
  alignItems: "center",
  pl: "14px",
  py: "10px",
  mt: "24px",
};
