import { Box, Button, Flex, Heading, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { CreateVouchers } from "./CreateVouchers/CreateVouchers";
import { CurrentAndUpcoming } from "./CurrentAndUpcoming";
import { Expired } from "./Expired";
import { useGetAllVouchers } from "./hooks/useGetAllVouchers";
import { useGetExpiredVouchers } from "./hooks/useGetExpiredVouchers";
import { TabData, TabWrapper } from "components/Tabs/TabWrapper";
import { SolidPlus } from "styles/icons/solid";

export const Vouchers = () => {
  const [tabIndex, setTabIndex] = useState<number>(
    parseInt(window.location.hash.replace("#", "") || "0"),
  );

  const { t } = useTranslation();

  const { data: allVouchers, isLoading: isLoadingAllVouchers } =
    useGetAllVouchers();
  const { data: allExpiredVouchers, isLoading: isLoadingAllExpiredVouchers } =
    useGetExpiredVouchers();

  const {
    isOpen: isOpenCreate,
    onOpen: onOpenCreate,
    onClose: onCloseCreate,
  } = useDisclosure();

  const tabsData: TabData[] = [
    {
      title: t("vouchers.current_and_upcoming"),
      content: (
        <CurrentAndUpcoming
          vouchers={allVouchers ?? []}
          isLoading={isLoadingAllVouchers}
        />
      ),
    },
    {
      title: t("vouchers.expired"),
      content: (
        <Expired
          vouchers={allExpiredVouchers ?? []}
          isLoading={isLoadingAllExpiredVouchers}
        />
      ),
    },
  ];

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
    window.location.hash = `${index}`;
  };

  return (
    <Box margin="auto">
      <Flex display="column">
        <Flex justifyContent="space-between">
          <Heading as="h1" size="h1" fontWeight="bold" mb={2} mt={5} ml={10}>
            {t("Vouchers")}
          </Heading>
          <Button
            size="lg"
            mt={15}
            mr={8}
            leftIcon={<SolidPlus fontSize={24} />}
            fontWeight="semibold"
            onClick={onOpenCreate}
            data-test-id="createVoucher"
          >
            {t("vouchers.create_voucher")}
          </Button>
        </Flex>
        <TabWrapper
          tabListProps={{ pl: 10 }}
          tabPanelsProps={{ pl: 2 }}
          tabsData={tabsData}
          tabIndex={tabIndex}
          handleTabsChange={handleTabsChange}
        />
        <CreateVouchers isOpen={isOpenCreate} onClose={onCloseCreate} />
      </Flex>
    </Box>
  );
};
