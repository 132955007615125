/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-unsafe-return */
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { BrandPageUpdateRequestDetails } from "features/BrandRequests/types";
import httpClient from "services/httpClient";

export const GET_BRAND_PAGE_UPDATE_REQUEST_BY_ID =
  "/admin/brand-page-requests/";

export const useGetBrandPageUpdateRequestById = (
  id: string,
): UseQueryResult<BrandPageUpdateRequestDetails> =>
  useQuery({
    queryKey: [GET_BRAND_PAGE_UPDATE_REQUEST_BY_ID, id],
    queryFn: async () => {
      const { data: brandPageUpdateDetails } =
        await httpClient.get<BrandPageUpdateRequestDetails>(
          `${GET_BRAND_PAGE_UPDATE_REQUEST_BY_ID}${id}`,
        );
      return brandPageUpdateDetails;
    },
  });
