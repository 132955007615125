/* eslint-disable no-restricted-imports */
import { Box, Icon } from "@chakra-ui/react";
import { format } from "date-fns";
import {
  Calendar as ReactCalendar,
  CalendarProps as ReactCalendarProps,
} from "react-calendar";

import { ActionButtonGroup } from "../Button/ActionButtonGroup";
import { boxWrapperStyle } from "./Calendar.styles";
import { ButtonProps } from "components/Button/Button";
import { useCheckMobileScreen } from "hooks/useCheckMobileScreen";
import {
  SolidArrowChevronLeft,
  SolidArrowChevronRight,
} from "styles/icons/solid";

const SINGLE_CALENDAR_WIDTH = "256px";
const DOUBLE_CALENDAR_WIDTH = "496px";

export type CalendarProps = ReactCalendarProps & {
  primaryAction?: ButtonProps;
  secondaryAction?: ButtonProps;
};

export const Calendar = ({
  showDoubleView,
  primaryAction,
  secondaryAction,
  ...rest
}: CalendarProps) => {
  const isMobileScreen = useCheckMobileScreen();
  const calendarWidth =
    showDoubleView && !isMobileScreen
      ? DOUBLE_CALENDAR_WIDTH
      : SINGLE_CALENDAR_WIDTH;

  return (
    <Box maxWidth={calendarWidth} {...boxWrapperStyle}>
      <ReactCalendar
        nextLabel={
          <Icon
            as={SolidArrowChevronRight}
            color="grey.800"
            boxSize={5}
            mt={1}
          />
        }
        prevLabel={
          <Icon
            as={SolidArrowChevronLeft}
            color="grey.800"
            boxSize={5}
            mt={1}
          />
        }
        formatShortWeekday={(locale, date) => format(date, "EEEEEE")}
        formatMonth={(locale, date) => format(date, "MMM")}
        showFixedNumberOfWeeks
        showDoubleView={showDoubleView && !isMobileScreen}
        minDetail="year"
        {...rest}
      />
      <ActionButtonGroup
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
      />
    </Box>
  );
};
