import { useMutation } from "@tanstack/react-query";

import httpClient from "services/httpClient";

export const PUT_MARK_AS_PAID = "/admin/payouts/suborder";

type MarkAsPaidProps = {
  id: string;
  currencyCode: string;
  amount: number;
};

const putMarkAsPaid = async ({ id, currencyCode, amount }: MarkAsPaidProps) => {
  return await httpClient.put(`${PUT_MARK_AS_PAID}/${id}/mark-as-paid`, {
    currencyCode,
    amount: amount,
  });
};

export const usePutMarkAsPaid = () => useMutation(putMarkAsPaid);
