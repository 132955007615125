import { Button, Flex, Heading, Textarea, useToast } from "@chakra-ui/react";
import { ErrorResponse } from "@remix-run/router";
import { AxiosResponse } from "axios";
import { MultiSelect, SelectItem } from "chakra-multiselect";
import { isAfter, isPast, parseISO } from "date-fns";
import { useState } from "react";
import { Control, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import "./editPromotion.scss";
import {
  BrandItem,
  PromotionResponse,
  usePostPromotion,
  usePutPromotion,
} from "./usePromotions";
import { DrawerWrapper } from "components/DrawerWrapper/DrawerWrapper";
import { ImageUpload } from "components/ImageUpload/ImageUpload";
import { InputField } from "components/InputField/InputField";
import { SingleDatePickerField } from "components/SingleDatePickerField/SingleDatePickerField";
import { TextAreaField } from "components/TextAreaField/TextAreaField";
import { useBrands } from "features/Pdfs/StandardDeck/hooks/useBrands";
import { getErrorCodes } from "services/httpClient";
import { filterOnLabelAndValue } from "utils/multiSelect";

export type PromotionForm = Omit<PromotionResponse, "discount"> & {
  discount: number;
};

export const EditPromotion = ({
  isOpen,
  onClose,
  promotion,
}: {
  isOpen: boolean;
  onClose: () => void;
  promotion?: PromotionForm;
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [isImageUploading, setIsImageUploading] = useState(false);

  let promotionEdit = promotion;
  let defaultSelectedBrand: SelectItem[] = [];
  if (promotion) {
    promotionEdit = {
      ...promotion,
      fromDate: new Date(promotion.fromDate),
      untilDate: new Date(promotion.untilDate),
    };

    defaultSelectedBrand = promotion.brands.map((brand) => ({
      label: brand.key,
      value: brand.key,
    }));
  } else {
    defaultSelectedBrand = [];
  }

  const {
    register,
    control,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<PromotionForm>({
    mode: "onSubmit",
    defaultValues: promotionEdit,
  });

  const { data: brands = [], isLoading } = useBrands();
  const requiredError = t("promotions.required");

  const handlePostSucces = () =>
    toast({ description: t("promotions.post_success") });
  const handlePostError = (error: ErrorResponse) => {
    toast({
      status: "error",
      description: <div>{t(getErrorCodes(error))}</div>,
    });
  };
  const [selectedBrand, setSelectedBrand] =
    useState<SelectItem[]>(defaultSelectedBrand);

  const { mutateAsync: postPromotion, isLoading: isMutatingPostPromotion } =
    usePostPromotion(handlePostSucces, handlePostError);

  const handlePutSuccess = () =>
    toast({ description: t("promotions.put_success") });
  const handlePutError = (error: ErrorResponse) => {
    toast({
      status: "error",
      description: t(getErrorCodes(error)[0]),
    });
  };

  const { mutateAsync: putPromotion, isLoading: isMutatingPutPromotion } =
    usePutPromotion(handlePutSuccess, handlePutError);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let selectableBrands: BrandItem[] = [];
  if (!isLoading && brands.length > 0) {
    selectableBrands = brands.map((brand) => ({
      label: brand.storeName,
      value: brand.storeKey,
    }));
  }

  const getBrand = (brandKey: string) =>
    selectableBrands.find((brand) => brand.value === brandKey);

  const onSubmit = handleSubmit(async () => {
    const { name, key, description, fromDate, untilDate, discount, imageUrl } =
      getValues();

    let imageId = imageUrl;

    if (imageUrl.includes("https")) {
      const imageUrlSplit = imageUrl.split("/");
      imageId = imageUrlSplit[imageUrlSplit.length - 1].split(".")[0];
    }

    let response: AxiosResponse;

    if (promotion?.id) {
      response = await putPromotion({
        id: promotion.id,
        name,
        key,
        description,
        brandKeys: selectedBrand.map((item) => item.value as string),
        fromDate,
        untilDate,
        discount: { type: "relative", value: [{ amount: discount }] },
        imageId,
      });
    } else {
      response = await postPromotion({
        name,
        key,
        description,
        brandKeys: selectedBrand.map((item) => item.value as string),
        fromDate,
        untilDate,
        discount: { type: "relative", value: [{ amount: discount }] },
        imageId,
      });
    }

    if (response.status === 200) {
      onClose();
      reset();
    }
  });

  return (
    <DrawerWrapper
      drawerProps={{ isOpen, onClose, children: undefined, size: "xl" }}
      drawerHeader={
        <Heading fontSize="6xl" fontWeight={700} paddingTop={8} paddingX={3}>
          {promotion?.id ? t("promotions.edit") : t("promotions.create")}
        </Heading>
      }
      drawerBody={
        <>
          <Flex marginBottom={5}>
            <InputField
              {...register("name", {
                required: requiredError,
                maxLength: {
                  value: 24,
                  message: t("promotions.maximum_length_error"),
                },
              })}
              errors={errors}
              label={t("promotions.name")}
              placeholder={t("promotions.enter_name")}
            />
          </Flex>
          <Flex marginBottom={5}>
            <InputField
              {...register("key", { required: requiredError })}
              errors={errors}
              label={t("promotions.key")}
              placeholder={t("promotions.enter_key")}
              disabled={!!promotion}
            />
          </Flex>
          <Flex marginBottom={5}>
            <TextAreaField
              {...register("description", { required: requiredError })}
              errors={errors}
              label={t("promotions.description")}
              placeholder={t("promotions.add_description")}
              minH={120}
            />
          </Flex>
          <MultiSelect
            label="Brands"
            paddingTop={5}
            className="multiselectasdsad"
            options={selectableBrands}
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/prefer-nullish-coalescing
            value={selectedBrand}
            placeholder="Select ..."
            searchPlaceholder="Search ..."
            size="md"
            onChange={(items) => {
              const brandItems = items as unknown as BrandItem[];
              setSelectedBrand(brandItems);
            }}
            filterFn={filterOnLabelAndValue}
          />
          <Textarea
            defaultValue={selectedBrand
              .map((item) => item.value as string)
              .join("\n")}
            value={selectedBrand.map((item) => item.value as string).join("\n")}
            onChange={(ev) => {
              const keys = ev.target.value.split("\n");
              const newValue: BrandItem[] = [];
              keys.forEach((key) => {
                const option = getBrand(key.replace(",", "").trim());
                if (option) {
                  newValue.push(option);
                }
              });
              setSelectedBrand(newValue);
            }}
            placeholder="Put brand keys here"
            marginBottom={5}
          />
          <Flex marginBottom={5}>
            <Flex width="100%" marginRight={5}>
              <SingleDatePickerField
                name="fromDate"
                control={control as unknown as Control}
                minDate={new Date()}
                rules={{
                  required: requiredError,
                  validate: (value: string) =>
                    !isPast(parseISO(value)) ||
                    t("promotions.date_in_past_error"),
                }}
                label={t("promotions.first_date")}
                placeholder={t("promotions.select_date")}
                inputFormat="dd/MM/yyyy HH:mm"
                disableExcludedTagNames
              />
            </Flex>
            <SingleDatePickerField
              name="untilDate"
              control={control as unknown as Control}
              minDate={new Date()}
              rules={{
                required: requiredError,
                validate: (value: string) =>
                  isAfter(new Date(value), new Date(getValues().fromDate)) ||
                  t("promotions.end_date_before_start_error"),
              }}
              label={t("promotions.last_date")}
              placeholder={t("promotions.select_date")}
              inputFormat="dd/MM/yyyy HH:mm"
              disableExcludedTagNames
            />
          </Flex>
          <Flex marginBottom={5}>
            <InputField
              {...register("discount", {
                valueAsNumber: true,
                required: requiredError,
                validate: (value: number) =>
                  (value >= 0 && value <= 100) ||
                  t("promotions.discount_error"),
              })}
              errors={errors}
              type="number"
              label={t("promotions.discount")}
              placeholder="0"
              rightAddon="%"
            />
          </Flex>
          <ImageUpload
            name="imageUrl"
            rules={{
              required: requiredError,
            }}
            errorMessage={errors.imageUrl?.message}
            control={control as unknown as Control}
            label={t("promotions.add_photo")}
            description={t("promotions.file_types")}
            setIsUploadLoading={setIsImageUploading}
            size="small"
          />
          <br />
          <br />
        </>
      }
      drawerFooter={
        <>
          <Button size="lg" variant="outline" marginRight={5} onClick={onClose}>
            {t("promotions.cancel")}
          </Button>
          {/* eslint-disable-next-line */}
          <Button
            size="lg"
            disabled={
              isImageUploading ||
              isMutatingPostPromotion ||
              isMutatingPutPromotion
            }
            onClick={() => void onSubmit()}
          >
            {isImageUploading ? "..." : t("promotions.save")}
          </Button>
        </>
      }
    />
  );
};
