import { Flex, Input } from "@chakra-ui/react";
import ReactSelect, {
  GroupBase,
  MultiValue,
  OptionProps,
  Options,
  SingleValue,
  components,
} from "react-select";

import "./Select.css";
import { ErrorMessage } from "components/ErrorMessage/ErrorMessage";
import { Label } from "components/Label/Label";

export type Option = {
  label: string;
  value: string | boolean | number;
};

export type GroupedOption = {
  label: string;
  options: Option[];
};

export type SelectOptions = Options<Option> | Options<GroupedOption>;
type SelectProps = {
  name: string;
  id?: string;
  label?: string;
  options: SelectOptions;
  value?: Option | Option[];
  placeholder?: string;
  isDisabled?: boolean;
  errorMessage?: string;
  className?: string;
  isMulti?: boolean;
  onChange: (
    value: string | string[] | undefined | number | boolean,
  ) => unknown;
};

export const Select = ({
  id,
  name,
  options,
  label,
  placeholder = "Select",
  value,
  errorMessage,
  isDisabled,
  onChange,
  isMulti = false,
}: SelectProps) => {
  const handleChange = (
    option: MultiValue<Option> | SingleValue<Option> | null,
  ) => {
    if (Array.isArray(option)) {
      const multiValue: string[] = [];
      option.forEach((o: Option) => {
        if (o.value) {
          multiValue.push(String(o.value));
        }
      });
      onChange(multiValue);
    } else {
      onChange((option as Option).value);
    }
  };

  return (
    <Flex direction="column" marginBottom={5} width="100%" position="relative">
      {label && <Label label={label} />}
      <ReactSelect
        id={id}
        instanceId={name}
        placeholder={placeholder}
        options={options}
        classNamePrefix="custom-select"
        value={value}
        isDisabled={isDisabled}
        components={isMulti ? { Option } : { IndicatorSeparator: () => null }}
        aria-label={label}
        onChange={handleChange}
        inputId={id}
        isMulti={isMulti}
        hideSelectedOptions={false}
        closeMenuOnSelect={isMulti ? false : true}
      />
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </Flex>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
const Option = ({
  getStyles,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}: OptionProps<Option, boolean, GroupBase<Option>>) => {
  const props = {
    ...innerProps,
  };

  return (
    <components.Option
      className="option"
      getStyles={getStyles}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      innerProps={props}
      {...rest}
    >
      <Input
        type="checkbox"
        checked={isSelected}
        className="checkbox"
        onChange={() => null}
      />
      {children}
    </components.Option>
  );
};
