import { Circle, Text } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { format, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";

import { ActionsType } from "./useGetActions";
import { circleStyles } from "components/Status/Status.styles";

export const useSetupActionsTable = () => {
  const columnHelper = createColumnHelper<ActionsType>();
  const { t } = useTranslation();
  const detailsColumns = [
    columnHelper.accessor("action", {
      cell: ({ row }) => (
        <Text
          display="flex"
          textAlign="center"
          textStyle="bodyS"
          color={row.index === 0 ? "grey.700" : "grey.600"}
        >
          {row.index === 0 && (
            <Circle
              {...circleStyles("done")}
              position="absolute"
              ml={-4}
              mt={1}
            />
          )}
          {row.original.action}
        </Text>
      ),
      header: t("actions.action_type"),
      size: 50,
      enableSorting: false,
    }),
    columnHelper.accessor("user", {
      cell: ({ row }) => (
        <Text
          textStyle="bodyS"
          color={row.index === 0 ? "grey.700" : "grey.600"}
        >
          {row.original.user ? row.original.user : "-"}
        </Text>
      ),
      header: t("actions.user"),
      size: 40,
      enableSorting: false,
    }),
    columnHelper.accessor("creationDate", {
      cell: ({ row }) => (
        <Text
          textStyle="bodyS"
          color={row.index === 0 ? "grey.700" : "grey.600"}
        >
          {format(
            parseISO(row.original.creationDate),
            "dd, MMMM yyyy, HH:mm:ss",
          )}
        </Text>
      ),
      header: t("actions.date_time"),
      size: 10,
      enableSorting: false,
    }),
  ];

  return {
    detailsColumns,
  };
};
