import { useMutation } from "@tanstack/react-query";

import { TrackingUrlFields } from "./useTrackingUrlForm";
import { ErrorResponse } from "services/ErrorResponse";
import httpClient from "services/httpClient";

const POST_EXTERNAL_TRACKING_URL = "admin/orders/tracking/external/";
const postExternalTrackingUrl = async (form: TrackingUrlFields) => {
  return await httpClient.put(
    `${POST_EXTERNAL_TRACKING_URL}${form.subOrderId}`,
    {
      trackingLink: form.shipmentId,
    },
  );
};

export const usePostExternalTrackingUrl = (
  onSuccess: () => void,
  onError: (data: ErrorResponse) => void,
) =>
  useMutation(postExternalTrackingUrl, {
    onSuccess,
    onError,
  });
