/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Link, Text } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import { ReturnRequest } from "./useGetRequestsByOrder";
import { Button } from "components/Button/Button";
import { Status } from "components/Status/Status";
import {
  capitalizeFirstLetter,
  convertToStatusVariant,
} from "features/UserManagement/utils";
import { formatDate } from "utils/functions";

type ReturnRequestTable = {
  action: string;
  resolutionDate?: string;
} & ReturnRequest;

export const useSetupReturnRequestsTable = (type?: string) => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<ReturnRequestTable>();

  const columns = [
    columnHelper.accessor("subOrderNumber", {
      cell: ({ row }) => (
        <Link href={`/orders/${row.original.orderId}`}>
          <Text
            textStyle="bodyS"
            cursor="pointer"
            color="#1C7EC6"
          >{`#${row.original.subOrderNumber}`}</Text>
        </Link>
      ),
      header: t("return_requests.order_id"),
      size: 20,
      enableSorting: false,
    }),
    columnHelper.accessor("retailerName", {
      cell: ({ row }) => (
        <Link href={`/user-management/retailer/${row.original.retailerId}`}>
          <Text textStyle="bodyS" cursor="pointer" color="#1C7EC6">
            {row.original.retailerName}
          </Text>
        </Link>
      ),
      header: t("return_requests.retailer_name"),
      size: 20,
      enableSorting: false,
    }),
    columnHelper.accessor("brandName", {
      cell: ({ row }) => (
        <Link href={`/user-management/brand/${row.original.brandId}`}>
          <Text textStyle="bodyS" cursor="pointer" color="#1C7EC6">
            {row.original.brandName}
          </Text>
        </Link>
      ),
      header: t("return_requests.brand_name"),
      size: 20,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => formatDate(row.requestDate), {
      id: "requestDate",
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {formatDate(row.original.requestDate)}
        </Text>
      ),
      header: t("return_requests.request_date"),
      size: 20,
      enableSorting: false,
      sortingFn: (a, b) => {
        return (
          new Date(a.original.requestDate).getTime() -
          new Date(b.original.requestDate).getTime()
        );
      },
    }),
    columnHelper.accessor("resolutionDate", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {row.original.resolutionDate &&
            formatDate(row.original.resolutionDate)}
        </Text>
      ),
      header: t("return_requests.resolution_date"),
      size: 10,
      enableSorting: false,
    }),
    columnHelper.accessor("status", {
      cell: ({ row }) => (
        <Status
          variant={convertToStatusVariant(row.original.status)}
          text={capitalizeFirstLetter(
            row.original.status === "PARTIAL_APPROVED"
              ? "PARTIALLY_APPROVED"
              : row.original.status,
          )}
        />
      ),
      header: t("return_requests.status"),
      size: 20,
      enableSorting: false,
    }),
    columnHelper.accessor("action", {
      cell: ({ row }) => (
        <Link href={`/return-requests/${row.original.id}`}>
          <Button variant="outline">{t("return_requests.view")}</Button>
        </Link>
      ),
      header: t("return_requests.action"),
      size: 10,
      enableSorting: false,
    }),
  ];
  return { columns };
};
