/* eslint-disable @tanstack/query/exhaustive-deps */
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { NOTES } from "services/ReactQueryKeys";
import httpClient from "services/httpClient";

export const ADMIN_BRANDS = "/admin/brands";

type Note = {
  id: string;
  content: string;
  createdBy: string;
  creationDate: string;
};

export const useGetAllBrandNotesById = (id: string): UseQueryResult<Note[]> =>
  useQuery({
    queryKey: [NOTES, id],
    queryFn: async () => {
      const { data: allNotes } = await httpClient.get<Note>(
        `${ADMIN_BRANDS}/${id}/notes`,
      );
      return allNotes;
    },
  });
