import { As, BoxProps, Flex, Icon, Text } from "@chakra-ui/react";
import { ReactNode } from "react";

export type InfoDetailsSingleColumnProps = {
  heading: string;
  headingIcon: As;
  details: ReactNode;
} & BoxProps;
export const InfoDetailsSingleColumn = ({
  heading,
  headingIcon,
  details,
  ...rest
}: InfoDetailsSingleColumnProps) => {
  return (
    <Flex
      w="480px"
      flexDirection="column"
      p={5}
      borderRadius="base"
      boxShadow={1}
      border="1px solid"
      borderColor="grey.400"
      {...rest}
    >
      <Flex mb={6}>
        <Icon as={headingIcon} w={6} h={6} mr={2} color="grey.400" />
        <Text textStyle="bodyL" fontWeight="semibold">
          {heading}
        </Text>
      </Flex>
      <Flex flexDir="column" px={4}>
        {details}
      </Flex>
    </Flex>
  );
};
