import { useMutation } from "@tanstack/react-query";

import { MASEFormFields } from "./useMASERequestForm";
import { ErrorResponse } from "services/ErrorResponse";
import httpClient from "services/httpClient";

export const POST_MASE_REQUEST = "/admin/support/mase";

const postMaseRequest = async (form: MASEFormFields) => {
  return await httpClient.post(POST_MASE_REQUEST, {
    passedAccount: form.passedAccount,
    submittedAccount: form.submittedAccount,
  });
};

export const usePostMaseRequest = (
  onSuccess: () => void,
  onError: (data: ErrorResponse) => void,
) =>
  useMutation(postMaseRequest, {
    onSuccess,
    onError,
  });
