import { accordionAnatomy as parts } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyleContainer = defineStyle({
  border: 0,
});

const baseStyleButton = defineStyle({
  color: "grey.900",
  minHeight: 12,
  transitionProperty: "common",
  transitionDuration: "normal",
  fontSize: "md",
  _hover: {
    backgroundColor: "transparent",
  },
  _disabled: {
    opacity: 0.4,
    cursor: "not-allowed",
  },
  p: 3,
});

const baseStylePanel = defineStyle({
  pl: 11,
  pr: 3,
  pt: 1,
});

const baseStyleIcon = defineStyle({
  fontSize: "1.25em",
});

const baseStyle = definePartsStyle({
  container: baseStyleContainer,
  button: baseStyleButton,
  panel: baseStylePanel,
  icon: baseStyleIcon,
});

export const Accordion = defineMultiStyleConfig({ baseStyle });
