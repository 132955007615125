import { Flex, FormControl } from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";
import { FieldErrors } from "react-hook-form";

import { ErrorMessage } from "components/ErrorMessage/ErrorMessage";
import { Label } from "components/Label/Label";

export type FieldWrapperProps = {
  name: string;
  label?: string;
  info?: string;
  rightInfo?: string | ReactNode;
  errors: FieldErrors;
  isRequired?: boolean;
  children: ReactElement;
};

export const FieldWrapper = ({
  name,
  label,
  info,
  rightInfo,
  errors,
  isRequired,
  children,
}: FieldWrapperProps) => (
  <FormControl isInvalid={!errors[name]} isRequired={isRequired}>
    <Flex justifyContent="space-between">
      {label ? <Label label={label} info={info} /> : null}
      {rightInfo ?? rightInfo}
    </Flex>
    {children}
    {errors[name] ? (
      <ErrorMessage message={errors[name]?.message?.toString() ?? ""} />
    ) : null}
  </FormControl>
);
