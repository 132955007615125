/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable @typescript-eslint/no-unnecessary-condition */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useMemo } from "react";
import { useFieldArray, useForm } from "react-hook-form";

import { useGetSettings } from "./useGetSettings";
import { usePostSettings } from "./usePostSettings";
import { useSimpleToast } from "hooks/useSimpleToast";
import { SETTINGS_KEY } from "services/ReactQueryKeys";
import { queryClient } from "services/queryClient";

export type Market = {
  minOrderAmount: number | null;
  autoWalletCreditAmount: number | null;
  autoBuyNowPayLaterAmount: number | null;
  currencyCode: string;
  currencySign: string;
};
export type SettingsFormFields = {
  minimumOrderValue: number;
  firstOrderCommission: number;
  commission: number;
  bestsellerProductLimit: number;
  paymentTermsDelay: number;
  newProductDurationInDays: number;
  companyName: string;
  companyCountry: string;
  companyAddress: string;
  accountName: string;
  bankAccountNumber: string;
  bankCode: string;
  swiftCode: string;
  retailerCountrySettings?: Market[];
  payoutCommissionRate: number;
  delayPayoutDays: number;
  returnEligibilityDays: number;
};

export const useSettingsForm = () => {
  const { data: settings, isLoading: isLoadingGet } = useGetSettings();

  const {
    control,
    register,
    formState: { errors, isDirty, isValid },
    getValues,
    handleSubmit,
    setValue,
    watch,
    reset,
  } = useForm<SettingsFormFields>({
    mode: "onChange",
    defaultValues: {
      companyName: "",
      companyCountry: "",
      companyAddress: "",
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "retailerCountrySettings",
  });

  const toast = useSimpleToast();
  const watchCompanyName = watch("companyName");

  useEffect(() => {
    if (!isLoadingGet && settings) {
      reset(() => ({
        minimumOrderValue: settings.minimumOrderValue,
        firstOrderCommission: settings.firstOrderCommission,
        commission: settings.commission,
        bestsellerProductLimit: settings.bestsellerProductLimit,
        paymentTermsDelay: settings.paymentTermsDelay,
        newProductDurationInDays: settings.newProductDurationInDays,
        companyName: settings.companyDetails.companyName,
        companyCountry: settings.companyDetails.companyCountry,
        companyAddress: settings.companyDetails.companyAddress,
        accountName: settings.companyDetails.accountName,
        bankAccountNumber: settings.companyDetails.bankAccountNumber,
        bankCode: settings.companyDetails.bankCode,
        swiftCode: settings.companyDetails.swiftCode,
        payoutCommissionRate: settings.payoutCommissionRate,
        delayPayoutDays: settings.delayPayoutDays,
        returnEligibilityDays: settings.returnEligibilityDays,
        retailerCountrySettings: settings.retailerCountrySettings.sort(
          (country1, country2) => {
            const firstCode = country1.currencyCode;
            const secondCode = country2.currencyCode;
            return firstCode < secondCode ? -1 : firstCode > secondCode ? 1 : 0;
          },
        ),
      }));
    }
  }, [isLoadingGet, settings]);
  const onSuccess = () => {
    void queryClient.fetchQuery({ queryKey: [SETTINGS_KEY] });
    toast({
      position: "top",
      description: "Changes have been saved successfully",
    });
  };
  const { mutateAsync: postSettings, isLoading: isLoadingPost } =
    usePostSettings(onSuccess);

  const isFormValid = useMemo(() => {
    return !isDirty || !isValid;
  }, [watchCompanyName, isDirty, isValid]);

  const onSubmit = handleSubmit(() => {
    void postSettings(getValues());
  });

  return {
    control,
    register,
    errors,
    isFormValid,
    getValues,
    onSubmit,
    setValue,
    watchCompanyName,
    isLoadingGet,
    isLoadingPost,
    fields,
  };
};
