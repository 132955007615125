import { Flex, Spinner, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { PromotionResponse } from "./usePromotions";
import { Promotion } from "components/Promotion/Promotion";

export const PromotionList = ({
  promotions,
  isLoading,
  onPromotionClick,
}: {
  promotions: PromotionResponse[];
  isLoading: boolean;
  onPromotionClick: (promotion: PromotionResponse) => void;
}) => {
  const { t } = useTranslation();

  return (
    <Flex direction="column" padding={5}>
      {isLoading ? (
        <Spinner position="absolute" top="50%" left="50%" />
      ) : promotions.length > 0 ? (
        promotions.map((promotion) => (
          <Promotion
            {...promotion}
            key={promotion.id}
            promotionKey={promotion.key}
            onPromotionClick={onPromotionClick}
            marginBottom={4}
          />
        ))
      ) : (
        <Text color="grey.600" textStyle="bodyM">
          {t("promotions.no_promotions")}
        </Text>
      )}
    </Flex>
  );
};
