import { Button, Flex, Heading } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { EditAdminForm } from "./EditAdminForm";
import { useEditAdminForm } from "./hooks/useEditAdminForm";
import { AdminAccount } from "features/UserManagement/types";
import { convertFromAccessFormat } from "features/UserManagement/utils";

type AdminDetailsProps = {
  adminAccount?: AdminAccount;
};
export const AdminDetails = ({ adminAccount }: AdminDetailsProps) => {
  const { t } = useTranslation();
  const {
    control,
    register,
    errors,
    onSubmit,
    isFormValid,
    isLoading: isCreateLoading,
  } = useEditAdminForm({
    defaultState: {
      id: adminAccount?.id ?? "",
      account: convertFromAccessFormat(adminAccount?.policy?.account ?? ""),
      settings: convertFromAccessFormat(adminAccount?.policy?.settings ?? ""),
      users: convertFromAccessFormat(adminAccount?.policy?.users ?? ""),
      financials: convertFromAccessFormat(
        adminAccount?.policy?.financials ?? "",
      ),
      promotions: convertFromAccessFormat(
        adminAccount?.policy?.promotions ?? "",
      ),
    },
  });
  return (
    <Flex pl={5} mt={4.5}>
      <Flex flexDir="column" w="300px">
        <Heading as="h4" size="h4" fontWeight="bold" mb={6}>
          {t("Admin policies")}
        </Heading>
        <EditAdminForm
          register={register}
          errors={errors}
          onSubmit={onSubmit}
          control={control}
          isEditLoading={isCreateLoading}
        />
      </Flex>
      <Button
        size="lg"
        colorScheme="greyAlpha"
        position="absolute"
        left="90%"
        top="90%"
        type="submit"
        form="editAdmin"
        isDisabled={isFormValid}
      >
        {t("Save changes")}
      </Button>
    </Flex>
  );
};
