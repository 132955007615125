/* eslint-disable @typescript-eslint/unbound-method */
import { switchAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyleTrack = defineStyle({
  padding: "1px",
  bgColor: "grey.400",
  _disabled: {
    bgColor: "grey.500",
  },
  _hover: {
    bgColor: "grey.500",
  },
  _checked: {
    bgColor: "primary.500",
    _hover: {
      bgColor: "primary.700",
    },
  },
});

const baseStyleThumb = defineStyle({
  _disabled: {
    bgColor: "grey.200",
  },
});

const baseStyle = definePartsStyle({
  container: {
    padding: "0px",
    margin: "0px",
  },
  track: baseStyleTrack,
  thumb: baseStyleThumb,
});

const sizes = {
  md: {
    track: {
      width: "26px",
      height: "14px",
    },
    thumb: {
      width: "14px",
      height: "14px",
      padding: "1px",
      _checked: {
        transform: "translateX(12px)",
      },
    },
  },
  lg: {
    track: {
      width: "32px",
      height: "16px",
      padding: "2px",
    },
    thumb: {
      width: "16px",
      height: "16px",
      _checked: {
        transform: "translateX(16px)",
      },
    },
  },
};

export const Switch = defineMultiStyleConfig({ baseStyle, sizes });
