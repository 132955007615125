import { Text } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { format, parseISO } from "date-fns";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Usage } from "../hooks/useGetPaymentTermsUsage";

type UseSetupUsageTableProps = {
  usages: Usage[];
  outstanding: number | null;
  currency: string;
};

export const useSetupUsageTable = ({
  usages,
  outstanding,
  currency,
}: UseSetupUsageTableProps) => {
  type UsageTable = {
    type: string;
    currency: string;
    amount: number;
  } & Usage;
  const columnHelper = createColumnHelper<UsageTable>();
  const { t } = useTranslation();

  const termColumns = [
    columnHelper.accessor("type", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700" fontWeight="bold">
          {row.original.type}
        </Text>
      ),
      header: "Method",
      size: 20,
      enableSorting: false,
    }),
    columnHelper.accessor("transactionReference", {
      cell: ({ row }) =>
        row.original.transactionReference && (
          <Text textStyle="bodyS" color="grey.700">
            {row.original.transactionReference}
          </Text>
        ),
      header: "Payment ID",
      size: 40,
      enableSorting: false,
    }),
    columnHelper.accessor("creationDate", {
      cell: ({ row }) =>
        row.original.creationDate && (
          <Text textStyle="bodyS" color="grey.700">
            {format(parseISO(row.original.creationDate), "dd-MM-yyyy HH:mm:ss")}
          </Text>
        ),
      header: "Date Created",
      size: 20,
      enableSorting: false,
    }),
    columnHelper.accessor("dueDate", {
      cell: ({ row }) =>
        row.original.type === "Debt" && (
          <Text textStyle="bodyS" color="grey.700">
            {format(parseISO(row.original.dueDate), "dd-MM-yyyy")}
          </Text>
        ),
      header: "Due Date",
      size: 20,
      enableSorting: false,
      footer: () => (
        <Text textStyle="bodyS" fontWeight="bold" float="right">
          Outstanding:
        </Text>
      ),
    }),
    columnHelper.accessor("amount", {
      cell: ({ row }) =>
        row.original.amount && (
          <Text textStyle="bodyS" color="grey.700">
            {Intl.NumberFormat("en-US", {
              style: "currency",
              currency: currency,
            }).format(row.original.amount)}
          </Text>
        ),
      header: "Amount",
      size: 10,
      enableSorting: false,
      footer: () => (
        <Text textStyle="bodyS" fontWeight="bold" float="left" pl={4}>
          {Intl.NumberFormat("en-US", {
            style: "currency",
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            currency: currency ?? "EUR",
          }).format(outstanding ?? 0)}
        </Text>
      ),
    }),
  ];

  const termRows = useMemo((): Usage[] => {
    if (!usages.length) {
      return [];
    }

    if (usages.length > 1) {
      return [
        ...usages.map((item) => {
          let type;
          let amount;
          if (item.payIn) {
            type = t("retailer_requests.pay_in");
            amount = item.payIn;
          } else if (item.payOut) {
            type = t("retailer_requests.debt");
            amount = item.payOut;
          }
          return {
            type,
            amount,
            currency: currency,
            creationDate: item.creationDate,
            dueDate: item.dueDate,
            transactionReference: item.transactionReference,
          } as UsageTable;
        }),
      ];
    }

    return [];
  }, [currency, usages, t]);

  return {
    termColumns,
    termRows,
  };
};
