import { Flex, Spinner } from "@chakra-ui/react";

import { useSetupReturnReqestsTable } from "../hooks/useSetupReturnReqestsTable";
import { useGetHistoryRequests } from "./hooks/useGetHistoryRequests";
import { EmptyList } from "components/EmptyList/EmptyList";
import { TableWrapper } from "components/Table/TableWrapper";

export const History = () => {
  const { columns } = useSetupReturnReqestsTable("history");
  const { data: history, isLoading } = useGetHistoryRequests();
  if (isLoading) {
    return <Spinner position="absolute" top="50%" left="50%" />;
  }
  return (
    <Flex display="column" width="100%" pt={4} pl={1} pr={1}>
      {history && history.length > 0 ? (
        <TableWrapper
          tableData={history}
          columns={columns}
          data-test-id="submitted-table"
        />
      ) : (
        <EmptyList />
      )}
    </Flex>
  );
};
