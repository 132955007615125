import { useMutation } from "@tanstack/react-query";

import { AddWalletCreditFields } from "./useAddWalletCredit";
import { ErrorResponse } from "services/ErrorResponse";
import httpClient from "services/httpClient";

export const POST_WALLET_CREDIT = "/admin/retailer/wallet/";

const postWalletCredit = async (form: AddWalletCreditFields) => {
  return await httpClient.post(`${POST_WALLET_CREDIT}${form.id}`, {
    id: form.id,
    amount: Number(form.amount),
    reason: form.reason,
  });
};

export const usePostWalletCredit = (
  onSuccess: () => void,
  onError: (axiosError: ErrorResponse) => void,
) =>
  useMutation(postWalletCredit, {
    onSuccess,
    onError,
  });
