/* eslint-disable @typescript-eslint/no-misused-promises */
import { Button, Flex, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useForm } from "react-hook-form";

import { downloadFile } from "./utils/helpers";
import { PanelForm } from "components/PanelForm/PanelForm";
import { ErrorResponse } from "services/ErrorResponse";
import httpClient from "services/httpClient";

// TODO: add inputs for shipment filtering
type Inputs = {
  fields?: string; // optional
};
export const ESShipments = () => {
  const REQUEST_URL = "/admin/support/defunct-pickups.csv";

  const postRequest = async (form: Inputs) => {
    return await httpClient.post(REQUEST_URL, {});
  };

  const usePostRequest = (
    onSuccess: (response: AxiosResponse) => void,
    onError: (data: ErrorResponse) => void,
  ) =>
    useMutation(postRequest, {
      onSuccess,
      onError,
    });

  // Toast
  const toast = useToast();

  const onSuccess = (response: AxiosResponse) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { data } = response;
    downloadFile(data, "text/csv");
    toast({
      position: "top",
      status: "success",
      description: `Success`,
    });
  };

  const onError = (error: ErrorResponse) => {
    toast({
      position: "top",
      status: "error",
      description: `Failed: ${error.response.data.code[0]}`,
    });
  };

  const { mutateAsync: shipmentsCsv, isLoading: isMutating } = usePostRequest(
    onSuccess,
    onError,
  );

  const { handleSubmit, getValues } = useForm<Inputs>();

  const onSubmit = handleSubmit(async () => {
    await shipmentsCsv(getValues());
  });

  return (
    <PanelForm title={"Download ES Shipments"}>
      <Flex
        justifyContent="space-between"
        borderRadius="4px 4px 0px 0px"
        textStyle="bodyS"
        fontWeight="medium"
        pt={2.5}
        gap={5}
        flexDir={"column"}
      >
        <Flex w={250}>
          <Button size={"lg"} onClick={onSubmit} disabled={isMutating}>
            Submit
          </Button>
        </Flex>
      </Flex>
    </PanelForm>
  );
};
