/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Button, Flex, Link, Text } from "@chakra-ui/react";
import { format, parseISO } from "date-fns";
import { FieldArrayWithId } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { GET_READY_FOR_REVIEW_REQUESTS } from "../ReadyForReview/hooks/useGetReadyForReviewRequests";
import { ReturnRequestDetails } from "./hooks/useGetReturnRequestDetails";
import { usePutResolveRequest } from "./hooks/usePutResolveRequest";
import { ProductQuantityFields } from "./hooks/useReturnRequestDetails";
import { TextWithIcon } from "components/TextWithIcon/TextWithIcon";
import { queryClient } from "services/queryClient";
import { RegularLocation } from "styles/icons/regular";

type HeaderActionsProps = {
  returnRequest: ReturnRequestDetails;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields: FieldArrayWithId<ProductQuantityFields, "products">[] | any[];
};
export const HeaderActions = ({
  returnRequest,
  fields,
}: HeaderActionsProps) => {
  const navigate = useNavigate();
  const onSuccess = () => {
    void queryClient.invalidateQueries({
      queryKey: [GET_READY_FOR_REVIEW_REQUESTS],
    });

    navigate(`/return-requests#0`);
  };
  const { mutateAsync: postResolveRequest, isLoading } =
    usePutResolveRequest(onSuccess);
  const { t } = useTranslation();
  const { status } = returnRequest;

  return (
    <Flex mt={2} mr={8} align="center">
      {status === "CONFIRMED" && returnRequest.pickupTime && (
        <Flex align="center" mr={8}>
          <Text fontWeight="bold" textStyle="bodyS">
            {t("order.pickup_time")}
          </Text>
          <Text ml={1} textStyle="bodyS">
            {format(
              parseISO(returnRequest.pickupTime.date),
              "EEEE, MMMM dd, yyyy,",
            )}
          </Text>
          <Text textStyle="bodyS">{`${returnRequest.pickupTime.fromTime}-${returnRequest.pickupTime.toTime}`}</Text>
        </Flex>
      )}
      {status === "IN_DELIVERY" && (
        <Link href={returnRequest.trackUrl} target="_blank">
          <TextWithIcon
            icon={RegularLocation}
            text={t("order.track_order")}
            flexProps={{
              color: "#1C7EC6",
              cursor: "pointer",
            }}
          />
        </Link>
      )}
      {returnRequest.resolvedBy && (
        <Flex align="center" mr={8}>
          <Text fontWeight="bold" textStyle="bodyS">
            {t("order.resolved_by")}
          </Text>
          <Text ml={1} textStyle="bodyS">
            {returnRequest.resolvedBy}
          </Text>
        </Flex>
      )}
      {returnRequest.status === "PENDING_REVIEW" && (
        <Flex align="center">
          <Button
            variant="solid"
            isLoading={isLoading}
            onClick={() =>
              void postResolveRequest({
                form: fields,
                id: returnRequest.id,
              })
            }
          >
            {t("return_requests.submit_resolution")}
          </Button>
        </Flex>
      )}
    </Flex>
  );
};
