import { useMutation } from "@tanstack/react-query";

import { ErrorResponse } from "services/ErrorResponse";
import httpClient from "services/httpClient";

export const POST_APPROVE_BR =
  "/admin/business-registration-requests/approve/{id}";
export const POST_REJECT_BR =
  "/admin/business-registration-requests/reject/{id}";

const postApproveBr = async ({ id, note }: { id: string; note: string }) => {
  return await httpClient.post(POST_APPROVE_BR.replace("{id}", id), {
    note,
  });
};

const postRejectBr = async ({ id, note }: { id: string; note: string }) => {
  return await httpClient.post(POST_REJECT_BR.replace("{id}", id), {
    note,
  });
};

export const usePostApproveBr = (
  onSuccess: () => void,
  onError: (axiosError: ErrorResponse) => void,
) =>
  useMutation(postApproveBr, {
    onSuccess,
    onError,
  });

export const usePostRejectBr = (
  onSuccess: () => void,
  onError: (axiosError: ErrorResponse) => void,
) =>
  useMutation(postRejectBr, {
    onSuccess,
    onError,
  });
