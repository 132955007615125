/* eslint-disable react/destructuring-assignment */
import { Flex, Icon, Link, Text } from "@chakra-ui/react";
import { format, parseISO } from "date-fns";

import { SolidDocument } from "styles/icons/solid";
import { ReactComponent as ExcelIcon } from "styles/icons/svg/ExcelIcon.svg";
import { ReactComponent as PdfIcon } from "styles/icons/svg/PdfIcon.svg";

type DocumentProps = {
  url: string;
  name: string;
  date?: string;
};

export const Document = ({ url, name, date }: DocumentProps) => {
  const GeneratedDocumentImage = (url: string) => {
    if (url.includes("pdf")) {
      return <PdfIcon />;
    } else if (url.includes("xlsx") || url.includes("xls")) {
      return <ExcelIcon />;
    }
    return <Icon as={SolidDocument} height={11} width={10} color="#1C7EC6" />;
  };
  return (
    <Link href={url} isExternal w="fit-content">
      <Flex>
        {GeneratedDocumentImage(url)}
        <Flex
          textStyle="bodyS"
          flexDir="column"
          color="grey.700"
          ml={3}
          alignSelf="center"
        >
          <Text>{name}</Text>
          {date && <Text>{format(parseISO(date), "dd-MM-yyyy")}</Text>}
        </Flex>
      </Flex>
    </Link>
  );
};
