import { avatarAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(avatarAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    bg: "grey.400",
    color: "greyAlpha.500",
    div: {
      fontSize: "4xl",
      lineHeight: "4xl",
      fontWeight: "semibold",
    },
    path: {
      fill: "greyAlpha.400",
    },
  },
});

const sizes = {
  md: {
    container: {
      width: 10,
      height: 10,
    },
  },
  sm: {
    container: {
      width: 8,
      height: 8,
      div: {
        fontSize: "lg",
        lineHeight: "xl",
      },
    },
  },
  xs: {
    container: {
      width: 6,
      height: 6,
      div: {
        fontSize: "sm",
        lineHeight: "tall",
      },
    },
  },
  lg: {
    container: {
      width: 14,
      height: 14,
      div: {
        fontSize: "5xl",
        lineHeight: "5xl",
      },
    },
  },
};

export const Avatar = defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: "sm",
  },
});
