/* eslint-disable @tanstack/query/exhaustive-deps */
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { RetailerDetailsType } from "features/UserManagement/types";
import { RETAILERS_KEY } from "services/ReactQueryKeys";
import httpClient from "services/httpClient";

export const GET_ALL_RETAILERS_URL = "/admin/retailer";

export const useGetAllRetailerById = (
  id: string,
): UseQueryResult<RetailerDetailsType> =>
  useQuery({
    queryKey: [RETAILERS_KEY, id],
    queryFn: async () => {
      const { data: retailerAccount } =
        await httpClient.get<RetailerDetailsType>(
          `${GET_ALL_RETAILERS_URL}/${id}`,
        );
      return retailerAccount;
    },
  });
