import {
  type ThemeConfig,
  extendTheme,
  theme,
  withDefaultColorScheme,
} from "@chakra-ui/react";
import { MultiSelectTheme } from "chakra-multiselect";

import * as components from "./components";
import * as foundations from "./foundations";

// Chakra Configuration on Initial Mode
const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const symphonyTheme = extendTheme(
  {
    config,
    ...foundations,
    components: {
      ...components,
      MultiSelect: MultiSelectTheme,
    },
  },
  withDefaultColorScheme({ colorScheme: "primary" }),
  theme,
);

export default symphonyTheme;
