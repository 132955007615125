/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Box, Flex, Input, Link, Spinner, Text } from "@chakra-ui/react";
import { SortingState, createColumnHelper } from "@tanstack/react-table";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Brand } from "../types";
import { capitalizeFirstLetter, convertToStatusVariant } from "../utils";
import { useExportBrands } from "./hooks/useExportBrands";
import { useGetAllBrands } from "./hooks/useGetAllBrands";
import { Button } from "components/Button/Button";
import { Status } from "components/Status/Status";
import { TableWrapper } from "components/Table/TableWrapper";
import { LightDocument } from "styles/icons/light";
import { DEBOUNCED_SEARCH, downloadCSV, formatDate } from "utils/functions";

type BrandsTable = {
  action: string;
  newRelicLink: string;
} & Brand;

export const BrandsTableOverview = () => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>("" as unknown as HTMLInputElement);
  const [globalFilter, setGlobalFilter] = useState("");

  const { data: allBrands, isLoading } = useGetAllBrands();

  const { mutateAsync: exportBrands, isLoading: isMutating } =
    useExportBrands();

  const initialSort: SortingState = [{ id: "email", desc: true }];
  const columnHelper = createColumnHelper<BrandsTable>();
  const columns = [
    columnHelper.accessor("storeName", {
      cell: ({ row }) => (
        <Flex>
          <Text textStyle="bodyS" color="grey.700">
            {row.original.storeName}
          </Text>
        </Flex>
      ),
      header: "Store Name",
      size: 30,
    }),
    columnHelper.accessor("email", {
      cell: ({ row }) => (
        <Flex>
          <Text textStyle="bodyS" color="grey.700">
            {row.original.email}
          </Text>
        </Flex>
      ),
      header: "Email",
      size: 30,
    }),
    columnHelper.accessor("categoryName", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {row.original.categoryName}
        </Text>
      ),
      header: "Category",
      size: 20,
    }),
    columnHelper.accessor("country", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {row.original.country}
        </Text>
      ),
      header: "Country",
      size: 20,
    }),
    columnHelper.accessor((row) => formatDate(row.creationDate), {
      id: "creationDate",
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {formatDate(row.original.creationDate)}
        </Text>
      ),
      header: "Creation Date",
      sortingFn: (a, b) => {
        return (
          new Date(a.original.creationDate).getTime() -
          new Date(b.original.creationDate).getTime()
        );
      },
    }),
    columnHelper.accessor("status", {
      cell: (row) => (
        <Status
          variant={convertToStatusVariant(
            row.getValue() ? "INACTIVE" : "ACTIVE",
          )}
          text={capitalizeFirstLetter(row.getValue() ? "INACTIVE" : "ACTIVE")}
        />
      ),
      header: "Status",
      size: 20,
    }),
    columnHelper.accessor("action", {
      cell: ({ row }) => (
        <Link href={`/user-management/brand/${row.original.id}`}>
          <Button variant="outline">View</Button>
        </Link>
      ),
      header: "Action",
      size: 10,
    }),
  ];

  if (isLoading) {
    return <Spinner position="absolute" top="50%" left="50%" />;
  }

  const handleOnExport = async () => downloadCSV(await exportBrands());

  const handleSearchBar = () => {
    setGlobalFilter(inputRef.current.value);
  };

  return (
    <Flex display="column" width="100%">
      <Flex pt={2.5} pb={5} justifyContent="space-between">
        <Box></Box>
        <Box></Box>
      </Flex>
      <Flex justify="space-between">
        <Input
          width="xs"
          mb={5}
          mr={5}
          placeholder={t("orders.search")}
          onChange={DEBOUNCED_SEARCH(handleSearchBar, 500)}
          ref={inputRef}
        />
        <Button
          leftIcon={LightDocument}
          onClick={() => void handleOnExport()}
          disabled={isMutating}
        >
          {t("retailer_details.export")}
        </Button>
      </Flex>
      <TableWrapper
        tableData={allBrands}
        columns={columns}
        initialSort={initialSort}
        data-test-id="brand-table"
        globalFilter={globalFilter}
      />
    </Flex>
  );
};
