import { useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { usePutBrandBrandName } from "./usePutBrandName";
import { ErrorResponse } from "services/ErrorResponse";
import { BRANDS_KEY } from "services/ReactQueryKeys";
import { getErrorCodes } from "services/httpClient";
import { queryClient } from "services/queryClient";

export type ChangeBrandBrandNameFields = {
  newBrandName: string;
  confirmNewBrandName: string;
  brandId: string;
};

type UseChangeBrandNameFormProps = {
  brandId: string;
  onClose: () => void;
};

export const useChangeBrandNameForm = ({
  brandId,
  onClose,
}: UseChangeBrandNameFormProps) => {
  const { t } = useTranslation();
  const toast = useToast();

  const {
    register,
    handleSubmit,
    control,
    getValues,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm<ChangeBrandBrandNameFields>({
    defaultValues: {
      brandId,
    },
    mode: "onChange",
  });

  const onSuccess = () => {
    onClose();
    toast({
      position: "top-right",
      description: t("brand_settings.brand_name_change_success"),
    });
    reset({
      brandId,
      newBrandName: "",
      confirmNewBrandName: "",
    });

    void queryClient.invalidateQueries({
      queryKey: [BRANDS_KEY, brandId],
    });
  };

  const onError = (error: ErrorResponse) => {
    toast({
      position: "top-right",
      status: "error",
      description: t(getErrorCodes(error)),
    });
  };

  const { mutateAsync: putBrandBrandName, isLoading } = usePutBrandBrandName(
    onSuccess,
    onError,
  );

  const onSubmit = handleSubmit(async () => {
    await putBrandBrandName({
      newBrandName: getValues("newBrandName").trim(),
      confirmNewBrandName: getValues("confirmNewBrandName").trim(),
      brandId,
    });
  });

  return {
    register,
    onSubmit,
    control,
    getValues,
    reset,
    errors,
    isDirty,
    isValid,
    isLoading,
  };
};
