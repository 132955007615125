import { As, Flex, FlexProps, Icon, Text } from "@chakra-ui/react";
import { ReactNode } from "react";

type PanelProps = {
  title: string;
  titleIcon?: As;
  children: ReactNode;
} & FlexProps;
export const Panel = ({ title, titleIcon, children, ...rest }: PanelProps) => {
  return (
    <Flex
      w="480px"
      p={5}
      flexDir="column"
      borderRadius="base"
      boxShadow={1}
      border="1px solid"
      borderColor="grey.400"
      {...rest}
    >
      <Flex>
        {titleIcon ? <Icon as={titleIcon} w={6} h={6} /> : null}
        <Text textStyle="bodyM" color="grey.700" fontWeight="semibold">
          {title}
        </Text>
      </Flex>
      <Flex flexDir="column" mt={4}>
        {children}
      </Flex>
    </Flex>
  );
};
