import {
  Button,
  Flex,
  Heading,
  Icon,
  Image,
  Link,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { isPast } from "date-fns";
import { useTranslation } from "react-i18next";

import { Status } from "components/Status/Status";
import { DeletePromotionModal } from "features/Promotions/DeletePromotionModal";
import { PromotionResponse } from "features/Promotions/usePromotions";
import { useFormatDateRange } from "hooks/useFormatDateRange";
import { RegularDelete } from "styles/icons/regular";

// Omitting 'key' and replacing with 'promotionKey' since it is a reserved keyword in React
type PromotionProps = Omit<PromotionResponse, "key"> & {
  onPromotionClick: (promotion: PromotionResponse) => void;
  marginBottom?: number;
  promotionKey: string;
};

export const Promotion = ({
  id,
  discount,
  fromDate,
  untilDate,
  name,
  promotionKey,
  description,
  imageUrl,
  brands = [],
  imageId,
  onPromotionClick,
  marginBottom,
}: PromotionProps) => {
  const { t } = useTranslation();

  const start = new Date(fromDate);
  const end = new Date(untilDate);
  const formattedDate = useFormatDateRange(start, end);
  const isActive = isPast(start) && !isPast(end);
  const isFinished = isPast(end);

  const {
    isOpen: isOpenDeletePromotionModal,
    onOpen: onOpenDeletePromotionModal,
    onClose: onCloseDeletePromotionModal,
  } = useDisclosure();

  const displayBrands: string[] = brands.map((brand) => brand.name);

  const handlePromotionClick = () => {
    if (!isFinished) {
      onPromotionClick({
        id,
        key: promotionKey,
        discount,
        fromDate,
        untilDate,
        name,
        description,
        imageUrl,
        imageId,
        brands,
      });
    }
  };

  return (
    <Flex
      borderWidth={1}
      borderRadius={4}
      borderColor="grey.400"
      padding={5}
      position="relative"
      userSelect="none"
      cursor={isFinished ? "auto" : "pointer"}
      marginBottom={marginBottom}
      _hover={!isFinished ? { borderColor: "primary.500" } : undefined}
      onClick={handlePromotionClick}
    >
      {imageUrl && (
        <Link
          href={`https://www.markato.com/search/promotion/${promotionKey}`}
          isExternal
          _hover={{ textDecoration: "none" }}
        >
          <Image src={imageUrl} width={146} height={146} />
        </Link>
      )}
      <Flex
        direction="column"
        marginLeft={5}
        justify="space-between"
        width="100%"
      >
        <Flex width="100%" justify="space-between">
          <Flex alignItems="center">
            <Text textStyle="bodyS" color="gray.700" fontWeight={700}>
              {formattedDate}
            </Text>
            {isActive && <Status variant="done" text="Active" marginLeft={3} />}
          </Flex>

          {!isPast(end) && (
            <Button
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                onOpenDeletePromotionModal();
              }}
            >
              <Icon
                as={RegularDelete}
                color="white"
                cursor="pointer"
                borderRadius={1}
                _hover={{ background: "greyAlpha.100" }}
                aria-label={t("promotions.delete")}
              />
              {t("Delete")}
            </Button>
          )}
        </Flex>
        <Heading
          textStyle="h3"
          color="gray.700"
          fontWeight={700}
          marginBottom={1}
        >
          {name}
        </Heading>
        <Text
          textStyle="bodyS"
          color="grey.600"
          fontWeight={400}
          marginBottom={2}
        >
          {description}
        </Text>
        <Flex wrap="wrap" alignItems={"center"}>
          {displayBrands.map((brandKey) => (
            <Text
              key={brandKey}
              borderRadius={3}
              marginRight={1}
              backgroundColor="blue.50"
              paddingX={2}
              paddingY={0.5}
              marginY={0.5}
              whiteSpace="nowrap"
            >
              {brandKey}
            </Text>
          ))}
          {displayBrands.length > 0 && `(Total: ${displayBrands.length})`}
        </Flex>
      </Flex>
      {isOpenDeletePromotionModal && (
        <DeletePromotionModal
          isOpen={isOpenDeletePromotionModal}
          onClose={onCloseDeletePromotionModal}
          promotionId={id}
        />
      )}
    </Flex>
  );
};
