import {
  Avatar,
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { format, parseISO } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  RetailerRequest,
  useGetRetailerRequests,
} from "../hooks/useGetRetailerRequests";
import { Button } from "components/Button/Button";
import { TableWrapper } from "components/Table/TableWrapper";
import { SolidSearch } from "styles/icons/solid";

export const PaymentTermsTableOverview = () => {
  const { t } = useTranslation();
  const { data: retailerRequests, isLoading } = useGetRetailerRequests();

  const [globalFilter, setGlobalFilter] = useState("");

  type RetailerRequestTable = {
    action: string;
  } & RetailerRequest;

  const columnHelper = createColumnHelper<RetailerRequestTable>();
  const columns = [
    columnHelper.accessor((row) => [row.name, row.email].join(","), {
      cell: ({ row }) => (
        <Flex>
          <Avatar name={`${row.original.name}`} mr={4} />
          <Flex flexDirection="column">
            <Text textStyle="bodyS" color="grey.700">
              {row.original.name}
            </Text>
            <Text
              textStyle="bodyXXS"
              color="grey.500"
              maxW="250px"
              textOverflow="ellipsis"
              overflow="auto"
            >
              {row.original.email}
            </Text>
          </Flex>
        </Flex>
      ),
      header: t("retailer_requests.name"),
      size: 40,
    }),
    columnHelper.accessor(
      (row) => format(parseISO(row.creationDate), "dd-MM-yyyy"),
      {
        id: "creationDate",
        cell: ({ row }) =>
          row.original.creationDate && (
            <Text textStyle="bodyS" color="grey.700">
              {format(parseISO(row.original.creationDate), "dd-MM-yyyy")}
            </Text>
          ),
        header: t("retailer_requests.date"),
        size: 20,
        sortingFn: (a, b) => {
          return (
            new Date(a.original.creationDate).getTime() -
            new Date(b.original.creationDate).getTime()
          );
        },
      },
    ),
    columnHelper.accessor("category", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {row.original.category}
        </Text>
      ),
      header: t("retailer_requests.category"),
      size: 20,
    }),
    columnHelper.accessor("country", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {row.original.country}
        </Text>
      ),
      header: t("retailer_requests.country"),
      size: 20,
    }),
    columnHelper.accessor("action", {
      cell: ({ row }) => (
        <Link href={`/retailer-requests/${row.original.id}`}>
          <Button variant="outline" mr={9}>
            {t("retailer_requests.view")}
          </Button>
        </Link>
      ),
      header: t("retailer_requests.action"),
      size: 20,
    }),
  ];

  if (isLoading) {
    return <Spinner position="absolute" top="50%" left="50%" />;
  }

  return (
    <Flex display="column" width="100%">
      {retailerRequests && retailerRequests.length > 0 ? (
        <>
          <Flex pt={2.5} pb={5} justifyContent="space-between">
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                // eslint-disable-next-line react/no-children-prop
                children={
                  <SolidSearch color="#B3B3B8" width={16} height={16} />
                }
              />
              <Input
                width="sm"
                mb={5}
                mr={5}
                pl={7}
                placeholder="Search"
                value={globalFilter}
                onChange={(event) => setGlobalFilter(event.target.value)}
              />
            </InputGroup>
            <Box></Box>
          </Flex>
          <TableWrapper
            tableData={retailerRequests}
            columns={columns}
            globalFilter={globalFilter}
            data-test-id="retailer-requests-table"
          />
        </>
      ) : (
        <Text color="grey.600" textStyle="bodyM">
          There are no requests at the moment
        </Text>
      )}
    </Flex>
  );
};
