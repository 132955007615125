import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { AdminAccount } from "features/UserManagement/types";
import httpClient from "services/httpClient";

export const GET_ALL_ADMIN_URL = "/admin/accounts/";

export const useGetAdminById = (id: string): UseQueryResult<AdminAccount> =>
  useQuery({
    queryKey: [GET_ALL_ADMIN_URL, id],
    queryFn: async () => {
      const { data: adminAccount } = await httpClient.get<AdminAccount>(
        `${GET_ALL_ADMIN_URL}${id}`,
      );
      return adminAccount;
    },
  });
