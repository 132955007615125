/* eslint-disable @typescript-eslint/no-base-to-string */

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { SubOrder } from "./SubOrder";
import { Order } from "./hooks/useGetOrderById";

type OrderDetailsParams = {
  order: Order;
};

export const OrderDetails = ({ order }: OrderDetailsParams) => {
  const { t } = useTranslation();
  return (
    <Flex flexDir="column" py={5}>
      <Flex justifyContent="end">
        {order.subOrders[0]?.shippingDetails?.insuranceIncluded ? (
          <Text
            textStyle="bodyS"
            fontWeight="semibold"
            color="primary.500"
            pb={9}
          >
            {t("order.shipping_insurance_included")}
          </Text>
        ) : null}
      </Flex>
      <Flex
        border="1px solid"
        borderColor="grey.400"
        borderRadius="base"
        justifyContent="space-between"
        height={12}
        py={3}
        px={5}
        mb={5}
      >
        <Text textStyle="bodyL" fontWeight="bold">
          {`${t("order.brand_details")} (${order.subOrders.length})`}
        </Text>
        <Text textStyle="bodyL" fontWeight="bold">
          {`${t("order.grand_total")} ${Intl.NumberFormat("en-US", {
            style: "currency",
            currency: order.currency,
          }).format(order.total)}`}
        </Text>
      </Flex>
      {order.subOrders.map((item) => (
        <SubOrder
          key={item.id}
          subOrder={item}
          currency={order.currency}
          retailer={order.retailerDetails}
          creationDate={order.creationDate}
        />
      ))}
    </Flex>
  );
};
