/* eslint-disable @tanstack/query/exhaustive-deps */
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import httpClient from "services/httpClient";

export const GET_WALLET_CREDIT = "/admin/retailer/wallet/";

export type TransactionType =
  | "WALLET_CREDIT"
  | "REFUND"
  | "OVERPAY"
  | "CANCEL"
  | "WALLET_CREDIT_REFUND";

export type Transaction = {
  orderId: string | null;
  id: string;
  orderNumber: string;
  transactionReference: string;
  manuallyAddedCredit: boolean;
  creationDate: string;
  description?: string;
  email?: string;
  type: TransactionType;
  amount: number;
};
export type WalletCreditResponse = {
  transactionsSum: number;
  transactionsCurrency: string;
  transactions: Transaction[];
};

export const useGetWalletCredit = (
  id: string,
): UseQueryResult<WalletCreditResponse> =>
  useQuery({
    queryKey: [GET_WALLET_CREDIT],
    queryFn: async () => {
      const { data: wallet } = await httpClient.get<WalletCreditResponse>(
        `${GET_WALLET_CREDIT}${id}`,
      );
      return wallet;
    },
  });

export const convertWalletType = (transactionType: TransactionType) => {
  switch (transactionType) {
    case "REFUND":
      return "Refund";
    case "OVERPAY":
      return "Overpaid";
    case "CANCEL":
      return "Canceled";
    case "WALLET_CREDIT":
      return "Wallet Credit";
    case "WALLET_CREDIT_REFUND":
      return "Credit Refund";
    default:
      return transactionType;
  }
};
