import { Box, Flex, Heading } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ActiveOrders } from "./ActiveOrders";
import { OrderHistory } from "./OrderHistory";
import { TabData, TabWrapper } from "components/Tabs/TabWrapper";

export const Orders = () => {
  const [tabIndex, setTabIndex] = useState<number>(
    parseInt(window.location.hash.replace("#", "") || "0"),
  );

  const { t } = useTranslation();

  const tabsData: TabData[] = [
    {
      title: t("orders.active"),
      content: <ActiveOrders />,
    },
    {
      title: t("orders.history"),
      content: <OrderHistory />,
    },
  ];

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
    window.location.hash = `${index}`;
  };

  return (
    <Box margin="auto">
      <Flex display="column">
        <Heading as="h1" size="h1" fontWeight="bold" mb={2} mt={5} ml={10}>
          {t("orders.heading")}
        </Heading>
        <TabWrapper
          tabListProps={{ pl: 10 }}
          tabPanelsProps={{ pl: 2 }}
          tabsData={tabsData}
          tabIndex={tabIndex}
          handleTabsChange={handleTabsChange}
        />
      </Flex>
    </Box>
  );
};
