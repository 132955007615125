/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Button, Divider, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { SubOrder } from "../hooks/useGetOrderById";
import { useTrackingUrlForm } from "../hooks/useTrackingUrlForm";
import { InputField } from "components/InputField/InputField";
import { ModalWrapper } from "components/Modal/ModalWrapper";

type TrackingModalProps = {
  isOpen: boolean;
  onClose: () => void;
  subOrder: SubOrder;
};

export const TrackingModal = ({
  isOpen,
  onClose,
  subOrder,
}: TrackingModalProps) => {
  const { t } = useTranslation();
  const { onSubmit, reset, register, errors, trackingUrl, onSubmitExternal } =
    useTrackingUrlForm({
      onClose,
      suborderId: subOrder.id,
      subOrderNumber: subOrder.orderNumber,
    });

  return (
    <ModalWrapper
      modalProps={{ size: "l", isOpen, onClose, children: undefined }}
      isOpen={isOpen}
      onClose={onClose}
      modalHeader={
        <Flex flexDir="column" textStyle="h3" fontWeight="bold">
          <Text>{t("order.update_shipment_id")}</Text>
          <Text>{`#${subOrder.orderNumber}`}</Text>
        </Flex>
      }
      modalBody={
        <Flex flexDir="column" textStyle="bodyS">
          <>
            <Text textStyle="bodyM" mb={3}>
              {t("order.shipment_id_label")}
            </Text>
            <Flex>
              <InputField
                errors={errors}
                {...register("shipmentId", {
                  required: t("order.shipment_id_required"),
                })}
              />
            </Flex>
            <Divider mt={2} />
            <Text textStyle="bodyS">
              {t("order.set_shipment_id", {
                suborderId: subOrder.orderNumber,
              })}
            </Text>
          </>
        </Flex>
      }
      modalFooter={
        <>
          <Button
            variant="outline"
            fontWeight="600"
            size="lg"
            onClick={() => {
              onClose();
              reset();
            }}
            data-test-id="close-tracking-url-modal"
          >
            {t("Cancel")}
          </Button>
          <Button
            type="submit"
            form="addExternalTrackingUrl"
            variant="solid"
            size="lg"
            ml={3}
            data-test-id="post-external-tracking-url-button"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={onSubmitExternal}
            disabled={!trackingUrl}
          >
            {t("order.update_tracking_link")}
          </Button>
          <Button
            type="submit"
            form="addTrackingUrl"
            variant="solid"
            size="lg"
            ml={3}
            data-test-id="post-tracking-url-button"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={onSubmit}
            disabled={!trackingUrl}
          >
            {t("order.update_shipment_id")}
          </Button>
        </>
      }
    />
  );
};
