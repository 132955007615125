import { useMutation } from "@tanstack/react-query";

import { ChangeBrandEmailFields } from "./useChangeEmailForm";
import { ErrorResponse } from "services/ErrorResponse";
import httpClient from "services/httpClient";

const CHANGE_EMAIL = "/admin/brands/";

const putBrandEmail = async (form: ChangeBrandEmailFields) => {
  return await httpClient.put(`${CHANGE_EMAIL}${form.brandId}/change-email`, {
    newEmail: form.newEmail,
    confirmEmail: form.confirmEmail,
  });
};

export const usePutBrandEmail = (
  onSuccess: () => void,
  onError: (error: ErrorResponse) => void,
) =>
  useMutation(putBrandEmail, {
    onSuccess,
    onError,
  });
