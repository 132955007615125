import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { usePutBnpl } from "./usePutBnpl";
import { useSimpleToast } from "hooks/useSimpleToast";
import { ErrorResponse } from "services/ErrorResponse";
import { getErrorCodes } from "services/httpClient";

export type UpdateBnplFields = {
  retailerId: string;
  amount: number;
  note: string;
};

export const useUpdateBnpl = (
  retailerId: string,
  closeModal: () => void,
  isOpenModal: boolean,
) => {
  const {
    control,
    register,
    formState: { errors, isDirty, isValid },
    getValues,
    handleSubmit,
    reset,
  } = useForm<UpdateBnplFields>({
    defaultValues: { retailerId: retailerId },
  });
  const { t } = useTranslation();
  const toast = useSimpleToast();

  const onSuccess = () => {
    toast({
      position: "top",
      description: t("bnpl.updated"),
    });
    closeModal();
  };

  const onError = (error: ErrorResponse) =>
    toast({ status: "error", description: t(getErrorCodes(error)) });

  const { mutateAsync: putBnpl, isLoading } = usePutBnpl(onSuccess, onError);

  const handleUpdateBnpl = handleSubmit(async () => {
    await putBnpl({
      retailerId: getValues("retailerId"),
      amount: Number(getValues("amount")),
      note: getValues("note"),
    });
  });

  const isFormValid = useMemo(() => {
    return !isDirty || !isValid;
  }, [isDirty, isValid]);

  useEffect(() => {
    if (isOpenModal) {
      return;
    }
    reset({
      retailerId: retailerId,
      amount: undefined,
      note: "",
    });
  }, [retailerId, isOpenModal, reset]);

  return {
    control,
    register,
    errors,
    isFormValid,
    getValues,
    isLoading,
    handleUpdateBnpl,
  };
};
