import { Avatar, AvatarProps, Flex, Text } from "@chakra-ui/react";

export type AdminAvatarProps = {
  name: string;
  displayName: string;
  email: string;
  avatarProps?: AvatarProps;
};
export const AdminAvatar = ({
  name,
  displayName,
  email,
  avatarProps,
}: AdminAvatarProps) => {
  return (
    <Flex>
      <Avatar name={name} {...avatarProps} />
      <Flex flexDir="column" ml={3}>
        <Text textStyle="bodyS" color="grey.900">
          {displayName}
        </Text>
        <Text textStyle="bodyXSS" color="grey.600">
          {email}
        </Text>
      </Flex>
    </Flex>
  );
};
