import { Flex, Icon, Spinner, Text, Tooltip } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { format, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";

import {
  ApplicationHistory as ApplicationHistoryType,
  useGetApplicationHistory,
} from "../hooks/useGetApplicationHistory";
import { TableWrapper } from "components/Table/TableWrapper";
import { LightDocument } from "styles/icons/light";

type ApplicationHistoryProps = {
  id: string;
};
export const ApplicationHistory = ({ id }: ApplicationHistoryProps) => {
  const { t } = useTranslation();

  const columnHelper = createColumnHelper<ApplicationHistoryType>();
  const { data: applicationHistory, isLoading } = useGetApplicationHistory(id);

  const columns = [
    columnHelper.accessor("date", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {format(parseISO(row.original.date), "dd-MM-yyyy")}
        </Text>
      ),
      header: "Name",
      size: 10,
      enableSorting: false,
    }),
    columnHelper.accessor("limit", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {Intl.NumberFormat("en-US", {
            style: "currency",
            currency: row.original.currency,
          }).format(Number(row.original.limit))}
        </Text>
      ),
      header: "Desired Limit Amount",
      size: 10,
      enableSorting: false,
    }),
    columnHelper.accessor("rejectReason", {
      cell: ({ row }) =>
        row.original.rejectReason.length > 200 ? (
          <Tooltip
            hasArrow
            label={row.original.rejectReason}
            borderRadius="base"
            p={5}
            backgroundColor="white"
            color="grey.700"
          >
            <Text textStyle="bodyS" color="grey.700" overflow="hidden">
              {`${row.original.rejectReason.slice(0, 100)}...`}
            </Text>
          </Tooltip>
        ) : (
          <Text textStyle="bodyS" color="grey.700" overflow="hidden">
            {row.original.rejectReason.slice(0, 100)}
          </Text>
        ),
      header: "Rejection Reason",
      size: 55,
      enableSorting: false,
    }),
    columnHelper.accessor("status", {
      cell: ({ row }) => (
        <Text
          textStyle="bodyS"
          color={row.original.status === "APPROVED" ? "green.500" : "red.500"}
        >
          {row.original.status.charAt(0) +
            row.original.status.substring(1).toLowerCase()}
        </Text>
      ),
      header: "Status",
      size: 10,
      enableSorting: false,
    }),
  ];

  return (
    <Flex mt={4} ml={3} flexDir="column">
      {isLoading ? (
        <Spinner position="absolute" top="50%" left="50%" />
      ) : applicationHistory && applicationHistory.length > 0 ? (
        <TableWrapper
          tableData={applicationHistory}
          columns={columns}
          data-test-id="application-history-table"
        />
      ) : (
        <Flex
          flexDir="column"
          justify="center"
          align="center"
          position="absolute"
          top="50%"
          left="50%"
        >
          <Icon as={LightDocument} color="grey.600" boxSize={20} mb={5} />
          <Text color="grey.600" textStyle="bodyL">
            {t("retailer_requests.no_history")}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};
