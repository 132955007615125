import {
  Box,
  Divider,
  Flex,
  Heading,
  Link,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { PayoutOrders, useGetPayoutDetails } from "./hooks/useGetPayoutDetails";
import { usePutHoldUnhold } from "./hooks/usePutHoldUnhold";
import { usePutMarkAsPaid } from "./hooks/usePutMarkAsPaid";
import { Button } from "components/Button/Button";
import { ModalWrapper } from "components/Modal/ModalWrapper";
import { TableWrapper } from "components/Table/TableWrapper";
import { useSimpleToast } from "hooks/useSimpleToast";
import { PAYOUT_DETAILS_KEY } from "services/ReactQueryKeys";
import { getErrorCodes } from "services/httpClient";
import { queryClient } from "services/queryClient";
import { LightArrowLeft } from "styles/icons/light";
import { formatDate } from "utils/functions";

export const PayoutDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose: onCloseMarkAsPaidModal } = useDisclosure();
  const { payoutId } = useParams();
  const toast = useSimpleToast();

  const [selectedPayout, setSelectedPayout] = useState<PayoutOrders | null>(
    null,
  );

  type PayoutDetailsTable = PayoutOrders & {
    action: string;
  };
  const { data: payout, isLoading: isLoadingGet } = useGetPayoutDetails(
    payoutId ?? "",
  );

  useEffect(() => {
    if (payout) {
      const hasZeroBrandPayouts = !payout.orders.length;
      hasZeroBrandPayouts && navigate("/payouts");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payout]);

  const columnHelper = createColumnHelper<PayoutDetailsTable>();

  const { mutateAsync: holdToggle, isLoading: isLoadingHoldToggle } =
    usePutHoldUnhold();
  const { mutateAsync: markAsPaid, isLoading: isLoadingMarkAsPaid } =
    usePutMarkAsPaid();

  const handleToogleHold = async (id: string, isOnHold: boolean) => {
    await holdToggle({ id, type: isOnHold ? "hold" : "unhold" });

    toast({
      description: isOnHold
        ? t("payout.onhold_action")
        : t("payout.hold_action"),
    });
  };

  const handleMarkAsPay = async (payoutOrder: PayoutOrders) => {
    await markAsPaid(
      {
        id: payoutOrder.subOrderId,
        currencyCode: payout?.currency ?? "",
        amount: payoutOrder.amount,
      },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries({
            queryKey: [PAYOUT_DETAILS_KEY],
          });

          onCloseMarkAsPaidModal();
          toast({
            description: (
              <Trans
                i18nKey={"payout.mark_as_paid_success"}
                values={{
                  suborderNumber: selectedPayout?.orderNumber,
                }}
              />
            ),
          });
        },
        onError: (error) => {
          const errorCodes = getErrorCodes(error);
          toast({
            status: "error",
            description: t(errorCodes[0]),
          });
        },
      },
    );
  };

  const columns = [
    columnHelper.accessor("orderNumber", {
      cell: ({ row }) => (
        <Link
          href={`/orders/${row.original.orderId}`}
          textStyle="bodyS"
          color="#1C7EC6"
        >
          {`#${row.original.orderNumber}`}
        </Link>
      ),
      header: t("payout.order_header"),
      size: 15,
    }),
    columnHelper.accessor("retailerName", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {row.original.retailerName}
        </Text>
      ),
      header: t("payout.retailer_name"),
      size: 15,
    }),
    columnHelper.accessor((row) => formatDate(row.dateFrom), {
      id: "dateFrom",
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {formatDate(row.original.dateFrom)}
        </Text>
      ),
      header: t("payout.from_date"),
      size: 25,
      sortingFn: (a, b) => {
        return (
          new Date(a.original.dateFrom).getTime() -
          new Date(b.original.dateFrom).getTime()
        );
      },
    }),
    columnHelper.accessor((row) => formatDate(row.dateTo), {
      id: "dateTo",
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {formatDate(row.original.dateTo)}
        </Text>
      ),
      header: t("payout.to_date"),
      size: 15,
      sortingFn: (a, b) => {
        return (
          new Date(a.original.dateTo).getTime() -
          new Date(b.original.dateTo).getTime()
        );
      },
    }),

    columnHelper.accessor("amount", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {Intl.NumberFormat("en-US", {
            style: "currency",
            currency: payout?.currency,
            minimumFractionDigits: 2,
          }).format(Number(row.original.amount))}
        </Text>
      ),
      header: t("payout.amount"),
      size: 10,
    }),
    columnHelper.accessor("action", {
      cell: ({ row }) => (
        <Flex float="right">
          <Button
            mr={3}
            variant="outline"
            onClick={() => {
              void handleToogleHold(
                row.original.subOrderId,
                row.original.isHeld,
              );
            }}
            disabled={isLoadingHoldToggle}
          >
            {row.original.isHeld ? t("payout.unHold") : t("payout.hold")}
          </Button>
          <Button
            variant="outline"
            onClick={() => {
              setSelectedPayout(row.original);
              onOpen();
            }}
            disabled={isLoadingMarkAsPaid}
          >
            {t("payout.mark_as_paid")}
          </Button>
        </Flex>
      ),
      header: t("payout.action"),
      size: 20,
    }),
  ];

  if (isLoadingGet) {
    return <Spinner position="absolute" top="50%" left="50%" />;
  }

  return (
    <Flex display="column">
      <Flex mb={7} mt={8} ml={8}>
        <Button
          variant="ghost"
          colorScheme="grey"
          mainIcon={LightArrowLeft}
          aria-label="backButton"
          data-test-id="backToPayouts"
          alignSelf="center"
          mr={4}
          onClick={() => window.history.back()}
        />

        <Heading as="h1" size="h1" fontWeight="bold">
          {payout?.brandName}
        </Heading>
      </Flex>
      <Divider h="2px" />
      <Flex flexDir="column" px={10} py={8} w="100%">
        <Flex w="100%" justifyContent="space-between">
          <Flex flexDir="column">
            <Text textStyle="bodyL" fontWeight="bold" mb={2}>
              {t("order.order_details")}
            </Text>
            <Text textStyle="bodyS" mb={8}>
              From <b>{formatDate(payout?.dateFrom ?? "")}</b> to{" "}
              <b>{formatDate(payout?.dateTo ?? "")}</b>
            </Text>
          </Flex>
        </Flex>

        <Box>
          <TableWrapper
            tableData={payout?.orders ?? []}
            columns={columns}
            data-test-id="payout-details-table"
          />
        </Box>
      </Flex>
      <ModalWrapper
        modalHeader={t("payout.confirm_payout_mark_as_paid_header")}
        modalBody={
          <Flex direction="column">
            <Text>
              <Trans
                i18nKey={"payout.confirm_payout_mark_as_paid"}
                values={{
                  brandName: payout?.brandName,
                  suborderNumber: selectedPayout?.orderNumber,
                  currencyCode: payout?.currency,
                  amount: Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                  }).format(Number(selectedPayout?.amount)),
                }}
              />
            </Text>
            <Flex mt={5} gap={5} alignSelf="flex-end">
              <Button
                w={100}
                onClick={() => {
                  if (!selectedPayout) return;
                  void handleMarkAsPay(selectedPayout);
                }}
                disabled={isLoadingMarkAsPaid}
                isLoading={isLoadingMarkAsPaid}
              >
                {t("confirm")}
              </Button>
              <Button
                w={100}
                onClick={onCloseMarkAsPaidModal}
                disabled={isLoadingMarkAsPaid}
              >
                {t("cancel")}
              </Button>
            </Flex>
          </Flex>
        }
        isOpen={isOpen}
        onClose={onCloseMarkAsPaidModal}
      />
    </Flex>
  );
};
