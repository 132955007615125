/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */

/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { VoucherAppliedTo } from "../../UserManagement/types";
import { voucherTypeToLabel } from "../CreateVouchers/VoucherUtils";
import { usePostCreateVoucher } from "./usePostCreateVoucher";
import { Option } from "components/Select/Select";
import { useSimpleToast } from "hooks/useSimpleToast";
import { ErrorResponse } from "services/ErrorResponse";
import { VOUCHER_KEY } from "services/ReactQueryKeys";
import { getErrorCodes } from "services/httpClient";
import { queryClient } from "services/queryClient";

export type VoucherFormFields = {
  code: string;
  discountType: Option;
  discountAppliedTo: Option;
  discountValue: string;
  expiryDate: Date;
  quantity: number;
  maxUsage: number;
  minAmount: number;
  maxAmount: number;
  voucherCurrency: Option;
  firstOrder: "FIRST_ORDER" | "ALL_ORDERS";
  applicableBrands?: Option | null;
};

type UseCreateVouchersFormProps = {
  isOpen: boolean;
  onClose: () => void;
};
export const useCreateVouchersForm = ({
  isOpen,
  onClose,
}: UseCreateVouchersFormProps) => {
  const { t } = useTranslation();
  const {
    control,
    register,
    formState: { errors, isDirty, isValid },
    getValues,
    clearErrors,
    handleSubmit,
    setValue,
    watch,
    reset,
  } = useForm<VoucherFormFields>({
    defaultValues: {
      firstOrder: "ALL_ORDERS",
    },
  });

  const toast = useSimpleToast();

  const discountTypeOptions: Option[] = [
    {
      label: "Percentage",
      value: "percentage",
    },
    {
      label: "Fixed Amount",
      value: "fixedAmount",
    },
  ];

  const discountApplyOptions: Option[] = [
    "TOTAL_ORDER",
    "SHIPPING_FEE",
    "BRAND_ORDER",
    "BRAND_SHIPPING_FEE",
  ].map((it) => {
    return {
      label: voucherTypeToLabel(it as VoucherAppliedTo),
      value: it,
    };
  });

  const discountType = watch("discountType") ?? discountTypeOptions[0];
  const discountAppliedTo =
    watch("discountAppliedTo") ?? discountApplyOptions[0];

  const generateCode = useCallback(() => {
    setValue(
      "code",
      (Math.random().toString(36).split(".").pop() ?? "").toUpperCase(),
    );
    clearErrors("code");
  }, [setValue, clearErrors]);

  const onSuccess = () => {
    void queryClient.invalidateQueries({
      queryKey: [VOUCHER_KEY],
    });
    toast({
      position: "top",
      description: "New voucher created successfully",
    });
    reset();
    onClose();
  };

  const onError = (error: ErrorResponse) => {
    const errorCodes = getErrorCodes(error);
    toast({ status: "error", description: t(errorCodes[0]) });
  };

  const { mutateAsync: postCreateVoucher, isLoading } = usePostCreateVoucher(
    onSuccess,
    onError,
  );

  const onSubmit = handleSubmit(async () => {
    await postCreateVoucher(getValues());
  });

  const isFormValid = useMemo(() => {
    return !isDirty || !isValid;
  }, [isDirty, isValid]);

  useEffect(() => {
    if (isOpen) {
      return;
    }
    reset();
  }, [reset, isOpen]);

  return {
    control,
    register,
    errors,
    isFormValid,
    getValues,
    setValue,
    onSubmit,
    reset,
    discountTypeOptions,
    discountApplyOptions,
    generateCode,
    isLoading,
    discountType,
    discountAppliedTo,
    watch,
  };
};
