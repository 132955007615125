import { useMutation } from "@tanstack/react-query";

import httpClient from "services/httpClient";

export const LOGIN_POST = "/admin/auth/signin";

export type LoginRequest = {
  username: string;
  password: string;
};

export type LoginResponse = {
  accessToken: string;
  expiresIn: number;
};

const login = async (form: LoginRequest) => {
  return await httpClient.post<LoginResponse>(`${LOGIN_POST}`, form);
};

export const useLogin = () => useMutation(login);
