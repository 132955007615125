/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import { ChangeEvent, useEffect, useState } from "react";
import { Control, RegisterOptions, useController } from "react-hook-form";
import uuid from "react-uuid";

import { useUpload } from "hooks/useUpload";

export type ImageUploadType = {
  id: string;
  url: string;
};

export type UseImageUploadProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rules?: Omit<
    RegisterOptions<any, any>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  setLoading: (isLoading: boolean) => void;
};

export const useImageUpload = ({
  control,
  name,
  rules,
  setLoading,
}: UseImageUploadProps) => {
  const { field } = useController({
    name,
    control,
    rules,
  });
  const [uploadedImage, setUploadedImage] = useState<
    ImageUploadType | undefined
  >(undefined);

  const uploadAxiosClient = axios.create({});

  const { mutateAsync: uploadImage } = useUpload();

  const upload = (file: File) => {
    setLoading(true);
    const extension = file.name.split(".").pop() ?? "png";

    void uploadImage({ key: "promotions", extension }).then(
      async (response) => {
        setUploadedImage((prevVal) => {
          return {
            url: "",
            ...prevVal,
            id: response.data.id,
          };
        });
        field.onChange(response.data.id);
        await uploadAxiosClient.put(response.data.uploadUrl, file, {
          headers: {
            "Content-Type": `image/${extension === "jpg" ? "jpeg" : extension}`,
          },
        });
        setLoading(false);
      },
    );
  };

  const handleOnFilesChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    setUploadedImage({
      url: URL.createObjectURL(event.target.files[0]),
      id: "",
    });
    upload(event.target.files[0]);
  };

  useEffect(() => {
    field.value &&
      uploadedImage === undefined &&
      setUploadedImage({ url: field.value as string, id: `${uuid()}` });
  }, [field.value, uploadedImage]);

  return {
    handleOnFilesChange,
    uploadedImage,
  };
};
