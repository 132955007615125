import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { BrandWithKey } from "features/UserManagement/types";
import httpClient from "services/httpClient";

const GET_ALL_BRANDS_FOR_EXPORT_PRODUCT = "/admin/brands/active";

export const useGetAllBrands = (): UseQueryResult<BrandWithKey[]> =>
  useQuery({
    queryKey: [GET_ALL_BRANDS_FOR_EXPORT_PRODUCT],
    queryFn: async () => {
      const { data: response } = await httpClient.get<BrandWithKey[]>(
        GET_ALL_BRANDS_FOR_EXPORT_PRODUCT,
      );
      return response.map((brand) => ({
        label: brand.storeName,
        value: brand.storeName,
      }));
    },
  });
