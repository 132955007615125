import { theme } from "@chakra-ui/react";

import { space } from "./space";

export const sizes = {
  ...space,
  ...theme.sizes,
  141: "564px",
  "9xl": "1200px",
  "10xl": "1440px",
};
