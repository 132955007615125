/* eslint-disable @typescript-eslint/no-unnecessary-condition */

/* eslint-disable @typescript-eslint/prefer-optional-chain */
import { Flex, Text } from "@chakra-ui/react";
import { ChangeEvent, useRef } from "react";

import { chooseImageContainer } from "./ImageUpload.styles";
import { Button } from "components/Button/Button";

type ChooseImageProps = {
  handleOnFilesChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label: string;
  description: string;
  acceptFormat: string;
  size?: "small" | "full";
  isError?: boolean;
};

export const ChooseImage = ({
  label,
  handleOnFilesChange,
  description,
  acceptFormat,
  size = "full",
  isError = false,
}: ChooseImageProps) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    hiddenFileInput &&
      hiddenFileInput.current &&
      hiddenFileInput.current.click();
  };
  return (
    <Flex
      w={size === "full" ? "100%" : "146px"}
      {...chooseImageContainer(isError)}
    >
      <Flex
        flexDir="column"
        margin="auto"
        textAlign="center"
        alignItems="center"
      >
        <Button
          size="md"
          variant="outline"
          onClick={handleClick}
          w="fit-content"
        >
          {label}
        </Button>
        <Text textStyle="bodyXS" mt={3} px={4}>
          {description}
        </Text>
      </Flex>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleOnFilesChange}
        accept={acceptFormat}
        style={{ display: "none" }}
      />
    </Flex>
  );
};
