import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

import { colors } from "styles/foundations";

const defaultBorder = `1px solid ${colors.primary[500]}`;
const greyBorder = `1px solid ${colors.grey[500]}`;

const baseStyle = defineStyle({
  // define the part you're going to style
  border: greyBorder,
  paddingX: 1.5,
  paddingY: 2,
  borderRadius: "base",
  color: "grey.900",
  _hover: {
    border: defaultBorder,
  },
  _focus: {
    border: defaultBorder,
    boxShadow: "var(--chakra-shadows-5)",
  },
  _invalid: {
    background: "red.100",
    border: `1px solid ${colors.red[500]}`,
  },
  _disabled: {
    color: "grey.500",
    background: "light.lightGrey",
    border: greyBorder,
    _hover: {
      border: greyBorder,
    },
  },
  _placeholder: {
    color: "grey.500",
  },
  _readOnly: {
    border: greyBorder,
    _hover: {
      border: greyBorder,
    },
    _focus: {
      border: greyBorder,
      boxShadow: "0px 0px 0px 0px",
    },
  },
});

const sizes = {
  md: {
    fontSize: "lg",
    height: 8,
    pl: 2,
    pr: 2,
  },
  lg: {
    fontSize: "lg",
    height: 9,
    pl: 2.5,
    pr: 2.5,
    pt: 2,
  },
};

export const Textarea = defineStyleConfig({
  baseStyle,
  defaultProps: {
    variant: "default",
    size: "md",
  },
  sizes,
});
