import { useMutation } from "@tanstack/react-query";

import { RejectBrandFormFields } from "./useRejectForm";
import { BRAND_REGISTRATION_REQUESTS_KEY } from "services/ReactQueryKeys";
import httpClient from "services/httpClient";
import { queryClient } from "services/queryClient";

export const POST_REJECT_BRAND_PAGE_UPDATE_REQUEST =
  "/admin/brand-page-requests/";

const rejectBrandPageUpdateRequest = async (form: RejectBrandFormFields) => {
  return await httpClient.post(
    `${POST_REJECT_BRAND_PAGE_UPDATE_REQUEST}${form.id}/reject`,
    { rejectReason: form.reason },
  );
};

export const usePostRejectBrandPageUpdateRequest = () =>
  useMutation(rejectBrandPageUpdateRequest, {
    onSuccess: () => {
      void queryClient.fetchQuery({
        queryKey: [BRAND_REGISTRATION_REQUESTS_KEY],
      });
    },
  });
