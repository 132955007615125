import { Box, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export const Banners = () => {
  const { t } = useTranslation();

  return (
    <Box margin="auto">
      <Text color="grey.600" textStyle="bodyXS" m={10}>
        {t("banners.top_banner_description")}
      </Text>
    </Box>
  );
};
