import { Flex, FlexProps, Text } from "@chakra-ui/react";
import { ReactNode } from "react";

type PanelFormProps = {
  title: string;
  titleIcon?: ReactNode;
  children: ReactNode;
} & FlexProps;

export const PanelForm = ({
  title,
  titleIcon,
  children,
  ...rest
}: PanelFormProps) => {
  return (
    <Flex
      px={7}
      pb={7}
      pt={9}
      flexDir="column"
      borderRadius="base"
      boxShadow={1}
      border="1px solid"
      borderColor="grey.400"
      {...rest}
    >
      <Flex alignItems="center">
        {titleIcon}
        <Text textStyle="h4" color="grey.700" fontWeight="semibold">
          {title}
        </Text>
      </Flex>

      <Flex flexDir="column" mt={4}>
        {children}
      </Flex>
    </Flex>
  );
};
