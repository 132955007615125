import { Box, Button, Flex, Heading, Link } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Recommendation } from "./Recommendation/";
import "./print.scss";
import { TabData, TabWrapper } from "components/Tabs/TabWrapper";
import { SolidNote } from "styles/icons/solid";

export const Chatbot = () => {
  const [tabIndex, setTabIndex] = useState<number>(
    parseInt(window.location.hash.replace("#", "") || "0"),
  );

  const { t } = useTranslation();

  const tabsData: TabData[] = [
    {
      title: t("chatbot.tab1"),
      content: <Recommendation />,
    },
  ];

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
    window.location.hash = `${index}`;
  };

  return (
    <Box>
      <Flex display="column">
        <Flex justifyContent="space-between" className="hidePrint">
          <Heading as="h1" size="h1" fontWeight="bold" mb={2} mt={5} ml={10}>
            {t("chatbot.menu_title")}
          </Heading>
          <Link
            href="https://docs.google.com/forms/d/e/1FAIpQLSct4ZSK7-3rmxCFBb719gbc6lpAx_a_qI_Eey9-PpDyNpz5nA/viewform"
            isExternal
            w="fit-content"
          >
            <Button
              size="lg"
              mt={15}
              mr={8}
              leftIcon={<SolidNote fontSize={24} />}
              fontWeight="semibold"
              data-test-id="feedback"
            >
              Feedback
            </Button>
          </Link>
        </Flex>
        <TabWrapper
          tabListProps={{ pl: 10 }}
          tabPanelsProps={{ pl: 2 }}
          tabsData={tabsData}
          tabIndex={tabIndex}
          handleTabsChange={handleTabsChange}
        />
      </Flex>
    </Box>
  );
};
