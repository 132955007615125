import { Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { SingleRetailerRequest } from "../hooks/useGetRetailerRequestById";
import { Document } from "components/Document/Document";
import { InfoDetails } from "components/InfoDetails/InfoDetails";
import { Panel } from "components/Panel/Panel";

type RequestDetailsProps = {
  retailerRequest: SingleRetailerRequest;
};
export const RequestDetails = ({ retailerRequest }: RequestDetailsProps) => {
  const { t } = useTranslation();
  const {
    retailerDetails,
    requestDetails,
    uploadedDocuments,
    previousDocuments,
  } = retailerRequest;
  const { name, email, website, country, phoneNumber } = retailerDetails;
  const { limit, additionalNotes } = requestDetails;

  return (
    <Flex mt={4} ml={3} gap={5}>
      <Flex flexDirection="column" gap={5}>
        <InfoDetails
          heading={"Retailer Details"}
          leftList={[
            "Retailer Name",
            t("brand_details.email"),
            t("brand_details.website"),
            t("brand_details.country"),
            t("brand_details.phone"),
          ]}
          rightList={[name, email, website, country, phoneNumber]}
          leftTextProps={{ w: "30%" }}
        />
        <InfoDetails
          heading={t("retailer_requests.request_details")}
          leftList={["Desired Limit Amount:", "Additional Notes:"]}
          rightList={[limit, additionalNotes]}
          mb={6}
          leftTextProps={{ w: "35%" }}
        />
        <Panel title={t("retailer_requests.documentation")}>
          <Flex flexDirection="column" gap={4}>
            {uploadedDocuments.map((document) => (
              <Document
                key={document.creationDate}
                url={document.url}
                name={document.name}
                date={document.creationDate}
              />
            ))}
          </Flex>
        </Panel>
      </Flex>
      {previousDocuments.length > 0 && (
        <Flex height="fit-content" backgroundColor="light.lightGrey">
          <Panel title={t("retailer_requests.previously_documents")}>
            <Flex flexDirection="column" gap={4}>
              {previousDocuments.map((document) => (
                <Document
                  key={document.creationDate}
                  url={document.url}
                  name={document.name}
                  date={document.creationDate}
                />
              ))}
            </Flex>
          </Panel>
        </Flex>
      )}
    </Flex>
  );
};
