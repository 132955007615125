import { Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { PaymentTermUsage } from "../hooks/useGetPaymentTermsUsage";
import { useSetupUsageTable } from "../hooks/useSetupUsageTable";
import { InfoDetails } from "components/InfoDetails/InfoDetails";
import { TableWrapper } from "components/Table/TableWrapper";
import { SolidDolarSign } from "styles/icons/solid";

export const PaymentTermsUsageDetails = ({
  usage,
  currency,
  outstanding,
  paymentTerms,
}: PaymentTermUsage) => {
  const { t } = useTranslation();
  const { termColumns, termRows } = useSetupUsageTable({
    usages: usage,
    currency,
    outstanding: outstanding ?? 0,
  });

  return (
    <Flex mt={4} ml={3} flexDir="column">
      <InfoDetails
        heading={t("retailer_requests.active_payment_terms")}
        headingIcon={SolidDolarSign}
        leftList={[
          t("retailer_requests.approved"),
          t("retailer_requests.used_amount"),
          t("retailer_requests.available_amount"),
        ]}
        rightList={[
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: currency,
          }).format(paymentTerms.approved),
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: currency,
          }).format(paymentTerms.used),
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: currency,
          }).format(paymentTerms.available),
        ]}
        leftTextProps={{ w: "30%" }}
      />

      {termRows.length > 0 && (
        <Flex flexDir="column" mt={8}>
          <Text
            textTransform="uppercase"
            textStyle="bodyL"
            fontWeight="bold"
            mb={5}
          >
            {t("retailer_requests.usage_list")}
          </Text>
          <TableWrapper
            columns={termColumns}
            tableData={termRows}
            tableProps={{ variant: "order", size: "sm" }}
            showFooter
          />
        </Flex>
      )}
    </Flex>
  );
};
