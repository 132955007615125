/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  Flex,
  Heading,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { AdminDetails } from "./AdminDetails";
import { useDeleteAdminById } from "./hooks/useDeleteAdminById";
import { useGetAdminById } from "./hooks/useGetAdminById";
import { Button } from "components/Button/Button";
import { ModalWrapper } from "components/Modal/ModalWrapper";
import { Status } from "components/Status/Status";
import { TabData, TabWrapper } from "components/Tabs/TabWrapper";
import {
  capitalizeFirstLetter,
  convertToStatusVariant,
} from "features/UserManagement/utils";
import { RegularDelete } from "styles/icons/regular";
import {
  SolidArrowChevronLeft,
  SolidDotsMoreHorizontal,
} from "styles/icons/solid";

export const EditAdminOverview = () => {
  const { adminId } = useParams();
  const {
    isOpen: isOpenDeleteAdmin,
    onOpen: onOpenDeleteAdmin,
    onClose: onCloseDeleteAdmin,
  } = useDisclosure();

  const { mutateAsync: deleteAdminAccount, isLoading: isMutating } =
    useDeleteAdminById();

  const { t } = useTranslation();
  const {
    data: adminAccount,
    isLoading,
    isFetching,
  } = useGetAdminById(adminId ?? "");

  const navigate = useNavigate();

  if (isFetching || isLoading) {
    return <Spinner position="absolute" top="50%" left="50%" />;
  }

  const tabsData: TabData[] = [
    {
      title: t("Details"),
      content: <AdminDetails adminAccount={adminAccount} />,
    },
  ];
  const handleDeleteAdmin = async () => {
    await deleteAdminAccount(adminId ?? "");

    onCloseDeleteAdmin();
    navigate("/user-management");
  };

  return (
    <Flex display="column">
      <Flex mb={2} mt={5} ml={10} justifyContent="space-between">
        <Flex>
          <Button
            variant="outline"
            colorScheme="grey"
            mainIcon={SolidArrowChevronLeft}
            aria-label="backButton"
            data-test-id="backToAdminList"
            alignSelf="center"
            mr={4}
            onClick={() => window.history.back()}
          />
          <Heading as="h1" size="h1" fontWeight="bold">
            {adminAccount?.email}
          </Heading>
          <Status
            display="flex"
            variant={convertToStatusVariant(adminAccount?.status ?? "done")}
            text={capitalizeFirstLetter(adminAccount?.status ?? "Active")}
            ml={3.5}
            h="22px"
            alignSelf="center"
          />
        </Flex>
        <Flex>
          <Menu placement="bottom-end">
            <MenuButton
              as={Button}
              variant="outline"
              mainIcon={SolidDotsMoreHorizontal}
              colorScheme="grey"
              data-test-id="threeDotsMenuButton"
            ></MenuButton>
            <MenuList minW={48}>
              <MenuItem color="red" w={48} mx={2} onClick={onOpenDeleteAdmin}>
                <Icon as={RegularDelete} boxSize={4.5} mr={2} />
                {t("Delete account")}
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
      <TabWrapper
        tabListProps={{ pl: 10 }}
        tabPanelsProps={{ pl: 2 }}
        tabsData={tabsData}
      />
      <ModalWrapper
        isOpen={isOpenDeleteAdmin}
        onClose={onCloseDeleteAdmin}
        showHeaderDivider
        modalHeader={t("Delete account")}
        modalBody={
          <Text textStyle="bodyM" color="black">
            {t("Are you sure you want to delete")} {adminAccount?.email}
          </Text>
        }
        modalFooter={
          <>
            <Button
              variant="outline"
              colorScheme="grey"
              fontWeight="600"
              color="grey.black"
              onClick={onCloseDeleteAdmin}
              data-test-id="cancelDeletAdminButton"
            >
              {t("Cancel")}
            </Button>
            <Button
              variant="solid"
              colorScheme="red"
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={() => handleDeleteAdmin()}
              data-test-id="deleteAdminButton"
              disabled={isMutating}
            >
              {t("Delete")}
            </Button>
          </>
        }
      />
    </Flex>
  );
};
