import { useMutation } from "@tanstack/react-query";

import { SettingsFormFields } from "./useSettingsForm";
import httpClient from "services/httpClient";

export const POST_SETTINGS = "/admin/settings";

const postSettings = async (form: SettingsFormFields) => {
  return await httpClient.put(`${POST_SETTINGS}`, {
    minimumOrderValue: Number(form.minimumOrderValue),
    firstOrderCommission: Number(form.firstOrderCommission),
    commission: Number(form.commission),
    bestsellerProductLimit: Number(form.bestsellerProductLimit),
    paymentTermsDelay: Number(form.paymentTermsDelay),
    newProductDurationInDays: Number(form.newProductDurationInDays),
    payoutCommissionRate: Number(form.payoutCommissionRate),
    delayPayoutDays: Number(form.delayPayoutDays),
    returnEligibilityDays: Number(form.returnEligibilityDays),
    companyDetails: {
      companyName: form.companyName,
      companyCountry: form.companyCountry,
      companyAddress: form.companyAddress,
      accountName: form.accountName,
      bankAccountNumber: form.bankAccountNumber,
      bankCode: form.bankCode,
      swiftCode: form.swiftCode,
    },
    ...(form.retailerCountrySettings && {
      retailerCountrySettings: [
        ...form.retailerCountrySettings.map((market) => {
          return {
            minOrderAmount: Number(market.minOrderAmount),
            autoBuyNowPayLaterAmount: Number(market.autoBuyNowPayLaterAmount),
            autoWalletCreditAmount: Number(market.autoWalletCreditAmount),
            currencyCode: market.currencyCode,
          };
        }),
      ],
    }),
  });
};

export const usePostSettings = (onSuccess: () => void) =>
  useMutation(postSettings, {
    onSuccess,
  });
