/* eslint-disable @tanstack/query/exhaustive-deps */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import httpClient from "services/httpClient";

export const GET_ALL_REWARDS_VOUCHERS =
  "/admin/vouchers/batchAssignRewardsVouchers";

export const useGetAllRewardsVouchers = (): UseQueryResult<unknown[]> =>
  useQuery({
    queryKey: [GET_ALL_REWARDS_VOUCHERS],
    queryFn: async () => {
      const { data: allVouchers } = await httpClient.get<unknown[]>(
        GET_ALL_REWARDS_VOUCHERS,
      );

      return allVouchers;
    },
  });
