import { useMemo } from "react";
import { useForm } from "react-hook-form";

import { usePutAdminAccount } from "./usePutAdminAccount";
import { convertToAccessFormat } from "features/UserManagement/utils";

export type EditAdminFormFields = {
  id: string;
  account: string | null;
  settings: string | null;
  users: string | null;
  financials: string | null;
  promotions: string | null;
};

export type UseEditAdminFormProps = {
  defaultState: EditAdminFormFields;
};
export const useEditAdminForm = ({ defaultState }: UseEditAdminFormProps) => {
  const {
    control,
    register,
    formState: { errors, isDirty, isValid },
    handleSubmit,
    watch,
    getValues,
  } = useForm<EditAdminFormFields>({
    defaultValues: defaultState,
  });

  const { mutateAsync: putAdminAccount, isLoading } = usePutAdminAccount();
  const watchRadioFields = watch([
    "account",
    "financials",
    "promotions",
    "settings",
    "users",
  ]);

  const isFormValid = useMemo(() => {
    return (
      !isDirty || !isValid || !watchRadioFields.some((item) => item !== "none")
    );
  }, [isDirty, isValid, watchRadioFields]);

  const onSubmit = handleSubmit(async () => {
    await putAdminAccount({
      id: getValues().id,
      account: convertToAccessFormat("Account", getValues().account),
      promotions: convertToAccessFormat("Promotions", getValues().promotions),
      settings: convertToAccessFormat("Settings", getValues().settings),
      users: convertToAccessFormat("Users", getValues().users),
      financials: convertToAccessFormat("Financials", getValues().financials),
    });
  });

  return {
    control,
    register,
    errors,
    onSubmit,
    isFormValid,
    isLoading,
  };
};
