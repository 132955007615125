import { menuAnatomy as parts } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";

import { shadows } from "styles/foundations";

const lightBg = "light.appBg";

// eslint-disable-next-line @typescript-eslint/unbound-method
const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyleList = defineStyle({
  border: "1px solid",
  borderColor: "grey.200",
  borderRadius: "md",
  boxShadow: shadows[6],
});

const baseStyleItem = defineStyle({
  textStyle: "bodyS",
  height: 8,
  color: "grey.900",
  px: 4,
  _hover: {
    backgroundColor: lightBg,
    borderRadius: "base",
  },
  _active: {
    backgroundColor: lightBg,
    borderRadius: "base",
  },
  _focus: {
    backgroundColor: lightBg,
    borderRadius: "base",
  },
});

const baseStyleGroupTitle = defineStyle({
  textStyle: "bodyS",
  height: 8,
  color: "grey.900",
  fontWeight: "semibold",
  mx: 2,
  my: 0,
  pt: 1.5,
  px: 2,
});

const baseStyleCommand = defineStyle({
  textStyle: "bodyS",
  color: "grey.600",
});

const baseStyleDivider = defineStyle({
  borderColor: "grey.400",
  marginX: 2,
});

const baseStyle = definePartsStyle({
  list: baseStyleList,
  item: baseStyleItem,
  groupTitle: baseStyleGroupTitle,
  command: baseStyleCommand,
  divider: baseStyleDivider,
});

export const Menu = defineMultiStyleConfig({
  baseStyle,
});
