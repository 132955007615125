import { useMutation } from "@tanstack/react-query";

import { UpdateBnplFields } from "./useUpdateBnpl";
import { GET_APPLICATION_HISTORY_QK } from "features/RetailerRequests/RetailerRequestOverview/hooks/useGetApplicationHistory";
import {
  GET_PAYMENT_TERMS_USAGE_QK,
  getPaymentTermsUsageViaRetailerId,
} from "features/RetailerRequests/RetailerRequestOverview/hooks/useGetPaymentTermsUsage";
import { GET_RETAILER_REQEUSTS_QK } from "features/RetailerRequests/hooks/useGetRetailerRequests";
import { ErrorResponse } from "services/ErrorResponse";
import httpClient from "services/httpClient";
import { queryClient } from "services/queryClient";

export const PUT_BNPL = "/admin/retailer/payment-terms-apply";

const putBnpl = async ({ retailerId, amount, note }: UpdateBnplFields) => {
  return await httpClient.put(PUT_BNPL, { retailerId, amount, note });
};

export const usePutBnpl = (
  onSuccess: () => void,
  onError: (axiosError: ErrorResponse) => void,
) =>
  useMutation(putBnpl, {
    onSuccess: (_, { retailerId }) => {
      void queryClient.fetchQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: [GET_PAYMENT_TERMS_USAGE_QK],
        queryFn: async () =>
          await getPaymentTermsUsageViaRetailerId(retailerId),
      });
      void queryClient.invalidateQueries([
        GET_APPLICATION_HISTORY_QK,
        GET_RETAILER_REQEUSTS_QK,
      ]);
      onSuccess();
    },
    onError,
  });
