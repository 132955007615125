import { useMutation } from "@tanstack/react-query";

import { AddAdminFormFields } from "./useAddAdminForm";
import { ErrorResponse } from "services/ErrorResponse";
import { ADMINS_KEY } from "services/ReactQueryKeys";
import httpClient from "services/httpClient";
import { queryClient } from "services/queryClient";

export const POST_ADMIN_ACCOUNT = "/admin/auth/invite";

const postAdminAccount = async (form: AddAdminFormFields) => {
  return await httpClient.post(`${POST_ADMIN_ACCOUNT}`, form);
};

export const usePostAdminAccount = (
  onError: (axiosError: ErrorResponse) => void,
) =>
  useMutation(postAdminAccount, {
    onSuccess: () => {
      void queryClient.fetchQuery({ queryKey: [ADMINS_KEY] });
    },
    onError,
  });
