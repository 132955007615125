/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Box, Flex, Input, Link, Spinner, Text } from "@chakra-ui/react";
import { SortingState, createColumnHelper } from "@tanstack/react-table";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Retailer } from "../types";
import { capitalizeFirstLetter, convertToStatusVariant } from "../utils";
import { useExportRetailers } from "./hooks/useExportRetailers";
import { useGetAllRetailers } from "./hooks/useGetAllRetailers";
import { Button } from "components/Button/Button";
import { Status } from "components/Status/Status";
import { TableWrapper } from "components/Table/TableWrapper";
import { LightDocument } from "styles/icons/light";
import { DEBOUNCED_SEARCH, downloadCSV } from "utils/functions";

type RetailersTable = {
  action: string;
  newRelicLink: string;
} & Retailer;

export const RetailersTableOverview = () => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>("" as unknown as HTMLInputElement);
  const [globalFilter, setGlobalFilter] = useState("");

  const { data: allRetailers, isLoading } = useGetAllRetailers();

  const { mutateAsync: exportRetailers, isLoading: isMutating } =
    useExportRetailers();

  const initialSort: SortingState = [{ id: "email", desc: true }];

  const columnHelper = createColumnHelper<RetailersTable>();
  const columns = [
    columnHelper.accessor("name", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {row.original.firstName} {row.original.lastName}
        </Text>
      ),
      header: "Name",
      size: 45,
    }),
    columnHelper.accessor("storeName", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {row.original.storeName}
        </Text>
      ),
      header: "Store Name",
      size: 30,
    }),
    columnHelper.accessor("businessPhoneNumber", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {row.original.businessPhoneNumber}
        </Text>
      ),
      header: "Phone Number",
      size: 30,
    }),
    columnHelper.accessor("email", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {row.original.email}
        </Text>
      ),
      header: "Email",
      size: 30,
    }),
    columnHelper.accessor("country", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {row.original.country}
        </Text>
      ),
      header: "Country",
      size: 20,
    }),
    columnHelper.accessor("status", {
      cell: (row) => (
        <Status
          variant={convertToStatusVariant(
            row.getValue() ? "INACTIVE" : "ACTIVE",
          )}
          text={capitalizeFirstLetter(row.getValue() ? "INACTIVE" : "ACTIVE")}
        />
      ),
      header: "Status",
      size: 20,
    }),
    columnHelper.accessor("brStatus", {
      cell: (row) => capitalizeFirstLetter(row.getValue()),
      header: "BR Status",
      size: 20,
    }),
    columnHelper.accessor("action", {
      cell: ({ row }) => (
        <Link href={`/user-management/retailer/${row.original.id}`}>
          <Button variant="outline">View</Button>
        </Link>
      ),
      header: "Action",
      size: 10,
    }),
  ];

  if (isLoading) {
    return <Spinner position="absolute" top="50%" left="50%" />;
  }

  const handleOnExport = async () => downloadCSV(await exportRetailers());

  const handleSearchBar = () => {
    setGlobalFilter(inputRef.current.value);
  };

  return (
    <Flex display="column" width="100%">
      <Flex pt={2.5} pb={5} justifyContent="space-between">
        <Box></Box>
        <Box></Box>
      </Flex>
      <Flex justify="space-between">
        <Input
          width="xs"
          mb={5}
          mr={5}
          placeholder={t("orders.search")}
          onChange={DEBOUNCED_SEARCH(handleSearchBar)}
          ref={inputRef}
        />
        <Button
          leftIcon={LightDocument}
          onClick={() => void handleOnExport()}
          disabled={isMutating}
        >
          {t("retailer_details.export")}
        </Button>
      </Flex>
      <TableWrapper
        tableData={allRetailers}
        columns={columns}
        initialSort={initialSort}
        globalFilter={globalFilter}
        data-test-id="retailer-table"
      />
    </Flex>
  );
};
