/* eslint-disable @tanstack/query/exhaustive-deps */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import httpClient from "services/httpClient";

export const GET_ALL_MISSIONS = "/admin/rewards/missions";

export const useGetAllMissions = (): UseQueryResult<unknown[]> =>
  useQuery({
    queryKey: [GET_ALL_MISSIONS],
    queryFn: async () => {
      const { data: allMissions } = await httpClient.get<unknown[]>(
        GET_ALL_MISSIONS,
      );

      return allMissions.map((mission) => {
        const filteredMission: Record<string, unknown> = {};
        for (const key in mission as Record<string, unknown>) {
          if (key.startsWith("en")) {
            filteredMission[key] = (mission as Record<string, unknown>)[key];
          }
        }
        return filteredMission;
      });
    },
  });
