import { Box, Button, Flex, Heading } from "@chakra-ui/react";
import { useDynamoDbUserContext } from "contexts/DynamoDbUserContext";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { LoginRequest, useLogin } from "./useLogin";
import { Checkbox } from "components/Checkbox/Checkbox";
import { ErrorMessage } from "components/ErrorMessage/ErrorMessage";
import { InputField } from "components/InputField/InputField";
import { Logo } from "components/Logo/Logo";
import { ForgotPassword } from "features/ForgotPassword";
import { setAccessToken } from "services/httpClient";

const USERNAME = "username";

export const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { getUserFromDynamo } = useDynamoDbUserContext();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [queryParameters] = useSearchParams();

  useEffect(() => {
    const hasUsernameAndCode =
      queryParameters.has("username") && queryParameters.has("code");
    if (hasUsernameAndCode) setIsModalOpen(true);
  }, [queryParameters]);

  const {
    register,
    getValues,
    formState: { errors, isValid },
  } = useForm<LoginRequest>({ mode: "onChange" });

  const [rememberMe, setRememberMe] = useState(
    localStorage.getItem(USERNAME) ? true : false,
  );
  const [isError, setIsError] = useState(false);
  const { mutateAsync: login, isLoading } = useLogin();

  const handleRememberMeChanged = (event: ChangeEvent<HTMLInputElement>) =>
    setRememberMe(event.target.checked);

  const handleForgotPasswordClick = () => {
    setIsModalOpen((prevState) => !prevState);
  };

  const handleLogin = async () => {
    if (rememberMe) {
      localStorage.setItem(USERNAME, getValues().username);
    } else {
      localStorage.removeItem(USERNAME);
    }

    await login(
      {
        username: getValues().username.trim(),
        password: getValues().password,
      },
      {
        onError: () => setIsError(true),
        onSuccess: ({ data: { accessToken, expiresIn } }) => {
          setIsError(false);
          setAccessToken(accessToken, expiresIn);
          navigate("/user-management");
          getUserFromDynamo();
        },
      },
    );
  };

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    void handleLogin();
  };

  return (
    <Flex height="100vh" width="100%" justify="center" align="center">
      <Flex width="360px" align="center" direction="column">
        <Logo />
        <Heading marginTop="8px" size="md">
          {t("Admin Panel")}
        </Heading>
        <form onSubmit={handleFormSubmit} style={{ width: "100%" }}>
          <Box marginTop="40px" marginBottom="6px">
            <InputField
              id="username"
              type="email"
              info="This is a username field"
              placeholder={t("Username")}
              errors={errors}
              {...register("username", {
                required: t("Invalid Username"),
                value: localStorage.getItem(USERNAME) ?? "",
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: t("Invalid Username"),
                },
              })}
            />
          </Box>
          <Box marginBottom="24px">
            <InputField
              id="password"
              type="password"
              info="This is a password field"
              placeholder={t("Password")}
              errors={errors}
              {...register("password", { required: t("Password Is Required") })}
              canToggleVisibility
            />
          </Box>
          {isError && (
            <ErrorMessage
              width="100%"
              borderColor="red.300"
              borderStyle="solid"
              borderWidth="1px"
              borderRadius="4px"
              backgroundColor="red.100"
              padding="8px 12px"
              marginBottom="20px"
              message={t("Login error")}
            />
          )}
          <Flex width="100%" marginBottom="20px">
            <Checkbox isChecked={rememberMe} onChange={handleRememberMeChanged}>
              {t("Remember me")}
            </Checkbox>
          </Flex>
          <Button
            size="lg"
            width="100%"
            disabled={!isValid}
            type="submit"
            isLoading={isLoading}
          >
            {t("Login")}
          </Button>
        </form>
        <Button
          variant="ghost"
          _hover={{ backgroundColor: "transparent" }}
          _active={{ backgroundColor: "transparent" }}
          color="primary"
          onClick={handleForgotPasswordClick}
        >
          {t("Forgot password")}?
        </Button>
      </Flex>
      <ForgotPassword
        isOpen={isModalOpen}
        setIsOpen={() => setIsModalOpen((prevState) => !prevState)}
        username={
          getValues().username || (queryParameters.get("username") ?? "")
        }
      />
    </Flex>
  );
};
