import { useMutation } from "@tanstack/react-query";

import { GET_ORDER_DETAILS_QK } from "./useGetOrderById";
import httpClient from "services/httpClient";
import { queryClient } from "services/queryClient";

export const PUT_SCHEDULE_PICKUP = "/admin/orders/";

const putSchedulePickup = async (orderId: string) => {
  return await httpClient.put(
    `${PUT_SCHEDULE_PICKUP}${orderId}/pickup-request/cancel`,
    {},
  );
};

export const usePutSchedulePickup = (orderId: string) =>
  useMutation(() => putSchedulePickup(orderId), {
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [GET_ORDER_DETAILS_QK],
      });
    },
  });
