import { SVGProps } from "react";

const SvgSolidAccountBox = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5 17.85C5.9 16.9667 6.946 16.2707 8.138 15.762C9.32933 15.254 10.6167 15 12 15C13.3833 15 14.671 15.254 15.863 15.762C17.0543 16.2707 18.1 16.9667 19 17.85V5H5V17.85ZM12 13C11.0333 13 10.2083 12.6583 9.525 11.975C8.84167 11.2917 8.5 10.4667 8.5 9.5C8.5 8.53333 8.84167 7.70833 9.525 7.025C10.2083 6.34167 11.0333 6 12 6C12.9667 6 13.7917 6.34167 14.475 7.025C15.1583 7.70833 15.5 8.53333 15.5 9.5C15.5 10.4667 15.1583 11.2917 14.475 11.975C13.7917 12.6583 12.9667 13 12 13ZM5 21C4.45 21 3.979 20.8043 3.587 20.413C3.19567 20.021 3 19.55 3 19V5C3 4.45 3.19567 3.979 3.587 3.587C3.979 3.19567 4.45 3 5 3H19C19.55 3 20.021 3.19567 20.413 3.587C20.8043 3.979 21 4.45 21 5V19C21 19.55 20.8043 20.021 20.413 20.413C20.021 20.8043 19.55 21 19 21H5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgSolidAccountBox;
