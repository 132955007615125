import { CSSReset, ChakraProvider } from "@chakra-ui/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import DynamoDbUserContextProvider from "contexts/DynamoDbUserContext";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { queryClient } from "services/queryClient";
import theme from "styles/theme";

const root = ReactDOM.createRoot(
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  document.getElementById("root")!,
);

root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <DynamoDbUserContextProvider>
        <ReactQueryDevtools initialIsOpen={false} />
        <ChakraProvider theme={theme}>
          <CSSReset />
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ChakraProvider>
      </DynamoDbUserContextProvider>
    </QueryClientProvider>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
