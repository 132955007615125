import { Flex, Link, Spinner, Text } from "@chakra-ui/react";
import { SortingState, createColumnHelper } from "@tanstack/react-table";
import { format, parseISO } from "date-fns";

import { BrandRequest } from "../types";
import { useGetAllBrandRegistrationRequests } from "./useGetAllBrandRegistrationsRequests";
import { Button } from "components/Button/Button";
import { AdminAvatar } from "components/Table/AdminAvatar";
import { TableWrapper } from "components/Table/TableWrapper";

export const RegistrationsTableOverview = () => {
  const { data: allBrandRegistrationsRequests, isLoading } =
    useGetAllBrandRegistrationRequests();

  type BrandRequestsTable = {
    action: string;
    requestDate: string;
  } & BrandRequest;

  const columnHelper = createColumnHelper<BrandRequestsTable>();

  const initialSort: SortingState = [{ id: "requestDate", desc: true }];
  const columns = [
    columnHelper.accessor("name", {
      cell: ({ row }) => (
        <AdminAvatar
          name={row.original.name}
          displayName={row.original.name}
          email={row.original.email}
        />
      ),
      header: "Name",
      size: 30,
    }),
    columnHelper.accessor("requestDate", {
      cell: (row) =>
        row.getValue() && (
          <Text textStyle="bodyS">
            {format(parseISO(row.getValue()), "dd-MM-yyyy")}
          </Text>
        ),
      header: "Request Date",
      size: 20,
    }),
    columnHelper.accessor("category", {
      cell: (row) => <Text textStyle="bodyS">{row.getValue()}</Text>,
      header: "Category",
      size: 20,
    }),
    columnHelper.accessor("country", {
      cell: (row) => <Text textStyle="bodyS">{row.getValue()}</Text>,
      header: "Country",
      size: 40,
    }),
    columnHelper.accessor("action", {
      cell: ({ row }) => (
        <Link href={`/brand-requests/${row.original.id}`}>
          <Button variant="outline">View</Button>
        </Link>
      ),
      header: "Action",
      size: 10,
    }),
  ];

  if (isLoading) {
    return <Spinner position="absolute" top="50%" left="50%" />;
  }

  return (
    <Flex display="column" width="100%" mt={5}>
      <TableWrapper
        tableData={allBrandRegistrationsRequests}
        columns={columns}
        initialSort={initialSort}
      />
    </Flex>
  );
};
