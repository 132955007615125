import { useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { usePostMaseRequest } from "./usePostMaseRequest";
import { ErrorResponse } from "services/ErrorResponse";

export type MASEFormFields = {
  passedAccount: string;
  submittedAccount: string;
};

export const useMASERequestForm = () => {
  type possibleMaseErrorCodes =
    | "BRAND_002"
    | "BRAND_012"
    | "ERR_000"
    | "ERR_001";

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
    getValues,
  } = useForm<MASEFormFields>({
    mode: "onChange",
    defaultValues: {
      passedAccount: "",
      submittedAccount: "",
    },
  });

  const { t } = useTranslation();

  const toast = useToast();

  const onSuccess = () => {
    toast({
      position: "top",
      status: "success",
      description: `Successfully updated MASE for ${getValues(
        "submittedAccount",
      )}`,
    });
  };

  const onError = (error: ErrorResponse) => {
    toast({
      position: "top",
      status: "error",
      description: `Failed to update MASE for ${getValues(
        "submittedAccount",
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-base-to-string
      )}, ${t(
        `tech_support.${error.response.data.code[0] as possibleMaseErrorCodes}`,
      )}`,
    });
  };

  const { mutateAsync: postMaseRequest, isLoading: isLoadingPost } =
    usePostMaseRequest(onSuccess, onError);

  const onSubmit = handleSubmit(() => {
    void postMaseRequest(getValues());
  });

  return {
    register,
    onSubmit,
    getValues,
    errors,
    isDirty,
    isValid,
    isLoadingPost,
    control,
  };
};
