import { Flex, Text, useDisclosure } from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";

import { useChangeBrandNameForm } from "../../hooks/brandName/useChangeBrandNameForm";
import { Button } from "components/Button/Button";
import { InputField } from "components/InputField/InputField";
import { ModalWrapper } from "components/Modal/ModalWrapper";
import { PanelForm } from "components/PanelForm/PanelForm";
import { MAX_BRAND_NAME_LENGTH } from "constants/brand/brand";
import { BrandDetailsType } from "features/UserManagement/types";

type BrandNameUpdateFormProps = {
  brandAccount: BrandDetailsType;
};

const BrandNameUpdateForm = ({ brandAccount }: BrandNameUpdateFormProps) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const brandId = brandAccount.id;

  const { register, errors, onSubmit, getValues, isValid, isLoading } =
    useChangeBrandNameForm({
      brandId,
      onClose,
    });

  const handleOpen = () => {
    onOpen();
  };

  return (
    <PanelForm title={t("brand_settings.brand_name_update")} w="100%">
      <Flex mt={2} mb={5} w="496px">
        <InputField
          errors={errors}
          label={t("brand_settings.new_brand_name")}
          placeholder={t("brand_settings.new_brand_name")}
          type="text"
          {...register("newBrandName", {
            required: t("This field is required."),
            maxLength: {
              value: MAX_BRAND_NAME_LENGTH,
              message: t("common.form_validate.max_length_exceed", {
                value: MAX_BRAND_NAME_LENGTH,
              }),
            },
          })}
        />
      </Flex>
      <Flex w="496px">
        <InputField
          errors={errors}
          label={t("brand_settings.confirm_new_brand_name")}
          placeholder={t("brand_settings.confirm_new_brand_name")}
          type="text"
          {...register("confirmNewBrandName", {
            required: t("This field is required."),
            validate: (value) => {
              return (
                value === getValues("newBrandName") ||
                t("brand_settings.confirm_new_brand_name_error")
              );
            },
            maxLength: {
              value: MAX_BRAND_NAME_LENGTH,
              message: t("common.form_validate.max_length_exceed", {
                value: MAX_BRAND_NAME_LENGTH,
              }),
            },
          })}
        />
      </Flex>
      <Text mt={5} textStyle="bodyXS">
        - {t("brand_settings.change_brand_Name_remind")}
      </Text>
      <Button
        size="lg"
        w="fit-content"
        mt={5}
        onClick={handleOpen}
        disabled={!isValid}
      >
        {t("brand_settings.change_brand_name")}
      </Button>
      <ModalWrapper
        modalHeader={t("brand_settings.confirm_change_brand_name")}
        modalBody={
          <Flex direction="column">
            <Text>
              <Trans
                i18nKey={"brand_settings.confirm_new_brand_name_changes"}
                values={{
                  newName: getValues("newBrandName"),
                  oldName: brandAccount.storeName,
                }}
              />
            </Text>
            <Flex mt={5} gap={5} alignSelf="flex-end">
              <Button
                w={100}
                onClick={() => void onSubmit()}
                disabled={isLoading}
                isLoading={isLoading}
              >
                {t("confirm")}
              </Button>
              <Button w={100} onClick={onClose} disabled={isLoading}>
                {t("cancel")}
              </Button>
            </Flex>
          </Flex>
        }
        isOpen={isOpen}
        onClose={onClose}
      />
    </PanelForm>
  );
};

export default BrandNameUpdateForm;
