import { useMutation } from "@tanstack/react-query";

import { EditAdminFormFields } from "./useEditAdminForm";
import httpClient from "services/httpClient";

export const PUT_ADMIN_ACCOUNT = "/admin/accounts/";

const putAdminAccount = async (form: EditAdminFormFields) => {
  const { id, ...rest } = form;
  return await httpClient.put(`${PUT_ADMIN_ACCOUNT}${form.id}`, rest);
};

export const usePutAdminAccount = () => useMutation(putAdminAccount);
