import { Flex, Icon, Link, Text } from "@chakra-ui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Address, Order } from "../Details/hooks/useGetOrderById";
import { InfoDetailsSingleColumn } from "components/InfoDetailsSingleColumn/InfoDetailsSingleColumn";
import { capitalizeFirstLetter } from "features/UserManagement/utils";
import { RegularBus } from "styles/icons/regular";
import {
  SolidAccountBox,
  SolidBuilding,
  SolidDocument,
  SolidNote,
} from "styles/icons/solid";

export type RetailerDetailsProps = {
  order: Order;
};

export const RetailerDetails = ({ order }: RetailerDetailsProps) => {
  const { t } = useTranslation();
  const retailerDetails = (
    <Flex flexDirection="column" textStyle="bodyS" gap={2} fontWeight="medium">
      <Text fontWeight="bold">
        <Link href={`/user-management/retailer/${order.retailerId}`}>
          {order.retailerDetails.firstName} {order.retailerDetails.lastName}
        </Link>
      </Text>
      <Text>{order.retailerDetails.email}</Text>
      <Text>{order.retailerDetails.website}</Text>
      <Text>{order.retailerDetails.businessPhoneNumber}</Text>
    </Flex>
  );

  const shippingInfoDetails = (
    <Flex
      flexDirection="column"
      textStyle="bodyS"
      gap={2}
      color="#1C7EC6"
      fontWeight="medium"
      mx={-4}
    >
      <Link
        href={order.subOrders[0].shippingDetails.label?.labelUrl}
        display="flex"
        target="_blank"
      >
        <Icon as={SolidNote} w={4.5} h={4.5} mr={1} />
        <Text>{t("retailer_details.label")}</Text>
      </Link>

      {order.subOrders[0].shippingDetails.documents?.map((item) => (
        <Link
          key={item.category}
          href={item.url}
          display="flex"
          target="_blank"
        >
          <Icon as={SolidDocument} w={4.5} h={4.5} mr={1} />
          <Text>
            {item.category
              ? capitalizeFirstLetter(item.category)
              : t("retailer_details.documentation")}
          </Text>
        </Link>
      ))}
    </Flex>
  );

  const shouldDisplayShippingInfo = useMemo(() => {
    return order.subOrders[0].shippingDetails.label?.labelUrl ? true : false;
  }, [order.subOrders]);
  return (
    <Flex flexDirection="column" pt={5}>
      <InfoDetailsSingleColumn
        headingIcon={SolidAccountBox}
        heading={t("retailer_details.retailer_info")}
        details={retailerDetails}
        mb={5}
      />
      <InfoDetailsSingleColumn
        headingIcon={SolidBuilding}
        heading={t("retailer_details.billing_address")}
        details={renderAddress(
          order.retailerDetails.deliveryAddress,
          order.retailerDetails.businessPhoneNumber,
        )}
        mb={5}
      />
      <InfoDetailsSingleColumn
        headingIcon={RegularBus}
        heading={t("retailer_details.delivery_address")}
        details={renderAddress(
          order.retailerDetails.billingAddress,
          order.retailerDetails.businessPhoneNumber,
        )}
        mb={5}
      />
      {shouldDisplayShippingInfo ? (
        <InfoDetailsSingleColumn
          headingIcon={SolidDocument}
          heading={t("retailer_details.shipping_info")}
          details={shippingInfoDetails}
        />
      ) : null}
    </Flex>
  );
};

const renderAddress = (address: Address, phone: string) => {
  const {
    companyName,
    address: address1,
    address2,
    city,
    postCode,
    state,
  } = address;
  return (
    <Flex flexDirection="column" textStyle="bodyS" gap={2} fontWeight="medium">
      {companyName && <Text>{companyName}</Text>}
      <Text>{address1}</Text>
      <Text>{address2}</Text>
      <Text>
        {`${city},
    ${postCode},
    ${state}`}
      </Text>
      <Text>{phone}</Text>
    </Flex>
  );
};
