import { UseQueryResult, useQuery } from "@tanstack/react-query";

import httpClient from "services/httpClient";

export const GET_AVAILABLE_PICKUPS = "/admin/orders/";

type Address = {
  country: string;
  firstAddress: string;
};
export type TimeSlot = {
  id: string;
  fromTime: string;
  toTime: string;
};
export type DateSlot = {
  date: string;
  timeSlots: TimeSlot[];
};

export type SchedulePickup = {
  fromAddress: Address;
  toAddress: Address;
  dateSlots: DateSlot[];
};

export const useGetSchedulePickup = (
  id: string,
): UseQueryResult<SchedulePickup> =>
  useQuery({
    queryKey: [GET_AVAILABLE_PICKUPS, id],
    queryFn: async () => {
      const { data: pickups } = await httpClient.get<SchedulePickup>(
        `${GET_AVAILABLE_PICKUPS}${id}/pickup-request`,
      );
      return pickups;
    },
  });
