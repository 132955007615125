import {
  FormControlProps,
  RadioGroup,
  ResponsiveValue,
  Stack,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { Control, UseControllerProps, useController } from "react-hook-form";

import { FieldWrapper } from "components/FieldWrapper/FieldWrapper";

export type RadioFieldProps = {
  label?: string;
  info?: string;
  placeholder?: string;
  name: string;
  control?: Control;
  size?: ResponsiveValue<string>;
  disabled?: boolean;
  readOnly?: boolean;
  isRequired?: boolean;
  radios: ReactNode;
} & FormControlProps &
  UseControllerProps;

export const RadioField = ({
  label,
  info,
  isRequired,
  control,
  rules,
  placeholder,
  disabled,
  readOnly,
  size,
  name,
  radios,
  ...rest
}: RadioFieldProps) => {
  const {
    // issue: https://github.com/react-hook-form/react-hook-form/issues/7577
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    field: { value, onBlur, onChange },
    formState: { errors },
  } = useController({
    name,
    control,
    rules,
  });

  return (
    <FieldWrapper
      name={name}
      label={label}
      info={info}
      errors={errors}
      isRequired={!!rules?.required}
    >
      <RadioGroup
        {...rest}
        onChange={(args) => onChange(args)}
        value={value as string}
        onBlur={onBlur}
        name={name}
      >
        <Stack direction="row">{radios}</Stack>
      </RadioGroup>
    </FieldWrapper>
  );
};

RadioField.displayName = "RadioField";
