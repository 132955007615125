import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { Brand } from "features/UserManagement/types";
import httpClient from "services/httpClient";

const GET_ADMIN_MULTIPLIER = "admin/msrp-multiplier/search";

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type XOR<T extends any[], U = T[number]> = T extends [infer Head, ...infer Rest]
  ? U extends Head
    ? Without<U, XOR<Rest>> & U
    : XOR<Rest, U>
  : never;

type RetailerCountryType = {
  name: string;
  countryCode: string;
};

export type MSRPMultiplierType = "COUNTRY";

type AdminMultiplierType = {
  id: string;
  type: MSRPMultiplierType;
  multiplier: number;
  priorityRanking: number;
  country?: RetailerCountryType;
  categoryId?: string;
  brand?: Brand;
  createDate: string;
  updateDate: string;
};

export type AdminCountryMultiplierType = Omit<
  AdminMultiplierType,
  "country"
> & {
  country: RetailerCountryType;
};

// TODO: Update for Phase 2 and 3.
type MultiplierTypes = XOR<[AdminCountryMultiplierType, AdminMultiplierType]>;

const useGetMSRPMultiplier = (type: MSRPMultiplierType) =>
  useQuery({
    queryKey: [GET_ADMIN_MULTIPLIER, type],
    queryFn: async () => {
      const { data: adminMultipliers } = await httpClient.post<
        MultiplierTypes[]
      >(GET_ADMIN_MULTIPLIER, {
        type: type,
      });
      return adminMultipliers;
    },
  });

export const useGetMsrpCountryMultiplier = (): UseQueryResult<
  AdminCountryMultiplierType[]
> =>
  useGetMSRPMultiplier("COUNTRY") as UseQueryResult<
    AdminCountryMultiplierType[]
  >;
