import { UseQueryResult, useQuery } from "@tanstack/react-query";

import httpClient from "services/httpClient";

export const GET_BRANDS = "/brands";

export type Brand = {
  id: string;
  storeName: string;
  storeKey: string;
  brandPage?: {
    logoRef?: string;
  };
};

export const useBrands = (): UseQueryResult<Brand[]> =>
  useQuery({
    queryKey: [GET_BRANDS],
    queryFn: async () => {
      const { data: brands } = await httpClient.get<Brand>(GET_BRANDS);
      return brands;
    },
    staleTime: 10,
  });
