import { useMutation } from "@tanstack/react-query";

import { ErrorResponse } from "services/ErrorResponse";
import httpClient from "services/httpClient";

export const FORCE_COMPLETE_SUBORDER = "/admin/orders/";

const forceCompleteSubOrder = async (subOrderId: string) => {
  return await httpClient.put(
    `${FORCE_COMPLETE_SUBORDER}${subOrderId}/force-complete`,
    {},
  );
};

export const useForceCompleteSubOrder = (
  onSuccess: () => void,
  onError: (data: ErrorResponse) => void,
) =>
  useMutation(forceCompleteSubOrder, {
    onSuccess,
    onError,
  });
