import { useMutation } from "@tanstack/react-query";

import { ErrorResponse } from "services/ErrorResponse";
import httpClient from "services/httpClient";

export const ADMIN_LOGOUT = "/admin/auth/signout";

const handleLogout = async () => {
  return httpClient.post(`${ADMIN_LOGOUT}`);
};

export const usePostLogout = (
  onSuccess: () => void,
  onError: (errors: ErrorResponse) => void,
) =>
  useMutation(handleLogout, {
    onSuccess,
    onError,
  });
