import { useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { usePutRetailerEmail } from "./useUpdateRetailerEmail";
import { normalizeText } from "features/UserManagement/utils";
import { ErrorResponse } from "services/ErrorResponse";
import { getErrorCodes } from "services/httpClient";

type UseChangeEmailFormProps = {
  retailerId: string;
  onClose: () => void;
};

export type ChangeRetailerEmailFields = {
  newEmail: string;
  confirmEmail: string;
  retailerId: string;
};

export const useChangeRetailerEmailForm = ({
  retailerId,
  onClose,
}: UseChangeEmailFormProps) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    control,
    getValues,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm<ChangeRetailerEmailFields>({
    defaultValues: {
      retailerId: retailerId,
    },
  });

  const toast = useToast();

  const onSuccess = () => {
    onClose();
    toast({
      position: "top-right",
      description: t("retailer_settings.email_success_change"),
    });
    reset({
      retailerId: retailerId,
      newEmail: "",
      confirmEmail: "",
    });
  };

  const onError = (error: ErrorResponse) => {
    toast({
      position: "top-right",
      status: "error",
      description: t(getErrorCodes(error)),
    });
  };

  const { mutateAsync: putRetailerEmail } = usePutRetailerEmail(
    onSuccess,
    onError,
  );

  const onSubmit = handleSubmit(async () => {
    const newEmail = normalizeText(getValues().newEmail);
    const confirmEmail = normalizeText(getValues().confirmEmail);
    await putRetailerEmail({
      newEmail,
      confirmEmail,
      retailerId,
    });
  });

  return {
    register,
    onSubmit,
    control,
    getValues,
    reset,
    errors,
    isDirty,
    isValid,
  };
};
