import { CommonAddressExtended } from "interfaces/address";
import { useTranslation } from "react-i18next";

import { InfoDetails } from "components/InfoDetails/InfoDetails";

type AddressDetailsProps = {
  heading: string;
  address: CommonAddressExtended | undefined;
};

const AddressDetails = ({ heading, address }: AddressDetailsProps) => {
  const { t } = useTranslation();
  return (
    <InfoDetails
      w="554px"
      leftTextProps={{ width: "40%" }}
      heading={heading}
      leftList={[
        t("common.address.companyName") + ":",
        t("common.address.firstAddress") + ":",
        t("common.address.secondAddress") + ":",
        t("common.address.city") + ":",
        t("common.address.zipcode") + ":",
        t("common.address.state") + ":",
        t("common.address.country") + ":",
        t("common.address.phone") + ":",
      ]}
      rightList={[
        address?.companyName,
        address?.firstAddress,
        address?.secondAddress,
        address?.city,
        address?.zipcode,
        address?.state,
        address?.country,
        address?.phone,
      ]}
      mb={5}
    />
  );
};

export default AddressDetails;
