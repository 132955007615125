/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Flex,
  Icon,
  Image,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { Control, RegisterOptions } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ChooseImage } from "./ChooseImage";
import { Thumbnail } from "./Thumbnail";
import { useImageUpload } from "./useImageUpload";
import { Button } from "components/Button/Button";
import { ErrorMessage } from "components/ErrorMessage/ErrorMessage";
import { Logo } from "components/Logo/Logo";
import { ModalWrapper } from "components/Modal/ModalWrapper";
import LightEye from "styles/icons/light/LightEye";

type ImageUploadProps = {
  control: Control<any>;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rules?: Omit<
    RegisterOptions<any, any>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  label: string;
  errorMessage?: string;
  size?: "full" | "small";
  description?: string;
  value?: string;
  disabled?: boolean;
  showPreview?: boolean;
  setIsUploadLoading?: (isLoading: boolean) => void;
};
export const ImageUpload = ({
  control,
  name,
  rules,
  errorMessage,
  label,
  size = "full",
  description = "",
  disabled,
  value,
  showPreview,
  setIsUploadLoading,
}: ImageUploadProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();

  const onLoading = (isLoading: boolean) => {
    setIsLoading(isLoading);
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    setIsUploadLoading && setIsUploadLoading(isLoading);
  };
  const { handleOnFilesChange, uploadedImage } = useImageUpload({
    control,
    name,
    rules,
    setLoading: onLoading,
  });

  return (
    <Flex direction="column">
      {!uploadedImage && !value ? (
        <ChooseImage
          isError={errorMessage ? true : false}
          label={label}
          description={description}
          acceptFormat="image/png, image/gif, image/jpeg"
          handleOnFilesChange={handleOnFilesChange}
          size={size}
        />
      ) : (
        <Thumbnail
          acceptFormat="image/png, image/gif, image/jpeg"
          src={value ? value : uploadedImage ? uploadedImage.url : ""}
          handleOnFilesChange={handleOnFilesChange}
          size={size}
          disabled={disabled}
          position="relative"
        />
      )}
      {isLoading ? (
        <Spinner color="white" position="absolute" top="50%" left="18%" />
      ) : null}

      {errorMessage && <ErrorMessage message={errorMessage} />}
      {showPreview && uploadedImage?.url ? (
        <Flex alignItems="center" mt={8} cursor="pointer" onClick={onOpenModal}>
          <Icon as={LightEye} color="#4D4D54" w={4.5} h={4.5} />
          <Text textStyle="bodyS" ml={2}>
            Preview Banner
          </Text>
        </Flex>
      ) : null}
      <ModalWrapper
        modalProps={{
          isOpen: isOpenModal,
          onClose: onCloseModal,
          children: undefined,
          size: "full",
        }}
        isOpen={isOpenModal}
        onClose={onCloseModal}
        modalHeader={
          <Flex justifyContent="space-between">
            <Button variant="outline" w="fit-content" onClick={onCloseModal}>
              {t("close_preview")}
            </Button>
            <Logo />
            <Text></Text>
          </Flex>
        }
        modalBody={
          <Flex px={14} h="100%">
            <Image
              src={uploadedImage?.url}
              h="300px"
              w="100%"
              mt="210px"
              objectFit="cover"
            />
          </Flex>
        }
        showHeaderDivider
      />
    </Flex>
  );
};
