import { useMutation } from "@tanstack/react-query";

import { PickupFormFields } from "./useSchedulePickupForm";
import httpClient from "services/httpClient";

export const POST_SCHEDULE_PICKUP = "/admin/orders/";

const postSchedulePickup = async (form: PickupFormFields) => {
  return await httpClient.post(
    `${POST_SCHEDULE_PICKUP}${form.id}/pickup-request/${
      form.pickupTime?.value ?? ""
    }`,
    {},
  );
};

export const usePostSchedulePickup = (onSuccess: () => void) =>
  useMutation(postSchedulePickup, {
    onSuccess,
  });
