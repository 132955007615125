import {
  Box,
  Flex,
  Link,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { SortingState, createColumnHelper } from "@tanstack/react-table";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { AdminAccount } from "../types";
import { capitalizeFirstLetter, convertToStatusVariant } from "../utils";
import { AddAdminForm } from "./AddAdmin/AddAdminForm";
import { useAddAdminForm } from "./AddAdmin/hooks/useAddAdminForm";
import { useGetAllAdmins } from "./useGetAllAdmins";
import { Button } from "components/Button/Button";
import { ModalWrapper } from "components/Modal/ModalWrapper";
import { Status } from "components/Status/Status";
import { TableWrapper } from "components/Table/TableWrapper";
import { SolidPlus } from "styles/icons/solid";

export const AdminTableOverview = () => {
  const { t } = useTranslation();
  const { data: allAdmins, isLoading } = useGetAllAdmins();
  const {
    isOpen: isOpenAddAdminModal,
    onOpen: onOpenAddAdminModal,
    onClose: onCloseAddAdminModal,
  } = useDisclosure();

  const {
    control,
    register,
    errors,
    onSubmit,
    reset,
    isFormValid,
    isLoading: isCreateLoading,
  } = useAddAdminForm({ onCloseModal: onCloseAddAdminModal });

  type AdminAccoutTable = {
    action: string;
  } & AdminAccount;
  const initialSort: SortingState = [{ id: "email", desc: true }];
  const columnHelper = createColumnHelper<AdminAccoutTable>();
  const columns = [
    columnHelper.accessor("email", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {row.original.email}
        </Text>
      ),
      header: "Name",
      size: 65,
    }),
    columnHelper.accessor("status", {
      cell: (row) => (
        <Status
          variant={convertToStatusVariant(row.getValue())}
          text={capitalizeFirstLetter(row.getValue())}
        />
      ),
      header: "Status",
      size: 25,
    }),
    columnHelper.accessor("action", {
      cell: ({ row }) =>
        row.original.status === "ACTIVE" && (
          <Link href={`/user-management/admin/${row.original.id}`}>
            <Button variant="outline">View</Button>
          </Link>
        ),
      header: "Action",
      size: 10,
      sortingFn: ({ original }) => {
        return original.status === "ACTIVE" ? -1 : 1;
      },
    }),
  ];

  useEffect(() => {
    reset();
  }, [isOpenAddAdminModal, reset]);

  if (isLoading) {
    return <Spinner position="absolute" top="50%" left="50%" />;
  }

  return (
    <Flex display="column" width="100%">
      <Flex pt={2.5} pb={5} justifyContent="space-between">
        <Box></Box>
        <Button
          leftIcon={SolidPlus}
          fontWeight="semibold"
          onClick={onOpenAddAdminModal}
          data-test-id="addAdminOpenModal"
        >
          {t("Add new admin")}
        </Button>
      </Flex>
      <TableWrapper
        tableData={allAdmins}
        columns={columns}
        initialSort={initialSort}
        data-test-id="admin-table"
      />

      <ModalWrapper
        isOpen={isOpenAddAdminModal}
        onClose={onCloseAddAdminModal}
        showHeaderDivider
        modalHeader={t("Add New Admin")}
        modalBody={
          <AddAdminForm
            register={register}
            errors={errors}
            onSubmit={onSubmit}
            control={control}
            isCreateLoading={isCreateLoading}
          />
        }
        modalFooter={
          <>
            <Button
              variant="outline"
              colorScheme="grey"
              fontWeight="600"
              color="grey.black"
              onClick={onCloseAddAdminModal}
              data-test-id="cancelAddAdminButton"
            >
              {t("Cancel")}
            </Button>
            <Button
              type="submit"
              form="addAdmin"
              variant="solid"
              disabled={isFormValid}
              data-test-id="addAdminSubmitButton"
            >
              {t("Send Invitation")}
            </Button>
          </>
        }
      />
    </Flex>
  );
};
