/* eslint-disable @typescript-eslint/no-non-null-assertion, react/jsx-no-useless-fragment */
import {
  Flex,
  Heading,
  Spinner,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { ApplicationHistory } from "./ApplicationHistory/ApplicationHistory";
import { PaymentTermsUsage } from "./PaymentTermsUsage/PaymentTermsUsage";
import { RequestDetails } from "./RequestDetails/RequestDetails";
import { useGetRetailerRequestById } from "./hooks/useGetRetailerRequestById";
import { useRejectRetailerRequest } from "./hooks/useRejectRetailerRequest";
import { Button } from "components/Button/Button";
import { ModalWrapper } from "components/Modal/ModalWrapper";
import { TabData, TabWrapper } from "components/Tabs/TabWrapper";
import { SolidArrowChevronLeft } from "styles/icons/solid";

type RetailerAccountParam = {
  id: string;
};

export const RetailerRequestOverview = () => {
  const { t } = useTranslation();
  const { id } = useParams<
    keyof RetailerAccountParam
  >() as RetailerAccountParam;
  const { data: retailerRequest, isLoading: isRequestLoading } =
    useGetRetailerRequestById(id);

  const tabsData: TabData[] = [
    {
      title: t("retailer_requests.request_details"),
      content: <RequestDetails retailerRequest={retailerRequest!} />,
    },
    {
      title: t("retailer_requests.payment_terms_usage"),
      content: <PaymentTermsUsage id={id} />,
    },
    {
      title: t("retailer_requests.application_history"),
      content: <ApplicationHistory id={id} />,
    },
  ];
  const {
    isOpen: isOpenReject,
    onOpen: onOpenReject,
    onClose: onCloseReject,
  } = useDisclosure();
  const {
    register,
    isFormValid,
    isRejectLoading,
    handleAcceptClick,
    handleRejectClick,
  } = useRejectRetailerRequest(id, onCloseReject);

  return (
    <>
      {isRequestLoading ? (
        <Flex width="100%">
          <Spinner position="absolute" top="50%" bottom="50%" />
        </Flex>
      ) : (
        <Flex flexDirection="column">
          <Flex mb={2} mt={5} ml={10} justifyContent="space-between">
            <Flex position="relative">
              <Button
                variant="outline"
                colorScheme="grey"
                mainIcon={SolidArrowChevronLeft}
                aria-label="backButton"
                data-test-id="backToRetailersList"
                alignSelf="center"
                mr={4}
                onClick={() => window.history.back()}
              />
              <Heading as="h1" size="h1" fontWeight="bold">
                {retailerRequest!.retailerDetails.name}
              </Heading>
            </Flex>
            <Flex position="absolute" right={0} top={15} gap={3} mr={10}>
              <Button
                size="lg"
                colorScheme="red"
                variant="outline"
                onClick={onOpenReject}
              >
                {t("retailer_requests.reject")}
              </Button>
              <Button size="lg" onClick={handleAcceptClick}>
                {t("retailer_requests.accept")}
              </Button>
            </Flex>
          </Flex>
          <TabWrapper
            tabListProps={{ pl: 10 }}
            tabPanelsProps={{ pl: 2 }}
            tabsData={tabsData}
          />
          <ModalWrapper
            isOpen={isOpenReject}
            onClose={onCloseReject}
            modalProps={{
              size: "sm",
              children: undefined,
              isOpen: isOpenReject,
              onClose: onCloseReject,
            }}
            showHeaderDivider
            modalHeader={`${t("Reject")} ${
              retailerRequest!.retailerDetails.name
            }`}
            modalBody={
              <>
                <Text textStyle="bodyM" color="black" mb={1}>
                  {t("Add Comment")}
                </Text>
                <Textarea
                  height="125px"
                  resize="none"
                  placeholder={t("retailer_requests.add_reject_explanation")}
                  {...register("reason", {
                    required: true,
                    validate: (value) => !!value.trim(),
                  })}
                ></Textarea>
              </>
            }
            modalFooter={
              <>
                <Button
                  variant="outline"
                  colorScheme="grey"
                  fontWeight="600"
                  color="grey.black"
                  onClick={onCloseReject}
                  data-test-id="cancelRetailerRequestButton"
                >
                  {t("Cancel")}
                </Button>
                <Button
                  variant="solid"
                  colorScheme="red"
                  isLoading={isRejectLoading}
                  disabled={isFormValid}
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick={handleRejectClick}
                >
                  {t("Reject")}
                </Button>
              </>
            }
          />
        </Flex>
      )}
    </>
  );
};
