import { useMutation } from "@tanstack/react-query";

import { RejectBrandFormFields } from "./useRejectForm";
import { BRAND_REGISTRATION_REQUESTS_KEY } from "services/ReactQueryKeys";
import httpClient from "services/httpClient";
import { queryClient } from "services/queryClient";

export const POST_REJECT_BRAND_REQUEST = "/admin/brand-requests/";

const rejectBrandRequest = async (form: RejectBrandFormFields) => {
  return await httpClient.post(
    `${POST_REJECT_BRAND_REQUEST}${form.id}/reject`,
    { rejectReason: form.reason },
  );
};

export const usePostRejectBrandRequest = () =>
  useMutation(rejectBrandRequest, {
    onSuccess: () => {
      void queryClient.fetchQuery({
        queryKey: [BRAND_REGISTRATION_REQUESTS_KEY],
      });
    },
  });
