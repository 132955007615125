import {
  Box,
  Button,
  Flex,
  Image,
  Link,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useSimpleToast } from "../../../hooks/useSimpleToast";
import { AddNoteModal } from "./AddNoteModal/AddNoteModal";
import { CancelPickup } from "./CancelPickup/CancelPickup";
import { downloadInvoice } from "./DownloadInvoice/DownloadInvoice";
import { EditOrderPreviewModal } from "./EditOrderPreviewModal/EditOrderPreviewModal";
import { CancelPickupModal } from "./PickupModal/CancelPickupModal";
import { PickupModal } from "./PickupModal/PickupModal";
import { TrackingModal } from "./TrackingModal/TrackingModal";
import ZendeskTicketsTable from "./ZendeskTicketTable";
import { useGetBrandProductsBySuborderId } from "./hooks/useGetBrandProductsBySuborderId";
import {
  Product,
  RetailerDetails,
  SubOrder as SubOrderType,
  Total,
  useGetOrderById,
} from "./hooks/useGetOrderById";
import { usePostPreAlertEmailToDhl } from "./hooks/useSendDHLPreAlertEmailToMy";
import { OrderAvatar } from "components/OrderAvatar/OrderAvatar";
import { Status } from "components/Status/Status";
import { TableWrapper } from "components/Table/TableWrapper";
import { TextWithIcon } from "components/TextWithIcon/TextWithIcon";
import { OrderAdditionalFooter } from "features/Orders/Details/OrderAdditionalFooter/OrderAdditionalFooter";
import {
  capitalizeFirstLetter,
  convertToStatusVariant,
} from "features/UserManagement/utils";
import {
  RegularBus,
  RegularCall,
  RegularDocument,
  RegularEnvelope,
  RegularHome,
  RegularLink,
  RegularLocation,
  RegularPackage,
  RegularShoppingCart,
} from "styles/icons/regular";
import { SolidPlus } from "styles/icons/solid";

type SubOrderProps = {
  subOrder: SubOrderType;
  currency: string;
  retailer: RetailerDetails;
  creationDate: string;
  orderId?: string;
};

export type SubOrderTable = {
  product: Product;
  qty: number;
  caseQty?: number;
  sku: string;
  price: string;
  id: string;
  name: string;
  total: Total;
  imageUrl: string;
  variantName?: string;
  variantKey?: string;
  brandPrice: number;
  currency: {
    retailer: string;
    brand: string;
  };
};

export const SubOrder = ({
  subOrder,
  currency,
  retailer,
  creationDate,
}: SubOrderProps) => {
  const retailerCountry = subOrder.destinationCountry;
  const { orderId } = useParams();
  const {
    isOpen: isOpenPickupModal,
    onOpen: onOpenPickupModal,
    onClose: onClosePickupModal,
  } = useDisclosure();
  const {
    isOpen: isOpenCancelPickupModal,
    onOpen: onOpenCancelPickupModal,
    onClose: onCloseCancelPickupModal,
  } = useDisclosure();
  const {
    isOpen: isOpenAddTrackingModal,
    onOpen: onOpenAddTrackingModal,
    onClose: onCloseAddTrackingModal,
  } = useDisclosure();
  const {
    isOpen: isOpenAddNoteModal,
    onOpen: onOpenAddNoteModal,
    onClose: onCloseAddNoteModal,
  } = useDisclosure();

  const {
    isOpen: isOpenEditOrderModal,
    onOpen: onOpenEditOrderModal,
    onClose: onCloseEditOrderModal,
  } = useDisclosure();

  const { refetch: refetchOrder } = useGetOrderById(orderId ?? "");
  const { data: brandProducts, isLoading: isProductsLoading } =
    useGetBrandProductsBySuborderId(subOrder.id);

  const onUpdateNoteSuccess = async () => {
    await refetchOrder();
  };
  const toast = useSimpleToast();

  const onPreAlertSuccess = () => {
    toast({
      position: "top",
      description: "Pre-alert email sent successfully",
    });
  };

  const onPreAlertError = () => {
    toast({
      status: "error",
      position: "top",
      description: "Pre-alert email failed to send",
    });
  };
  const { mutateAsync: postPreAlertEmailToDhl, isLoading: isMutating } =
    usePostPreAlertEmailToDhl(onPreAlertSuccess, onPreAlertError);

  const handlePreAlertEmailClick = async (subOrderId: string) => {
    await postPreAlertEmailToDhl(subOrderId);
  };

  const { t } = useTranslation();
  const columnHelper = createColumnHelper<SubOrderTable>();

  const columns = [
    columnHelper.accessor("product.id", {
      cell: ({ row }) => (
        <OrderAvatar
          name={row.original.name}
          sku={`SKU: ${row.original.sku}`}
          image={row.original.imageUrl}
          variant={row.original.variantName ?? ""}
        />
      ),
      header: "Product",
      size: 70,
      enableSorting: false,
    }),
    columnHelper.accessor("product.price", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {Intl.NumberFormat("en-US", {
            style: "currency",
            currency: currency,
          }).format(Number(row.original.price))}
        </Text>
      ),
      header: "Price",
      size: 10,
      enableSorting: false,
    }),
    columnHelper.accessor("product.qty", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {row.original.caseQty !== undefined
            ? row.original.qty * row.original.caseQty
            : row.original.qty}
        </Text>
      ),
      header: "Quantity",
      size: 10,
      enableSorting: false,
      footer: () => (
        <Flex flexDir="column" color="grey.700">
          <Text textStyle="bodyS">Subtotal:</Text>
          <Text textStyle="bodyS">Risk Free Returns:</Text>
          <Text textStyle="bodyS">Shipping:</Text>
          <Text textStyle="bodyS">Tax:</Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("product.total", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {Intl.NumberFormat("en-US", {
            style: "currency",
            currency: currency,
          }).format(Number(row.original.total.retailer))}
        </Text>
      ),
      header: "Subtotal",
      size: 10,
      enableSorting: false,
      footer: () => (
        <Flex
          flexDir="column"
          color="grey.700"
          fontWeight="medium"
          textAlign="right"
          pr={14}
        >
          <Text textStyle="bodyS">
            {Intl.NumberFormat("en-US", {
              style: "currency",
              currency: currency,
            }).format(Number(subOrder.subtotal))}
          </Text>
          <Text textStyle="bodyS">
            {Intl.NumberFormat("en-US", {
              style: "currency",
              currency: currency,
            }).format(Number(subOrder.returnSurchargeApplied))}
          </Text>
          <Text textStyle="bodyS">
            {Intl.NumberFormat("en-US", {
              style: "currency",
              currency: currency,
            }).format(Number(subOrder.shippingDetails.totalCharge))}
          </Text>
          <Text textStyle="bodyS">
            {Intl.NumberFormat("en-US", {
              style: "currency",
              currency: currency,
            }).format(Number(subOrder.tax))}
          </Text>
        </Flex>
      ),
    }),
  ];

  const editOrderColumns = [
    columns[0],
    columnHelper.accessor("product.brandPrice", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {Intl.NumberFormat("en-US", {
            style: "currency",
            currency: row.original.currency.brand,
          }).format(Number(row.original.brandPrice))}
        </Text>
      ),
      header: `Original Price (Brand Currency)`,
      size: 10,
    }),
    ...columns.slice(1),
  ];

  return (
    <Flex flexDir="column">
      <Flex flexDir="column" mb={-5}>
        <Flex flexDirection="column" mb={3}>
          <Text
            textStyle="bodyS"
            fontWeight="bold"
            mb={3}
          >{`#${subOrder.orderNumber}`}</Text>
          <Flex justifyContent="space-between">
            <Flex>
              <Link mb={3} display="flex">
                <Image
                  src={subOrder.brand.image}
                  alt={subOrder.brand.storeName}
                  w={6}
                  h={6}
                  borderRadius="50%"
                />
                <Text textStyle="bodyS" color="#1C7EC6" ml={2} pt={0.5}>
                  <Link href={`/user-management/brand/${subOrder.brand.id}`}>
                    {subOrder.brand.storeName}
                  </Link>
                </Text>
              </Link>
            </Flex>

            <Flex textStyle="bodyS">
              {subOrder.shippingDetails.pickupTime && (
                <CancelPickup
                  pickupTime={subOrder.shippingDetails.pickupTime}
                  openModal={onOpenCancelPickupModal}
                />
              )}
            </Flex>
          </Flex>
          <Flex justifyContent="space-between">
            <Flex>
              <TextWithIcon
                icon={RegularHome}
                text={subOrder.brand.countryName}
                iconProps={{ color: "#9999A0" }}
              />
              <TextWithIcon
                icon={RegularEnvelope}
                text={subOrder.brand.email}
                iconProps={{ color: "#9999A0" }}
              />
              <TextWithIcon
                icon={RegularCall}
                text={subOrder.brand.phoneNumber}
                iconProps={{ color: "#9999A0" }}
              />
              {!!subOrder.shippingDetails.invoiceAmount && (
                <TextWithIcon
                  icon={RegularPackage}
                  text={`${
                    subOrder.shippingDetails.invoiceAmount as unknown as string
                  } ${subOrder.products[0].currency.brand} `}
                  iconProps={{ color: "#9999A0" }}
                />
              )}
            </Flex>
            <Flex>
              {subOrder.status !== "PENDING" &&
                retailerCountry === "Malaysia" && (
                  <TextWithIcon
                    icon={RegularEnvelope}
                    text={t("order.pre_alert_email")}
                    flexProps={{
                      color: "#1C7EC6",
                      cursor: "pointer",
                      // eslint-disable-next-line @typescript-eslint/no-misused-promises
                      onClick: () =>
                        !isMutating && handlePreAlertEmailClick(subOrder.id),
                    }}
                  />
                )}
              {subOrder.status !== "PENDING" && (
                <TextWithIcon
                  icon={RegularShoppingCart}
                  text={t("order.packing_slip")}
                  flexProps={{
                    color: "#1C7EC6",
                    cursor: "pointer",
                    onClick: () =>
                      downloadInvoice(subOrder, retailer, creationDate),
                  }}
                />
              )}
              {!subOrder.shippingDetails.pickupTime &&
                subOrder.status === "CONFIRMED" && (
                  <TextWithIcon
                    icon={RegularBus}
                    text={t("order.schedule_pickup")}
                    flexProps={{
                      color: "#1C7EC6",
                      cursor: "pointer",
                      onClick: onOpenPickupModal,
                    }}
                  />
                )}
              <TextWithIcon
                icon={RegularLink}
                text={t("order.update_shipment_id")}
                flexProps={{
                  color: "#1C7EC6",
                  cursor: "pointer",
                  onClick: onOpenAddTrackingModal,
                }}
              />
              {
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                !!subOrder.shippingDetails &&
                !!subOrder.shippingDetails.trackingUrl ? (
                  <Link
                    href={subOrder.shippingDetails.trackingUrl}
                    target="_blank"
                  >
                    <TextWithIcon
                      icon={RegularLocation}
                      text={t("order.track_order")}
                      flexProps={{
                        color: "#1C7EC6",
                        cursor: "pointer",
                      }}
                    />
                  </Link>
                ) : (
                  ""
                )
              }
              {
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                !!subOrder.shippingDetails &&
                !!subOrder.shippingDetails.selfShipInvoiceUrl ? (
                  <Link
                    href={subOrder.shippingDetails.selfShipInvoiceUrl}
                    target="_blank"
                  >
                    <TextWithIcon
                      icon={RegularDocument}
                      text={"Self-Shipping-Invoice"}
                      flexProps={{
                        color: "#1C7EC6",
                        cursor: "pointer",
                      }}
                    />
                  </Link>
                ) : (
                  ""
                )
              }
              <Status
                display="flex"
                variant={convertToStatusVariant(subOrder.status ?? "done")}
                text={capitalizeFirstLetter(subOrder.status ?? "Active")}
                alignSelf="center"
              />
            </Flex>
          </Flex>
        </Flex>
        <TableWrapper
          tableData={subOrder.products}
          columns={columns}
          tableProps={{ variant: "order" }}
          additionalFooter={
            <OrderAdditionalFooter
              total={subOrder.total.toString()}
              suborderId={subOrder.id}
              currency={currency}
              returnable={subOrder.returnable}
              subOrder={subOrder}
            />
          }
          showFooter
          disablePagination
        />
        {["PENDING", "CONFIRMED"].includes(subOrder.status ?? "") &&
          !subOrder.isEdited && (
            <Box width={220} mb={5}>
              <Button onClick={onOpenEditOrderModal}>
                <TextWithIcon
                  icon={RegularDocument}
                  iconProps={{ color: "#fff" }}
                  text={t("order.edit_suborder")}
                  textProps={{ textStyle: "bodyL", color: "#fff" }}
                  flexProps={{ mr: 0 }}
                />
              </Button>
            </Box>
          )}
        {(["PENDING", "CONFIRMED"].includes(subOrder.status ?? "") ||
          !!subOrder.additionalNotes) && (
          <Box
            w="554px"
            px={5}
            pb={5}
            mb={50}
            flexDir="column"
            borderRadius="base"
            boxShadow={1}
            border="1px solid"
            borderColor="grey.400"
          >
            <Flex
              pt={3}
              flexDir="row"
              w="100%"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text textStyle="bodyM" color="grey.700" fontWeight="semibold">
                {t("brand_details.notes")}
              </Text>
              {["PENDING", "CONFIRMED"].includes(subOrder.status ?? "") && (
                <Button
                  size="md"
                  w="fit-content"
                  mt={5}
                  onClick={onOpenAddNoteModal}
                  leftIcon={<SolidPlus width={20} height={20} />}
                >
                  {subOrder.additionalNotes
                    ? t("order.edit_note")
                    : t("order.add_note")}
                </Button>
              )}
            </Flex>
            {subOrder.additionalNotes && (
              <Flex flexDir="column">
                <Text py={5}>{subOrder.additionalNotes}</Text>
              </Flex>
            )}
          </Box>
        )}
        {isOpenPickupModal && (
          <PickupModal
            isOpen={isOpenPickupModal}
            onClose={onClosePickupModal}
            subOrder={subOrder}
          />
        )}
        {isOpenCancelPickupModal && (
          <CancelPickupModal
            isOpen={isOpenCancelPickupModal}
            onClose={onCloseCancelPickupModal}
            orderId={subOrder.id}
          />
        )}
        {isOpenAddTrackingModal && (
          <TrackingModal
            isOpen={isOpenAddTrackingModal}
            onClose={onCloseAddTrackingModal}
            subOrder={subOrder}
          />
        )}
        {isOpenAddNoteModal && (
          <AddNoteModal
            isOpen={isOpenAddNoteModal}
            onClose={onCloseAddNoteModal}
            subOrder={subOrder}
            onAfterSuccess={onUpdateNoteSuccess}
          />
        )}
        {isOpenEditOrderModal && (
          <EditOrderPreviewModal
            isOpen={isOpenEditOrderModal}
            onClose={onCloseEditOrderModal}
            subOrder={subOrder}
            currency={currency}
            brandProducts={brandProducts}
            isProductsLoading={isProductsLoading}
            columns={editOrderColumns}
            originalOrderPrice={subOrder.subtotal}
          />
        )}
      </Flex>
      {(subOrder.zendeskTickets?.length ?? 0) > 0 && (
        <>
          <Text textStyle="bodyS" fontWeight="bold" mb={3}>
            ZenDesk tickets for {`#${subOrder.orderNumber}`}
          </Text>
          <Flex>
            <ZendeskTicketsTable tickets={subOrder.zendeskTickets ?? []} />
          </Flex>
        </>
      )}
      <hr />
    </Flex>
  );
};
