/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/no-unsafe-return */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-misused-promises */

/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Divider, Flex, Icon, Image, Text } from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { BaseSyntheticEvent, useCallback, useMemo, useRef } from "react";
import {
  FieldArrayWithId,
  FieldErrorsImpl,
  UseFormRegister,
  UseFormReset,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { EditReturnQuantityModal } from "./EditReturnQuantityModal";
import {
  Product,
  ReturnRequestDetails,
} from "./hooks/useGetReturnRequestDetails";
import {
  EditReturnFields,
  ProductQuantityFields,
} from "./hooks/useReturnRequestDetails";
import { Button } from "components/Button/Button";
import ImagesViewer from "components/ImagesViewer/ImagesViewer";
import { capitalizeFirstLetter } from "features/UserManagement/utils";
import { SolidDocument } from "styles/icons/solid";
import { isSupportedImage } from "utils/fileUtils";

type BrandOverviewProps = {
  returnRequest: ReturnRequestDetails;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields: FieldArrayWithId<ProductQuantityFields, "products">[] | any[];
  onOpenModal: () => void;
  onCloseModal: () => void;
  isOpenModal: boolean;
  register: UseFormRegister<EditReturnFields>;
  errors: Partial<
    FieldErrorsImpl<{
      id: string;
      quantity: number;
      caseQty: number;
      sku: string;
      comment: string;
    }>
  >;
  handleApply: (event?: BaseSyntheticEvent | undefined) => Promise<void>;
  resetModal: UseFormReset<EditReturnFields>;
};

export const BrandOverview = ({
  returnRequest,
  fields,
  onOpenModal,
  isOpenModal,
  onCloseModal,
  register,
  errors,
  handleApply,
  resetModal,
}: BrandOverviewProps) => {
  const { t } = useTranslation();

  const currentProductRef = useRef("");

  const maxProductQty = useMemo(() => {
    return (
      fields.find((item) => item.sku === currentProductRef.current)
        ?.requestUnitQuantity ?? 1
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, currentProductRef.current]);

  const displayStrokeQty = useCallback((product: Product) => {
    return (
      Number(product.approvedUnitQuantity) < Number(product.requestUnitQuantity)
    );
  }, []);

  const approvedReturnArr = fields.map(
    (product) => Number(product.approvedUnitQuantity) * Number(product.price),
  );

  const approvedReturnSum = approvedReturnArr.reduce(
    (acc, approved) => acc + approved,
    0,
  );

  return (
    <Flex
      mx={7}
      mt={8}
      border="1px solid #CCCCD1"
      p={4}
      borderRadius="base"
      flexDir="column"
    >
      <Flex
        background="light.lightGrey"
        borderRadius="base"
        w="100%"
        p={2}
        mb={8}
        justify="space-between"
      >
        <Flex>
          <Image
            src={returnRequest.brand.image}
            width={12}
            height={12}
            borderRadius="50%"
            mr={3}
          />
          <Flex flexDir="column" textStyle="h6">
            <Text fontWeight="semibold">{returnRequest.brand.storeName}</Text>
            <Text>{returnRequest.brand.country}</Text>
          </Flex>
        </Flex>

        <Flex flexDir="column">
          <Flex>
            <Text textStyle="h6" fontWeight="semibold" mr={4}>
              {t("return_requests.type")}:
            </Text>
            <Text>{capitalizeFirstLetter(returnRequest.type)}</Text>
          </Flex>
          <Flex>
            <Text textStyle="h6" fontWeight="semibold" mr={4}>
              Preferred resolution:
            </Text>
            <Text>
              {capitalizeFirstLetter(returnRequest.preferredResolution)}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex flexDir="column">
        {fields.map((product: Product, index) => (
          <Flex key={product.sku} flexDir="column">
            <Flex justifyContent="flex-start" w="100%">
              <Flex w="32%">
                <Image src={product.image} width={16} height={16} mr={5} />
                <Flex textStyle="bodyS" flexDir="column">
                  <Text color="grey.600">{product.name}</Text>

                  <Text color="grey.600">
                    {t("return_requests.sku")} {product.sku}
                  </Text>
                </Flex>
              </Flex>
              <Flex flexDir="column" textStyle="bodyS">
                <Flex display={"flex"} flexDir={"column"} mb={4}>
                  <Text fontWeight="medium" mr={4}>
                    {t("return_requests.return_reason")}
                  </Text>
                  {product.returnReason && <Text>{product.returnReason}</Text>}
                  {!product.returnReason &&
                    !!product.reasonList &&
                    product.reasonList?.map((reason) => (
                      <Text key={reason.type}>
                        {`${reason.type} - ${reason.unitQuantity} ${
                          reason.unitQuantity > 1 ? "units" : "unit"
                        }`}
                      </Text>
                    ))}
                </Flex>
                {product.retailerComment && (
                  <Flex display={"flex"} flexDir={"column"}>
                    <Text fontWeight="medium" mr={4}>
                      {t("return_requests.retailer_notes")}
                    </Text>
                    <Text>{product.retailerComment}</Text>
                  </Flex>
                )}
                <Flex mt="5">
                  {!isEmpty(product.retailerImageUrls) && (
                    <ImagesViewer
                      label={"return-images"}
                      images={product.retailerImageUrls ?? []}
                    />
                  )}
                  {product.retailerImageUrls?.map((url, i) => {
                    if (!isSupportedImage(url)) {
                      return (
                        <Text
                          key={url}
                          as="a"
                          href={url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Flex
                            display={"flex"}
                            flexDir={"column"}
                            align={"center"}
                          >
                            <Icon
                              as={SolidDocument}
                              height={11}
                              width={10}
                              color="#1C7EC6"
                            />
                            <span>File {i + 1}</span>
                          </Flex>
                        </Text>
                      );
                    }
                    return null;
                  })}
                </Flex>
              </Flex>
              <Flex
                flexDir="column"
                gap={5}
                w="15%"
                justifyItems="flex-end"
                ml="auto"
              >
                {product.approvedUnitQuantity >= 0 && (
                  <Flex
                    flexDir="column"
                    textStyle="bodyS"
                    textAlign="right"
                    as={
                      product.status === "DECLINED" ||
                      product.status === "PARTIAL_APPROVED"
                        ? "s"
                        : displayStrokeQty(product)
                        ? "s"
                        : undefined
                    }
                  >
                    <Text>
                      {`${product.requestUnitQuantity}`}{" "}
                      {t("return_requests.unit")}
                    </Text>
                    <Text>
                      {Intl.NumberFormat("en-US", {
                        currency: returnRequest.currency,
                        style: "currency",
                      }).format(product.price * product.requestUnitQuantity)}
                    </Text>
                  </Flex>
                )}
                {!!Number(product.approvedUnitQuantity) &&
                  displayStrokeQty(product) && (
                    <Flex flexDir="column" textStyle="bodyS" textAlign="right">
                      <Text>
                        {`${product.approvedUnitQuantity}`}{" "}
                        {t("return_requests.unit")}
                      </Text>
                      <Text>
                        {Intl.NumberFormat("en-US", {
                          currency: returnRequest.currency,
                          style: "currency",
                        }).format(product.price * product.approvedUnitQuantity)}
                      </Text>
                    </Flex>
                  )}
              </Flex>
            </Flex>
            <Flex flexDir="column" mt={4}>
              {product.adminComment !== undefined &&
              product.adminComment !== "" ? (
                <Flex
                  borderRadius="base"
                  p={2}
                  mb={4}
                  textStyle="bodyS"
                  backgroundColor={
                    Number(product.approvedUnitQuantity) === 0
                      ? "red.100"
                      : Number(product.approvedUnitQuantity) <
                        Number(product.quantity)
                      ? "yellow.100"
                      : "red.100"
                  }
                >
                  {product.adminComment}
                </Flex>
              ) : null}
              <Flex justifyContent="right">
                {returnRequest.status === "PENDING_REVIEW" ? (
                  <Button
                    variant="outline"
                    w="fit-content"
                    onClick={() => {
                      currentProductRef.current = product.sku;
                      onOpenModal();
                    }}
                  >
                    {t("return_requests.edit")}
                  </Button>
                ) : null}
              </Flex>
            </Flex>

            {index !== returnRequest.products.length - 1 ? (
              <Divider my={8} />
            ) : null}
          </Flex>
        ))}
      </Flex>

      <Flex
        background="light.lightGrey"
        borderRadius="base"
        w="100%"
        p={2}
        mt={8}
        justifyContent="right"
      >
        <Flex>
          <Flex textStyle="bodyS" flexDir="column" mr={6}>
            <Text>{t("return_requests.subtotal")}</Text>
            <Text>{t("return_requests.shipping")}</Text>
          </Flex>
          <Flex textStyle="bodyS" flexDir="column" textAlign="right">
            <Text>
              {Intl.NumberFormat("en-US", {
                currency: returnRequest.currency,
                style: "currency",
              }).format(
                returnRequest.status === "DECLINED"
                  ? 0
                  : approvedReturnSum ?? returnRequest.subtotal,
              )}
            </Text>
            <Text>{t("return_requests.free")}</Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex mt={2} p={2} textStyle="bodyS" justifyContent="right">
        <Text fontWeight="bold" mr={8}>
          {returnRequest.status === "PENDING" ||
          returnRequest.status === "CONFIRMED"
            ? t("return_requests.estimated_refund")
            : t("return_requests.total_refund")}
        </Text>
        <Text>
          {Intl.NumberFormat("en-US", {
            currency: returnRequest.currency,
            style: "currency",
          }).format(
            returnRequest.status === "DECLINED"
              ? 0
              : approvedReturnSum ?? returnRequest.total,
          )}
        </Text>
      </Flex>
      <EditReturnQuantityModal
        isOpenModal={isOpenModal}
        onCloseModal={onCloseModal}
        sku={currentProductRef.current}
        register={register}
        handleApply={handleApply}
        errors={errors}
        resetModal={resetModal}
        maxProductQty={maxProductQty}
      />
    </Flex>
  );
};
