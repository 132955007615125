import { Link, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";

export type ZendeskTicket = {
  id: number;
  status: string;
  title: string;
  url: string;
};
type ZendeskTicketsTableProps = {
  tickets: ZendeskTicket[];
};

const ZendeskTicketsTable = (props: ZendeskTicketsTableProps) => {
  const { tickets } = props;
  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th>ID</Th>
          <Th>Status</Th>
          <Th>Title</Th>
        </Tr>
      </Thead>
      <Tbody>
        {tickets.map((ticket) => (
          <Link
            key={ticket.id}
            href={`https://markato.zendesk.com/agent/tickets/${ticket.id}`}
            isExternal
            cursor="pointer"
            display="contents"
          >
            <Tr>
              <Td>{ticket.id}</Td>
              <Td>{ticket.status}</Td>
              <Td>{ticket.title}</Td>
            </Tr>
          </Link>
        ))}
      </Tbody>
    </Table>
  );
};

export default ZendeskTicketsTable;
