import { useQuery } from "@tanstack/react-query";

import httpClient from "services/httpClient";

const GET_BRAND_PRODUCTS = "/admin/orders/";
const EDIT_PRODUCTS = "/edit/products";

export type AdminSuborderProduct = {
  productKey: string;
  productName: string;
  description: string;
  productOptions: string;
  variantId: number;
  variantKey: string;
  variantName?: string;
  sku: string;
  pricePerCase: number;
  unitPrice: number;
  discountedPrice: number;
  msrpPrice: number;
  wholesalePriceCurrency: string;
  discountedPriceCurrency: string;
  msrpPriceCurrency: string;
  wholesalePriceLabel: string;
  discountedPriceLabel: string;
  msrpPriceLabel: string;
  minOrderQuantity: number;
  maxOrderQuantity: number;
  caseQuantity: number;
  media: {
    images: string[];
  };
};

export type AdminSuborderProductDTO = {
  data: AdminSuborderProduct[];
};

export const useGetBrandProductsBySuborderId = (suborderId: string) =>
  useQuery({
    queryKey: [GET_BRAND_PRODUCTS, EDIT_PRODUCTS, suborderId],
    queryFn: async () => {
      const { data: brandProducts } =
        await httpClient.get<AdminSuborderProductDTO>(
          `${GET_BRAND_PRODUCTS}${suborderId}${EDIT_PRODUCTS}`,
        );
      return brandProducts;
    },
  });
