/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";

// eslint-disable-next-line @typescript-eslint/unbound-method
const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyleHeader = defineStyle({
  px: 8,
  py: 4,
  fontSize: "xl",
  fontWeight: "bold",
});

const baseStyleCloseButton = defineStyle({
  position: "absolute",
  top: 4,
  right: 8,
});

const baseStyleBody = defineStyle({
  px: 8,
  py: 6,
  flex: "1",
  overflow: "auto",
});

const baseStyleFooter = defineStyle({
  px: 8,
  py: 6,
});

const baseStyle = definePartsStyle({
  header: baseStyleHeader,
  closeButton: baseStyleCloseButton,
  footer: baseStyleFooter,
  body: baseStyleBody,
});

/**
 * Since the `maxWidth` prop references theme.sizes internally,
 * we can leverage that to size our modals.
 */
function getSize(value: string) {
  if (value === "full") {
    return definePartsStyle({
      dialog: {
        maxW: "100vw",
        minH: "$100vh",
        my: "0",
        borderRadius: "0",
      },
    });
  }
  return definePartsStyle({
    dialog: { maxW: value },
  });
}

const sizes = {
  xs: getSize("xs"),
  sm: getSize("sm"),
  md: getSize("md"),
  lg: getSize("lg"),
  xl: getSize("xl"),
  "2xl": getSize("2xl"),
  "3xl": getSize("3xl"),
  "4xl": getSize("4xl"),
  "5xl": getSize("5xl"),
  "6xl": getSize("6xl"),
  "141": getSize("141"),
  full: getSize("full"),
};

export const Drawer = defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: { size: "md" },
});
