import { useMutation } from "@tanstack/react-query";

import { MultiplierFormFields } from "./useMultiplierForm";
import { ErrorResponse } from "services/ErrorResponse";
import httpClient from "services/httpClient";

export const POST_MULTIPLIERS = "admin/msrp-multiplier/batch";

const updateBatchMultipliers = async (
  multiplierFields: MultiplierFormFields,
) => {
  return await httpClient.post<MultiplierFormFields>(
    POST_MULTIPLIERS,
    multiplierFields.multipliers,
  );
};

export const useUpdateBatchMultipliers = (
  onSuccess: () => void,
  onError: (error: ErrorResponse) => void,
) => useMutation(updateBatchMultipliers, { onSuccess, onError });
