import { VoucherAppliedTo } from "../../UserManagement/types";

export const voucherTypeToLabel = (type: VoucherAppliedTo) => {
  switch (type) {
    case "TOTAL_ORDER":
      return "Total Order";
    case "SHIPPING_FEE":
      return "Shipping Fee";
    case "BRAND_ORDER":
      return "Brand Order";
    case "BRAND_SHIPPING_FEE":
      return "Brand Shipping Fee";
    default:
      return type;
  }
};

export const isBrandSpecific = (type: VoucherAppliedTo) =>
  ["BRAND_SHIPPING_FEE", "BRAND_ORDER"].includes(type);
