import { Flex, Image } from "@chakra-ui/react";
import { PhotoProvider, PhotoView as ReactPhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";

import "./ImagesViewer.scss";
import { isSupportedImage } from "utils/fileUtils";

type PhotoViewProps = {
  images: string[];
  label: string;
};

const ImagesViewer = ({ images, label }: PhotoViewProps) => {
  return (
    <Flex gap={2}>
      <PhotoProvider
        maskOpacity={0.6}
        photoClassName="imgClass"
        photoWrapClassName="imgWrapperClass"
      >
        {images
          .filter((image) => isSupportedImage(image))
          .map((image, index) => {
            return (
              <ReactPhotoView src={image} key={image} height={10} width={10}>
                <Image
                  src={image}
                  alt={`${label}-${index}`}
                  height={75}
                  width={75}
                  objectFit="contain"
                  cursor="pointer"
                />
              </ReactPhotoView>
            );
          })}
      </PhotoProvider>
    </Flex>
  );
};

export default ImagesViewer;
