import { useMutation } from "@tanstack/react-query";

import { DeactivateRetailerFormFields } from "./useDeactivateForm";
import { RETAILERS_KEY } from "services/ReactQueryKeys";
import httpClient from "services/httpClient";
import { queryClient } from "services/queryClient";

export const DEACTIVATE_RETAILER_BY_ID_URL = "/admin/retailer";

const deactivateRetailer = async (form: DeactivateRetailerFormFields) => {
  return await httpClient.put(
    `${DEACTIVATE_RETAILER_BY_ID_URL}/${form.id}/block`,
    {
      blockReason: form.reason,
    },
  );
};

export const usePutDeactivateRetailerAccount = (id: string) =>
  useMutation(deactivateRetailer, {
    onSuccess: () => {
      void queryClient.fetchQuery({ queryKey: [RETAILERS_KEY, id] });
    },
  });
