import {
  BoxProps,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/react";
import { ReactNode, forwardRef } from "react";

export type ModalWrapperProps = {
  isOpen: boolean;
  onClose: () => void;
  modalProps?: ModalProps & BoxProps;
  modalHeader?: ReactNode;
  modalBody?: ReactNode;
  modalFooter?: ReactNode;
  headerProps?: BoxProps;
  bodyProps?: BoxProps;
  showHeaderDivider?: boolean;
  showCloseButton?: boolean;
};
export const ModalWrapper = forwardRef<HTMLDivElement, ModalWrapperProps>(
  (
    {
      isOpen,
      onClose,
      modalHeader,
      modalBody,
      modalFooter,
      modalProps,
      headerProps,
      bodyProps,
      showHeaderDivider = false,
      showCloseButton = true,
    },
    ref,
  ) => {
    return (
      <Modal {...modalProps} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader {...headerProps}>{modalHeader}</ModalHeader>
          {showCloseButton && <ModalCloseButton />}
          {showHeaderDivider && <Divider />}
          <ModalBody {...bodyProps}>{modalBody}</ModalBody>
          {modalFooter && (
            <>
              <Divider />
              <ModalFooter>{modalFooter}</ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    );
  },
);

ModalWrapper.displayName = "ModalWrapper";
