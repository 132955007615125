/* eslint-disable @typescript-eslint/no-unnecessary-condition */

/* eslint-disable @typescript-eslint/restrict-template-expressions */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-misused-promises */

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Card,
  CardBody,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Radio,
  RadioGroup,
  SimpleGrid,
  Spinner,
  Stack,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { MultiSelect, SelectItem } from "chakra-multiselect";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Product, useBrandProfile } from "./hooks/useBrandProfile";
import { useBrands } from "./hooks/useBrands";
import { settings } from "./settings";
import "./style.scss";
import { Logo } from "components/Logo/Logo";
import { filterOnLabelAndValue } from "utils/multiSelect";

const BrandProfile = (props: {
  brandId: string;
  text: Record<string, string>;
  lang: string;
  showWholesale: string;
}) => {
  const { brandId, text, lang, showWholesale } = props;

  const { data } = useBrandProfile(
    brandId,
    lang,
    settings[lang as unknown as keyof typeof settings].currency,
  );

  const [selectedProduct0, setSelectedProduct0] = useState<Product>();
  const [selectedProduct1, setSelectedProduct1] = useState<Product>();
  const [selectedProduct2, setSelectedProduct2] = useState<Product>();

  useEffect(() => {
    const saved0 = localStorage.getItem(`pdf_${brandId}_0`);
    const saved1 = localStorage.getItem(`pdf_${brandId}_1`);
    const saved2 = localStorage.getItem(`pdf_${brandId}_2`);

    if (saved0 && data?.brandProducts?.results.some((el) => el.id === saved0)) {
      setSelectedProduct0(
        data?.brandProducts?.results.find((el) => el.id === saved0),
      );
    } else {
      setSelectedProduct0(data?.brandProducts?.results[0]);
    }

    if (saved1 && data?.brandProducts?.results.some((el) => el.id === saved1)) {
      setSelectedProduct1(
        data?.brandProducts?.results.find((el) => el.id === saved1),
      );
    } else {
      setSelectedProduct1(data?.brandProducts?.results[1]);
    }

    if (saved2 && data?.brandProducts?.results.some((el) => el.id === saved2)) {
      setSelectedProduct2(
        data?.brandProducts?.results.find((el) => el.id === saved2),
      );
    } else {
      setSelectedProduct2(data?.brandProducts?.results[2]);
    }
  }, [brandId, data?.brandProducts?.results]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const setSelectedProduct = useRef(setSelectedProduct0);
  const selectedProductIndex = useRef(0);

  return (
    <div key={brandId} className="page page4">
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Choose a product to replace</DrawerHeader>
          <DrawerBody>
            <div className="choose-product">
              <Grid templateColumns="repeat(3, 1fr)" gap={2}>
                {data?.brandProducts?.results?.map((product, i) => {
                  return (
                    <GridItem
                      p="2"
                      key={product?.id}
                      className="card"
                      onClick={() => {
                        setSelectedProduct.current(
                          data?.brandProducts?.results[i],
                        );
                        localStorage.setItem(
                          `pdf_${brandId}_${selectedProductIndex.current}`,
                          data?.brandProducts?.results[i].id ?? "",
                        );
                        onClose();
                      }}
                    >
                      <img
                        src={`https://www.markato.com/cdn-cgi/image/width=384,quality=60,format=auto/${product?.image}`}
                        alt={product?.name}
                      />
                      {product?.name}
                    </GridItem>
                  );
                })}
              </Grid>
            </div>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <div className="wrapper">
        <div className="left">
          <h1>{text.text1}</h1>
          <p className="brandName">
            <a
              target="_blank"
              href={`https://www.markato.com/${lang}/brand/${brandId}`}
              rel="noreferrer"
            >
              {data?.brandName}
            </a>
          </p>
          <p className="brandLocation">{data?.brandLocation}</p>

          {data?.product_types && (
            <p className="product_types">
              <strong>{text.text2}</strong>
              <br />
              {data.product_types}
            </p>
          )}

          {data?.values && (
            <p className="values">
              <strong>{text.text3}</strong>
              <br />
              {data.values}
            </p>
          )}

          {data?.style && (
            <p className="style">
              <strong>{text.text4}</strong>
              <br />
              {data.style}
            </p>
          )}

          {data?.highlights && (
            <p className="highlights">
              <strong>{text.text5}</strong>
              <br />
              {data.highlights}
            </p>
          )}
        </div>
        <div className="right">
          <div className="top">
            <div className="tile">
              {selectedProduct0?.image && (
                <a
                  href={`https://www.markato.com/${lang}/product/${selectedProduct0?.keyParam}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setSelectedProduct.current = setSelectedProduct0;
                    selectedProductIndex.current = 0;
                    onOpen();
                  }}
                >
                  <img
                    src={`https://www.markato.com/cdn-cgi/image/width=384,quality=60,format=auto/${selectedProduct0?.image}`}
                    alt=""
                  />

                  <h2>{selectedProduct0?.name}</h2>
                  <p>
                    {selectedProduct0?.wholeSalePrice && showWholesale === "yes"
                      ? `${text.text6} ${selectedProduct0?.wholeSalePrice}`
                      : ""}
                  </p>
                  <p className="msrp">
                    {text.text7} {selectedProduct0?.msrp}
                  </p>
                </a>
              )}
            </div>
            <div className="tile">
              {selectedProduct1?.image && (
                <a
                  href={`https://www.markato.com/${lang}/product/${selectedProduct1?.keyParam}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setSelectedProduct.current = setSelectedProduct1;
                    selectedProductIndex.current = 1;
                    onOpen();
                  }}
                >
                  <img
                    src={`https://www.markato.com/cdn-cgi/image/width=384,quality=60,format=auto/${selectedProduct1?.image}`}
                    alt=""
                  />

                  <h2>{selectedProduct1?.name}</h2>
                  <p>
                    {selectedProduct1?.wholeSalePrice && showWholesale === "yes"
                      ? `${text.text6} ${selectedProduct1?.wholeSalePrice}`
                      : ""}
                  </p>
                  <p className="msrp">
                    {text.text7} {selectedProduct1?.msrp}
                  </p>
                </a>
              )}
            </div>
            <div className="tile">
              {selectedProduct2?.image && (
                <a
                  href={`https://www.markato.com/${lang}/product/${selectedProduct2?.keyParam}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setSelectedProduct.current = setSelectedProduct2;
                    selectedProductIndex.current = 2;
                    onOpen();
                  }}
                >
                  <img
                    src={`https://www.markato.com/cdn-cgi/image/width=384,quality=60,format=auto/${selectedProduct2?.image}`}
                    alt=""
                  />

                  <h2>{selectedProduct2?.name}</h2>
                  <p>
                    {selectedProduct2?.wholeSalePrice && showWholesale === "yes"
                      ? `${text.text6} ${selectedProduct2?.wholeSalePrice}`
                      : ""}
                  </p>
                  <p className="msrp">
                    {text.text7} {selectedProduct2?.msrp}
                  </p>
                </a>
              )}
            </div>
          </div>
          <div className="bottom">
            {data?.imgRef?.[0] && (
              <div className="tile">
                <a
                  target="_blank"
                  href={`https://www.markato.com/${lang}/brand/${brandId}`}
                  rel="noreferrer"
                >
                  <img
                    src={`https://www.markato.com/cdn-cgi/image/width=384,quality=60,format=auto/${data.imgRef[0]}`}
                    alt=""
                  />
                </a>
              </div>
            )}

            {data?.imgRef?.[1] && (
              <div className="tile">
                <a
                  target="_blank"
                  href={`https://www.markato.com/${lang}/brand/${brandId}`}
                  rel="noreferrer"
                >
                  <img
                    src={`https://www.markato.com/cdn-cgi/image/width=384,quality=60,format=auto/${data.imgRef[1]}`}
                    alt=""
                  />
                </a>
              </div>
            )}

            {data?.imgRef?.[2] && (
              <div className="tile">
                <a
                  target="_blank"
                  href={`https://www.markato.com/${lang}/brand/${brandId}`}
                  rel="noreferrer"
                >
                  <img
                    src={`https://www.markato.com/cdn-cgi/image/width=384,quality=60,format=auto/${data.imgRef[2]}`}
                    alt=""
                  />
                </a>
              </div>
            )}

            {data?.imgRef?.[3] && (
              <div className="tile">
                <a
                  target="_blank"
                  href={`https://www.markato.com/${lang}/brand/${brandId}`}
                  rel="noreferrer"
                >
                  <img
                    src={`https://www.markato.com/cdn-cgi/image/width=384,quality=60,format=auto/${data.imgRef[3]}`}
                    alt=""
                  />
                </a>
              </div>
            )}

            {data?.imgRef?.[4] && (
              <div className="tile">
                <a
                  target="_blank"
                  href={`https://www.markato.com/${lang}/brand/${brandId}`}
                  rel="noreferrer"
                >
                  <img
                    src={`https://www.markato.com/cdn-cgi/image/width=384,quality=60,format=auto/${data.imgRef[4]}`}
                    alt=""
                  />
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

type Option = {
  label: string;
  value: string;
};

export const StandardDeck = () => {
  const [queryParameters] = useSearchParams();

  const { data: brands = [] } = useBrands();
  const options = brands.map(({ storeName, storeKey }) => ({
    label: storeName,
    value: storeKey,
  }));
  const [selectedBrand, setSelectedBrand] = useState<SelectItem[]>();

  const [lang, setLang] = useState("en-hk");
  const [showWholesale, setShowWholesale] = useState("yes");
  const [email, setEmail] = useState<string>(
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    localStorage.getItem("email") || "kyle@markato.com",
  );
  const [phone, setPhone] = useState<string>(
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    localStorage.getItem("phone") || "+852 9852 0791",
  );

  if (!settings[lang as unknown as keyof typeof settings]) {
    throw new Error(`Language ${lang} not found in settings`);
  }

  const text = settings[lang as unknown as keyof typeof settings].text;

  const getBrand = (brandKey: string) =>
    options.find((v) => v.value === brandKey);

  useEffect(() => {
    const qs = queryParameters.get("brands");

    if (qs) {
      const keys = qs.split(",");
      const initialValue: Option[] = [];
      keys.forEach((key) => {
        const option = getBrand(key.trim());
        if (option) {
          initialValue.push(option as Option);
        }
      });
      setSelectedBrand(initialValue);
    } else {
      const initialValue = options.slice(0, 1);
      if (brands.length > 0) {
        setSelectedBrand(initialValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brands]);

  if (options.length === 0) {
    return <Spinner position="absolute" top="50vh" left="50%" />;
  }

  return (
    <Box>
      <Card className="hidePrint" mt={3} mb={8}>
        <CardBody>
          <Box mb={4} textAlign="right" fontSize={14}>
            <a
              href="https://www.notion.so/markato/PDF-Deck-Generation-3e618800731948ee990aa864e54a1e29?pvs=4"
              target="_blank"
              rel="noreferrer"
            >
              ℹ️ User Guide
            </a>
          </Box>

          <Box mb={4}>
            <FormLabel>Language</FormLabel>
            <RadioGroup defaultValue={lang} onChange={setLang}>
              <Stack direction="row">
                {Object.keys(settings).map((lang) => (
                  <Radio key={lang} value={lang}>
                    {lang}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          </Box>

          <Box mb={4}>
            <FormLabel>Brands</FormLabel>
            <MultiSelect
              options={options}
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/prefer-nullish-coalescing
              value={selectedBrand}
              placeholder="Select ..."
              searchPlaceholder="Search ..."
              size="md"
              onChange={(items) => {
                setSelectedBrand(items as unknown as SelectItem[]);
              }}
              filterFn={filterOnLabelAndValue}
            />
            <Textarea
              defaultValue={selectedBrand
                ?.map((item) => `${item.value}`)
                .join("\n")}
              value={selectedBrand?.map((item) => `${item.value}`).join("\n")}
              onChange={(ev) => {
                const keys = ev.target.value.split("\n");
                const newValue: Option[] = [];
                keys.forEach((key) => {
                  const option = getBrand(key.replace(",", "").trim());
                  if (option) {
                    newValue.push(option as Option);
                  }
                });
                setSelectedBrand(newValue);
              }}
              placeholder="Put brand keys here"
            />
          </Box>

          <Box mb={4}>
            <SimpleGrid columns={2} spacing={4}>
              <Box>
                <FormLabel>Email address</FormLabel>
                <Input
                  value={email}
                  onChange={(ev) => {
                    setEmail(ev.target.value);
                    localStorage.setItem("email", ev.target.value);
                  }}
                />
              </Box>
              <Box>
                <FormLabel>Phone number</FormLabel>
                <Input
                  value={phone}
                  onChange={(ev) => {
                    setPhone(ev.target.value);
                    localStorage.setItem("phone", ev.target.value);
                  }}
                />
              </Box>
            </SimpleGrid>
          </Box>

          <Box mb={4}>
            <FormLabel>Show wholesale price</FormLabel>
            <RadioGroup
              defaultValue={showWholesale}
              onChange={setShowWholesale}
            >
              <Stack direction="row">
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Stack>
            </RadioGroup>
          </Box>
        </CardBody>
      </Card>

      <Box>
        <div className="page page1">
          <div className="wrapper">
            <img src="/images/pdfs/standard_deck/page1_bg.jpg" alt="" />
            <h1>{text.page1.text1}</h1>
            <h2>{text.page1.text2}</h2>
            <img
              src="/images/pdfs/standard_deck/logo.png"
              alt=""
              className="logo"
            />
          </div>
        </div>

        <div className="page page2">
          <div className="wrapper">
            <div className="card">
              <h2>{text.page2.text1}</h2>
              <div className="inner">
                <div className="row">
                  <div className="left">600+</div>
                  <div className="right">{text.page2.text2}</div>
                </div>
                <div className="row">
                  <div className="left">20%</div>
                  <div className="right">{text.page2.text3}</div>
                </div>
                <div className="row">
                  <div className="left">{text.page2.text4}</div>
                  <div className="right">{text.page2.text5}</div>
                </div>
              </div>
            </div>
            <div className="card">
              <h2>{text.page2.text6}</h2>
              <div className="inner">
                <div className="row">
                  <div className="left">~2.5-3x</div>
                  <div className="right">{text.page2.text7}</div>
                </div>
                <div className="row">
                  <div className="left">{text.page2.text8}</div>
                  <div className="right">{text.page2.text9}</div>
                </div>
                <div className="row">
                  <div className="left">{text.page2.text10}</div>
                  <div className="right">{text.page2.text11}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page page3">
          <img src="/images/pdfs/standard_deck/page_3_1.jpg" alt="" />

          <h1>{text.page3.text1}</h1>

          <h2>{text.page3.text2}</h2>
        </div>

        {selectedBrand?.map((brand) => {
          return (
            <BrandProfile
              brandId={brand.value as string}
              key={brand.value as string}
              text={text.page4}
              lang={lang}
              showWholesale={showWholesale}
            ></BrandProfile>
          );
        })}

        <div className="page page5">
          <img src="/images/pdfs/standard_deck/page_6.jpg" alt="" />
          <h1>{text.page5.text1}</h1>
          <h2>{text.page5.text2}</h2>

          <ul>
            <li>
              <h3>{text.page5.text3}</h3>
              <div className="founder">
                <img
                  src="/images/pdfs/standard_deck/c16bffda-a30c-4cc2-8d6d-d904e2a71ec8.jpg"
                  alt=""
                />
                <strong>{text.page5.text4}</strong>
                <br />
                <strong>{text.page5.text5}</strong> {text.page5.text6}
              </div>
              <br />
              <div className="founder">
                <img
                  src="/images/pdfs/standard_deck/ebfede35-9966-4d53-9c19-16d7c6d42f9d.jpeg"
                  alt=""
                />
                <strong>{text.page5.text7}</strong>
                <br />
                <strong>{text.page5.text8}</strong> {text.page5.text9}
              </div>
            </li>
            <li>
              <h3>{text.page5.text10}</h3>
              <h4>{text.page5.text11}</h4>
              <img src="/images/pdfs/standard_deck/page_5_1.png" alt="" />
              <h4>{text.page5.text12}</h4>
              <img src="/images/pdfs/standard_deck/page_5_2.png" alt="" />
            </li>
            <li>
              <h3>{text.page5.text13}</h3>
              <img src="/images/pdfs/standard_deck/page_5_3.png" alt="" />
            </li>
          </ul>
        </div>

        <div className="page page6">
          <div className="wrapper">
            <Logo variant="secondary" mb={5} />
            <p>
              <a target="_blank" href={`mailto:${email}`} rel="noreferrer">
                {email}
              </a>
            </p>
            <p>{phone}</p>
            <br />
            <a target="_blank" href="https://www.markato.com" rel="noreferrer">
              www.markato.com
            </a>
          </div>
        </div>
      </Box>
    </Box>
  );
};
