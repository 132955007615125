import { useMutation } from "@tanstack/react-query";

import httpClient from "services/httpClient";

export const DELETE_VOUCHER_BY_ID = "/admin/vouchers";

const deleteVoucher = async ({ key }: { key: string }) => {
  return await httpClient.delete(`${DELETE_VOUCHER_BY_ID}/${key}`);
};

export const useDeleteVoucher = () => useMutation(deleteVoucher);
