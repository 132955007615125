import { Spinner } from "@chakra-ui/react";

import { useGetPaymentTermsUsage } from "../hooks/useGetPaymentTermsUsage";
import { PaymentTermsUsageDetails } from "./PaymentTermsUsageDetails";

type PaymentTermsUsageProps = {
  id: string;
};

export const PaymentTermsUsage = ({ id }: PaymentTermsUsageProps) => {
  const { data: paymentTerms, isLoading } = useGetPaymentTermsUsage(id);

  return isLoading ? (
    <Spinner position="absolute" top="50%" left="50%" />
  ) : (
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    <PaymentTermsUsageDetails {...paymentTerms!} />
  );
};
