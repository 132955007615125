export const closeIconStyle = {
  color: "grey.600",
  w: 5,
  h: 5,
  marginRight: 1,
};

export const calendarIconStyle = (disabled?: boolean) => ({
  color: disabled ? "grey.200" : "grey.600",
  w: 5,
  h: 5,
  marginRight: 2.5,
});
