import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import {
  leftSideModalStyle,
  rightSideModalStyle,
  textStyle,
} from "./ForgotPassword.style";
import { CreateNewPassword } from "./Steps/CreateNewPassword";
import { EnterUsername } from "./Steps/EnterUsername";
import { ThankYou } from "./Steps/ThankYou";
import { ForgotPasswordProps } from "./types";
import { Logo } from "components/Logo/Logo";
import { ModalWrapper } from "components/Modal/ModalWrapper";

export const ForgotPassword = ({
  isOpen,
  setIsOpen,
  username: enteredUsername,
}: ForgotPasswordProps) => {
  const [step, setStep] = useState<number>(0);
  const [username, setUsername] = useState<string>(enteredUsername || "");
  const [code, setCode] = useState<string>("");

  const { t } = useTranslation();

  const [queryParameters] = useSearchParams();

  useEffect(() => {
    const hasCode = queryParameters.has("code");
    if (hasCode) {
      const code = queryParameters.get("code") ?? "";
      setCode(code);
      setStep(1);
    }
  }, [queryParameters, setIsOpen]);

  const switchSteps = (step: number) => {
    switch (step) {
      case 1:
        return (
          <CreateNewPassword
            username={username}
            code={code}
            step={step}
            setStep={setStep}
          />
        );
      case 2:
        return <ThankYou setIsOpen={setIsOpen} setStep={setStep} />;
      case 0:
      default:
        return (
          <EnterUsername
            username={enteredUsername}
            setUsername={setUsername}
            setStep={setStep}
          />
        );
    }
  };

  const modalBody = (
    <Flex h="600px">
      <Flex {...leftSideModalStyle}>
        <Logo mb={32} mt={10} />
        <Box w="200px" h="165px">
          <Image src="/images/reset-password.svg" />
        </Box>
        <Text {...textStyle}>{t("Password reset")}</Text>
      </Flex>
      <Flex {...rightSideModalStyle}>{switchSteps(step)}</Flex>
    </Flex>
  );

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={() => {
        setStep(0);
        setIsOpen();
      }}
      modalBody={modalBody}
      modalProps={{
        isOpen: isOpen,
        onClose: () => setIsOpen,
        size: "lg",
        children: undefined,
      }}
      headerProps={{
        m: "0px",
        p: "0px",
      }}
      bodyProps={{
        p: "0px",
      }}
    />
  );
};
