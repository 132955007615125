import { SVGProps } from "react";

const SvgLightCheckCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="m10.575 14.15-2.35-2.35a.652.652 0 0 0-.512-.187.737.737 0 0 0-.513.212.72.72 0 0 0-.225.525c0 .2.075.375.225.525l2.75 2.75c.167.167.375.25.625.25s.467-.083.65-.25l5.575-5.6a.657.657 0 0 0 .188-.513.74.74 0 0 0-.213-.512.72.72 0 0 0-.525-.225.72.72 0 0 0-.525.225l-5.15 5.15ZM12 21.5a9.263 9.263 0 0 1-3.712-.75 9.58 9.58 0 0 1-3.013-2.025 9.58 9.58 0 0 1-2.025-3.013A9.263 9.263 0 0 1 2.5 12c0-1.317.25-2.554.75-3.713a9.583 9.583 0 0 1 2.025-3.012A9.58 9.58 0 0 1 8.288 3.25 9.263 9.263 0 0 1 12 2.5a9.27 9.27 0 0 1 3.713.75 9.583 9.583 0 0 1 3.012 2.025 9.583 9.583 0 0 1 2.025 3.012A9.27 9.27 0 0 1 21.5 12c0 1.317-.25 2.554-.75 3.712a9.58 9.58 0 0 1-2.025 3.013 9.583 9.583 0 0 1-3.012 2.025A9.27 9.27 0 0 1 12 21.5Zm0-1.5c2.217 0 4.104-.779 5.663-2.337C19.221 16.104 20 14.217 20 12s-.779-4.104-2.337-5.663C16.104 4.779 14.217 4 12 4s-4.104.779-5.662 2.337C4.779 7.896 4 9.783 4 12s.78 4.104 2.338 5.663C7.896 19.221 9.783 20 12 20Z"
    />
  </svg>
);
export default SvgLightCheckCircle;
