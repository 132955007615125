/* eslint-disable @tanstack/query/exhaustive-deps */
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { BrandDetailsType } from "features/UserManagement/types";
import { BRANDS_KEY } from "services/ReactQueryKeys";
import httpClient from "services/httpClient";

export const GET_ALL_BRANDS_URL = "/admin/brands";

export const useGetBrandById = (id: string): UseQueryResult<BrandDetailsType> =>
  useQuery({
    queryKey: [BRANDS_KEY, id],
    queryFn: async () => {
      const { data: brandAccount } = await httpClient.get<BrandDetailsType>(
        `${GET_ALL_BRANDS_URL}/${id}`,
      );
      return brandAccount;
    },
  });
