import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { BrandRequestDetails } from "features/BrandRequests/types";
import httpClient from "services/httpClient";

export const GET_BRAND_REQUEST_BY_ID = "/admin/brand-requests/";

export const useGetBrandRequestById = (
  id: string,
): UseQueryResult<BrandRequestDetails> =>
  useQuery({
    queryKey: [GET_BRAND_REQUEST_BY_ID, id],
    queryFn: async () => {
      const { data: brandRequestDetails } =
        await httpClient.get<BrandRequestDetails>(
          `${GET_BRAND_REQUEST_BY_ID}${id}`,
        );
      return brandRequestDetails;
    },
  });
