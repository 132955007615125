import { useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { usePutBrandEmail } from "./usePutBrandEmail";
import { normalizeText } from "features/UserManagement/utils";
import { ErrorResponse } from "services/ErrorResponse";
import { getErrorCodes } from "services/httpClient";

export type ChangeBrandEmailFields = {
  newEmail: string;
  confirmEmail: string;
  brandId: string;
};

type UseChangeEmailFormProps = {
  brandId: string;
  onClose: () => void;
};

export const useChangeEmailForm = ({
  brandId,
  onClose,
}: UseChangeEmailFormProps) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    control,
    getValues,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm<ChangeBrandEmailFields>({
    defaultValues: {
      brandId: brandId,
    },
    mode: "onChange",
  });

  const toast = useToast();

  const onSuccess = () => {
    onClose();
    toast({
      position: "top-right",
      description: t("brand_settings.email_success_change"),
    });
    reset({
      brandId: brandId,
      newEmail: "",
      confirmEmail: "",
    });
  };

  const onError = (error: ErrorResponse) => {
    toast({
      position: "top-right",
      status: "error",
      description: t(getErrorCodes(error)),
    });
  };

  const { mutateAsync: putBrandEmail } = usePutBrandEmail(onSuccess, onError);

  const onSubmit = handleSubmit(async () => {
    const newEmail = normalizeText(getValues().newEmail);
    const confirmEmail = normalizeText(getValues().confirmEmail);
    await putBrandEmail({
      newEmail,
      confirmEmail,
      brandId,
    });
  });

  return {
    register,
    onSubmit,
    control,
    getValues,
    reset,
    errors,
    isDirty,
    isValid,
  };
};
