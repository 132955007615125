import { SVGProps } from "react";

const SvgSolidPeople = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.86867 12.4808C11.1238 12.4808 12.952 10.5823 12.952 8.24038C12.952 5.89848 11.1238 4 8.86867 4C6.61352 4 4.78537 5.89848 4.78537 8.24038C4.78537 10.5823 6.61352 12.4808 8.86867 12.4808ZM15.3251 19.75C16.2181 19.75 16.7687 18.7539 16.2285 18.0153C14.268 15.3349 11.6267 13.6923 8.72113 13.6923C5.81559 13.6923 3.1743 15.3349 1.21377 18.0153C0.673572 18.7539 1.22412 19.75 2.11721 19.75H15.3251ZM14.1189 8.24086C14.1189 9.71107 13.5585 11.0454 12.6474 12.0259C13.3045 12.6563 14.1769 13.0404 15.134 13.0404C17.1785 13.0404 18.8359 11.288 18.8359 9.12621C18.8359 6.96446 17.1785 5.21201 15.134 5.21201C14.5381 5.21201 13.9751 5.36089 13.4764 5.62538C13.886 6.40183 14.1189 7.2931 14.1189 8.24086ZM17.3355 19.7464L17.3333 19.7505H20.9874C21.797 19.7505 22.2962 18.831 21.8064 18.1493C20.029 15.675 17.6344 14.1588 15.0003 14.1588C14.6873 14.1588 14.3777 14.1802 14.0722 14.222C15.2269 15.0212 16.2662 16.064 17.1578 17.283C17.7268 18.0609 17.7248 19.0152 17.3355 19.7464Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgSolidPeople;
