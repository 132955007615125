import { Box, Flex, Heading } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { PageUpdateTableOverview } from "./table/BrandPageUpdateTableOverview";
import { RegistrationsTableOverview } from "./table/BrandRegistrationsTableOverview";
import { TabData, TabWrapper } from "components/Tabs/TabWrapper";

export const BrandRequests = () => {
  const [tabIndex, setTabIndex] = useState<number>(
    parseInt(window.location.hash.replace("#", "") || "0"),
  );

  const { t } = useTranslation();

  const tabsData: TabData[] = [
    {
      title: t("registrations"),
      content: <RegistrationsTableOverview />,
    },
    {
      title: t("page_update"),
      content: <PageUpdateTableOverview />,
    },
  ];

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
    window.location.hash = `${index}`;
  };

  return (
    <Box margin="auto">
      <Flex display="column">
        <Heading as="h1" size="h1" fontWeight="bold" mb={2} mt={5} ml={10}>
          {t("Brand Requests")}
        </Heading>
        <TabWrapper
          tabListProps={{ pl: 10 }}
          tabPanelsProps={{ pl: 2 }}
          tabsData={tabsData}
          tabIndex={tabIndex}
          handleTabsChange={handleTabsChange}
        />
      </Flex>
    </Box>
  );
};
