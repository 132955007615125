import { Flex, UseRadioProps, useRadio } from "@chakra-ui/react";

type ColorCircleProps = {
  color: string;
  borderColor: string;
  width?: string | number;
  height?: string | number;
  mr?: string | number;
} & UseRadioProps;

export const ColorCircle = ({
  color,
  borderColor,
  width,
  height,
  mr,
  ...rest
}: ColorCircleProps) => {
  const { state, getInputProps } = useRadio(rest);

  const input = getInputProps();

  return (
    <Flex
      h={height ?? 8}
      w={width ?? 8}
      borderRadius="50%"
      border={state.isChecked ? "1px solid" : ""}
      borderColor={state.isChecked ? "#4D4D54" : ""}
      justifyContent="center"
      alignItems="center"
      cursor="pointer"
      mr={mr}
    >
      <Flex
        as="label"
        border="1px solid"
        borderColor={borderColor}
        backgroundColor={color}
        h={height ?? 6}
        w={width ?? 6}
        borderRadius="50%"
        cursor="pointer"
      >
        <input {...input} />
      </Flex>
    </Flex>
  );
};
