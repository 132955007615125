/* eslint-disable @typescript-eslint/restrict-template-expressions*/
import {
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { SortingState, createColumnHelper } from "@tanstack/react-table";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/Button/Button";
import { TableWrapper } from "components/Table/TableWrapper";
import { SolidSearch } from "styles/icons/solid";
import { DEBOUNCED_SEARCH } from "utils/functions";

export type RowType = {
  mission: string;
  desc: string;
  countryCode: string;
  firstName: string;
  lastName: string;
  email: string;
  voucherCode: string;
  targetReached: boolean;
  retailerId: string;
  action: string;
  progressPercent: number;
};

type MissionProps = {
  missions: unknown[];
  isLoading: boolean;
};

export const Missions = ({ missions, isLoading }: MissionProps) => {
  const [globalFilter, setGlobalFilter] = useState("");
  const inputRef = useRef<HTMLInputElement>("" as unknown as HTMLInputElement);

  const { t } = useTranslation();

  const columnHelper = createColumnHelper<RowType>();
  const initialSort: SortingState = [{ id: "Progress", desc: true }];
  const columns = [
    columnHelper.accessor((row) => `${row.countryCode}_${row.mission}`, {
      cell: ({ row }) => (
        <Text>
          {row.original.countryCode}_{row.original.mission}
        </Text>
      ),
      header: "Mission",
      size: 20,
    }),
    columnHelper.accessor((row) => `${row.desc}`, {
      cell: ({ row }) => (
        <Text style={{ whiteSpace: "pre-wrap" }}>{row.original.desc}</Text>
      ),
      header: "Description",
      size: 20,
    }),
    columnHelper.accessor((row) => `${row.email}`, {
      cell: ({ row }) => <Text>{row.original.email}</Text>,
      header: "Email",
      size: 20,
    }),
    columnHelper.accessor((row) => `${row.firstName} ${row.lastName}`, {
      cell: ({ row }) => (
        <Text>
          {row.original.firstName} {row.original.lastName}
        </Text>
      ),
      header: "Name",
      size: 20,
    }),
    columnHelper.accessor((row) => `${row.progressPercent}%`, {
      cell: ({ row }) => <Text>{row.original.progressPercent}%</Text>,
      header: "Progress",
      size: 20,
    }),
    columnHelper.accessor((row) => `${row.voucherCode}`, {
      cell: ({ row }) => (
        <Text>{row.original.targetReached && row.original.voucherCode}</Text>
      ),
      header: "Voucher Code",
      size: 20,
    }),
    columnHelper.accessor("action", {
      cell: ({ row }) => (
        <Link href={`/user-management/retailer/${row.original.retailerId}`}>
          <Button variant="outline">View</Button>
        </Link>
      ),
      header: "Action",
      size: 20,
    }),
  ];

  const handleSearchBar = () => {
    setGlobalFilter(inputRef.current.value);
  };

  if (isLoading) {
    return <Spinner position="absolute" top="50%" left="50%" />;
  }

  return (
    <Flex display="column" width="100%">
      <Flex
        width="100%"
        justify="space-between"
        align="center"
        paddingRight={4}
      >
        <InputGroup mb={5} mr={5} mt={5} width="xs">
          <InputLeftElement
            pointerEvents="none"
            // eslint-disable-next-line react/no-children-prop
            children={<SolidSearch color="#B3B3B8" width={16} height={16} />}
          />
          <Input
            placeholder={t("vouchers.search")}
            ref={inputRef}
            onChange={DEBOUNCED_SEARCH(handleSearchBar)}
            pl={7}
          />
        </InputGroup>
      </Flex>
      <TableWrapper
        tableData={missions}
        columns={columns}
        initialSort={initialSort}
        pageSize={100}
        globalFilter={globalFilter}
      />
    </Flex>
  );
};
