import { useMutation } from "@tanstack/react-query";

import { ErrorResponse } from "services/ErrorResponse";
import httpClient from "services/httpClient";

export type PostEditOrderPreviewFields = {
  items: ItemPreviewDTO[];
  subOrderId: string;
};

export type ItemPreviewDTO = {
  key: string;
  sku: string;
  unitQuantity: number;
  isEditedPrice: boolean;
  editedBrandPrice?: number;
  isExistingItem: boolean;
};

const POST_EDIT_SUBORDER_PREVIEW = "admin/orders/";
const postEditOrderPreview = async (form: PostEditOrderPreviewFields) => {
  return await httpClient.put<ItemPreviewDTO>(
    `${POST_EDIT_SUBORDER_PREVIEW}${form.subOrderId}/edit/preview`,
    { items: form.items },
  );
};

export const usePostEditOrderPreview = (
  onSuccess: () => void,
  onError: (data: ErrorResponse) => void,
) =>
  useMutation(postEditOrderPreview, {
    onSuccess,
    onError,
  });
