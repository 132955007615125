/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Button, Divider, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { SubOrder } from "../hooks/useGetOrderById";
import { useAddNoteModalForm } from "../hooks/useNoteModalForm";
import { ModalWrapper } from "components/Modal/ModalWrapper";
import { TextAreaField } from "components/TextAreaField/TextAreaField";

type AddNoteModalProps = {
  isOpen: boolean;
  onClose: () => void;
  subOrder: SubOrder;
  onAfterSuccess: () => Promise<void>;
};

export const AddNoteModal = ({
  isOpen,
  onClose,
  subOrder,
  onAfterSuccess,
}: AddNoteModalProps) => {
  const { t } = useTranslation();

  const { onSubmit, reset, register, errors } = useAddNoteModalForm({
    onClose,
    notes: subOrder.additionalNotes,
    suborderId: subOrder.id,
    onAfterSuccess,
    suborderNumber: subOrder.orderNumber,
  });

  return (
    <ModalWrapper
      modalProps={{ size: "l", isOpen, onClose, children: undefined }}
      isOpen={isOpen}
      onClose={onClose}
      modalHeader={
        <Flex flexDir="column" textStyle="h3" fontWeight="bold">
          <Text>{t("order.additional_notes")}</Text>
          <Text>{`#${subOrder.orderNumber}`}</Text>
        </Flex>
      }
      modalBody={
        <Flex flexDir="column" textStyle="bodyS">
          <>
            <Flex>
              <TextAreaField
                placeholder={t("order.additional_notes_placeholder")}
                height={400}
                errors={errors}
                {...register("note")}
              />
            </Flex>
            <Divider mt={2} />
            <Text textStyle="bodyS">{t("order.additional_notes_info")}</Text>
          </>
        </Flex>
      }
      modalFooter={
        <>
          <Button
            variant="outline"
            fontWeight="600"
            size="lg"
            onClick={() => {
              onClose();
              reset();
            }}
            data-test-id="close-add-additional-notes-modal"
          >
            {t("Cancel")}
          </Button>
          <Button
            type="submit"
            form="addAdditionalNotes"
            variant="solid"
            size="lg"
            ml={3}
            data-test-id="post-add-additional-notes-button"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={onSubmit}
          >
            {t("order.additional_notes_button")}
          </Button>
        </>
      }
    />
  );
};
