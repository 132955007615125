export const KEYWORDS = [
  "Sterling Silver",
  "Brass",
  "Silver",
  "Bronze",
  "Glass",
  "Acrylic",
  "Leather",
  "Titanium",
  "Ceramics",
  "Gold",
  "All Metals",
  "Amber",
  "Coral",
  "Crystal",
  "Emerald",
  "Jade",
  "Sapphire",
  "Ruby",
  "Pearl",
  "Gold Plated",
  "Formal",
  "Active",
  "Casual",
  "Wedding",
  "Spring-Summer",
  "Fall-Winter",
  "Pre-fall",
  "Resort",
  "Classics",
  "Artistic",
  "Fashion-Forward",
  "Minimalist",
  "Vintage",
  "Colourful",
  "SPF",
  "Anti-Aging",
  "Brightening",
  "Recovery",
  "Exfoliating",
  "Hydrating",
  "PH-Balancing",
  "Skin Firming",
  "Waterproof",
  "Vegan",
  "Oil Based",
  "Non-Oil Based",
  "Organic - Full",
  "Organic",
  "Organic - Partial",
  "Scented",
  "Unscented",
  "NMN",
  "Non-GMO",
  "Cruelty Free",
  "Sustainable",
  "Certified",
  "Travel - Below 100ml",
  "Small - 100-500ml",
  "Large - Above 500ml",
  "Sensitive Skin",
  "Oily Skin",
  "Dry Skin",
  "Not On Taobao",
  "Mens",
  "Womens",
  "Gender Neutral",
  "Biodegradable",
  "Compostable",
  "Plastic-free",
  "Recyclable",
  "Recycled",
  "Zero Waste",
  "Carbon Neutral",
  "Ethically Sourced",
  "Fair trade",
  "Sustainably Sourced",
  "Floral",
  "Fruity",
  "Nature",
  "Dark",
  "Light",
  "Strong",
  "Wood",
  "Metal",
  "Ceramics",
  "Glass",
  "Desktop",
  "Small Furniture",
  "Large Furniture",
  "Plain",
  "Scandinavian",
  "Colourful",
  "Retro",
];
