import { StyleFunctionProps } from "@chakra-ui/react";

import {
  defaultButtonStyle,
  greyAlphaStyle,
  greyStyle,
  lgButtonStyle,
  mdButtonStyle,
  smButtonStyle,
  xlButtonStyle,
} from "styles/foundations/buttonStyles";

const grey = "grey";

export const Button = {
  baseStyle: {
    ...defaultButtonStyle,
  },
  sizes: {
    sm: {
      ...smButtonStyle,
    },
    md: {
      ...mdButtonStyle,
    },
    lg: {
      ...lgButtonStyle,
    },
    xl: {
      ...xlButtonStyle,
    },
  },
  variants: {
    solid: (props: StyleFunctionProps) => {
      const { colorScheme } = props;
      if (colorScheme === grey) {
        return greyStyle;
      }

      return {
        _active: {
          bg: `${colorScheme}.800`,
        },
        _hover: {
          bg: `${colorScheme}.700`,
        },
      };
    },
    outline: (props: StyleFunctionProps) => {
      const { colorScheme } = props;

      if (colorScheme === grey) {
        return greyAlphaStyle;
      }

      return {
        color: `${colorScheme}.500`,
        border: "1px solid",
        borderColor: `${colorScheme}.300`,
        _active: {
          bg: `${colorScheme}.200`,
        },
        _hover: {
          bg: `${colorScheme}.100`,
        },
      };
    },
    ghost: (props: StyleFunctionProps) => {
      const { colorScheme } = props;

      if (colorScheme === grey) {
        return greyAlphaStyle;
      }

      return {
        color: `${colorScheme}.500`,
        _hover: {
          bg: `${colorScheme}.100`,
        },
        _active: {
          bg: `${colorScheme}.200`,
        },
      };
    },
  },
};
