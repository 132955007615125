/* eslint-disable @typescript-eslint/restrict-template-expressions*/
import {
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useDeleteVoucher } from "./hooks/useDeleteVoucher";
import { usePutActivateVoucher } from "./hooks/usePutActivateVoucher";
import { usePutDeactivateVoucher } from "./hooks/usePutDeactivateVoucher";
import { Button } from "components/Button/Button";
import { ModalWrapper } from "components/Modal/ModalWrapper";
import { Status } from "components/Status/Status";
import { TableWrapper } from "components/Table/TableWrapper";
import { Voucher } from "features/UserManagement/types";
import {
  capitalizeFirstLetter,
  convertToStatusVariant,
} from "features/UserManagement/utils";
import { VOUCHER_KEY } from "services/ReactQueryKeys";
import { getErrorCodes } from "services/httpClient";
import { queryClient } from "services/queryClient";
import { SolidDotsMoreHorizontal, SolidSearch } from "styles/icons/solid";
import { DEBOUNCED_SEARCH, formatDate } from "utils/functions";

type VoucherTableType = {
  action: string;
} & Voucher;

type VoucherProps = {
  vouchers: Voucher[];
  isLoading: boolean;
};

export const CurrentAndUpcoming = ({ vouchers, isLoading }: VoucherProps) => {
  const [globalFilter, setGlobalFilter] = useState("");
  const inputRef = useRef<HTMLInputElement>("" as unknown as HTMLInputElement);
  const selectedVoucherCodeRef = useRef("");

  const toast = useToast();

  const { mutateAsync: activateVoucher, isLoading: isLoadingActivate } =
    usePutActivateVoucher();
  const { mutateAsync: deactivateVoucher, isLoading: isLoadingDeactivate } =
    usePutDeactivateVoucher();
  const { mutateAsync: deleteVoucher, isLoading: isLoadingDelete } =
    useDeleteVoucher();

  const {
    isOpen: isOpenActivateVoucher,
    onOpen: onOpenActivateVoucher,
    onClose: onCloseActivateVoucher,
  } = useDisclosure();
  const {
    isOpen: isOpenDeactivateVoucher,
    onOpen: onOpenDeactivateVoucher,
    onClose: onCloseDeactivateVoucher,
  } = useDisclosure();
  const {
    isOpen: isOpenDeleteVoucher,
    // onOpen: onOpenDeleteVoucher,
    onClose: onCloseDeleteVoucher,
  } = useDisclosure();

  const { t } = useTranslation();

  const columnHelper = createColumnHelper<VoucherTableType>();

  const columns = [
    columnHelper.accessor((row) => `${row.code}`, {
      cell: ({ row }) => (
        <Text>
          <b>{row.original.code}</b>
        </Text>
      ),
      header: t("vouchers.code"),
      size: 20,
    }),
    columnHelper.accessor((row) => formatDate(row.expiryDate), {
      id: "expiryDate",
      cell: ({ row }) => (
        <Text align="left">{formatDate(row.original.expiryDate)}</Text>
      ),
      header: t("vouchers.expiry_date"),
      size: 10,
      sortingFn: (a, b) => {
        return (
          new Date(a.original.expiryDate).getTime() -
          new Date(b.original.expiryDate).getTime()
        );
      },
    }),
    columnHelper.accessor((row) => `${row.applicableBrand}`, {
      cell: ({ row }) => <Text>{row.original.applicableBrand}</Text>,
      header: t("vouchers.brands"),
      size: 10,
    }),
    columnHelper.accessor((row) => `${row.firstOrderOnly}`, {
      cell: ({ row }) => <Text>{row.original.firstOrderOnly}</Text>,
      header: t("vouchers.first_order"),
      size: 10,
    }),
    columnHelper.accessor((row) => `${row.appliedTo}`, {
      cell: ({ row }) => <Text>{row.original.appliedTo}</Text>,
      header: t("vouchers.applied_to"),
      size: 10,
    }),
    columnHelper.accessor((row) => `${row.discountType}`, {
      cell: ({ row }) => <Text align="left">{row.original.discountType}</Text>,
      header: t("vouchers.discount_type"),
      size: 10,
    }),
    columnHelper.accessor((row) => `${row.currency}`, {
      cell: ({ row }) => <Text align="left">{row.original.currency}</Text>,
      header: t("vouchers.currency"),
      size: 10,
    }),
    columnHelper.accessor((row) => `${row.discountValue}`, {
      cell: ({ row }) => <Text align="left">{row.original.discountValue}</Text>,
      header: t("vouchers.discount_value"),
      size: 10,
    }),
    columnHelper.accessor((row) => `${row.usageQuantity}`, {
      cell: ({ row }) => (
        <Text align="center">{row.original.usageQuantity}</Text>
      ),
      header: t("vouchers.usage_quantity"),
      size: 10,
    }),
    columnHelper.accessor((row) => `${row.maxUsagePerUser}`, {
      cell: ({ row }) => (
        <Text align="center">{row.original.maxUsagePerUser}</Text>
      ),
      header: t("vouchers.max_usage_per_user"),
      size: 10,
    }),
    columnHelper.accessor((row) => `${row.minAmount}`, {
      cell: ({ row }) => <Text align="center">{row.original.minAmount}</Text>,
      header: t("vouchers.min_amount"),
      size: 10,
    }),
    columnHelper.accessor((row) => `${row.maxAmount}`, {
      cell: ({ row }) => <Text align="center">{row.original.maxAmount}</Text>,
      header: t("vouchers.max_amount"),
      size: 10,
    }),
    columnHelper.accessor((row) => `${row.isActive}`, {
      cell: (row) => (
        <Status
          variant={convertToStatusVariant(
            row.getValue() ? "ACTIVE" : "INACTIVE",
          )}
          text={capitalizeFirstLetter(row.getValue() ? "ACTIVE" : "INACTIVE")}
        />
      ),
      header: t("vouchers.status"),
      size: 10,
    }),
    columnHelper.accessor("action", {
      cell: ({ row }) => (
        <Flex>
          <Menu placement="bottom-end">
            <MenuButton
              as={Button}
              variant="outline"
              mainIcon={SolidDotsMoreHorizontal}
              colorScheme="grey"
              data-test-id="threeDotsMenuButton"
              onClick={() =>
                (selectedVoucherCodeRef.current = row.original.code)
              }
            ></MenuButton>
            <MenuList minW={48}>
              {row.original.isActive ? (
                <MenuItem w={48} onClick={onOpenDeactivateVoucher} mx={2}>
                  {t("vouchers.deactivate")}
                </MenuItem>
              ) : (
                <MenuItem w={48} onClick={onOpenActivateVoucher} mx={2}>
                  {t("vouchers.activate")}
                </MenuItem>
              )}
              {/* <MenuItem color="red" w={48} onClick={onOpenDeleteVoucher} mx={2}>
                {t("vouchers.delete")}
              </MenuItem> */}
            </MenuList>
          </Menu>
        </Flex>
      ),
      header: "Action",
    }),
  ];

  const handleActivateVoucher = async (code: string) => {
    await activateVoucher(
      {
        key: code,
      },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries({
            queryKey: [VOUCHER_KEY],
          });
          toast({
            description: `${code} has been activated successfully`,
          });
        },
        onError: (error) => {
          const errorCodes = getErrorCodes(error);
          toast({ status: "error", description: t(errorCodes[0]) });
        },
      },
    );
    onCloseActivateVoucher();
  };
  const handleDeactivateVoucher = async (code: string) => {
    await deactivateVoucher(
      {
        key: code,
      },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries({
            queryKey: [VOUCHER_KEY],
          });
          toast({
            description: `${code} has been deactivated successfully`,
          });
        },
        onError: (error) => {
          const errorCodes = getErrorCodes(error);
          toast({ status: "error", description: t(errorCodes[0]) });
        },
      },
    );
    onCloseDeactivateVoucher();
  };
  const handleDeleteVoucher = async (code: string) => {
    await deleteVoucher(
      {
        key: code,
      },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries({
            queryKey: [VOUCHER_KEY],
          });
          toast({
            description: `${code} has been deleted`,
          });
        },
        onError: (error) => {
          const errorCodes = getErrorCodes(error);
          toast({ status: "error", description: t(errorCodes[0]) });
        },
      },
    );
    onCloseDeleteVoucher();
  };

  const handleSearchBar = () => {
    setGlobalFilter(inputRef.current.value);
  };

  if (isLoading) {
    return <Spinner position="absolute" top="50%" left="50%" />;
  }

  return (
    <>
      <Flex display="column" width="100%">
        <Flex
          width="100%"
          justify="space-between"
          align="center"
          paddingRight={4}
        >
          <InputGroup mb={5} mr={5} mt={5} width="xs">
            <InputLeftElement
              pointerEvents="none"
              // eslint-disable-next-line react/no-children-prop
              children={<SolidSearch color="#B3B3B8" width={16} height={16} />}
            />
            <Input
              placeholder={t("vouchers.search")}
              ref={inputRef}
              onChange={DEBOUNCED_SEARCH(handleSearchBar)}
              pl={7}
            />
          </InputGroup>
        </Flex>
        <TableWrapper
          tableData={vouchers}
          columns={columns}
          globalFilter={globalFilter}
        />
      </Flex>

      <ModalWrapper
        isOpen={isOpenActivateVoucher}
        onClose={onCloseActivateVoucher}
        showHeaderDivider
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-base-to-string
        modalHeader={t("vouchers.activate_voucher")}
        modalBody={
          <Text textStyle="bodyM" color="black">
            {t("vouchers.activate_voucher_message")}
            <Flex>
              <Text fontWeight="bold">{`"${selectedVoucherCodeRef.current}"`}</Text>
              ?
            </Flex>
          </Text>
        }
        modalFooter={
          <>
            <Button
              variant="outline"
              colorScheme="grey"
              fontWeight="600"
              color="grey.black"
              onClick={onCloseActivateVoucher}
              data-test-id="cancelActivateVoucherButton"
            >
              {t("vouchers.cancel")}
            </Button>
            <Button
              variant="solid"
              colorScheme="blue"
              isLoading={isLoadingActivate}
              onClick={() =>
                void handleActivateVoucher(selectedVoucherCodeRef.current)
              }
              data-test-id="activateVoucherButton"
            >
              {t("vouchers.yes_activate")}
            </Button>
          </>
        }
      />
      <ModalWrapper
        isOpen={isOpenDeactivateVoucher}
        onClose={onCloseDeactivateVoucher}
        showHeaderDivider
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-base-to-string
        modalHeader={t("vouchers.deactivate_voucher")}
        modalBody={
          <Text textStyle="bodyM" color="black">
            {t("vouchers.deactivate_voucher_message")}
            <Flex>
              <Text fontWeight="bold">{`"${selectedVoucherCodeRef.current}"`}</Text>
              ?
            </Flex>
          </Text>
        }
        modalFooter={
          <>
            <Button
              variant="outline"
              colorScheme="grey"
              fontWeight="600"
              color="grey.black"
              onClick={onCloseDeactivateVoucher}
              data-test-id="cancelDeactivateVoucherButton"
            >
              {t("vouchers.cancel")}
            </Button>
            <Button
              variant="solid"
              colorScheme="red"
              isLoading={isLoadingDeactivate}
              onClick={() =>
                void handleDeactivateVoucher(selectedVoucherCodeRef.current)
              }
              data-test-id="deactivateVoucherButton"
            >
              {t("vouchers.deactivate")}
            </Button>
          </>
        }
      />
      <ModalWrapper
        isOpen={isOpenDeleteVoucher}
        onClose={onCloseDeleteVoucher}
        showHeaderDivider
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-base-to-string
        modalHeader={t("vouchers.delete_voucher")}
        modalBody={
          <Text textStyle="bodyM" color="black">
            {t("vouchers.delete_voucher_message")}
            <Flex>
              <Text fontWeight="bold">{`"${selectedVoucherCodeRef.current}"`}</Text>
              ?
            </Flex>
          </Text>
        }
        modalFooter={
          <>
            <Button
              variant="outline"
              colorScheme="grey"
              fontWeight="600"
              color="grey.black"
              onClick={onCloseDeleteVoucher}
              data-test-id="cancelDeactivateVoucherButton"
            >
              {t("vouchers.cancel")}
            </Button>
            <Button
              variant="solid"
              colorScheme="red"
              isLoading={isLoadingDelete}
              onClick={() =>
                void handleDeleteVoucher(selectedVoucherCodeRef.current)
              }
              data-test-id="deleteVoucherButton"
            >
              {t("vouchers.delete")}
            </Button>
          </>
        }
      />
    </>
  );
};
