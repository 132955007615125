import { As, Badge, Flex } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";

import { badgeStyle, buttonStyle } from "./SidebarButton.style";
import { Button } from "components/Button/Button";

type SidebarButtonProps = {
  text: string;
  icon: As;
  href?: string;
  badge?: number;
  onClick?: () => void;
  disabled?: boolean;
};

export const SidebarButton = ({
  text,
  icon,
  href,
  badge,
  onClick,
  disabled = false,
}: SidebarButtonProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    href && navigate(href);
    onClick?.();
  };

  const isActive = href ? location.pathname.includes(href) : false;

  return (
    <Flex w="100%" position="relative" alignItems="center" my={1}>
      <Button
        {...buttonStyle}
        backgroundColor={isActive ? "#EAFAFF" : "transparent"}
        leftIcon={icon}
        onClick={handleClick}
        disabled={disabled}
      >
        {text}
      </Button>
      {badge && <Badge {...badgeStyle}>{badge}</Badge>}
    </Flex>
  );
};
