import { SVGProps } from "react";

const SvgSolidGrid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 20c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 4 18c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 6 16c.55 0 1.02.196 1.412.587.392.392.588.863.588 1.413s-.196 1.021-.588 1.413A1.925 1.925 0 0 1 6 20Zm6 0c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 10 18c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 12 16c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413s-.196 1.021-.587 1.413A1.928 1.928 0 0 1 12 20Zm6 0c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 16 18c0-.55.196-1.021.587-1.413A1.928 1.928 0 0 1 18 16c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413s-.196 1.021-.587 1.413A1.928 1.928 0 0 1 18 20ZM6 14c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 0 1 4 12c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 6 10c.55 0 1.02.196 1.412.587.392.392.588.863.588 1.413s-.196 1.02-.588 1.412A1.923 1.923 0 0 1 6 14Zm6 0c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 0 1 10 12c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 12 10c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413s-.196 1.02-.587 1.412A1.927 1.927 0 0 1 12 14Zm6 0c-.55 0-1.021-.196-1.413-.588A1.925 1.925 0 0 1 16 12c0-.55.196-1.021.587-1.413A1.928 1.928 0 0 1 18 10c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413s-.196 1.02-.587 1.412A1.927 1.927 0 0 1 18 14ZM6 8c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 0 1 4 6c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 6 4c.55 0 1.02.196 1.412.588C7.804 4.979 8 5.45 8 6c0 .55-.196 1.02-.588 1.412A1.923 1.923 0 0 1 6 8Zm6 0c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 0 1 10 6c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 12 4c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412 0 .55-.196 1.02-.587 1.412A1.927 1.927 0 0 1 12 8Zm6 0c-.55 0-1.021-.196-1.413-.588A1.925 1.925 0 0 1 16 6c0-.55.196-1.02.587-1.412A1.927 1.927 0 0 1 18 4c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412 0 .55-.196 1.02-.587 1.412A1.927 1.927 0 0 1 18 8Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgSolidGrid;
