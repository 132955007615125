/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Button, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { useForceCancelSubOrder } from "../hooks/useForceCancelSubOrder";
import { GET_ORDER_DETAILS_QK, SubOrder } from "../hooks/useGetOrderById";
import { ModalWrapper } from "components/Modal/ModalWrapper";
import { useSimpleToast } from "hooks/useSimpleToast";
import { ErrorResponse } from "services/ErrorResponse";
import { getErrorCodes } from "services/httpClient";
import { queryClient } from "services/queryClient";

type ForceCancelSubOrderModalProps = {
  isOpen: boolean;
  onClose: () => void;
  subOrder: SubOrder;
};

export const ForceCancelSubOrderModal = ({
  isOpen,
  onClose,
  subOrder,
}: ForceCancelSubOrderModalProps) => {
  const { t } = useTranslation();
  const toast = useSimpleToast();

  const onSuccess = () => {
    void queryClient.invalidateQueries({
      queryKey: [GET_ORDER_DETAILS_QK],
    });
    toast({
      position: "top",
      description: `${t("order.force_cancel_suborder_success", {
        suborderNumber: subOrder.orderNumber,
      })}`,
    });
  };

  const onError = (error: ErrorResponse) => {
    const errorCodes = getErrorCodes(error);
    toast({ status: "error", description: t(errorCodes[0]) });
  };

  const { mutateAsync: forceCancelSubOrder, isLoading } =
    useForceCancelSubOrder(onSuccess, onError);

  const onForceCancelSubOrder = async () => {
    await forceCancelSubOrder(subOrder.id);
    onClose();
  };

  return (
    <ModalWrapper
      modalProps={{ size: "l", isOpen, onClose, children: undefined }}
      isOpen={isOpen}
      onClose={onClose}
      modalHeader={
        <Flex flexDir="column" textStyle="h3" fontWeight="bold">
          <Text>{t("order.force_cancel_suborder")}</Text>
          <Text>{`#${subOrder.orderNumber}`}</Text>
        </Flex>
      }
      modalFooter={
        <Button
          isLoading={isLoading}
          type="submit"
          variant="solid"
          size="lg"
          ml={3}
          colorScheme={"red"}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={onForceCancelSubOrder}
        >
          {t("order.force_cancel_suborder")}
        </Button>
      }
    />
  );
};
