import {
  Alert,
  AlertDescription,
  AlertStatus,
  Flex,
  Icon,
  RenderProps,
  UseToastOptions as UseChakraToastOptions,
  useToast as useChakraToast,
} from "@chakra-ui/react";
import { ReactNode } from "react";

import { Button, ButtonProps } from "components/Button/Button";
import {
  SolidCheckCircle,
  SolidClose,
  SolidError,
  SolidInfo,
  SolidWarningTriangle,
} from "styles/icons/solid";

type DisplayIconProps = {
  status?: AlertStatus;
  icon: ReactNode;
};

const DisplayIcon = ({ status, icon }: DisplayIconProps): JSX.Element => {
  if (icon) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{icon}</>;
  }

  switch (status) {
    case "info":
      return <Icon as={SolidInfo} color="blue.500" boxSize={5} />;
    case "warning":
      return <Icon as={SolidWarningTriangle} color="yellow.500" boxSize={5} />;
    case "error":
      return <Icon as={SolidError} color="red.500" boxSize={5} />;
    default:
      return <Icon as={SolidCheckCircle} color="green.500" boxSize={5} />;
  }
};

export type UseToastOptions = UseChakraToastOptions & {
  width?: string;
  displayButtons?: boolean;
  primaryAction?: ButtonProps;
  secondaryAction?: ButtonProps;
};

export const useSimpleToast = () => {
  const toast = useChakraToast();

  return ({
    position,
    duration,
    title,
    description,
    isClosable,
    status,
    icon,
    id,
    onCloseComplete,
    containerStyle,
    width,
    variant,
  }: UseToastOptions) => {
    toast({
      position: position ?? "top",
      duration: duration,
      title: title,
      description: description,
      isClosable: isClosable ?? true,
      status: status,
      icon: icon,
      id: id,
      onCloseComplete: onCloseComplete,
      containerStyle: containerStyle,
      render: ({ isClosable, onClose }: RenderProps) => {
        return (
          <Alert width={width} variant={variant} status={status} h="46px">
            <Flex justifyContent="space-between" w="100%">
              <Flex mt={0.5}>
                <DisplayIcon status={status} icon={icon} />

                <AlertDescription ml={2}>{description}</AlertDescription>
              </Flex>
              <Flex>
                {isClosable ? (
                  <Button
                    variant="ghost"
                    size="sm"
                    colorScheme="grey"
                    onClick={onClose}
                    mainIcon={SolidClose}
                    aria-label="Solid close"
                    ml={3}
                  />
                ) : null}
              </Flex>
            </Flex>
          </Alert>
        );
      },
    });
  };
};
