import { Flex, Heading, Text, useToast } from "@chakra-ui/react";
import {
  getHours,
  isPast,
  parseISO,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
} from "date-fns";
import { Control, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useChangeCancelDueDate } from "./hooks/useChangeCancelDueDate";
import { Button } from "components/Button/Button";
import { SelectField } from "components/SelectField/SelectField";
import { SingleDatePickerField } from "components/SingleDatePickerField/SingleDatePickerField";
import { getErrorCodes } from "services/httpClient";

type ExtendForm = {
  date: Date;
  time: number;
};

export const OrderSettings = ({
  orderId,
  cancelDueDate,
}: {
  orderId: string;
  cancelDueDate: string;
}) => {
  const { t } = useTranslation();
  const { control, getValues, setValue } = useForm<ExtendForm>();
  const toast = useToast();

  const handleSuccess = () => {
    toast({
      description: t("order.auto_conf_updated"),
    });
  };

  const { mutateAsync: changeCancelDueDate, isLoading: isMutating } =
    useChangeCancelDueDate(handleSuccess);

  const cancelDate = parseISO(cancelDueDate);
  const times = [];

  for (let i = 0; i < 24; i++) {
    if (i < 10) {
      times.push({ label: `0${i}:00`, value: i });
    } else {
      times.push({ label: `${i}:00`, value: i });
    }
  }

  setValue("time", getHours(cancelDate));

  const handleApplyClick = async () => {
    let date = getValues("date");
    date = setHours(date, getValues("time"));
    date = setMinutes(date, 0);
    date = setSeconds(date, 0);
    date = setMilliseconds(date, 0);
    if (isPast(date)) {
      toast({ status: "error", description: t("order.select_future_date") });
    } else {
      await changeCancelDueDate(
        { id: orderId, date: date.toISOString() },
        {
          onError: (error) => {
            const errorCodes = getErrorCodes(error);
            toast({ status: "error", description: t(errorCodes[0]) });
          },
        },
      );
    }
  };

  return (
    <Flex
      width="calc(100% - 52px)"
      mt={8}
      mr={7}
      ml={7}
      p={7}
      borderRadius={1}
      border="1px solid"
      borderColor="gray.200"
      direction="column"
    >
      <Heading textStyle="bodyL" fontWeight="semibold" mb={6}>
        {t("order.extend_auto_confirmation")}
      </Heading>
      <Text mb={5}>{t("order.enter_new_date")}</Text>
      <Flex mb={5}>
        <Flex mr={5} width={256}>
          <SingleDatePickerField
            name="date"
            label={t("order.date")}
            placeholder={t("order.select_date")}
            control={control as unknown as Control}
            inputDefaultVal={cancelDate}
            inputFormat="dd/MM/yyyy"
            minDate={new Date()}
            rules={{
              required: t("order.date_required"),
              validate: (value: string) =>
                !isPast(parseISO(value)) || t("order.date_past"),
            }}
            height="46px"
          />
        </Flex>
        <Flex width={256}>
          <SelectField
            name="time"
            label={t("order.time")}
            placeholder={t("order.select_time")}
            control={control}
            options={times}
          />
        </Flex>
      </Flex>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises*/}
      <Button size="lg" w={73} onClick={handleApplyClick} disabled={isMutating}>
        {t("order.apply")}
      </Button>
    </Flex>
  );
};
