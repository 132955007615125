/* eslint-disable @tanstack/query/exhaustive-deps */
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { OrderReturnRequestType } from "constants/order/orderReturnRequest";
import { PickupTime } from "features/Orders/Details/hooks/useGetOrderById";
import { RETURN_REQUEST_DETAILS_KEY } from "services/ReactQueryKeys";
import httpClient from "services/httpClient";

export const GET_RETURN_REQUEST_DETAILS = "/admin/return-requests/";

type ReturnRequestStatusType =
  | "PENDING_REVIEW"
  | "CONFIRMED"
  | "IN_DELIVERY"
  | "PENDING"
  | "PARTIALLY_APPROVED"
  | "PARTIAL_APPROVED"
  | "DECLINED"
  | "APPROVED"
  | "LOST";

type Brand = {
  storeName: string;
  country: string;
  image: string;
  brandKey: string;
};

type Reason = {
  type: string;
  unitQuantity: number;
};

export type Product = {
  image: string;
  name: string;
  sku: string;
  returnReason: string;
  quantity: number;
  caseQuantity: number;
  price: number;
  key: string;
  requestUnitQuantity: number;
  approvedUnitQuantity: number;
  approvedCaseQty?: number;
  approvedPrice?: number;
  isOtherReason: boolean;
  adminComment?: string;
  comment?: string;
  isApprovedQtyDifferent: boolean;
  status: string;
  type: OrderReturnRequestType;
  retailerComment?: string;
  retailerImageUrls?: string[];
  reasonList?: Reason[];
};
export type ReturnRequestDetails = {
  orderIdName: string;
  subOrderId: string;
  id: string;
  status: ReturnRequestStatusType;
  currency: string;
  brand: Brand;
  products: Product[];
  subtotal: number;
  shipping: number;
  total: number;
  trackUrl?: string;
  resolvedBy?: string;
  pickupTime?: PickupTime;
  type: OrderReturnRequestType;
  preferredResolution: string;
};
export const useGetReturnRequestDetails = (
  id: string,
): UseQueryResult<ReturnRequestDetails> =>
  useQuery({
    queryKey: [RETURN_REQUEST_DETAILS_KEY, id],
    queryFn: async () => {
      const { data: details } = await httpClient.get<ReturnRequestDetails>(
        `${GET_RETURN_REQUEST_DETAILS}${id}`,
      );
      return details;
    },
  });
