import { useMutation } from "@tanstack/react-query";

import { ErrorResponse } from "services/ErrorResponse";
import httpClient from "services/httpClient";

export const START_PAYOUT = "/admin/payouts/start-payout";

const postStartPayout = async (phrase: string) => {
  return await httpClient.post(`${START_PAYOUT}`, {
    "confirmation-phrase": `start-payouts`,
  });
};

export const usePostStartPayout = (
  onSuccess: () => void,
  onError: (axiosError: ErrorResponse) => void,
) =>
  useMutation(postStartPayout, {
    onSuccess,
    onError,
  });
