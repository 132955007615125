import { Flex, Icon, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { LightDocument } from "styles/icons/light";

export const EmptyList = () => {
  const { t } = useTranslation();
  return (
    <Flex
      flexDir="column"
      justify="center"
      align="center"
      position="absolute"
      top="50%"
      left="50%"
    >
      <Flex
        borderRadius="50%"
        boxSize={20}
        backgroundColor="grey.200"
        justifyContent="center"
        alignItems="center"
        mb={5}
      >
        <Icon as={LightDocument} color="white" boxSize={9} />
      </Flex>
      <Text color="grey.600" textStyle="bodyL">
        {t("no_history")}
      </Text>
    </Flex>
  );
};
