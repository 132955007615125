import { ErrorResponse } from "@remix-run/router";
import { UseQueryResult, useMutation, useQuery } from "@tanstack/react-query";

import { Category } from "./useCategories";
import httpClient from "services/httpClient";
import { queryClient } from "services/queryClient";

export const PROMOTIONS = "/admin/promotions";

export type BrandItem = {
  value: string;
  label: string;
};

export type BrandInfo = {
  id: string;
  name: string;
  key: string;
};

export type PromotionResponse = {
  id: string;
  fromDate: Date;
  untilDate: Date;
  name: string;
  description: string;
  imageUrl: string;
  imageId: string;
  key: string;
  brands: BrandInfo[];
  discount: Discount;
};

export type PromotionDto = Omit<
  PromotionResponse,
  "imageUrl" | "id" | "brands"
> & {
  brandKeys: string[];
  imageId: string;
};

export type PromotionCategory = Omit<Category, "subCategories">;

type Discount = {
  type: DiscountType;
  value: { amount: number; currencyCode?: string }[];
};

type DiscountType = "absolute" | "relative";

export const useGetPromotions = (): UseQueryResult<PromotionResponse[]> =>
  useQuery({
    queryKey: [PROMOTIONS],
    queryFn: async () => {
      const { data: promotions } = await httpClient.get<PromotionResponse[]>(
        PROMOTIONS,
      );
      return promotions.reverse();
    },
  });

const postPromotion = async (promotion: PromotionDto) =>
  httpClient.post(PROMOTIONS, promotion);

export const usePostPromotion = (
  onSuccess: () => void,
  onError: (data: ErrorResponse) => void,
) =>
  useMutation(postPromotion, {
    onSuccess: () => {
      void queryClient.fetchQuery({ queryKey: [PROMOTIONS] });
      onSuccess();
    },
    onError,
  });

const putPromotion = async ({
  id,
  ...promotion
}: PromotionDto & { id: string }) =>
  httpClient.put(`${PROMOTIONS}/${id}`, promotion);

export const usePutPromotion = (
  onSuccess: () => void,
  onError: (data: ErrorResponse) => void,
) =>
  useMutation(putPromotion, {
    onSuccess: () => {
      void queryClient.fetchQuery({ queryKey: [PROMOTIONS] });
      onSuccess();
    },
    onError,
  });

const deletePromotion = async (id: string) =>
  httpClient.delete(`${PROMOTIONS}/${id}`);

export const useDeletePromotion = (
  onSuccess: () => void,
  onError: (data: ErrorResponse) => void,
) =>
  useMutation(deletePromotion, {
    onSuccess: () => {
      void queryClient.fetchQuery({ queryKey: [PROMOTIONS] });
      onSuccess();
    },
    onError,
  });
