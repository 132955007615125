import { Spinner } from "@chakra-ui/react";

import { BnplDetails } from "./BnplDetails";
import { useGetPaymentTermsUsageViaRetailerId } from "features/RetailerRequests/RetailerRequestOverview/hooks/useGetPaymentTermsUsage";

export const BuyNowPayLater = ({ retailerId }: { retailerId: string }) => {
  const { data: paymentTerms, isLoading } =
    useGetPaymentTermsUsageViaRetailerId(retailerId);
  return (
    <>
      {isLoading && <Spinner position="absolute" top="50%" left="50%" />}
      {paymentTerms && (
        <BnplDetails retailerId={retailerId} {...paymentTerms} />
      )}
    </>
  );
};
