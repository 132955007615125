import { UseQueryResult, useQuery } from "@tanstack/react-query";

import httpClient from "services/httpClient";

export const GET_ACTIONS = "/admin/orders/";

export type ActionsType = {
  id: string;
  creationDate: string;
  user?: string;
  action: string;
};

export const useGetActions = (id: string): UseQueryResult<ActionsType> =>
  useQuery({
    queryKey: [`${GET_ACTIONS}actions`, id],
    queryFn: async () => {
      const { data: financials } = await httpClient.get<ActionsType>(
        `${GET_ACTIONS}${id}/actions`,
      );
      return financials;
    },
  });
