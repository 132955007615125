import {
  Flex,
  Icon,
  Spinner,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { useAddWalletCredit } from "./hooks/useAddWalletCredit";
import { useGetWalletCredit } from "./hooks/useGetWalletCredit";
import { useSetupTransactionsTable } from "./hooks/useSetupTransactionsTable";
import { Button } from "components/Button/Button";
import { InputField } from "components/InputField/InputField";
import { ModalWrapper } from "components/Modal/ModalWrapper";
import { PanelForm } from "components/PanelForm/PanelForm";
import { TableWrapper } from "components/Table/TableWrapper";
import { SolidCoin } from "styles/icons/solid";

type WalletCreditProps = {
  id: string;
};
export const WalletCredit = ({ id }: WalletCreditProps) => {
  const { t } = useTranslation();
  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();
  const { data: wallet, isLoading: isGetLoading } = useGetWalletCredit(id);

  const { register, isLoading, handleAddWallet, errors } = useAddWalletCredit(
    id,
    onCloseModal,
    isOpenModal,
  );
  const { columns } = useSetupTransactionsTable({
    currency: wallet?.transactionsCurrency ?? "HKD",
  });

  if (isGetLoading) {
    return <Spinner position="absolute" top="50%" left="50%" />;
  }
  return (
    <Flex mt={4} px={3} w="100%" flexDir="column">
      <PanelForm
        title="Wallet Credit"
        titleIcon={<Icon as={SolidCoin} color="#B3B3B8" w={6} h={6} mr={2.5} />}
        w="100%"
        position="relative"
      >
        <Button
          position="absolute"
          w="fit-content"
          size="lg"
          top={6}
          right={6}
          onClick={onOpenModal}
        >
          {t("wallet_credit.adjust_wallet_credit")}
        </Button>
        <Flex>
          <Text textStyle="bodyL" mr={2}>
            {t("wallet_credit.currently_available:")}
          </Text>
          <Text fontWeight="bold">
            {Intl.NumberFormat("en-US", {
              style: "currency",
              currency: wallet?.transactionsCurrency ?? "HKD",
            }).format(Number(wallet?.transactionsSum ?? 0))}
          </Text>
        </Flex>
      </PanelForm>
      <Flex mt={8} flexDir="column">
        {columns.length > 0 && (
          <>
            <Flex flexDir="column" mt={8}>
              <Text textStyle="bodyL" fontWeight="semibold" mb={4}>
                {t("wallet_credit.transactions_list")}
              </Text>
            </Flex>
            <TableWrapper
              columns={columns}
              tableData={wallet?.transactions ?? []}
            />
          </>
        )}
      </Flex>
      <ModalWrapper
        isOpen={isOpenModal}
        onClose={onCloseModal}
        showHeaderDivider
        modalHeader={t("wallet_credit.adjust_wallet_credit")}
        modalBody={
          <>
            <Flex mb={5}>
              <InputField
                errors={errors}
                label={t("wallet_credit.enter_amount")}
                placeholder={"0"}
                rightAddon={wallet?.transactionsCurrency ?? "HKD"}
                type="number"
                {...register("amount", { required: "This field is required" })}
              />
            </Flex>
            <Text textStyle="bodyM" color="black" mb={1}>
              {t("wallet_credit.add_comment")}
            </Text>
            <Textarea
              height="125px"
              resize="none"
              placeholder={t("wallet_credit.please_reason")}
              {...register("reason")}
              mb={9}
            ></Textarea>
          </>
        }
        modalFooter={
          <Button
            w="100%"
            size="xl"
            variant="solid"
            isLoading={isLoading}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleAddWallet}
          >
            {t("wallet_credit.add_wallet_credit")}
          </Button>
        }
      />
    </Flex>
  );
};
