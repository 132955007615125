import { useToast } from "@chakra-ui/react";
import { ErrorResponse } from "@remix-run/router";
import { useTranslation } from "react-i18next";

import { useDeletePromotion } from "./usePromotions";
import { Button } from "components/Button/Button";
import { ModalWrapper } from "components/Modal/ModalWrapper";
import { getErrorCodes } from "services/httpClient";

type DeletePromotionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  promotionId: string;
};
export const DeletePromotionModal = ({
  isOpen,
  onClose,
  promotionId,
}: DeletePromotionModalProps) => {
  const { t } = useTranslation();
  const toast = useToast();

  const handleDeleteSuccess = () =>
    toast({ description: t("promotions.delete_success") });
  const handleDeleteError = (error: ErrorResponse) => {
    toast({ status: "error", description: getErrorCodes(error) });
  };

  const { mutateAsync: deletePromotion, isLoading: isMutating } =
    useDeletePromotion(handleDeleteSuccess, handleDeleteError);

  const handleDeleteClick = () => void deletePromotion(promotionId);
  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={onClose}
      modalHeader={t("promotions.delete")}
      modalBody={t("promotions.delete_confirmation")}
      modalFooter={
        <>
          <Button
            variant="outline"
            colorScheme="grey"
            fontWeight="600"
            onClick={onClose}
          >
            {t("Cancel")}
          </Button>

          <Button
            variant="solid"
            colorScheme="red"
            onClick={handleDeleteClick}
            disabled={isMutating}
          >
            {t("Delete")}
          </Button>
        </>
      }
    />
  );
};
