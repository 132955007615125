import { useMemo } from "react";
import { useForm } from "react-hook-form";

export type DeactivateRetailerFormFields = {
  id: string;
  reason: string;
};

export const useDeactivateForm = () => {
  const {
    control,
    register,
    formState: { errors, isDirty, isValid },
    getValues,
  } = useForm<DeactivateRetailerFormFields>();

  const isFormValid = useMemo(() => {
    return !isDirty || !isValid;
  }, [isDirty, isValid]);

  return {
    control,
    register,
    errors,
    isFormValid,
    getValues,
  };
};
