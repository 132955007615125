import { Box, Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  buttonStyle,
  errorWrapper,
  formStyle,
  messageStyle,
  resendStyle,
  resendWrapperStyle,
  titleStyle,
} from "../ForgotPassword.style";
import { useConfirmForgotPassword } from "../hooks/useConfirmForgotPassword";
import { usePostForgotPassword } from "../hooks/usePostForgotPassword";
import { CreateNewPasswordProps } from "../types";
import { Button } from "components/Button/Button";
import { ErrorMessage } from "components/ErrorMessage/ErrorMessage";
import { InputField } from "components/InputField/InputField";
import { ErrorCode } from "services/ErrorResponse";

export const CreateNewPassword = ({
  username,
  code,
  step,
  setStep,
}: CreateNewPasswordProps) => {
  const { t } = useTranslation();
  const [isCodeCorrect, setIsCodeCorrect] = useState<boolean>(false);
  const [errorM, setErrorM] = useState<string[]>([]);

  const onSuccess = () => {
    setStep(step + 1);
  };
  const onError = (errorMessages: string[]) => {
    setErrorM(errorMessages);
  };

  const { register, onSubmit, watch, errors, isLoading } =
    useConfirmForgotPassword({
      username,
      code,
      onSuccess,
      onError,
    });

  const { mutateAsync: postForgotPassword } = usePostForgotPassword();

  const codeValues = watch("confirmationCode");

  useEffect(() => {
    if (!codeValues) return;
    if (codeValues.length > 5) {
      setIsCodeCorrect(true);
    }
  }, [codeValues]);

  const getMessages = (errorMessages: string[]) => {
    return errorMessages.map((message) => {
      return t(message as ErrorCode);
    });
  };

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    void onSubmit();
  };

  const handleClickOnResend = async () => {
    await postForgotPassword({ email: username });
  };

  return (
    <>
      <Text {...messageStyle}>
        {t("We have just sent you an email with a verification code to ")}
        <b>{username}</b>
      </Text>
      <form onSubmit={handleSubmit} style={{ height: "100%" }}>
        <Flex {...formStyle}>
          <InputField
            label={t("Enter the code")}
            errors={errors}
            {...register("confirmationCode")}
          />
          <Flex {...resendWrapperStyle}>
            <Text>{t("Didn't get it?")}</Text>
            <Button {...resendStyle} onClick={() => void handleClickOnResend()}>
              {t("Resend")}
            </Button>
          </Flex>
          {isCodeCorrect && (
            <>
              <Flex>
                <Text {...titleStyle}>{t("Create new password")}</Text>
              </Flex>
              <Box mb={5}>
                <InputField
                  type="password"
                  canToggleVisibility={true}
                  label={t("New Password")}
                  placeholder={t("Create new password")}
                  errors={errors}
                  {...register("newPassword")}
                />
              </Box>
              <InputField
                type="password"
                canToggleVisibility={true}
                label={t("Confirm New Password")}
                placeholder={t("Repeat password")}
                errors={errors}
                {...register("confirmPassword")}
              />
              <Button type="submit" isLoading={isLoading} {...buttonStyle}>
                {t("Save new password")}
              </Button>
            </>
          )}
          {errorM.length > 0 && (
            <>
              {getMessages(errorM).map((message, index) => {
                return (
                  <Box key={index} {...errorWrapper}>
                    <ErrorMessage message={message} fontSize="4xl" />
                  </Box>
                );
              })}
            </>
          )}
        </Flex>
      </form>
    </>
  );
};
