import { useMutation } from "@tanstack/react-query";

import httpClient from "services/httpClient";

export const POST_PRE_ALERT_EMAIL = "/admin/orders/";

const postPreAlertEmailToDhl = async (suborderId: string) => {
  return await httpClient.post(
    `${POST_PRE_ALERT_EMAIL}${suborderId}/sendPrealertEmailToDhl`,
  );
};

export const usePostPreAlertEmailToDhl = (
  onSuccess?: () => void,
  onError?: () => void,
) =>
  useMutation(postPreAlertEmailToDhl, {
    onSuccess,
    onError,
  });
