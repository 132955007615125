import { useMutation } from "@tanstack/react-query";

import httpClient from "services/httpClient";

export const EXPORT_BRAND_ORDER_HISTORY = "/admin/brands";

const exportBrandOrderHistory = async (id: string) => {
  return await httpClient.get<string>(
    `${EXPORT_BRAND_ORDER_HISTORY}/${id}/orders/history/export`,
  );
};
export const useExportBrandOrderHistory = () =>
  useMutation(exportBrandOrderHistory);
