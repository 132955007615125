import { Flex, Link, Spinner, Text } from "@chakra-ui/react";
import { SortingState, createColumnHelper } from "@tanstack/react-table";

import { BrandRequest } from "../types";
import { useGetAllBrandPageUpdateRequests } from "./useGetAllBrandPageUpdateRequests";
import { Button } from "components/Button/Button";
import { EmptyList } from "components/EmptyList/EmptyList";
import { AdminAvatar } from "components/Table/AdminAvatar";
import { TableWrapper } from "components/Table/TableWrapper";

export const PageUpdateTableOverview = () => {
  const { data: allPageUpdateRequests, isLoading } =
    useGetAllBrandPageUpdateRequests();

  type BrandRequestsTable = {
    action: string;
  } & BrandRequest;

  const columnHelper = createColumnHelper<BrandRequestsTable>();
  const initialSort: SortingState = [{ id: "name", desc: true }];
  const columns = [
    columnHelper.accessor("name", {
      cell: ({ row }) => (
        <AdminAvatar
          name={row.original.name}
          displayName={row.original.name}
          email={row.original.email}
        />
      ),
      header: "Name",
      size: 30,
    }),
    columnHelper.accessor("category", {
      cell: (row) => <Text textStyle="bodyS">{row.getValue()}</Text>,
      header: "Category",
      size: 20,
    }),
    columnHelper.accessor("country", {
      cell: (row) => <Text textStyle="bodyS">{row.getValue()}</Text>,
      header: "Country",
      size: 40,
    }),
    columnHelper.accessor("action", {
      cell: ({ row }) => (
        <Link href={`/brand-requests/page-update/${row.original.id}`}>
          <Button variant="outline">View</Button>
        </Link>
      ),
      header: "Action",
      size: 10,
    }),
  ];

  if (isLoading) {
    return <Spinner position="absolute" top="50%" left="50%" />;
  }

  return (
    <Flex display="column" width="100%" mt={5}>
      {allPageUpdateRequests && allPageUpdateRequests.length > 0 ? (
        <TableWrapper
          tableData={allPageUpdateRequests}
          columns={columns}
          initialSort={initialSort}
        />
      ) : (
        <EmptyList />
      )}
    </Flex>
  );
};
