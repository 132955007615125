import { useMutation } from "@tanstack/react-query";

import {
  GET_ORDER_DETAILS_QK,
  getOrderById,
} from "../../Details/hooks/useGetOrderById";
import { ADMIN_ORDERS } from "features/Orders/hooks/usePutCancelOrderById";
import httpClient from "services/httpClient";
import { queryClient } from "services/queryClient";

export type ChangeCancelDueDateDto = {
  id: string;
  date: string;
};

const putChangeCancelDueDate = async ({ id, date }: ChangeCancelDueDateDto) => {
  return await httpClient.put(`${ADMIN_ORDERS}${id}/cancellation-date`, {
    date,
  });
};

export const useChangeCancelDueDate = (onSuccess: () => void) =>
  useMutation(putChangeCancelDueDate, {
    onSuccess: (_, { id }) => {
      void queryClient.fetchQuery({
        queryKey: [GET_ORDER_DETAILS_QK, id],
        queryFn: async () => await getOrderById(id),
      });
      onSuccess();
    },
  });
