import { SVGProps } from "react";

const SvgRegularBus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.875 15.75C4.5625 15.75 4.297 15.6405 4.0785 15.4215C3.8595 15.203 3.75 14.9375 3.75 14.625V13.4625C3.525 13.2125 3.34375 12.9342 3.20625 12.6278C3.06875 12.3217 3 11.9875 3 11.625V4.5C3 3.4625 3.48125 2.703 4.44375 2.2215C5.40625 1.7405 6.925 1.5 9 1.5C11.15 1.5 12.6875 1.73125 13.6125 2.19375C14.5375 2.65625 15 3.425 15 4.5V11.625C15 11.9875 14.9313 12.3217 14.7938 12.6278C14.6562 12.9342 14.475 13.2125 14.25 13.4625V14.625C14.25 14.9375 14.1405 15.203 13.9215 15.4215C13.703 15.6405 13.4375 15.75 13.125 15.75C12.8125 15.75 12.547 15.6405 12.3285 15.4215C12.1095 15.203 12 14.9375 12 14.625V14.25H6V14.625C6 14.9375 5.8905 15.203 5.6715 15.4215C5.453 15.6405 5.1875 15.75 4.875 15.75ZM4.5 7.5H13.5V5.25H4.5V7.5ZM6.375 12C6.6875 12 6.953 11.8905 7.1715 11.6715C7.3905 11.453 7.5 11.1875 7.5 10.875C7.5 10.5625 7.3905 10.297 7.1715 10.0785C6.953 9.8595 6.6875 9.75 6.375 9.75C6.0625 9.75 5.797 9.8595 5.5785 10.0785C5.3595 10.297 5.25 10.5625 5.25 10.875C5.25 11.1875 5.3595 11.453 5.5785 11.6715C5.797 11.8905 6.0625 12 6.375 12ZM11.625 12C11.9375 12 12.203 11.8905 12.4215 11.6715C12.6405 11.453 12.75 11.1875 12.75 10.875C12.75 10.5625 12.6405 10.297 12.4215 10.0785C12.203 9.8595 11.9375 9.75 11.625 9.75C11.3125 9.75 11.047 9.8595 10.8285 10.0785C10.6095 10.297 10.5 10.5625 10.5 10.875C10.5 11.1875 10.6095 11.453 10.8285 11.6715C11.047 11.8905 11.3125 12 11.625 12Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgRegularBus;
