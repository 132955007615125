import { Flex, Text } from "@chakra-ui/react";
import { WheelEvent } from "react";
import {
  FieldArrayWithId,
  FieldErrorsImpl,
  UseFormGetValues,
  UseFormRegister,
} from "react-hook-form";

import { SettingsFormFields } from "./hooks/useSettingsForm";
import { InputField } from "components/InputField/InputField";

type RetailerCountryProps = {
  field: FieldArrayWithId<SettingsFormFields, "retailerCountrySettings">;
  getValues: UseFormGetValues<SettingsFormFields>;
  index: number;
  countryName: string;
  register: UseFormRegister<SettingsFormFields>;
  errors: Partial<
    FieldErrorsImpl<{
      retailerCountrySettings: {
        minOrderAmount: number;
        autoWalletCreditAmount: number;
        autoBuyNowPayLaterAmount: number;
        currencyCode: string;
        currencySign: string;
      }[];
    }>
  >;
};
export const RetailerCountry = ({
  field,
  getValues,
  index,
  register,
  errors,
  countryName = "",
}: RetailerCountryProps) => {
  const requiredMessage = "This field is required";

  const disableInputScroll = (event: WheelEvent<HTMLInputElement>) =>
    event.target instanceof HTMLElement && event.target.blur();

  return (
    <Flex
      h="56px"
      p={2}
      bg="light.lightGrey"
      alignItems="baseline"
      justifyContent="space-between"
    >
      <Text textStyle="bodyM" fontWeight="medium" w="200px" pl={1}>
        {` ${countryName} (${
          getValues(`retailerCountrySettings.${index}`).currencyCode
        })`}
      </Text>
      <Flex w="260px">
        <InputField
          errors={errors}
          placeholder={"0"}
          rightAddon={
            getValues(`retailerCountrySettings.${index}`).currencySign
          }
          defaultValue={
            getValues(`retailerCountrySettings.${index}`).minOrderAmount ?? ""
          }
          type="number"
          {...register(`retailerCountrySettings.${index}.minOrderAmount`, {
            required: requiredMessage,
            validate: (value) => Number(value ?? 0) > -1,
          })}
          mb={5}
          onWheel={disableInputScroll}
        />
      </Flex>
      <Flex w="260px">
        <InputField
          errors={errors}
          placeholder={"0"}
          rightAddon={
            getValues(`retailerCountrySettings.${index}`).currencySign
          }
          defaultValue={
            getValues(`retailerCountrySettings.${index}`)
              .autoWalletCreditAmount ?? ""
          }
          type="number"
          {...register(
            `retailerCountrySettings.${index}.autoWalletCreditAmount`,
            {
              required: requiredMessage,
              validate: (value) => Number(value ?? 0) > -1,
            },
          )}
          mb={5}
          onWheel={disableInputScroll}
        />
      </Flex>
      <Flex w="260px">
        <InputField
          errors={errors}
          placeholder={"0"}
          rightAddon={
            getValues(`retailerCountrySettings.${index}`).currencySign
          }
          defaultValue={
            getValues(`retailerCountrySettings.${index}`)
              .autoBuyNowPayLaterAmount ?? ""
          }
          type="number"
          {...register(
            `retailerCountrySettings.${index}.autoBuyNowPayLaterAmount`,
            {
              required: requiredMessage,
              validate: (value) => Number(value ?? 0) > -1,
            },
          )}
          mb={5}
          onWheel={disableInputScroll}
        />
      </Flex>
    </Flex>
  );
};
