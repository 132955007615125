import { theme } from "@chakra-ui/react";

export const space = {
  ...theme.space,
  4.5: "18px",
  7.5: "30px",
  11: "44px",
  12: "48px",
  15: "60px",
  18: "72px",
  105: "420px",
  155: "620px",
  205: "820px",
  230: "920px",
};
