import { useMutation } from "@tanstack/react-query";

import { GET_FINANCIAL_DETAILS } from "./useGetFinancialDetails";
import { ADMIN_ORDERS } from "features/Orders/hooks/usePutCancelOrderById";
import httpClient from "services/httpClient";
import { queryClient } from "services/queryClient";

export type ForcePushForm = {
  id: string;
  note: string;
};

const forcePush = async (form: ForcePushForm) => {
  return await httpClient.put(`${ADMIN_ORDERS}${form.id}/force-push`, form);
};

export const useForcePush = (onSuccess: () => void) =>
  useMutation(forcePush, {
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [GET_FINANCIAL_DETAILS],
      });
      onSuccess();
    },
  });
