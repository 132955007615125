/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Flex, Text } from "@chakra-ui/react";
import { format, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";

import { PickupTime } from "../hooks/useGetOrderById";
import { Button } from "components/Button/Button";

type CancelPickupProps = {
  pickupTime: PickupTime;
  openModal: () => void;
};
export const CancelPickup = ({ pickupTime, openModal }: CancelPickupProps) => {
  const { t } = useTranslation();
  return (
    <Flex>
      <Text fontWeight="bold">{t("order.pickup_time")}</Text>
      <Text ml={1}>
        {format(parseISO(pickupTime.date), "EEEE, MMMM dd, yyyy,")}
      </Text>
      <Text>{`${pickupTime.fromTime}-${pickupTime.toTime}`}</Text>
      <Button
        variant="link"
        colorScheme="red"
        maxH={5}
        ml={3}
        onClick={openModal}
      >
        {t("Cancel")}
      </Button>
    </Flex>
  );
};
