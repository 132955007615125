import {
  TabList,
  TabListProps,
  TabPanel,
  TabPanels,
  TabPanelsProps,
  Tabs,
  TabsProps,
} from "@chakra-ui/react";
import { ReactNode, forwardRef } from "react";

import { LineTab } from "./LineTab";

export type TabData = {
  content: ReactNode;
  title: ReactNode;
};
export type TabWrapperProps = {
  tabsData: TabData[];
  tabsProps?: TabsProps;
  tabListProps?: TabListProps;
  tabPanelsProps?: TabPanelsProps;
  tabIndex?: number;
  handleTabsChange?: (index: number) => void;
};
export const TabWrapper = forwardRef<HTMLDivElement, TabWrapperProps>(
  (
    {
      tabsData,
      tabsProps,
      tabListProps,
      tabPanelsProps,
      tabIndex,
      handleTabsChange,
    },
    ref,
  ) => {
    return (
      <Tabs
        index={tabIndex}
        {...tabsProps}
        ref={ref}
        onChange={handleTabsChange}
        isLazy
      >
        <TabList {...tabListProps} className="tabList hidePrint">
          {tabsData.map((tab) => (
            <LineTab key={tab.title?.toString()}>{tab.title}</LineTab>
          ))}
        </TabList>
        <TabPanels {...tabPanelsProps} className="tabPanels">
          {tabsData.map((tab) => (
            <TabPanel key={tab.title?.toString()} className="tabPanel">
              {tab.content}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    );
  },
);

TabWrapper.displayName = "TabWrapper";
