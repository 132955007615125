import { useMutation } from "@tanstack/react-query";

import httpClient from "services/httpClient";

export const ACTIVATE_VOUCHER_BY_ID = "/admin/vouchers";

const activateVoucher = async ({ key }: { key: string }) => {
  return await httpClient.put(`${ACTIVATE_VOUCHER_BY_ID}/enable/${key}`);
};

export const usePutActivateVoucher = () => useMutation(activateVoucher);
