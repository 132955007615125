import { Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button } from "components/Button/Button";
import { ModalWrapper } from "components/Modal/ModalWrapper";

type DiscardChangesModalProps = {
  isOpenModal: boolean;
  onCloseModal: () => void;
};

export const DiscardChangesModal = ({
  isOpenModal,
  onCloseModal,
}: DiscardChangesModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <ModalWrapper
      isOpen={isOpenModal}
      onClose={onCloseModal}
      modalProps={{
        size: "sm",
        children: undefined,
        isOpen: isOpenModal,
        onClose: onCloseModal,
      }}
      showHeaderDivider
      modalHeader={t("return_requests.discard_changes")}
      modalBody={
        <Text>
          {t("return_requests.are_you_sure_you_want_to_discard_changes")}
        </Text>
      }
      modalFooter={
        <>
          <Button w="100%" size="lg" variant="outline" onClick={onCloseModal}>
            {t("return_requests.cancel")}
          </Button>
          <Button
            w="100%"
            size="lg"
            onClick={() => {
              navigate(`/return-requests`);
            }}
          >
            {t("return_requests.discard")}
          </Button>
        </>
      }
    />
  );
};
