import { useTranslation } from "react-i18next";

import { usePutSchedulePickup } from "../hooks/usePutSchedulePickup";
import { Button } from "components/Button/Button";
import { ModalWrapper } from "components/Modal/ModalWrapper";

type CancelPickupModalProps = {
  isOpen: boolean;
  onClose: () => void;
  orderId: string;
};
export const CancelPickupModal = ({
  isOpen,
  onClose,
  orderId,
}: CancelPickupModalProps) => {
  const { t } = useTranslation();
  const { mutateAsync: putSchedulePickup, isLoading: isMutating } =
    usePutSchedulePickup(orderId);

  const onSubmit = () => {
    void putSchedulePickup();
  };
  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={onClose}
      modalHeader={t("order.cancel_pickup_request")}
      modalBody={t("order.cancel_are_you_sure")}
      modalFooter={
        <>
          <Button
            variant="outline"
            colorScheme="grey"
            fontWeight="600"
            onClick={onClose}
          >
            {t("Cancel")}
          </Button>

          <Button
            variant="solid"
            colorScheme="red"
            disabled={isMutating}
            onClick={onSubmit}
          >
            {t("order.yes_cancel")}
          </Button>
        </>
      }
    />
  );
};
