import { As, BoxProps, Flex, Icon, Text, TextProps } from "@chakra-ui/react";

export type InfoDetailsProps = {
  heading: string;
  headingIcon?: As;
  leftList: string[];
  rightList: (string | JSX.Element | undefined)[];
  leftTextProps?: TextProps;
  rightTextProps?: TextProps;
} & BoxProps;
export const InfoDetails = ({
  heading,
  headingIcon,
  leftList,
  rightList,
  leftTextProps,
  rightTextProps,
  ...rest
}: InfoDetailsProps) => {
  return (
    <Flex
      w="480px"
      p={5}
      flexDir="column"
      borderRadius="base"
      boxShadow={1}
      border="1px solid"
      borderColor="grey.400"
      {...rest}
    >
      <Flex>
        {headingIcon ? (
          <Icon as={headingIcon} w={5} h={5} color="#B3B3B8" mr={2.5} />
        ) : null}
        <Text textStyle="bodyM" color="grey.700" fontWeight="semibold">
          {heading}
        </Text>
      </Flex>

      <Flex flexDir="column" mt={4}>
        {leftList.map((item, index) => {
          return (
            <Flex key={item}>
              <Text
                textStyle="bodyS"
                fontWeight="medium"
                color="grey.700"
                p={1}
                w="60%"
                {...leftTextProps}
              >
                {item}
              </Text>
              <Text
                textStyle="bodyS"
                fontWeight="normal"
                color="grey.700"
                p={1}
                noOfLines={100}
                overflowWrap="anywhere"
                w="60%"
                {...rightTextProps}
              >
                {rightList[index] ?? ""}
              </Text>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};
