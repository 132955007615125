/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  Button,
  Divider,
  Flex,
  Heading,
  Image,
  Link,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { useState } from "react";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { BypassKycForm } from "./BypassKyc";
import { ESShipments } from "./ESShipments";
import { useMASERequestForm } from "./hooks/useMASERequestForm";
import {
  ExportedProductsType,
  usePostExportProductsForm,
} from "./hooks/usePostExportProductsForm";
import { emailRegex } from "./utils";
import { AutocompleteField } from "components/AutocompleteField/AutocompleteField";
import { InputField } from "components/InputField/InputField";
import { PanelForm } from "components/PanelForm/PanelForm";
import { TableWrapper } from "components/Table/TableWrapper";
import {
  SolidDiscount,
  SolidDolarSign,
  SolidDownload,
  SolidGrid,
} from "styles/icons/solid";

export const TechSupport = () => {
  const { t } = useTranslation();
  const { register, onSubmit, errors, isLoadingPost } = useMASERequestForm();
  const [isUseMargin, setIsUseMargin] = useState<boolean>(false);

  const {
    register: registerProductsForm,
    control: productsFormControl,
    onSubmit: downloadCSV,
    allBrands,
    keywordOptions,
    getAllBrandsLoading,
    categoriesOptions,
    exportLoading,
    onSubmitJSON: generateTable,
    tableData,
    setValue: setProductFormValue,
  } = usePostExportProductsForm();

  const toggleMargin = () => {
    setProductFormValue("margin", 0);
    setProductFormValue("minRetailPrice", 0);
    setProductFormValue("maxRetailPrice", 0);
    setIsUseMargin(!isUseMargin);
  };

  const columnHelper = createColumnHelper<ExportedProductsType>();
  const columns = [
    columnHelper.accessor("image", {
      cell: ({ row }) => (
        <Image
          src={row.original.image}
          alt={row.original.name}
          w={120}
          h={120}
        />
      ),
      header: "Product Image",
      enableSorting: true,
    }),
    columnHelper.accessor("name", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700" fontWeight="medium">
          <Link href={row.original.link} target="_blank">
            {row.original.name}
          </Link>
        </Text>
      ),
      header: t("tech_support.product_table.name"),
      enableSorting: true,
    }),
    columnHelper.accessor("brand", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700" fontWeight="medium">
          {row.original.brand}
        </Text>
      ),
      header: "Brand",
      enableSorting: true,
    }),
    columnHelper.accessor("description", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700" fontWeight="medium">
          {row.original.description.substring(0, 60) + "..."}
        </Text>
      ),
      header: "Product description",
      enableSorting: false,
      size: 20,
    }),
    columnHelper.accessor("keywords", {
      cell: ({ row }) => (
        <div>
          {!!row.original.keywords.length &&
            row.original.keywords.map((keyword, i) => {
              let color = "grey.700";
              if (row.original.originalKeywords.includes(i)) {
                color = "green.700";
              }
              return (
                <Text
                  key={keyword}
                  textStyle="bodyS"
                  color={color}
                  fontWeight="medium"
                >
                  {keyword}
                </Text>
              );
            })}
        </div>
      ),
      header: "Keywords",
      enableSorting: false,
    }),
    columnHelper.accessor("retailPrice", {
      cell: ({ row }) => (
        <>
          <div>
            <Text textStyle="bodyS" color="grey.700" fontWeight="medium">
              {row.original.retailPrice} MSRP
            </Text>
          </div>
          <div>
            <Text textStyle="bodyS" color="grey.700" fontWeight="medium">
              {row.original.wholesalePrice} WSP
            </Text>
          </div>
        </>
      ),
      header: "Price",
      enableSorting: false,
    }),
    columnHelper.accessor("margin", {
      cell: ({ row }) => <Text>{row.original.margin}%</Text>,
      header: "Margin",
      enableSorting: true,
    }),
  ];

  return (
    <Flex flexDir="column">
      <Heading as="h1" size="h1" fontWeight="bold" mb={7} mt={11} ml={9}>
        {t("Tech_Support")}
      </Heading>
      <Divider />
      <Flex
        flexDir="column"
        padding={8}
        gap={5}
        width="100%"
        overflowY="scroll"
      >
        <PanelForm title={t("tech_support.mase_header")}>
          <Text textStyle="bodyM" mb={1} mt={1}>
            {t("tech_support.mase_sub_1")}
          </Text>
          <Flex
            justifyContent="space-between"
            borderRadius="4px 4px 0px 0px"
            textStyle="bodyS"
            fontWeight="medium"
            pt={2.5}
            gap={5}
            flexDir={"column"}
          >
            <InputField
              w={350}
              {...register("passedAccount", {
                required: t("tech_support.field_required"),
                pattern: {
                  message: t("tech_support.invalid_email"),
                  value: emailRegex,
                },
              })}
              label={t("tech_support.passed_account")}
              errors={errors}
            />
            <InputField
              w={350}
              {...register("submittedAccount", {
                required: t("tech_support.field_required"),
                pattern: {
                  message: t("tech_support.invalid_email"),
                  value: emailRegex,
                },
              })}
              label={t("tech_support.same_entity_account")}
              errors={errors}
            />
            <Flex w={250}>
              <Button size={"lg"} onClick={onSubmit} disabled={isLoadingPost}>
                {t("tech_support.submit_mase")}
              </Button>
            </Flex>
          </Flex>
        </PanelForm>
      </Flex>
      <Flex
        flexDir="column"
        padding={8}
        gap={5}
        width="100%"
        overflowY="scroll"
      >
        <BypassKycForm />
      </Flex>
      <Flex
        flexDir="column"
        padding={8}
        gap={5}
        width="100%"
        overflowY="scroll"
      >
        <ESShipments />
      </Flex>
      <Flex
        flexDir="column"
        padding={8}
        gap={5}
        width="100%"
        overflowY="scroll"
      >
        <PanelForm title={t("tech_support.product_export_header")}>
          <Text textStyle="bodyM" mb={1} mt={1}>
            {t("tech_support.product_export_sub_1")}
          </Text>
          <Flex
            justifyContent="space-between"
            borderRadius="4px 4px 0px 0px"
            textStyle="bodyS"
            fontWeight="medium"
            pt={2.5}
            gap={5}
            flexDir={"column"}
          >
            <AutocompleteField
              control={productsFormControl as unknown as Control}
              {...registerProductsForm("keywords")}
              isMulti={true}
              label="Keywords"
              options={keywordOptions}
            />
            {getAllBrandsLoading ? (
              <Spinner />
            ) : (
              <AutocompleteField
                control={productsFormControl as unknown as Control}
                {...registerProductsForm("brands")}
                isMulti={true}
                label="Brands"
                options={allBrands}
              />
            )}
            <AutocompleteField
              control={productsFormControl as unknown as Control}
              {...registerProductsForm("categories")}
              isMulti={true}
              label="Categories"
              options={categoriesOptions}
              isDisabled={true} // TODO: Implement w/ CT logic
            />
            <Flex w={500} gap={5}>
              <InputField
                label={"Minimum Wholesale Price"}
                errors={errors}
                {...registerProductsForm("minWholesalePrice")}
                rightAddon="HK$"
                defaultValue={0}
              />
              <InputField
                label={"Maximum Wholesale Price"}
                errors={errors}
                {...registerProductsForm("maxWholesalePrice")}
                rightAddon="HK$"
                defaultValue={0}
                disabled={isUseMargin}
              />
            </Flex>
            {!isUseMargin ? (
              <Flex w={500} gap={5}>
                <InputField
                  label={"Minimum Retail Price"}
                  errors={errors}
                  {...registerProductsForm("minRetailPrice")}
                  rightAddon="HK$"
                  defaultValue={0}
                />
                <InputField
                  label={"Maximum Retail Price"}
                  errors={errors}
                  {...registerProductsForm("maxRetailPrice")}
                  rightAddon="HK$"
                  defaultValue={0}
                />
              </Flex>
            ) : (
              <Flex w={300} gap={5}>
                <InputField
                  label={"Margin"}
                  errors={errors}
                  {...registerProductsForm("margin")}
                  rightAddon="%"
                  defaultValue={0}
                />
              </Flex>
            )}
            <Flex w={800} gap={5}>
              <Button
                leftIcon={exportLoading ? <Spinner /> : <SolidDownload />}
                size={"lg"}
                onClick={downloadCSV}
                disabled={exportLoading}
              >
                {t("tech_support.get_products")}
              </Button>
              <Button
                leftIcon={exportLoading ? <Spinner /> : <SolidGrid />}
                size={"lg"}
                onClick={generateTable}
                disabled={exportLoading}
              >
                {t("tech_support.get_table")}
              </Button>
              <Button
                leftIcon={!isUseMargin ? <SolidDiscount /> : <SolidDolarSign />}
                size={"lg"}
                onClick={toggleMargin}
              >
                {!isUseMargin ? "Use Margin" : "Use MSRP"}
              </Button>
            </Flex>
          </Flex>
        </PanelForm>
        {tableData.length > 0 && (
          <TableWrapper tableData={tableData} columns={columns} />
        )}
      </Flex>
    </Flex>
  );
};
