/* eslint-disable @tanstack/query/exhaustive-deps */
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { PAYOUT_DETAILS_KEY } from "services/ReactQueryKeys";
import httpClient from "services/httpClient";

export const GET_PAYOUT_BY_ID = "/admin/payouts/incoming/";

export type PayoutOrders = {
  orderId: string;
  subOrderId: string;
  orderNumber: string;
  retailerName: string;
  dateFrom: string;
  dateTo: string;
  amount: number;
  isHeld: boolean;
};
export type PayoutDetails = {
  brandName: string;
  dateFrom: string;
  dateTo: string;
  currency: string;
  orders: PayoutOrders[];
};
export const useGetPayoutDetails = (
  id: string,
): UseQueryResult<PayoutDetails> =>
  useQuery({
    queryKey: [PAYOUT_DETAILS_KEY],
    queryFn: async () => {
      const { data: payouts } = await httpClient.get<PayoutDetails>(
        `${GET_PAYOUT_BY_ID}${id}`,
      );
      return payouts;
    },
  });
