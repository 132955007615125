/* eslint-disable @tanstack/query/exhaustive-deps */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { voucherTypeToLabel } from "../CreateVouchers/VoucherUtils";
import { ApiVoucher, Voucher } from "features/UserManagement/types";
import { VOUCHER_KEY } from "services/ReactQueryKeys";
import httpClient from "services/httpClient";

export const GET_ALL_VOUCHERS = "/admin/vouchers";

export const useGetAllVouchers = (): UseQueryResult<Voucher[]> =>
  useQuery({
    queryKey: [VOUCHER_KEY],
    queryFn: async () => {
      const { data: allVouchers } = await httpClient.get<ApiVoucher[]>(
        GET_ALL_VOUCHERS,
      );

      return allVouchers.map((voucher: ApiVoucher) => ({
        code: voucher.key,
        expiryDate: voucher.validTo,
        appliedTo: voucherTypeToLabel(voucher.appliedTo),
        discountType: voucher.fixedAmount ? "Fixed Amount" : "Percentage",
        discountValue: voucher.fixedAmount
          ? `$${voucher.fixedAmount}`
          : voucher.fixedPercent && `${voucher.fixedPercent}%`,
        usageQuantity: voucher.maxUsage,
        minAmount: voucher.minAmount,
        maxAmount: voucher.maxAmount,
        currency: voucher.currency,
        maxUsagePerUser: voucher.maxUsagePerUser,
        isActive: voucher.isActive,
        firstOrderOnly: voucher.firstOrderOnly ? "Yes" : "No",
        applicableBrand: voucher.applicableBrands?.[0]?.storeName ?? "N/A",
      }));
    },
  });
