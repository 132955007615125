import { useMutation } from "@tanstack/react-query";

import httpClient from "services/httpClient";

export const DEACTIVATE_VOUCHER_BY_ID = "/admin/vouchers";

const deactivateVoucher = async ({ key }: { key: string }) => {
  return await httpClient.put(`${DEACTIVATE_VOUCHER_BY_ID}/disable/${key}`);
};

export const usePutDeactivateVoucher = () => useMutation(deactivateVoucher);
