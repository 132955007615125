import {
  FormControlProps,
  Icon,
  Input,
  InputGroup,
  InputProps,
  InputRightAddon,
  InputRightElement,
  ResponsiveValue,
} from "@chakra-ui/react";
import { ReactNode, forwardRef, useState } from "react";
import { FieldErrors, FieldValues, UseFormRegister } from "react-hook-form";

import { FieldWrapper } from "components/FieldWrapper/FieldWrapper";
import LightEye from "styles/icons/light/LightEye";

export type InputFieldProps = {
  label?: string;
  info?: string;
  placeholder?: string;
  errors: FieldErrors;
  size?: ResponsiveValue<string>;
  disabled?: boolean;
  readOnly?: boolean;
  isRequired?: boolean;
  canToggleVisibility?: boolean;
  rightAddon?: string;
  rightInfo?: string | ReactNode;
} & ReturnType<UseFormRegister<FieldValues>> &
  FormControlProps &
  InputProps;

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      label,
      info,
      errors,
      isRequired,
      placeholder,
      disabled,
      readOnly,
      size,
      name,
      onChange,
      onBlur,
      canToggleVisibility,
      rightInfo,
      type,
      rightAddon,
      ...rest
    },
    ref,
  ) => {
    const inputType = type;
    const [showPassword, setShowPassword] = useState(false);
    const handleShowPasswordClick = () =>
      setShowPassword((previousState) => !previousState);

    return (
      <FieldWrapper
        name={name}
        label={label}
        info={info}
        errors={errors}
        isRequired={isRequired}
        rightInfo={rightInfo}
      >
        <InputGroup size={size}>
          <Input
            {...rest}
            name={name}
            placeholder={placeholder}
            disabled={disabled}
            isInvalid={!!errors[name]}
            readOnly={readOnly}
            onChange={(args) => void onChange(args)}
            onBlur={(args) => void onBlur(args)}
            ref={ref}
            py="10px"
            height="initial"
            type={
              inputType === "password"
                ? showPassword
                  ? "text"
                  : "password"
                : type
            }
          />
          {type === "password" && canToggleVisibility && (
            <InputRightElement height="100%" mr="10px">
              <Icon
                as={LightEye}
                color="grey.600"
                boxSize={5}
                cursor="pointer"
                onClick={handleShowPasswordClick}
              />
            </InputRightElement>
          )}
          {rightAddon && (
            <InputRightAddon
              border="1px solid"
              borderColor="gray.400"
              color="gray.400"
              height="41.5px"
            >
              {rightAddon}
            </InputRightAddon>
          )}
        </InputGroup>
      </FieldWrapper>
    );
  },
);

InputField.displayName = "InputField";
