import { Flex, Radio, Spinner, Text } from "@chakra-ui/react";
import { Control, FieldErrorsImpl, UseFormRegister } from "react-hook-form";

import { EditAdminFormFields } from "./hooks/useEditAdminForm";
import { RadioField } from "components/RadioField/RadioField";

const radioTextStyles = {
  textStyle: "bodyM",
  fontWeight: "semibold",
  color: "black",
};
export type EditAdminFormType = {
  control: Control<EditAdminFormFields>;
  register: UseFormRegister<EditAdminFormFields>;
  errors: Partial<
    FieldErrorsImpl<{
      account: string;
    }>
  >;
  onSubmit: () => Promise<void>;
  isEditLoading: boolean;
};
export const EditAdminForm = ({
  control,
  onSubmit,
  isEditLoading,
}: EditAdminFormType) => {
  const accountOptions = (
    <Flex w="300px" justifyContent="space-between">
      <Radio value="none" />
      <Radio value="view" />
      <Radio value="edit" />
      <Radio value="admin" />
    </Flex>
  );

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form id="editAdmin" onSubmit={onSubmit}>
      <Flex display="column" w="476px">
        <Flex ml="160px" mt={5} justifyContent="space-between">
          <Text {...radioTextStyles}>None</Text>
          <Text {...radioTextStyles}>View</Text>
          <Text {...radioTextStyles}>Edit</Text>
          <Text {...radioTextStyles}>Admin</Text>
        </Flex>
        <Flex h={8} mt={4}>
          <Text {...radioTextStyles} w={20}>
            Account
          </Text>
          <RadioField
            ml={24}
            id="account"
            control={control as unknown as Control}
            name="account"
            radios={accountOptions}
          />
        </Flex>
        <Flex h={8} mt={2.5}>
          <Text {...radioTextStyles} w={20}>
            Settings
          </Text>
          <RadioField
            ml={24}
            id="settings"
            control={control as unknown as Control}
            name="settings"
            radios={accountOptions}
          />
        </Flex>
        <Flex h={8} mt={2.5}>
          <Text {...radioTextStyles} w={20}>
            Users
          </Text>
          <RadioField
            ml={24}
            id="users"
            control={control as unknown as Control}
            name="users"
            radios={accountOptions}
          />
        </Flex>
        <Flex h={8} mt={2.5}>
          <Text {...radioTextStyles} w={20}>
            Financials
          </Text>
          <RadioField
            ml={24}
            id="financials"
            control={control as unknown as Control}
            name="financials"
            radios={accountOptions}
          />
        </Flex>
        <Flex h={8} mt={2.5}>
          <Text {...radioTextStyles} w={20}>
            Promotions
          </Text>
          <RadioField
            ml="90px"
            id="promotions"
            control={control as unknown as Control}
            name="promotions"
            radios={accountOptions}
          />
        </Flex>
      </Flex>
      {isEditLoading && <Spinner position="absolute" top="50%" left="50%" />}
    </form>
  );
};
