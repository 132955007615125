import { ButtonProps } from "components/Button/Button";

export const chooseImageContainer = (isError: boolean) => ({
  h: "146px",
  borderRadius: "base",
  border: "1px dashed",
  borderColor: isError ? "red.500" : "primary.500",
});

export const thumbnailChangeButton: ButtonProps = {
  position: "absolute",
  top: "35%",
  left: "25%",
  lineHeight: 5,
};
