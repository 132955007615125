/* eslint-disable @typescript-eslint/prefer-for-of */

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  Box,
  Button,
  Flex,
  Icon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Spacer,
  Table,
  TableContainer,
  TableProps,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  ColumnFilter,
  PaginationState,
  Row,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ReactNode, useCallback, useEffect, useState } from "react";

import { RegularArrowDown, RegularArrowUp } from "styles/icons/regular";

export type Filter = ColumnFilter;

export type TableWrapperProps = {
  columns: any;
  tableData: any;
  initialSort?: SortingState;
  filter?: ColumnFilter[];
  globalFilter?: string;
  tableProps?: TableProps;
  showFooter?: boolean;
  additionalFooter?: ReactNode;
  getRowHref?: (row: Row<unknown>) => string;
  disableColumnClick?: boolean;
  textWrap?: boolean;
  disablePagination?: boolean;
  pageSize?: number;
};
export const TableWrapper = ({
  columns,
  tableData,
  initialSort,
  filter,
  globalFilter,
  tableProps,
  additionalFooter,
  showFooter = false,
  getRowHref,
  disableColumnClick = false,
  textWrap = false,
  disablePagination = false,
  pageSize = 10,
  ...rest
}: TableWrapperProps) => {
  const [sorting, setSorting] = useState<SortingState>(initialSort ?? []);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: pageSize,
  });
  const tableOptions = disablePagination
    ? {
        columns,
        data: tableData ?? [],
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        state: {
          sorting,
        },
      }
    : {
        columns,
        data: tableData ?? [],
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,
        state: {
          sorting,
          pagination,
        },
      };
  const table = useReactTable(tableOptions);

  const checkIfFiltersHaveValue = (filters: Filter[]) => {
    for (let i = 0; i < filters.length; i += 1) {
      if (filters[i].value !== "") {
        return true;
      }
    }
    return false;
  };

  const shouldRerenderFilters = useCallback(
    (filters: Filter[], oldFilters: Filter[]) => {
      if (oldFilters.length === 0) {
        return checkIfFiltersHaveValue(filters);
      }
      for (let i = 0; i < filters.length; i += 1) {
        if (filters[i].value !== "") {
          const selectedFilter = oldFilters.findIndex(
            (f) => f.id === filters[i].id && f.value === filters[i].value,
          );
          if (selectedFilter === -1) {
            return true;
          }
        } else {
          const selectedFilter = oldFilters.find((f) => f.id === filters[i].id);
          if (selectedFilter) {
            return true;
          }
        }
      }
      return false;
    },
    [],
  );

  useEffect(() => {
    if (
      filter &&
      shouldRerenderFilters(filter, table.getState().columnFilters)
    ) {
      table.setColumnFilters(filter);
    }
  }, [filter, shouldRerenderFilters, table]);

  useEffect(() => {
    if (globalFilter) {
      table.setGlobalFilter(globalFilter);
    }
    return () => {
      table.resetGlobalFilter();
    };
  }, [globalFilter, table]);

  const totalCompoent = (
    <div>
      Total <strong>{table.getPrePaginationRowModel().rows.length}</strong> Rows
    </div>
  );

  const paginationElements = (
    <Flex style={{ fontSize: 14 }}>
      <Box p="4" pl={0}>
        <Flex gap={2}>
          <Flex>
            <Button
              size="xs"
              variant="outline"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              {"<<"}
            </Button>
            <Button
              size="xs"
              variant="outline"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {"<"}
            </Button>
            <Button
              size="xs"
              variant="outline"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {">"}
            </Button>
            <Button
              size="xs"
              variant="outline"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              {">>"}
            </Button>
          </Flex>
          <Flex>
            <strong>
              {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount()}
            </strong>
          </Flex>
          <Flex>
            | Go to page:
            <NumberInput
              pl={1}
              size="xs"
              maxW={150}
              defaultValue={table.getState().pagination.pageIndex + 1}
              min={1}
              max={table.getPageCount() || Number.MAX_SAFE_INTEGER}
              onChange={(val) => {
                const page = val ? Number(val) - 1 : 0;
                table.setPageIndex(page);
              }}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Flex>
          <Select
            size="xs"
            maxW={150}
            value={table.getState().pagination.pageSize}
            onChange={(ev) => {
              table.setPageSize(Number(ev.target.value));
            }}
          >
            {[10, 100, 1000, 10000, 100000].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        </Flex>
      </Box>
      <Spacer />
      <Box p="4">{totalCompoent}</Box>
    </Flex>
  );

  const whiteSpaceStyle = textWrap ? { whiteSpace: "normal" } : {};

  return (
    <TableContainer mb={10} {...rest} style={{ overflow: "visible" }}>
      {!disablePagination && paginationElements}
      <Table {...tableProps}>
        <Thead style={whiteSpaceStyle}>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <Th
                    key={header.id}
                    width={
                      header.getSize() !== 0
                        ? `${header.getSize()}%`
                        : undefined
                    }
                    onClick={(ev) =>
                      disableColumnClick
                        ? ev.stopPropagation()
                        : header.column.getCanSort() &&
                          header.column.toggleSorting()
                    }
                  >
                    <Flex gap={1}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      <Box boxSize={5}>
                        {header.column.getIsSorted() ? (
                          header.column.getIsSorted() === "desc" ? (
                            <Icon
                              as={RegularArrowDown}
                              boxSize={4.5}
                              pl={0.5}
                            />
                          ) : (
                            <Icon as={RegularArrowUp} boxSize={4.5} pl={0.5} />
                          )
                        ) : null}
                      </Box>
                    </Flex>
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => {
            const child = row.getVisibleCells().map((cell) => (
              <Td
                key={cell.id}
                width={
                  cell.column.getSize() !== 0
                    ? `${cell.column.getSize()}%`
                    : undefined
                }
                style={whiteSpaceStyle}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Td>
            ));

            return getRowHref ? (
              <a
                href={getRowHref(row)}
                key={row.id}
                onClick={row.getToggleSelectedHandler()}
                className={row.getIsSelected() ? "selected-row" : ""}
                style={{
                  display: "table-row",
                  verticalAlign: "middle",
                }}
                onMouseEnter={(ev) =>
                  (ev.currentTarget.style.backgroundColor = "#f6f6f6")
                }
                onMouseLeave={(ev) =>
                  (ev.currentTarget.style.backgroundColor = "")
                }
              >
                {child}
              </a>
            ) : (
              <Tr
                key={row.id}
                onClick={row.getToggleSelectedHandler()}
                className={row.getIsSelected() ? "selected-row" : ""}
              >
                {child}
              </Tr>
            );
          })}
        </Tbody>
        <Tfoot>
          {showFooter &&
            table.getFooterGroups().map((footer) => (
              <Tr key={footer.id}>
                {footer.headers.map((column) => (
                  <Td key={column.id}>
                    {flexRender(
                      column.column.columnDef.footer,
                      column.getContext(),
                    )}
                  </Td>
                ))}
              </Tr>
            ))}
        </Tfoot>
        {additionalFooter}
      </Table>
      {!disablePagination && paginationElements}
    </TableContainer>
  );
};
