import { Flex, FlexProps, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export type KycStatus = "NOT_STARTED" | "STARTED" | "VERIFIED";

type KycStatusLabelProps = {
  status: KycStatus;
} & FlexProps;

const useMapStatus = (status: KycStatus) => {
  const { t } = useTranslation();
  switch (status) {
    case "NOT_STARTED":
      return { color: "red.300", text: t("brand_details.not_started") };
    case "STARTED":
      return { color: "yellow.300", text: t("brand_details.started") };
    case "VERIFIED":
      return { color: "green.300", text: t("brand_details.verified") };
    default:
      return { color: "red.300", text: t("brand_details.status_unknown") };
  }
};

export const KycStatusLabel = ({ status, ...rest }: KycStatusLabelProps) => {
  const { color, text } = useMapStatus(status);
  return (
    <Flex borderRadius={4} backgroundColor={color} px={2} py={1} {...rest}>
      <Text textStyle="bodyS">{text}</Text>
    </Flex>
  );
};

export type AWXKycStatus =
  | "CREATED"
  | "SUBMITTED"
  | "ACTION_REQUIRED"
  | "ACTIVE"
  | "SUSPENDED";

export type AWXKycStatusLabelProps = {
  status: AWXKycStatus;
} & FlexProps;

const useAWXMapStatus = (text: AWXKycStatus) => {
  switch (text) {
    case "ACTION_REQUIRED":
    case "SUSPENDED":
      return { color: "red.300", text };
    case "CREATED":
    case "SUBMITTED":
      return { color: "yellow.300", text };
    case "ACTIVE":
      return { color: "green.300", text };
    default:
      return { color: "red.300", text };
  }
};

export const AWXKycStatusLabel = ({
  status,
  ...rest
}: AWXKycStatusLabelProps) => {
  const { color, text } = useAWXMapStatus(status);
  return (
    <Flex borderRadius={4} backgroundColor={color} px={2} py={1} {...rest}>
      <Text textStyle="bodyS">{text}</Text>
    </Flex>
  );
};
