/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";

import { Product, ReturnRequestDetails } from "./useGetReturnRequestDetails";

export type EditReturnFields = {
  sku: string;
  approvedUnitQuantity: number;
  adminComment?: string;
};

export type ProductQuantityFields = {
  products: Product[];
};

type UseReturnRequestDetailsProps = {
  returnRequest?: ReturnRequestDetails;
  onCloseModal: () => void;
};
export const useReturnRequestDetails = ({
  onCloseModal,
  returnRequest,
}: UseReturnRequestDetailsProps) => {
  const {
    register,
    formState: { errors, isDirty },
    getValues,
    reset: resetModal,
    handleSubmit,
  } = useForm<EditReturnFields>();

  const [isChanged, setIsChanged] = useState(false);

  const {
    getValues: getValuesMain,
    control,
    reset,
  } = useForm<ProductQuantityFields>();

  const { fields, update } = useFieldArray<ProductQuantityFields>({
    control,
    name: "products",
  });

  const handleApply = handleSubmit(() => {
    const currentField = fields.find(
      (item) => (item as Product).sku === getValues("sku"),
    );
    update(
      fields.findIndex((item) => (item as Product).sku === getValues("sku")),
      {
        ...currentField,
        approvedUnitQuantity: Number(getValues("approvedUnitQuantity")),
        adminComment: getValues("adminComment"),
      } as Product,
    );
    setIsChanged(isDirty);
    onCloseModal();
    resetModal({});
  });

  useEffect(() => {
    reset({
      products: returnRequest?.products.map((product) => {
        return {
          ...product,
          approvedUnitQuantity:
            product.status === "DECLINED" ||
            product.status === "PARTIAL_APPROVED"
              ? product.approvedUnitQuantity
              : product.requestUnitQuantity,
          productId: product.sku,
          quantity: product.quantity,
          comment: product.comment,
        } as Product;
      }),
    });
  }, [returnRequest, reset]);

  return {
    register,
    errors,
    handleApply,
    fields,
    resetModal,
    getValuesMain,
    isChanged,
  };
};
