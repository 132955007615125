/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable @typescript-eslint/no-unnecessary-condition */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";

import { ErrorResponse } from "../../../../../services/ErrorResponse";
import { getErrorCodes } from "../../../../../services/httpClient";
import { useGetSettings } from "./useGetSettings";
import { usePostSettings } from "./usePostSettings";
import { useSimpleToast } from "hooks/useSimpleToast";
import { BRAND_FINANCIAL_KEY } from "services/ReactQueryKeys";
import { queryClient } from "services/queryClient";

export type BrandSettingsFormFields = {
  firstOrderCommission: number;
  commission: number;
};

type BrandSettingsFormProps = {
  brandId: string;
};

export const useBrandFinancialSettingsForm = ({
  brandId,
}: BrandSettingsFormProps) => {
  const { data: settings, isLoading: isLoadingGet } = useGetSettings(brandId);

  const {
    control,
    register,
    formState: { errors, isDirty, isValid },
    getValues,
    handleSubmit,
    setValue,
    reset,
  } = useForm<BrandSettingsFormFields>({});

  const toast = useSimpleToast();

  useEffect(() => {
    if (!isLoadingGet && settings) {
      reset(() => ({
        firstOrderCommission: settings.firstOrderCommission,
        commission: settings.commission,
      }));
    }
  }, [isLoadingGet, settings]);
  const onSuccess = () => {
    void queryClient.fetchQuery({ queryKey: [BRAND_FINANCIAL_KEY, brandId] });
    toast({
      position: "top",
      description: "Changes have been saved successfully",
    });
  };

  const onError = (error: ErrorResponse) => {
    const errorCodes = getErrorCodes(error);
    toast({
      position: "top",
      status: "error",
      description: errorCodes[0],
    });
  };

  const { mutateAsync: postSettings, isLoading: isLoadingPost } =
    usePostSettings(onSuccess, onError, brandId);

  const isFormValid = useMemo(() => {
    return !isDirty || !isValid;
  }, [isDirty, isValid]);

  const onSubmit = handleSubmit(async () => {
    try {
      await postSettings(getValues());
    } catch (error) {
      console.error(error);
    }
  });

  return {
    control,
    register,
    errors,
    isFormValid,
    getValues,
    onSubmit,
    setValue,
    isLoadingGet,
    isLoadingPost,
  };
};
