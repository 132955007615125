import { StatusVariant } from "./Status";

const getVariantColor = (variant: StatusVariant) => {
  switch (variant) {
    case "error":
      return "red";
    case "pending":
      return "yellow";
    case "done":
      return "green";
    case "info":
      return "blue";
    case "neutral":
      return "greyAlpha";
  }
};

export const statusContainerStyles = (
  variant: StatusVariant,
  text?: string,
) => ({
  background: text ? `${getVariantColor(variant)}.100` : "",
  borderRadius: "md",
  display: "inline-flex",
  alignItems: "center",
  paddingX: 1,
  paddingY: 0.5,
});

export const circleContainerStyles = {
  width: "4.5",
  justifyContent: "center",
  alignItems: "center",
};

export const circleStyles = (variant: StatusVariant) => ({
  border: "1px solid",
  borderColor:
    variant === "neutral" ? "grey.900" : `${getVariantColor(variant)}.700`,
  background:
    variant === "neutral" ? "greyAlpha.400" : `${getVariantColor(variant)}.500`,
  size: "9px",
});

export const statusTextStyles = {
  marginLeft: 0.5,
  color: "greyAlpha.600",
  fontWeight: "normal",
  fontSize: "sm",
  lineHeight: "tall",
};
