import {
  Button,
  Flex,
  Icon,
  Link,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ForceCancelSubOrderModal } from "../ForceCancelSubOrderModal/ForceCancelSubOrderModal";
import { ForceCompleteSubOrderModal } from "../ForceCompleteSubOrderModal/ForceCompleteSubOrderModal";
import { SubOrder } from "../hooks/useGetOrderById";
import { LightCheckCircle } from "styles/icons/light";

type OrderAdditionalFooterProps = {
  suborderId: string;
  currency: string;
  returnable: boolean;
  total?: string;
  subOrder: SubOrder;
};
export const OrderAdditionalFooter = ({
  suborderId,
  currency,
  returnable,
  total,
  subOrder,
}: OrderAdditionalFooterProps) => {
  const { t } = useTranslation();
  const [isForceCompleting, setIsForceCompleting] = useState<boolean>(false);

  const {
    isOpen: isOpenForceCancelSubOrder,
    onOpen: onOpenForceCancelSubOrder,
    onClose: onCloseForceCancelSubOrder,
  } = useDisclosure();
  const {
    isOpen: isOpenForceCompleteSubOrder,
    onOpen: onOpenForceCompleteSubOrder,
    onClose: onCloseForceCompleteSubOrder,
  } = useDisclosure();

  const onForceCompleteLoading = (isLoading: boolean) => {
    setIsForceCompleting(isLoading);
  };

  return (
    <Flex w="100%" h="106px" flexDir="column" p={4}>
      <Flex align="right" justifyContent="space-between" mb={5} maxW="100%">
        <Flex></Flex>
        <Flex
          w="160px"
          mr={10}
          justifyContent="space-between"
          textStyle="bodyS"
          fontWeight="bold"
        >
          <Text>{t("order.total")}</Text>
          <Text>
            {Intl.NumberFormat("en-US", {
              style: "currency",
              currency: currency,
            }).format(Number(total))}
          </Text>
        </Flex>
      </Flex>
      <Flex justifyContent="space-between" textStyle="bodyS">
        <Flex>
          {returnable ? (
            <>
              <Icon as={LightCheckCircle} color="green.500" w={5} h={5} />
              <Text fontWeight="medium" ml={2} color="grey.700">
                {t("order.eligible_for_return")}
              </Text>
            </>
          ) : (
            <Flex />
          )}
        </Flex>
        <Flex alignItems={"center"}>
          {subOrder.status &&
            ["PENDING", "CONFIRMED", "IN_SHIPPING"].includes(
              subOrder.status,
            ) && (
              <Button
                variant={"ghost"}
                size="sm"
                w="fit-content"
                mr={9}
                colorScheme="red"
                onClick={onOpenForceCancelSubOrder}
              >
                {t("order.force_cancel_suborder")}
              </Button>
            )}
          {subOrder.status &&
            ["CONFIRMED", "IN_SHIPPING", "IN_SHIPPING_EXTERNAL"].includes(
              subOrder.status,
            ) && (
              <Button
                variant={"ghost"}
                size="sm"
                w="fit-content"
                mr={9}
                onClick={onOpenForceCompleteSubOrder}
                isLoading={isForceCompleting}
                disabled={
                  subOrder.status === "IN_SHIPPING_EXTERNAL" &&
                  !subOrder.shippingDetails.selfShipInvoiceUrl
                }
              >
                {t("order.force_complete_suborder")}
              </Button>
            )}
          <Link href={`/financial-breakdown/${suborderId}`}>
            <Text color="#1C7EC6" mr={9} cursor="pointer">
              {t("order.see_financial_breakdown")}
            </Text>
          </Link>
        </Flex>
      </Flex>
      {isOpenForceCompleteSubOrder && (
        <ForceCompleteSubOrderModal
          isOpen={isOpenForceCompleteSubOrder}
          onClose={onCloseForceCompleteSubOrder}
          subOrder={subOrder}
          onLoading={onForceCompleteLoading}
        />
      )}
      {isOpenForceCancelSubOrder && (
        <ForceCancelSubOrderModal
          isOpen={isOpenForceCancelSubOrder}
          onClose={onCloseForceCancelSubOrder}
          subOrder={subOrder}
        />
      )}
    </Flex>
  );
};
