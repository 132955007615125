/* eslint-disable @tanstack/query/exhaustive-deps */
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { Brand } from "features/Orders/Details/hooks/useGetOrderById";
import { BRANDS_KEY } from "services/ReactQueryKeys";
import httpClient from "services/httpClient";

export const GET_ALL_BRANDS_URL = "/admin/brands";

export const useGetAllBrands = (): UseQueryResult<Brand[]> =>
  useQuery({
    queryKey: [BRANDS_KEY],
    queryFn: async () => {
      const { data: allBrands } = await httpClient.get<Brand>(
        GET_ALL_BRANDS_URL,
      );
      return allBrands;
    },
  });
