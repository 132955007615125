import { Flex, Spinner } from "@chakra-ui/react";

import { useSetupReturnReqestsTable } from "../hooks/useSetupReturnReqestsTable";
import { useGetReadyForReviewRequests } from "./hooks/useGetReadyForReviewRequests";
import { EmptyList } from "components/EmptyList/EmptyList";
import { TableWrapper } from "components/Table/TableWrapper";

export const ReadyForReview = () => {
  const { columns } = useSetupReturnReqestsTable();
  const { data: readyForReviews, isLoading } = useGetReadyForReviewRequests();
  if (isLoading) {
    return <Spinner position="absolute" top="50%" left="50%" />;
  }
  return (
    <Flex display="column" width="100%" pt={4} pl={1} pr={1}>
      {readyForReviews && readyForReviews.length > 0 ? (
        <TableWrapper
          tableData={readyForReviews}
          columns={columns}
          data-test-id="ready-for-review-table"
        />
      ) : (
        <EmptyList />
      )}
    </Flex>
  );
};
