import { useMutation } from "@tanstack/react-query";

import { ErrorResponse } from "../../../../../services/ErrorResponse";
import { BrandSettingsFormFields } from "./useBrandFinancialSettingsForm";
import httpClient from "services/httpClient";

const postSettings =
  (brandId: string) => async (form: BrandSettingsFormFields) => {
    return await httpClient.put(`/admin/brands/${brandId}/financials`, {
      firstOrderCommission: form.firstOrderCommission,
      commission: form.commission,
    });
  };

export const usePostSettings = (
  onSuccess: () => void,
  onError: (errors: ErrorResponse) => void,
  brandId: string,
) =>
  useMutation(postSettings(brandId), {
    onSuccess,
    onError,
  });
