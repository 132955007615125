import { useQuery } from "@tanstack/react-query";

import httpClient from "services/httpClient";

const ADMIN_ORDER = "admin/orders/";
const GET_ORDER_PREVIEW = "/edit/preview";

export type ProductObject = {
  brandPrice: number;
  id: string;
  key: string;
  sku: string;
  variantKey: string;
  variantName: string;
  imageUrl: string;
  price: number;
  discountPrice: number;
  promotionPrice: number;
  wholesalePrice: number;
  qty: number;
  caseQty: number;
  unitQuantity: number;
  total: {
    brand: number;
    retailer: number;
  };
  currency: {
    brand: string;
    retailer: string;
  };
  name: string;
  description: string;
};

type SnapshotObject = {
  id: string;
  orderId: string;
  products: ProductObject[];
};

export type OrderPreviewResponse = {
  id: string;
  subOrderId: string;
  snapshot: SnapshotObject;
  toCharge: number;
  toChargeCurrency: string;
  toRefund: number;
  diffInReturnSurcharge: number;
  toRefundCurrency: string;
  createdAt: string;
};

export const useGetOrderPreview = (suborderId: string) =>
  useQuery({
    queryKey: [ADMIN_ORDER, suborderId, GET_ORDER_PREVIEW],
    queryFn: async () => {
      const { data: orderPreview } = await httpClient.get<OrderPreviewResponse>(
        `${ADMIN_ORDER}${suborderId}${GET_ORDER_PREVIEW}`,
      );
      return orderPreview;
    },
  });
