import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  useApprovePaymentTermsRequest,
  useRejectPaymentTermsRequest,
} from "./usePostAcceptReject";
import { useSimpleToast } from "hooks/useSimpleToast";

export type RejectRetailerRequestFormFields = {
  id: string;
  reason: string;
};

export const useRejectRetailerRequest = (
  id: string,
  closeModal: () => void,
) => {
  const {
    control,
    register,
    formState: { errors, isDirty, isValid },
    getValues,
  } = useForm<RejectRetailerRequestFormFields>({ defaultValues: { id: id } });
  const { t } = useTranslation();
  const toast = useSimpleToast();
  const navigate = useNavigate();
  const onApproveSuccess = () => {
    toast({
      position: "top",
      description: t("retailer_requests.approve_success"),
    });
    navigate("/retailer-requests");
  };

  const onRejectSuccess = () => {
    toast({
      position: "top",
      description: t("retailer_requests.reject_success"),
    });
    closeModal();
    navigate("/retailer-requests");
  };

  const { mutateAsync: putApprove, isLoading: isApproveLoading } =
    useApprovePaymentTermsRequest(id, onApproveSuccess);

  const { mutateAsync: putReject, isLoading: isRejectLoading } =
    useRejectPaymentTermsRequest(onRejectSuccess);

  const handleAcceptClick = () => void putApprove();

  const handleRejectClick = () =>
    void putReject({ requestId: id, reason: getValues("reason") });

  const isFormValid = useMemo(() => {
    return !isDirty || !isValid;
  }, [isDirty, isValid]);

  return {
    control,
    register,
    errors,
    isFormValid,
    getValues,
    isApproveLoading,
    isRejectLoading,
    handleAcceptClick,
    handleRejectClick,
  };
};
