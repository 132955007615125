/* eslint-disable @typescript-eslint/no-unnecessary-condition */

/* eslint-disable @typescript-eslint/prefer-optional-chain */
import { Flex, FlexProps, Image } from "@chakra-ui/react";
import { ChangeEvent, useRef, useState } from "react";

import { thumbnailChangeButton } from "./ImageUpload.styles";
import { Button } from "components/Button/Button";

type ThumbnailProps = {
  src: string;
  handleOnFilesChange: (event: ChangeEvent<HTMLInputElement>) => void;
  acceptFormat: string;
  size?: "full" | "small";
  disabled?: boolean;
} & FlexProps;
export const Thumbnail = ({
  src,
  handleOnFilesChange,
  acceptFormat,
  size = "full",
  disabled,
  ...rest
}: ThumbnailProps) => {
  const [style, setStyle] = useState(false);
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (disabled) return;
    hiddenFileInput &&
      hiddenFileInput.current &&
      hiddenFileInput.current.click();
  };

  return (
    <Flex {...rest}>
      {src && (
        <Flex
          onMouseEnter={() => {
            if (disabled) return;
            setStyle(true);
          }}
          onMouseLeave={() => {
            if (disabled) return;
            setStyle(false);
          }}
          position="relative"
        >
          <Image
            w={size === "full" ? 650 : 146}
            h={146}
            src={src}
            alt={src}
            borderRadius="base"
          />
          <Button
            size="md"
            onClick={handleClick}
            display={style ? "block" : "none"}
            {...thumbnailChangeButton}
          >
            Change
          </Button>
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleOnFilesChange}
            accept={acceptFormat}
            style={{ display: "none" }}
          />
        </Flex>
      )}
    </Flex>
  );
};
