import { useMutation } from "@tanstack/react-query";

import { ChangeRetailerEmailFields } from "./useChangeRetailerEmailForm";
import { ErrorResponse } from "services/ErrorResponse";
import httpClient from "services/httpClient";

const CHANGE_EMAIL = "/admin/retailer/";

const putRetailerEmail = async (form: ChangeRetailerEmailFields) => {
  return await httpClient.put(
    `${CHANGE_EMAIL}${form.retailerId}/change-email`,
    {
      newEmail: form.newEmail,
      confirmEmail: form.confirmEmail,
    },
  );
};

export const usePutRetailerEmail = (
  onSuccess: () => void,
  onError: (error: ErrorResponse) => void,
) =>
  useMutation(putRetailerEmail, {
    onSuccess,
    onError,
  });
