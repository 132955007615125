import { Button, Flex, Input, Spinner, Text } from "@chakra-ui/react";
import { Row, SortingState, createColumnHelper } from "@tanstack/react-table";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useExportOrderHistory } from "./hooks/useExportOrderHistory";
import { Order } from "./hooks/useGetOrders";
import { Status } from "components/Status/Status";
import { TableWrapper } from "components/Table/TableWrapper";
import {
  capitalizeFirstLetter,
  convertToStatusVariant,
} from "features/UserManagement/utils";
import { LightDocument } from "styles/icons/light";
import { DEBOUNCED_SEARCH, downloadCSV, formatDate } from "utils/functions";

type OrderTableType = {
  action: string;
} & Order;

export const OrderTable = ({
  orders,
  isHistory,
  isLoading,
}: {
  orders?: Order[];
  isHistory?: boolean;
  isLoading: boolean;
}) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>("" as unknown as HTMLInputElement);

  const { mutateAsync: exportOrderHistory } = useExportOrderHistory();

  const initialSort: SortingState = [
    { id: isHistory ? "toDate" : "creationDate", desc: true },
  ];
  const [globalFilter, setGlobalFilter] = useState("");

  const columnHelper = createColumnHelper<OrderTableType>();

  const formatPrice = (currency: string, total: number) =>
    Intl.NumberFormat("en-US", {
      currency: currency,
      style: "currency",
    }).format(total);

  const dateColumn = isHistory
    ? [
        columnHelper.accessor((row) => formatDate(row.fromDate), {
          id: "fromDate",
          cell: ({ row }) => <Text>{formatDate(row.original.fromDate)}</Text>,
          header: t("orders.from"),
          size: 10,
          sortingFn: (a, b) => {
            return (
              new Date(a.original.fromDate).getTime() -
              new Date(b.original.fromDate).getTime()
            );
          },
        }),
        columnHelper.accessor((row) => formatDate(row.toDate), {
          id: "toDate",
          cell: ({ row }) => <Text>{formatDate(row.original.toDate)}</Text>,
          header: t("orders.to"),
          size: 10,
          sortingFn: (a, b) => {
            return (
              new Date(a.original.toDate).getTime() -
              new Date(b.original.toDate).getTime()
            );
          },
        }),
      ]
    : [
        columnHelper.accessor((row) => formatDate(row.creationDate), {
          id: "creationDate",
          cell: ({ row }) => (
            <Text>{formatDate(row.original.creationDate)}</Text>
          ),
          header: t("orders.date"),
          size: 10,
          sortingFn: (a, b) => {
            return (
              new Date(a.original.creationDate).getTime() -
              new Date(b.original.creationDate).getTime()
            );
          },
        }),
      ];

  const columns = [
    columnHelper.accessor("orderNumber", {
      cell: ({ row }) => <Text>{`#${row.original.orderNumber}`}</Text>,
      header: t("orders.id"),
      size: 10,
    }),
    columnHelper.accessor((row) => `${row.suborderNumbers?.join(",") ?? ""}`, {
      cell: ({ row }) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (row.original.suborderNumbers) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return row.original.suborderNumbers.map((orderNumber) => (
            <Text key={orderNumber}>#{orderNumber}</Text>
          ));
        }
        return null;
      },
      header: "Suborder ID",
      size: 10,
    }),
    columnHelper.accessor((row) => `${row.brandStoreNames?.join(",") ?? ""}`, {
      cell: ({ row }) => {
        if (row.original.brandStoreNames) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return row.original.brandStoreNames.map((storeName) => (
            <Text key={storeName}>{storeName}</Text>
          ));
        }
      },
      header: "Brand",
      size: 10,
    }),
    columnHelper.accessor("retailerName", {
      cell: ({ row }) => <Text>{row.original.retailerName}</Text>,
      header: t("orders.retailer_name"),
      size: 10,
    }),
    columnHelper.accessor("retailerStoreName", {
      cell: ({ row }) => {
        return <Text>{row.original.retailerStoreName}</Text>;
      },
      header: "Retailer Store",
      size: 10,
    }),
    columnHelper.accessor(
      (row) => `${capitalizeFirstLetter(row.retailerBrStatus)}`,
      {
        cell: ({ row }) => {
          return (
            <Text>{capitalizeFirstLetter(row.original.retailerBrStatus)}</Text>
          );
        },
        header: "BR Status",
        size: 10,
      },
    ),
    columnHelper.accessor("deliveryCountry", {
      cell: ({ row }) => <Text>{row.original.deliveryCountry}</Text>,
      header: t("orders.country"),
      size: 10,
    }),
    ...dateColumn,
    // Cast price to string for global filter to work
    columnHelper.accessor((row) => formatPrice(row.currency, row.total), {
      cell: ({ row }) => (
        <Text>{formatPrice(row.original.currency, row.original.total)}</Text>
      ),
      header: t("orders.price"),
      size: 10,
    }),
    columnHelper.accessor("status", {
      cell: (row) => (
        <Status
          variant={convertToStatusVariant(row.getValue())}
          text={capitalizeFirstLetter(row.getValue())}
        />
      ),
      header: t("orders.status"),
      size: 10,
    }),
  ];

  const handleExportClick = async () => downloadCSV(await exportOrderHistory());

  const handleSearchBar = () => {
    setGlobalFilter(inputRef.current.value);
  };

  if (isLoading) {
    return <Spinner position="absolute" top="50%" left="50%" />;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getRowHref = (row: Row<any>) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return `/orders/${row.original.id as string}`;
  };

  return (
    <Flex display="column" width="100%">
      <Flex
        width="100%"
        justify="space-between"
        align="center"
        paddingRight={4}
      >
        <Input
          width="xs"
          mb={5}
          mr={5}
          mt="20px"
          placeholder={t("orders.search")}
          onChange={DEBOUNCED_SEARCH(handleSearchBar, 500)}
          ref={inputRef}
        />
        {isHistory && (
          <Button
            leftIcon={<LightDocument />}
            /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
            onClick={handleExportClick}
          >
            {t("orders.export")}
          </Button>
        )}
      </Flex>
      <TableWrapper
        getRowHref={getRowHref}
        tableData={orders}
        columns={columns}
        initialSort={initialSort}
        globalFilter={globalFilter}
      />
    </Flex>
  );
};
