import { IconProps, TooltipProps } from "@chakra-ui/react";

export const tooltipStyle: TooltipProps = {
  placement: "right",
  bg: "grey.900",
  textStyle: "bodyXS",
  fontWeight: "normal",
  paddingX: 3,
  paddingY: 2,
  borderRadius: "lg",
  children: undefined,
};

export const iconStyle: IconProps = {
  color: "grey.600",
  marginLeft: 1,
};
