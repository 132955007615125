/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { usePostSuborderNote } from "./usePostSuborderNote";
import { useSimpleToast } from "hooks/useSimpleToast";
import { ErrorResponse } from "services/ErrorResponse";
import { getErrorCodes } from "services/httpClient";

export type AddSuborderNoteFields = {
  subOrderId: string;
  note: string;
};

export type NoteModalFormProps = {
  onClose: () => void;
  suborderId: string;
  notes?: string;
  onAfterSuccess: () => Promise<void>;
  suborderNumber: string;
};

export const useAddNoteModalForm = ({
  onClose,
  suborderId,
  notes,
  onAfterSuccess,
  suborderNumber,
}: NoteModalFormProps) => {
  const {
    control,
    register,
    getValues,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm<AddSuborderNoteFields>({
    defaultValues: {
      subOrderId: suborderId,
      note: notes,
    },
  });

  const { t } = useTranslation();

  const toast = useSimpleToast();

  const onSuccess = async () => {
    await onAfterSuccess();
    toast({
      position: "top",
      description: t("order.add_note_success", { suborderId: suborderNumber }),
    });
    onClose();
    return;
  };

  const onError = (error: ErrorResponse) => {
    const errorCodes = getErrorCodes(error);
    toast({
      status: "error",
      position: "top",
      description: t(errorCodes[0]),
    });
  };

  const { mutateAsync: postSuborderNote } = usePostSuborderNote(
    onSuccess,
    onError,
  );

  const onSubmit = handleSubmit(async () => {
    await postSuborderNote(getValues());
  });

  return {
    control,
    register,
    onSubmit,
    getValues,
    reset,
    errors,
    isDirty,
    isValid,
  };
};
