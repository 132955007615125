import { UseQueryResult, useQuery } from "@tanstack/react-query";

import httpClient from "services/httpClient";

export const GET_ALL_CATEGORIES = "/products/categories?isFlat=true?level=2";

export type CategoryType = {
  id: string;
  name: string;
  nameEn: string;
  parentId: string | null;
  key: string;
  subCategories: CategoryType[];
};

const GET_ALL_CATEGORIES_FOR_EXPORT_PRODUCT = "/products/categories";

export const useGetAllCategories = (): UseQueryResult<CategoryType[]> =>
  useQuery({
    queryKey: [GET_ALL_CATEGORIES_FOR_EXPORT_PRODUCT],
    queryFn: async () => {
      const { data: response } = await httpClient.get<CategoryType[]>(
        "/products/categories",
      );
      return response;
    },
  });
