import { InputProps, ResponsiveValue } from "@chakra-ui/react";
import { Locale } from "date-fns";
import { Control, UseControllerProps, useController } from "react-hook-form";

import { CalendarProps } from "components/Calendar/Calendar";
import { FieldWrapper } from "components/FieldWrapper/FieldWrapper";
import { SingleDatePicker } from "components/SingleDatePicker/SingleDatePicker";

export type SingleDatePickerProps = {
  label?: string;
  info?: string;
  inputLocale?: Locale;
  inputFormat?: string;
  placeholder?: string;
  name: string;
  size?: ResponsiveValue<string>;
  disabled?: boolean;
  control?: Control;
  readOnly?: boolean;
  isInitiallyOpen?: boolean;
  isRequired?: boolean;
  inputProps?: InputProps;
  inputDefaultVal?: Date;
  height?: number | string;
  disableExcludedTagNames?: boolean;
} & CalendarProps &
  UseControllerProps;

export const SingleDatePickerField = ({
  label,
  info,
  placeholder,
  name,
  size,
  inputLocale,
  inputFormat,
  disabled,
  control,
  readOnly,
  rules,
  inputDefaultVal,
  isRequired,
  isInitiallyOpen,
  inputProps,
  height,
  disableExcludedTagNames,
  ...rest
}: SingleDatePickerProps) => {
  const {
    // issue: https://github.com/react-hook-form/react-hook-form/issues/7577
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    field: { value, onChange },
    formState: { errors },
  } = useController({
    name,
    control,
    rules,
    defaultValue: inputDefaultVal,
  });

  return (
    <FieldWrapper
      name={name}
      label={label}
      info={info}
      errors={errors}
      isRequired={isRequired}
    >
      <SingleDatePicker
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        value={value}
        onValueChange={onChange}
        inputLocale={inputLocale}
        inputFormat={inputFormat}
        isInvalidField={!!errors[name]}
        isInitiallyOpen={isInitiallyOpen}
        disabled={disabled}
        inputProps={inputProps}
        height={height}
        disableExcludedTagNames={disableExcludedTagNames}
        {...rest}
      />
    </FieldWrapper>
  );
};
