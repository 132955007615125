import { Box, Text } from "@chakra-ui/react";
import { format, parseISO } from "date-fns";

type NoteProps = {
  note: NoteDetails;
};

type NoteDetails = {
  content: string;
  createdBy: string;
  creationDate: string;
  isRichText?: boolean;
};

export const Note = ({ note }: NoteProps) => {
  const { content, createdBy, creationDate, isRichText } = note;

  return (
    <Box mb={5}>
      <Text fontSize="md" color="gray.700">
        {createdBy}
      </Text>
      {isRichText ? (
        <Text
          my={2}
          p={3}
          border="1px solid"
          borderColor="grey.200"
          fontSize="md"
          dangerouslySetInnerHTML={{ __html: content }}
          style={{ whiteSpace: "pre-line" }}
        ></Text>
      ) : (
        <Text
          my={2}
          p={3}
          border="1px solid"
          borderColor="grey.200"
          fontSize="md"
        >
          {content}
        </Text>
      )}
      <Text textAlign="right" color="grey.400" fontSize="xs">
        {`${format(parseISO(creationDate), "dd-MM-yyyy")} at ${format(
          parseISO(creationDate),
          "HH:mm",
        )}`}
      </Text>
    </Box>
  );
};
