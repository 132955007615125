/* eslint-disable @tanstack/query/exhaustive-deps */
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { RETURN_REQUEST_BY_ORDER_KEY } from "services/ReactQueryKeys";
import httpClient from "services/httpClient";

export type ReturnRequest = {
  id: string;
  subOrderNumber: string;
  subOrderId: string;
  orderId: string;
  retailerName: string;
  retailerId: string;
  brandName: string;
  brandId: string;
  requestDate: string;
  status: string;
};

export const GET_REQUESTS_BY_ORDER = "/admin/return-requests/order";

export const useGetRequestsByOrder = (
  orderId: string,
): UseQueryResult<ReturnRequest[]> =>
  useQuery({
    queryKey: [RETURN_REQUEST_BY_ORDER_KEY, orderId],
    queryFn: async () => {
      const { data: requests } = await httpClient.get<ReturnRequest[]>(
        `${GET_REQUESTS_BY_ORDER}/${orderId}`,
      );
      return requests;
    },
  });
