import { Box } from "@chakra-ui/react";
import { ProtectedRoute } from "ProtectedRoute";
import { Navigate, Route, Routes } from "react-router-dom";

import { BrandRequests } from "./features/BrandRequests";
import { RetailerRequests } from "./features/RetailerRequests";
import { Banners } from "features/Banners/Banners";
import { BrandPageUpdateRequestOverview } from "features/BrandRequests/BrandPageUpdateRequestOverview/BrandPageUpdateRequestOverview";
import { BrandRequestOverview } from "features/BrandRequests/BrandRequestOverview/BrandRequestOverview";
import { Chatbot } from "features/Chatbot/Chatbot";
import { FinancialBreakdown } from "features/FinancialBreakdown/FinancialBreakdown";
import { Layout } from "features/Layout";
import { Login } from "features/Login";
import { Orders } from "features/Orders";
import { SingleOrder } from "features/Orders/SingleOrder";
import { PayoutDetails } from "features/Payouts/PayoutDetails/PayoutDetails";
import { Payouts } from "features/Payouts/Payouts";
import { Pdfs } from "features/Pdfs/Pdfs";
import { Promotions } from "features/Promotions";
import { RetailerRequestOverview } from "features/RetailerRequests/RetailerRequestOverview/RetailerRequestOverview";
import { ReturnRequestDetails } from "features/ReturnRequests/ReturnRequestDetails/ReturnRequestDetails";
import { ReturnRequests } from "features/ReturnRequests/ReturnRequests";
import { Rewards } from "features/Rewards";
import { Settings } from "features/Settings";
import { TechSupport } from "features/TechSupport/TechSupport";
import { UserManagement } from "features/UserManagement";
import { EditAdminOverview } from "features/UserManagement/Admins/EditAdmin/EditAdminOverview";
import { BrandOverview } from "features/UserManagement/Brands/BrandOverview";
import { BnplApplicationHistory } from "features/UserManagement/Retailers/BnplApplicationHistory";
import { RetailerOverview } from "features/UserManagement/Retailers/RetailerOverview";
import { Vouchers } from "features/Vouchers";
import "i18n";
import { setSessionId } from "services/httpClient";

function App() {
  setSessionId();
  return (
    <Box className="App">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/financial-breakdown/:orderId"
          element={
            <ProtectedRoute>
              <FinancialBreakdown />
            </ProtectedRoute>
          }
        />
        <Route
          path="/bnpl-application-history/:retailerId"
          element={
            <ProtectedRoute>
              <BnplApplicationHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          }
        >
          <Route path="/" element={<Navigate to="/user-management" />} />
          <Route
            path="/user-management"
            element={
              <ProtectedRoute>
                <UserManagement />
              </ProtectedRoute>
            }
          />
          <Route
            path="/user-management/admin/:adminId"
            element={
              <ProtectedRoute>
                <EditAdminOverview />
              </ProtectedRoute>
            }
          />
          <Route
            path="/user-management/brand/:brandId"
            element={
              <ProtectedRoute>
                <BrandOverview />
              </ProtectedRoute>
            }
          />
          <Route
            path="/user-management/retailer/:retailerId"
            element={
              <ProtectedRoute>
                <RetailerOverview />
              </ProtectedRoute>
            }
          />
          <Route
            path="/brand-requests"
            element={
              <ProtectedRoute>
                <BrandRequests />
              </ProtectedRoute>
            }
          />
          <Route
            path="/brand-requests/:brandRequestId"
            element={
              <ProtectedRoute>
                <BrandRequestOverview />
              </ProtectedRoute>
            }
          />
          <Route
            path="/brand-requests/page-update/:pageUpdateRequestId"
            element={
              <ProtectedRoute>
                <BrandPageUpdateRequestOverview />
              </ProtectedRoute>
            }
          />
          <Route
            path="/retailer-requests"
            element={
              <ProtectedRoute>
                <RetailerRequests />
              </ProtectedRoute>
            }
          />
          <Route
            path="/retailer-requests/:id"
            element={
              <ProtectedRoute>
                <RetailerRequestOverview />
              </ProtectedRoute>
            }
          />
          <Route
            path="/return-requests"
            element={
              <ProtectedRoute>
                <ReturnRequests />
              </ProtectedRoute>
            }
          />
          <Route
            path="/return-requests/:orderId"
            element={
              <ProtectedRoute>
                <ReturnRequestDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders"
            element={
              <ProtectedRoute>
                <Orders />
              </ProtectedRoute>
            }
          />
          <Route
            path="/promotions"
            element={
              <ProtectedRoute>
                <Promotions />
              </ProtectedRoute>
            }
          />
          <Route
            path="/rewards"
            element={
              <ProtectedRoute>
                <Rewards />
              </ProtectedRoute>
            }
          />
          <Route
            path="/vouchers"
            element={
              <ProtectedRoute>
                <Vouchers />
              </ProtectedRoute>
            }
          />
          <Route
            path="/banners"
            element={
              <ProtectedRoute>
                <Banners />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pdfs"
            element={
              <ProtectedRoute>
                <Pdfs />
              </ProtectedRoute>
            }
          />
          <Route
            path="/chatbot"
            element={
              <ProtectedRoute>
                <Chatbot />
              </ProtectedRoute>
            }
          />
          <Route
            path="/payouts"
            element={
              <ProtectedRoute>
                <Payouts />
              </ProtectedRoute>
            }
          />
          <Route
            path="/payouts/:payoutId"
            element={
              <ProtectedRoute>
                <PayoutDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders/:orderId"
            element={
              <ProtectedRoute>
                <SingleOrder />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/tech_support"
            element={
              <ProtectedRoute>
                <TechSupport />
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
    </Box>
  );
}

export default App;
