import { Button, Flex, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { SubmitHandler, useForm } from "react-hook-form";

import { InputField } from "../../components/InputField/InputField";
import { PanelForm } from "../../components/PanelForm/PanelForm";
import { ErrorResponse } from "../../services/ErrorResponse";
import httpClient from "../../services/httpClient";

type Inputs = {
  email: string;
};

export const BypassKycForm = () => {
  // API call
  const REQUEST_URL = "/admin/support/bypass-kyc";

  const postRequest = async (form: Inputs) => {
    return await httpClient.post(REQUEST_URL, {
      email: form.email,
    });
  };

  const usePostRequest = (
    onSuccess: () => void,
    onError: (data: ErrorResponse) => void,
  ) =>
    useMutation(postRequest, {
      onSuccess,
      onError,
    });

  // Toast
  const toast = useToast();

  const onSuccess = () => {
    toast({
      position: "top",
      status: "success",
      description: `Success`,
    });
  };

  const onError = (error: ErrorResponse) => {
    toast({
      position: "top",
      status: "error",
      description: `Failed: ${error.response.data.code[0]}`,
    });
  };

  // Combine toast + api call
  const { mutateAsync, isLoading: isMutating } = usePostRequest(
    onSuccess,
    onError,
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    console.log("data", data);
    void mutateAsync(getValues());
  };

  return (
    <PanelForm title={"Bypass Kyc"}>
      <Flex
        justifyContent="space-between"
        borderRadius="4px 4px 0px 0px"
        textStyle="bodyS"
        fontWeight="medium"
        pt={2.5}
        gap={5}
        flexDir={"column"}
      >
        <InputField
          w={350}
          {...register("email")}
          label={"Brand email"}
          errors={errors}
        />
        <Flex w={250}>
          <Button
            size={"lg"}
            disabled={isMutating}
            onClick={() => void handleSubmit(onSubmit)()}
          >
            Submit
          </Button>
        </Flex>
      </Flex>
    </PanelForm>
  );
};
