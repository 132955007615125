/* eslint-disable @typescript-eslint/no-misused-promises */
import { Flex, useDisclosure } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { useChangeRetailerEmailForm } from "./hooks/useChangeRetailerEmailForm";
import { Button } from "components/Button/Button";
import { InputField } from "components/InputField/InputField";
import { ModalWrapper } from "components/Modal/ModalWrapper";
import { PanelForm } from "components/PanelForm/PanelForm";

type RetailerSettingsProps = {
  retailerId: string;
};

export const RetailerSettings = ({ retailerId }: RetailerSettingsProps) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { register, errors, onSubmit } = useChangeRetailerEmailForm({
    retailerId,
    onClose,
  });

  const requiredMessage = "This field is required";
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const emailMismatchPattern = "Please input a valid email address";

  return (
    <>
      <Flex width={"100%"} padding={8} gap={5} flexDir={"column"}>
        <PanelForm title={t("retailer_settings.change_email")}>
          <Flex mt={2} mb={5} width={"496px"}>
            <InputField
              errors={errors}
              type="email"
              label={t("retailer_settings.new_email")}
              placeholder={t("retailer_settings.create_email")}
              {...register("newEmail", {
                required: requiredMessage,
                pattern: {
                  value: emailRegex,
                  message: emailMismatchPattern,
                },
              })}
            />
          </Flex>
          <Flex width={"496px"}>
            <InputField
              errors={errors}
              type="email"
              label={t("retailer_settings.confirm_new_email")}
              placeholder={t("retailer_settings.repeat_email")}
              {...register("confirmEmail", {
                required: requiredMessage,
                pattern: {
                  value: emailRegex,
                  message: emailMismatchPattern,
                },
              })}
            />
          </Flex>
          <Button size="lg" w="fit-content" mt={5} onClick={onOpen}>
            {t("retailer_settings.change_email")}
          </Button>
        </PanelForm>
      </Flex>
      <ModalWrapper
        modalHeader={t("retailer_settings.change_email")}
        modalBody={
          <Flex gap={5}>
            <Button w={100} onClick={onSubmit}>
              {t("confirm")}
            </Button>
            <Button w={100} variant={"outline"} onClick={onClose}>
              {t("cancel")}
            </Button>
          </Flex>
        }
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};
