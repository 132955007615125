import { useForm } from "react-hook-form";

import { usePostConfirmForgotPassword } from "./usePostConfirmForgotPassword";

export type PostForgotPasswordForm = {
  email: string;
  confirmationCode: string;
  newPassword: string;
  confirmPassword: string;
};

type ConfirmForgotPassword = {
  username: string;
  code: string;
  onSuccess: () => void;
  onError: (error: string[]) => void;
};

export const useConfirmForgotPassword = ({
  username,
  code,
  onSuccess,
  onError,
}: ConfirmForgotPassword) => {
  const {
    control,
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors, isValid },
  } = useForm<PostForgotPasswordForm>({
    mode: "onChange",
    defaultValues: { confirmationCode: code },
  });

  const { mutateAsync: postConfirmForgotPassword, isLoading } =
    usePostConfirmForgotPassword(onSuccess, onError);

  const onSubmit = handleSubmit(
    async () =>
      await postConfirmForgotPassword({
        email: username,
        confirmationCode: getValues().confirmationCode,
        newPassword: getValues().newPassword,
        confirmPassword: getValues().confirmPassword,
      }),
  );

  return {
    control,
    register,
    watch,
    errors,
    onSubmit,
    isValid,
    isLoading,
  };
};
