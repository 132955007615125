import { UseQueryResult, useQuery } from "@tanstack/react-query";

import httpClient from "services/httpClient";

export const GET_FINANCIAL_DETAILS = "/admin/orders/";

export type TransactionTerm = {
  creationDate: string;
  expirationDate?: string;
  currency: string;
  payIn: string;
  payOut: string;
  type: string;
  transactionReference: string;
};
export type Transaction = {
  amount: string;
  creationDate: string;
  expirationDate?: string;
  currency: string;
  isResolved: boolean;
  type: string;
  transactionReference?: string;
};

export type FinancialDetailsType = {
  paymentTermTransactions: TransactionTerm[];
  transactions: Transaction[];
  outstanding: number | null;
};

export const useGetFinancialDetails = (
  id: string,
): UseQueryResult<FinancialDetailsType> =>
  useQuery({
    queryKey: [GET_FINANCIAL_DETAILS, id],
    queryFn: async () => {
      const { data: financials } = await httpClient.get<FinancialDetailsType>(
        `${GET_FINANCIAL_DETAILS}${id}/transactions`,
      );
      return financials;
    },
  });
