/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Button, Divider, Flex, Spinner, Text } from "@chakra-ui/react";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { SubOrder } from "../hooks/useGetOrderById";
import { useGetSchedulePickup } from "../hooks/useGetSchedulePickup";
import { useSchedulePickupForm } from "../hooks/useSchedulePickupForm";
import { AutocompleteField } from "components/AutocompleteField/AutocompleteField";
import { ModalWrapper } from "components/Modal/ModalWrapper";

export type PickupOption = {
  value: string;
  label: string;
};

type PickupModalProps = {
  subOrder: SubOrder;
  isOpen: boolean;
  onClose: () => void;
};

export const PickupModal = ({
  isOpen,
  onClose,
  subOrder,
}: PickupModalProps) => {
  const { t } = useTranslation();

  const { data: pickups, isLoading } = useGetSchedulePickup(subOrder.id);

  const { control, onSubmit, isFormValid, reset, dateOptions, timeOptions } =
    useSchedulePickupForm({
      onClose,
      suborderId: subOrder.id,
      dateSlots: pickups?.dateSlots ?? [],
    });

  return (
    <ModalWrapper
      modalProps={{ size: "l", isOpen, onClose, children: undefined }}
      isOpen={isOpen}
      onClose={onClose}
      modalHeader={
        <Flex flexDir="column" textStyle="h3" fontWeight="bold">
          <Text>{t("order.schedule_pickup")}</Text>
          <Text>{`#${subOrder.orderNumber}`}</Text>
        </Flex>
      }
      modalBody={
        <Flex flexDir="column" textStyle="bodyS">
          {isLoading ? (
            <Spinner position="absolute" top="50%" left="50%" />
          ) : (
            <>
              <Flex>
                <Text color="#9999A0">Ship from: </Text>
                <Text ml={1}>
                  {`${pickups?.fromAddress.firstAddress}, ${pickups?.fromAddress.country}`}
                </Text>
              </Flex>
              <Flex>
                <Text color="#9999A0">Ship to: </Text>
                <Text ml={1}>
                  {`${pickups?.toAddress.firstAddress}, ${pickups?.toAddress.country}`}
                </Text>
              </Flex>
              <Divider my={5} />
              <Text textStyle="bodyS" mb={5}>
                {t("order.select_shipment_dates")}
              </Text>
              <Flex gap={5}>
                <AutocompleteField
                  label="Select Pickup Date"
                  placeholder="Select Available Date"
                  options={dateOptions}
                  closeMenuOnSelect
                  name="pickupDate"
                  control={control as unknown as Control}
                  rules={{ required: "This field is required" }}
                  w="2xs"
                />
                <AutocompleteField
                  label="Select Pickup Time"
                  placeholder="Select Available Time"
                  options={timeOptions}
                  closeMenuOnSelect
                  name="pickupTime"
                  control={control as unknown as Control}
                  rules={{ required: "This field is required" }}
                  w="2xs"
                />
              </Flex>
            </>
          )}
        </Flex>
      }
      modalFooter={
        <>
          <Button
            variant="outline"
            fontWeight="600"
            size="lg"
            onClick={() => {
              onClose();
              reset();
            }}
            data-test-id="cancelSchedulePickup"
          >
            {t("Cancel")}
          </Button>
          <Button
            type="submit"
            form="addAdmin"
            variant="solid"
            size="lg"
            ml={3}
            data-test-id="schedulePickup"
            disabled={isFormValid}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={onSubmit}
          >
            {t("order.schedule_pickup")}
          </Button>
        </>
      }
    />
  );
};
