// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const downloadFile = (data: any, type: string) => {
  const blob = new Blob([data], { type: type });

  // Create a URL for the Blob
  const url = window.URL.createObjectURL(blob);

  // Create an anchor element and set its href attribute to the Blob URL
  const a = document.createElement("a");
  a.href = url;
  a.download = "data.csv"; // Set the desired file name

  // Append the anchor to the document body and programmatically click it to trigger the download
  document.body.appendChild(a);
  a.click();

  // Clean up by removing the anchor element and revoking the Blob URL
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};
