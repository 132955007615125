import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import { KEYWORDS } from "../utils/constansts";
import { useGetAllBrands } from "./useGetAllBrands";
import { useGetAllCategories } from "./useGetAllCategories";
import { usePostProductJSON } from "./usePostExportJSONProducts";
import { usePostProductExport } from "./usePostExportProducts";
import { Option } from "components/Select/Select";

export type ExportProductFormFields = {
  brands: Option[];
  keywords: Option[];
  categories: Option[];
  minWholesalePrice?: number;
  maxWholesalePrice?: number;
  minRetailPrice?: number;
  maxRetailPrice?: number;
  margin?: number;
};

export type ExportProductReturnType = {
  data: {
    fileString: string;
    fileName: string;
  };
};

export type ExportedProductsType = {
  id: string;
  bestSeller: boolean;
  brand: string;
  brandCurrency: string;
  description: string;
  key: string;
  keywords: string[];
  link: string;
  name: string;
  retailPrice: number;
  wholesalePrice: number;
  originalKeywords: number[];
  matchingKeywords: number;
  margin?: number;
  image: string;
};

export type ExportProductJSONReturnType = {
  products: ExportedProductsType[];
};

export const usePostExportProductsForm = () => {
  const [tableData, setTableData] = useState<ExportedProductsType[]>([]);
  const { data: allBrands, isLoading: getAllBrandsLoading } = useGetAllBrands();
  const { data: allCategories, isLoading: getAllCategoriesLoading } =
    useGetAllCategories();

  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const keywordOptions = useMemo(() => {
    return KEYWORDS.map((keyword) => ({ label: keyword, value: keyword }));
  }, []);

  const categoriesOptions = useMemo(() => {
    if (!allCategories) return [];
    const l1_cat = allCategories.map((cat) => ({
      label: cat.name,
      value: cat.id,
    }));
    const l2_cat = allCategories.map((cat) => {
      return cat.subCategories.map((subCat) => ({
        label: subCat.name,
        value: subCat.id,
      }));
    });
    return [...l1_cat, ...l2_cat.flat()];
  }, [allCategories]);

  const { register, control, getValues, handleSubmit, setValue } =
    useForm<ExportProductFormFields>({
      mode: "onChange",
      defaultValues: {
        brands: [],
        keywords: [],
        categories: [],
        margin: 0,
      },
    });

  const onSuccess = (response: ExportProductReturnType) => {
    const { fileString, fileName } = response.data;
    const binaryString = window.atob(fileString);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([bytes], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
    setExportLoading(false);
  };

  const onJSONSuccess = (response: ExportProductJSONReturnType) => {
    setExportLoading(false);
    setTableData(response.products);
  };

  const { mutateAsync: postExportProducts } = usePostProductExport(
    onSuccess,
    () => setExportLoading(false),
  );

  const { mutateAsync: postProductJSON } = usePostProductJSON(
    onJSONSuccess,
    () => setExportLoading(false),
  );
  const onSubmit = handleSubmit(() => {
    setExportLoading(true);
    void postExportProducts(getValues());
  });

  const onSubmitJSON = handleSubmit(() => {
    setExportLoading(true);
    void postProductJSON(getValues());
  });

  return {
    allBrands,
    categoriesOptions,
    getAllCategoriesLoading,
    getAllBrandsLoading,
    keywordOptions,
    register,
    control,
    getValues,
    onSubmit,
    exportLoading,
    onSubmitJSON,
    tableData,
    setValue,
  };
};
