/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { API } from "@aws-amplify/api";
import jwtDecode from "jwt-decode";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { GetUserStoreQuery, UserStore } from "../API";
import { getUserStore } from "../graphql/queries";
import { useEnvironmentConfig } from "./useEnvironmentConfig";
import { AccessToken } from "services/httpClient";
import createSafeContext from "utils/context";

type UserInfo = {
  firstName: string;
  lastName: string;
  country: string;
  countryId: string;
  email: string;
  productPreferences: string[];
};

export type DynamoDbUser = {
  cognitoUserId: string;
  info: UserInfo;
};
export type DynamoDbUserContextType = {
  dynamoDbUser?: DynamoDbUser;
  getUserFromDynamo: () => void;
  unsetDynamoDbUser: () => void;
};

const DynamoDbUserContext = createSafeContext<DynamoDbUserContextType>();

export const useDynamoDbUserContext: () => DynamoDbUserContextType =
  DynamoDbUserContext.hook;

const DynamoDbUserContextProvider = ({ children }: { children: ReactNode }) => {
  const { awsConfig } = useEnvironmentConfig();

  API.configure(awsConfig);
  const [dynamoDbUser, setDynamoDbUser] = useState<DynamoDbUser>();

  const getUserStoreData = async (cognitoUserId: string) => {
    const response = (await API.graphql({
      query: getUserStore,
      variables: {
        cognitoUserId: cognitoUserId,
      },
    })) as { data: GetUserStoreQuery };
    return response.data.getUserStore as UserStore;
  };

  const parseGraphqlResponse = (response?: UserStore) => {
    if (response?.DOC) {
      const userInfoJson = JSON.parse(response.DOC) as UserInfo;
      setDynamoDbUser({
        cognitoUserId: response.cognitoUserId,
        info: userInfoJson,
      });
    }
  };

  const getUserFromDynamo = useCallback(() => {
    const accessToken = localStorage.getItem("access-token");

    if (accessToken) {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      const decodedToken = jwtDecode(accessToken) as AccessToken;

      void getUserStoreData(decodedToken.username).then((result) =>
        parseGraphqlResponse(result),
      );
    }
  }, []);

  useEffect(() => {
    getUserFromDynamo();
  }, [getUserFromDynamo]);

  const unsetDynamoDbUser = () => {
    setDynamoDbUser(undefined);
  };

  const value = useMemo<DynamoDbUserContextType>(
    () => ({ dynamoDbUser, getUserFromDynamo, unsetDynamoDbUser }),
    [dynamoDbUser, getUserFromDynamo],
  );

  return (
    <DynamoDbUserContext.Provider value={value}>
      {children}
    </DynamoDbUserContext.Provider>
  );
};

export default DynamoDbUserContextProvider;
