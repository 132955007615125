import {
  As,
  Flex,
  FlexProps,
  Icon,
  IconProps,
  Text,
  TextProps,
} from "@chakra-ui/react";

export type TextWithIconProps = {
  icon: As;
  text: string;
  textProps?: TextProps;
  iconProps?: IconProps;
  flexProps?: FlexProps;
};
export const TextWithIcon = ({
  icon,
  text,
  flexProps,
  iconProps,
  textProps,
}: TextWithIconProps) => {
  return (
    <Flex mr={7} {...flexProps}>
      <Icon as={icon} w={4.5} h={4.5} mr={2} {...iconProps} />
      <Text textStyle="bodyS" fontWeight="medium" {...textProps}>
        {text}
      </Text>
    </Flex>
  );
};
