import { useMutation } from "@tanstack/react-query";

import httpClient from "services/httpClient";
import { queryClient } from "services/queryClient";

export const ADMIN_ORDERS = "/admin/orders/";

export type RejectOrderFormFields = {
  id: string;
  reason: string;
};

const putCancelOrder = async (form: RejectOrderFormFields) => {
  return await httpClient.put(`${ADMIN_ORDERS}${form.id}/cancel`, {
    rejectReason: form.reason,
  });
};

export const usePutCancelOrder = () =>
  useMutation(putCancelOrder, {
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [ADMIN_ORDERS],
      });
    },
  });
