import { tableAnatomy as parts } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";

// eslint-disable-next-line @typescript-eslint/unbound-method
const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  table: {
    fontVariantNumeric: "lining-nums tabular-nums",
    borderCollapse: "collapse",
    width: "full",
  },
  th: {
    fontFamily: "heading",
    fontWeight: "semibold",
    textTransform: "uppercase",
    letterSpacing: "wider",
    textAlign: "start",
    cursor: "pointer",
    borderRadius: "8px 8px 0px 0px",
    bg: "light.lightGrey",
    span: {
      display: "flex",
      alignItems: "center",
      marginLeft: 0.5,
    },
  },
  td: {
    textAlign: "start",
  },
  tbody: {
    tr: {
      _hover: {
        bg: "greyAlpha.50",
      },
    },
  },
  caption: {
    mt: 4,
    fontFamily: "heading",
    textAlign: "center",
    fontWeight: "medium",
  },
});

const variantSimple = definePartsStyle((props) => {
  return {
    table: {
      display: "inline-table",
      border: "1px solid",
      borderColor: "grey.400",
      borderRadius: "base",
    },
    th: {
      color: "grey.900",
      borderBottom: "1px",
      borderColor: "grey.400",
      textTransform: "none",
      fontSize: "md",
      lineHeight: "taller",
      letterSpacing: "tighter",
      height: 8,
      py: 1.5,
      _first: {
        pl: 7,
      },
    },
    td: {
      borderBottom: "1px",
      borderColor: "grey.400",
      fontSize: "md",
      lineHeight: "taller",
      letterSpacing: "tighter",
      _first: {
        pl: 7,
      },
    },
  };
});

const variantOrder = definePartsStyle((props) => {
  return {
    table: {
      display: "inline-block",
      border: "1px solid",
      borderColor: "grey.400",
      borderRadius: "base",
    },
    th: {
      borderRadius: "base",
      color: "grey.900",
      borderBottom: "1px",
      borderColor: "grey.400",
      textTransform: "none",
      fontSize: "md",
      lineHeight: "taller",
      letterSpacing: "tighter",
      bgColor: "grey.200",
      height: 8,
      py: 1.5,
      _first: {
        pl: 7,
      },
    },
    td: {
      borderColor: "grey.400",
      fontSize: "md",
      lineHeight: "taller",
      letterSpacing: "tighter",
      _first: {
        pl: 7,
      },
    },

    tbody: {
      tr: {
        _hover: {
          bg: "white",
        },
      },
    },
    tfoot: {
      td: {
        p: 0,
      },
      tr: {
        _odd: {
          bg: "light.lightGrey",
        },
      },
    },
  };
});

const variants = {
  simple: variantSimple,
  order: variantOrder,
  unstyled: defineStyle({}),
};

const sizes = {
  sm: definePartsStyle({
    td: {
      px: 4,
      py: 2.5,
      h: 10,
    },
  }),
  md: definePartsStyle({
    th: {
      px: 4,
    },
    td: {
      px: 4,
      py: 4,
      h: 15,
    },
  }),
  lg: definePartsStyle({
    th: {
      px: 4,
    },
    td: {
      px: 4,
      py: 5,
      h: 20,
    },
  }),
};

export const Table = defineMultiStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    variant: "simple",
    size: "lg",
    colorScheme: "grey",
  },
});
