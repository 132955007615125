import { Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { BrandRequestDetails } from "../types";
import { InfoDetails } from "components/InfoDetails/InfoDetails";

export type BrandDetailsProps = {
  brandRequestDetails: BrandRequestDetails;
};
export const BrandDetails = ({ brandRequestDetails }: BrandDetailsProps) => {
  const {
    name,
    email,
    website,
    country,
    phoneNumber,
    primaryCategory,
    wholesaleProducts,
    independentStores,
    hearFrom,
  } = brandRequestDetails;
  const { t } = useTranslation();

  return (
    <Flex
      mt={9}
      ml={10}
      flexDir="column"
      h="calc(100vh - 170px)"
      overflow="auto"
    >
      <InfoDetails
        heading="Contact Details"
        leftList={[
          t("brand_details.name"),
          t("brand_details.email"),
          t("brand_details.website"),
          t("brand_details.country"),
          t("brand_details.phone"),
        ]}
        rightList={[name, email, website, country, phoneNumber]}
        mb={6}
        leftTextProps={{ w: "30%" }}
      />

      <InfoDetails
        heading="Brand Details"
        leftList={[
          t("brand_details.category"),
          t("brand_details.wholesaleNr"),
          t("brand_details.storesNr"),
          t("brand_details.how"),
        ]}
        rightList={[
          primaryCategory ?? "-",
          wholesaleProducts,
          independentStores,
          hearFrom ?? "-",
        ]}
      />
    </Flex>
  );
};
