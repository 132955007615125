export type ProductData = {
  slug: {
    "en-US": string;
  };
  sku: skuType;
  attributes: never;
  images: string;
  name: {
    "en-US": string;
  };
  "Product Key": string;
  "Product Images": string;
  "Brand Name": string;
  "Product Name": string;
};

export type skuType = {
  prices: {
    msrp: string | null;
  };
  key: string;
};

type SuggestionProps = Omit<
  ProductData,
  "Product Images" | "Product Key" | "Brand Name" | "Product Name" | "slug"
>;

export const Suggestion = ({
  name,
  images,
  attributes,
  sku,
}: SuggestionProps) => {
  console.log(sku);
  return (
    <h6
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      key={JSON.stringify(name["en-US"] ?? "")}
      style={{ display: "flex", alignItems: "center", gap: "10px" }}
    >
      <a
        href={`https://www.markato.com/product/${sku.key}`}
        target="_blank"
        rel="noreferrer"
      >
        <img
          style={{ height: "50px" }}
          src={`https://www.markato.com/cdn-cgi/image/width=100,quality=60,format=auto/${images[0]}`}
          alt=""
        />
      </a>
      {attributes["brand-name"]}:{" "}
      <a
        href={`https://www.markato.com/product/${sku.key}`}
        target="_blank"
        rel="noreferrer"
      >
        {name["en-US"]} - {sku.prices.msrp}
      </a>
    </h6>
  );
};
