import { Box, Divider, Flex, Link, Text } from "@chakra-ui/react";
import { useDynamoDbUserContext } from "contexts/DynamoDbUserContext";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";

import { layoutWrapper } from "./Layout.style";
import { SidebarButton } from "./SidebarButton";
import { Logo } from "components/Logo/Logo";
import { usePostLogout } from "hooks/usePostLogout";
import { useToast } from "hooks/useToast";
import { ErrorResponse } from "services/ErrorResponse";
import { deleteAccessToken, getErrorCodes } from "services/httpClient";
import { RegularPackage } from "styles/icons/regular";
import {
  SolidDiscount,
  SolidDocument,
  SolidDolarSign,
  SolidDownload,
  SolidHelp,
  SolidLogout,
  SolidMessageText,
  SolidNote,
  SolidPayouts,
  SolidPeople,
  SolidSProductTag,
  SolidSettingsAlt,
  SolidShoppingBag,
} from "styles/icons/solid";

export const Layout = () => {
  const { t } = useTranslation();
  const { dynamoDbUser, unsetDynamoDbUser } = useDynamoDbUserContext();
  const toast = useToast();
  const navigate = useNavigate();

  const onSuccess = () => {
    deleteAccessToken();
    unsetDynamoDbUser();
    navigate("/login?source=logout");
  };

  const onError = (error: ErrorResponse) => {
    const errorCodes = getErrorCodes(error);
    toast({ status: "error", description: t(errorCodes[0]) });

    onSuccess();
  };
  const { mutateAsync: postLogout } = usePostLogout(onSuccess, onError);

  const handleLogout = async () => {
    await postLogout();
  };
  return (
    <Flex margin="auto" w="100%">
      <Flex
        {...layoutWrapper}
        className="hidePrint"
        style={{
          position: "sticky",
          top: 0,
          left: 0,
          overflow: "auto",
          minWidth: 210,
          backgroundColor: "white",
          zIndex: 1,
        }}
      >
        <Box>
          <Flex alignItems="center" mb="20px">
            <Logo width={93} ml={9} />
          </Flex>
          <Flex flexDirection="column">
            <SidebarButton
              text={t("User Management")}
              icon={SolidPeople}
              href="/user-management"
            />
            <SidebarButton
              text={t("Orders")}
              icon={SolidShoppingBag}
              href="/orders"
            />
            <SidebarButton
              text={t("payout.payouts")}
              icon={SolidPayouts}
              href="/payouts"
            />
            <Divider borderColor="#D6D6D6" my="4px" />
            <SidebarButton
              text={t("Brand Requests")}
              icon={SolidNote}
              href="/brand-requests"
            />
            <SidebarButton
              text={t("Retailer Requests")}
              icon={SolidDocument}
              href="/retailer-requests"
            />
            <SidebarButton
              text={t("return_requests.return_requests")}
              icon={RegularPackage}
              href="/return-requests"
            />
            <Divider borderColor="#D6D6D6" my="4px" />
            <SidebarButton
              text={t("promotions_link")}
              icon={SolidSProductTag}
              href="/promotions"
            />
            <SidebarButton
              text={t("Rewards")}
              icon={SolidDolarSign}
              href="/rewards"
            />
            <SidebarButton
              text={t("Vouchers")}
              icon={SolidDiscount}
              href="/vouchers"
            />
            <SidebarButton
              text={t("pdf.menu_title")}
              icon={SolidDownload}
              href="/pdfs"
            />
            <Link
              href="https://ai.markato.com"
              isExternal
              _hover={{ textDecoration: "none" }}
            >
              <SidebarButton
                text={t("chatbot.menu_title")}
                icon={SolidMessageText}
                href="#"
              />
            </Link>
            <Divider borderColor="#D6D6D6" my="4px" />
            <SidebarButton
              text={t("Settings")}
              icon={SolidSettingsAlt}
              href="/settings"
            />
            <SidebarButton
              text={t("Tech_Support")}
              icon={SolidHelp}
              href="/tech_support"
            />
            <Divider borderColor="#D6D6D6" my="4px" />
            <SidebarButton
              text={t("logout")}
              icon={SolidLogout}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={handleLogout}
            />
          </Flex>
        </Box>
        <Flex alignItems="center">
          <Text textStyle="bodyXS" fontWeight="medium" color="primary.500">
            {dynamoDbUser?.info.email}
          </Text>
        </Flex>
      </Flex>
      <Box
        width="100%"
        style={{
          backgroundColor: "white",
          zIndex: 2,
          borderLeft: "1px solid #ebebeb",
        }}
      >
        <Outlet />
      </Box>
    </Flex>
  );
};
