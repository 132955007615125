import {
  Divider,
  Flex,
  Heading,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { AddBankDetailsModal } from "./AddBankDetailsModal";
import { RetailerCountry } from "./RetailerCountry";
import { RetailerCountryMultiplier } from "./RetailerCountryMultiplier";
import { useGetMsrpCountryMultiplier } from "./hooks/useGetMSRPMultipliers";
import { useMultiplierForm } from "./hooks/useMultiplierForm";
import { useSettingsForm } from "./hooks/useSettingsForm";
import { Button } from "components/Button/Button";
import { InfoDetails } from "components/InfoDetails/InfoDetails";
import { InputField } from "components/InputField/InputField";
import { PanelForm } from "components/PanelForm/PanelForm";
import { useRetailerCountries } from "hooks/useGetRetailerCountries";

export const Settings = () => {
  const { t } = useTranslation();
  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();

  const {
    register,
    errors,
    onSubmit,
    isFormValid,
    setValue,
    getValues,
    watchCompanyName,
    isLoadingGet,
    isLoadingPost,
    fields,
  } = useSettingsForm();
  const { data: countries, isLoading: isLoadingCountries } =
    useRetailerCountries();

  const { data: multipliers } = useGetMsrpCountryMultiplier();

  const {
    register: multiplierRegister,
    errors: multiplierErrors,
    getValues: getMultiplierForm,
    multiplierFields,
    onSubmit: batchUpdateMultipliers,
    onSubmitIndividual: updateMultiplier,
    isDirty: multiplierFormChanged,
  } = useMultiplierForm({
    existingMultipliers: multipliers,
    retailerCountries: countries,
  });

  const requiredMessage = "This field is required";

  const bankDetailsExist = useMemo(() => {
    return getValues("companyName") !== "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues, watchCompanyName]);

  return (
    <Flex flexDir="column">
      <Heading as="h1" size="h1" fontWeight="bold" mb={7} mt={11} ml={9}>
        {t("Settings")}
      </Heading>
      <Button
        position="absolute"
        top={15}
        right={8}
        w={32}
        size="lg"
        disabled={isFormValid}
        isLoading={isLoadingPost}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={onSubmit}
      >
        {t("settings.save_changes")}
      </Button>
      <Divider />
      <Flex
        flexDir="column"
        padding={8}
        gap={5}
        width="100%"
        height="84vh"
        overflowY="scroll"
      >
        {isLoadingGet || isLoadingCountries ? (
          <Spinner position="absolute" top="50%" left="50%" />
        ) : (
          <>
            <PanelForm title={t("settings.country_specific_settings")}>
              <Text textStyle="bodyM" mb={1} mt={1}>
                {t("settings.first_heading")}
              </Text>
              <Text textStyle="bodyM" mb={5}>
                {t("settings.second_heading")}
              </Text>
              <Flex
                h={10}
                justifyContent="space-between"
                bg="grey.200"
                borderRadius="4px 4px 0px 0px"
                textStyle="bodyS"
                fontWeight="medium"
                py={2.5}
                pl={3}
                mb={2}
              >
                <Text w="200px">{t("settings.country_header")}</Text>
                <Text w="260px">
                  {t("settings.minimum_order_value_header")}
                </Text>
                <Text w="260px">{t("settings.wallet_credit_header")}</Text>
                <Text w="260px">{t("settings.bnpl_header")}</Text>
              </Flex>
              <Flex flexDir="column" gap={1}>
                {fields.map((field, index) => {
                  return (
                    <RetailerCountry
                      key={field.id}
                      field={field}
                      errors={errors}
                      getValues={getValues}
                      register={register}
                      index={index}
                      countryName={
                        countries?.find(
                          (item) => item.currencyCode === field.currencyCode,
                        )?.name ?? ""
                      }
                    />
                  );
                })}
              </Flex>
            </PanelForm>
            {multiplierFields.length && (
              <PanelForm title={t("settings.msrp_settings.heading")}>
                <Flex
                  h={10}
                  justifyContent="space-between"
                  borderRadius="4px 4px 0px 0px"
                  textStyle="bodyS"
                  fontWeight="medium"
                  py={2.5}
                  pl={3}
                  mb={8}
                >
                  <Text textStyle="bodyM" mb={5} mt={1}>
                    {t("settings.msrp_settings.subheading_1")}
                  </Text>
                  <Button
                    disabled={!multiplierFormChanged}
                    pb={3}
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={batchUpdateMultipliers}
                    size="lg"
                  >
                    {t("settings.msrp_settings.batch_update")}
                  </Button>
                </Flex>
                <Flex
                  h={10}
                  justifyContent="space-between"
                  bg="grey.200"
                  borderRadius="4px 4px 0px 0px"
                  textStyle="bodyS"
                  fontWeight="medium"
                  py={2.5}
                  pl={3}
                  mb={2}
                >
                  <Text w="200px">{t("settings.country_header")}</Text>
                  <Text w="260px">{t("settings.msrp_header")}</Text>
                  <Text w="120px">{t("settings.msrp_button_header")}</Text>
                </Flex>
                <Flex flexDir="column" gap={1}>
                  {multiplierFields.map((field, index) => {
                    return (
                      <RetailerCountryMultiplier
                        errors={multiplierErrors}
                        index={index}
                        key={field.id}
                        field={field}
                        countryName={
                          countries?.find(
                            (item) => item.countryCode === field.countryCode,
                          )?.name ?? ""
                        }
                        register={multiplierRegister}
                        getValues={getMultiplierForm}
                        updateOne={updateMultiplier}
                      />
                    );
                  })}
                </Flex>
              </PanelForm>
            )}
            <PanelForm title={t("settings.order_commissions")}>
              <InputField
                errors={errors}
                label={t("settings.order_commission_placeholder")}
                placeholder={"0"}
                rightAddon="%"
                type="number"
                w="450px"
                {...register("firstOrderCommission", {
                  required: requiredMessage,
                })}
                mb={5}
              />
              <InputField
                errors={errors}
                label={t("settings.commission")}
                placeholder={"0"}
                rightAddon="%"
                type="number"
                w="450px"
                {...register("commission", {
                  required: requiredMessage,
                })}
              />
            </PanelForm>
            <PanelForm title={t("settings.express_payout_commission")}>
              <InputField
                errors={errors}
                label={t("settings.enter_commission_value")}
                placeholder={"0"}
                rightAddon="%"
                type="number"
                w="450px"
                {...register("payoutCommissionRate", {
                  required: requiredMessage,
                })}
                mb={5}
              />
              <Text
                textStyle="h4"
                color="grey.700"
                fontWeight="semibold"
                mb={7}
              >
                {t("settings.payout_delay_settings")}
              </Text>
              <InputField
                errors={errors}
                label={t("settings.eneter_payout_delay")}
                placeholder={"0"}
                type="number"
                w="496px"
                {...register("delayPayoutDays", {
                  required: requiredMessage,
                })}
                mb={5}
              />
            </PanelForm>
            <PanelForm title={t("settings.bestseller_products_limit")}>
              <InputField
                errors={errors}
                label={t("settings.bestseller_products_limit_placeholder")}
                placeholder={"0"}
                type="number"
                w="496px"
                {...register("bestsellerProductLimit", {
                  required: requiredMessage,
                })}
              />
            </PanelForm>
            <PanelForm title={t("settings.default_payment_terms_days")}>
              <InputField
                errors={errors}
                label={t("settings.default_payment_terms_days_placeholder")}
                placeholder={"0"}
                type="number"
                w="496px"
                {...register("paymentTermsDelay", {
                  required: requiredMessage,
                })}
              />
            </PanelForm>
            <PanelForm title={t("settings.return_eligibility_days")}>
              <InputField
                errors={errors}
                label={t("settings.return_eligibility_days_placeholder")}
                placeholder={"0"}
                type="number"
                w="496px"
                {...register("returnEligibilityDays", {
                  required: requiredMessage,
                })}
              />
            </PanelForm>
            <PanelForm title={t("settings.new_products_duration")}>
              <InputField
                errors={errors}
                label={t("settings.new_products_duration_placeholder")}
                placeholder={"0"}
                type="number"
                w="496px"
                {...register("newProductDurationInDays", {
                  required: requiredMessage,
                  validate: (value) =>
                    value > 0 || t("settings.new_product_duration_error"),
                })}
              />
            </PanelForm>
            <PanelForm title={t("settings.bank_transfer_company_details")}>
              <Button
                w="fit-content"
                size="lg"
                variant="outline"
                mt={3}
                onClick={onOpenModal}
              >
                {bankDetailsExist
                  ? t("settings.change")
                  : t("settings.add_bank_transfer_details")}
              </Button>
              {bankDetailsExist ? (
                <InfoDetails
                  heading=""
                  leftList={[
                    t("settings.company_name"),
                    t("settings.country"),
                    t("settings.address"),
                  ]}
                  rightList={[
                    getValues("companyName"),
                    getValues("companyCountry"),
                    getValues("companyAddress"),
                  ]}
                  mt={5}
                  leftTextProps={{ w: "30%" }}
                />
              ) : null}
            </PanelForm>
          </>
        )}
      </Flex>
      <AddBankDetailsModal
        isOpen={isOpenModal}
        onClose={onCloseModal}
        setValue={setValue}
        getMainFormValues={getValues}
        bankDetailsExist={bankDetailsExist}
      />
    </Flex>
  );
};
