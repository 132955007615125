import { Flex, Spinner } from "@chakra-ui/react";

import { useGetActions } from "./hooks/useGetActions";
import { useSetupActionsTable } from "./hooks/useSetupActionsTable";
import { TableWrapper } from "components/Table/TableWrapper";

type FinancialDetailsProps = {
  orderId: string;
};
export const Actions = ({ orderId }: FinancialDetailsProps) => {
  const { data: actions, isLoading } = useGetActions(orderId);

  const { detailsColumns } = useSetupActionsTable();

  if (isLoading) {
    return <Spinner position="absolute" top="50%" left="50%" />;
  }

  return (
    <Flex flexDirection="column" pt={5}>
      <TableWrapper
        columns={detailsColumns}
        tableData={actions}
        tableProps={{ size: "md" }}
        disablePagination
      />
    </Flex>
  );
};
