import { ResponsiveValue, Textarea } from "@chakra-ui/react";
import { TextareaProps as ChakraTextareaProps } from "@chakra-ui/react";
import { ReactNode, forwardRef } from "react";
import { FieldErrors, FieldValues, UseFormRegister } from "react-hook-form";

import { FieldWrapper } from "components/FieldWrapper/FieldWrapper";

export type TextAreaProps = {
  label?: string;
  info?: string;
  errors: FieldErrors;
  placeholder: string;
  isRequired?: boolean;
  size?: ResponsiveValue<string>;
  disabled?: boolean;
  readOnly?: boolean;
  rightInfo?: string | ReactNode;
} & ReturnType<UseFormRegister<FieldValues>> &
  ChakraTextareaProps;

export const TextAreaField = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      label,
      info,
      errors,
      isRequired,
      name,
      size,
      readOnly,
      disabled,
      placeholder,
      rightInfo,
      onChange,
      onBlur,
      ...rest
    },
    ref,
  ) => {
    return (
      <FieldWrapper
        name={name}
        label={label}
        errors={errors}
        info={info}
        rightInfo={rightInfo}
        isRequired={isRequired}
      >
        <Textarea
          {...rest}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          isInvalid={!!errors[name]}
          readOnly={readOnly}
          size={size}
          onChange={(args) => void onChange(args)}
          onBlur={(args) => void onBlur(args)}
          ref={ref}
        />
      </FieldWrapper>
    );
  },
);

TextAreaField.displayName = "TextAreaField";
