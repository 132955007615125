/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Flex,
  Heading,
  Icon,
  Link,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { format, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { AddOptionForm } from "./AddOption/AddOptionForm";
import { useAddOptionForm } from "./AddOption/useAddOptionForm";
import {
  Resolutions,
  useGetFinancialBreakdown,
} from "./useGetFinancialBreakdown";
import { Button } from "components/Button/Button";
import { ModalWrapper } from "components/Modal/ModalWrapper";
import { TableWrapper } from "components/Table/TableWrapper";
import { RegularClose } from "styles/icons/regular";

export const FinancialBreakdown = () => {
  const { orderId } = useParams();
  const { t } = useTranslation();

  const { data: financials, isLoading } = useGetFinancialBreakdown(
    orderId ?? "",
  );
  const {
    isOpen: isOpenAddOption,
    onOpen: onOpenAddOption,
    onClose: onCloseAddOption,
  } = useDisclosure();

  const {
    control,
    register,
    errors,
    onSubmit,
    reset,
    isFormValid,
    isLoading: isAddLoading,
  } = useAddOptionForm({
    orderId: orderId ?? "",
    onCloseModal: onCloseAddOption,
  });

  const calculatedBrandTotal = () => {
    const suborderResolutions = financials?.resolutions;
    if (suborderResolutions) {
      const brandTotal = suborderResolutions.reduce((acc, current) => {
        return acc + current.brandTotal;
      }, 0);
      return brandTotal;
    }

    return 0;
  };

  const orderBrandTotal = calculatedBrandTotal();

  const columnHelper = createColumnHelper<Resolutions>();

  const columns = [
    columnHelper.accessor("name", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {row.original.name}
        </Text>
      ),
      header: "Name",
      size: 15,
      enableSorting: false,
    }),
    columnHelper.accessor("type", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {row.original.type}
        </Text>
      ),
      header: "Type",
      size: 15,
      enableSorting: false,
    }),
    columnHelper.accessor("description", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {row.original.description}
        </Text>
      ),
      header: "Description",
      size: 25,
      enableSorting: false,
    }),
    columnHelper.accessor("adminEmail", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {row.original.adminEmail}
        </Text>
      ),
      header: "Admin Name",
      size: 15,
      enableSorting: false,
    }),
    columnHelper.accessor("creationDate", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {format(parseISO(row.original.creationDate), "dd-MM-yyyy HH:mm:ss")}
        </Text>
      ),
      header: "Date & Time",
      size: 15,
      enableSorting: false,
    }),
    columnHelper.accessor("total", {
      cell: ({ row }) => (
        <Text
          textStyle="bodyS"
          color={
            row.original.type !== "Split"
              ? Number(row.original.total) < 0
                ? "red.500"
                : "green.500"
              : "gray.700"
          }
          prefix={
            row.original.type !== "Split" && Number(row.original.total) > 0
              ? "+"
              : ""
          }
        >
          {(row.original.type !== "Split" && Number(row.original.total) > 0
            ? "+"
            : "") +
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: financials ? financials.currency : "EUR",
            }).format(Number(row.original.total))}
        </Text>
      ),
      header: "Amount",
      size: 15,
      enableSorting: false,
      footer: () => (
        <Flex fontWeight="bold" textAlign="right" pr={14}>
          <Text textStyle="bodyS">Total: </Text>
          <Text textStyle="bodyS">
            {Intl.NumberFormat("en-US", {
              style: "currency",
              currency: financials ? financials.currency : "EUR",
            }).format(Number(financials?.total))}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("brandTotal", {
      cell: ({ row }) => (
        <Text
          textStyle="bodyS"
          color={
            row.original.type !== "Split"
              ? Number(row.original.total) < 0
                ? "red.500"
                : "green.500"
              : "gray.700"
          }
          prefix={
            row.original.type !== "Split" && Number(row.original.total) > 0
              ? "+"
              : ""
          }
        >
          {(row.original.type !== "Split" && Number(row.original.total) > 0
            ? "+"
            : "") +
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: financials ? financials.brandCurrency : "EUR",
            }).format(Number(row.original.brandTotal))}
        </Text>
      ),
      header: `Amount in ${financials?.brandCurrency ?? "brand currency"}`,
      size: 15,
      enableSorting: false,
      footer: () => (
        <Flex fontWeight="bold" textAlign="right" pr={14}>
          <Text textStyle="bodyS">Total: </Text>
          <Text textStyle="bodyS">
            {Intl.NumberFormat("en-US", {
              style: "currency",
              currency: financials ? financials.brandCurrency : "EUR",
            }).format(Number(orderBrandTotal))}
          </Text>
        </Flex>
      ),
    }),
  ];

  const handleOnCancel = () => {
    onCloseAddOption();
    reset();
  };

  if (isLoading) {
    return <Spinner position="absolute" top="50vh" left="50%" />;
  }

  const resolutions = financials?.resolutions.filter((resolution) => {
    // MAR-3148: Remove shipping share
    if (resolution.name === "Shipping share") {
      return false;
    }

    // MAR-3148: Remove all Markato correction
    if (resolution.name === "Markato" && resolution.type === "Correction") {
      return false;
    }

    return true;
  });

  return (
    <Flex>
      <Flex position="absolute" right={0} p={8}>
        <Link href={`/orders/${financials?.orderId ?? ""}`}>
          <Icon as={RegularClose} w={6} h={6} cursor="pointer" />
        </Link>
      </Flex>
      <Flex p={16} width="100%" flexDir="column">
        <Heading as="h2" size="h2" fontWeight="bold">
          {t("order.financial_breakdown")}
        </Heading>
        <Flex py={9} justifyContent="space-between">
          <Flex flexDirection="column">
            <Text textStyle="bodyM" fontWeight="bold">
              #{financials?.orderNumber}
            </Text>
            <Text textStyle="bodyM" fontWeight="bold">
              {financials?.storeName}
            </Text>
          </Flex>
          <Button variant="outline" onClick={onOpenAddOption}>
            {t("order.add_new_option")}
          </Button>
        </Flex>
        <TableWrapper
          tableData={resolutions ?? []}
          columns={columns}
          tableProps={{ variant: "order", size: "sm" }}
          showFooter={false}
        />
      </Flex>
      <ModalWrapper
        isOpen={isOpenAddOption}
        onClose={onCloseAddOption}
        modalHeader={t("order.add_new_option_captial")}
        modalBody={
          <AddOptionForm
            register={register}
            errors={errors}
            control={control}
            isAddLoading={isAddLoading}
            currency={financials!.brandCurrency}
          />
        }
        modalFooter={
          <>
            <Button
              variant="outline"
              fontWeight="medium"
              onClick={handleOnCancel}
              data-test-id="cancelAddFinancial"
            >
              {t("Cancel")}
            </Button>
            <Button
              type="submit"
              form="addOption"
              variant="solid"
              data-test-id="addFinancial"
              disabled={isFormValid}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={onSubmit}
            >
              {t("Save changes")}
            </Button>
          </>
        }
      />
    </Flex>
  );
};
