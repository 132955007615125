import { Flex, Icon, Link, Text, useDisclosure } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { PaymentTermUsage } from "../../RetailerRequests/RetailerRequestOverview/hooks/useGetPaymentTermsUsage";
import { useSetupUsageTable } from "../../RetailerRequests/RetailerRequestOverview/hooks/useSetupUsageTable";
import { useUpdateBnpl } from "./hooks/useUpdateBnpl";
import { Button } from "components/Button/Button";
import { InputField } from "components/InputField/InputField";
import { ModalWrapper } from "components/Modal/ModalWrapper";
import { PanelForm } from "components/PanelForm/PanelForm";
import { TableWrapper } from "components/Table/TableWrapper";
import { TextAreaField } from "components/TextAreaField/TextAreaField";
import { SolidCoin } from "styles/icons/solid";

export const BnplDetails = ({
  currency,
  usage,
  outstanding,
  paymentTerms,
  retailerId,
}: PaymentTermUsage & { retailerId: string }) => {
  const { t } = useTranslation();
  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();

  const { register, isLoading, handleUpdateBnpl, errors } = useUpdateBnpl(
    retailerId,
    onCloseModal,
    isOpenModal,
  );

  const { termColumns, termRows } = useSetupUsageTable({
    usages: usage,
    currency,
    outstanding: outstanding ?? 0,
  });

  return (
    <Flex mt={4} px={3} w="100%" flexDir="column">
      <PanelForm
        title={t("bnpl.title")}
        titleIcon={<Icon as={SolidCoin} color="#B3B3B8" w={6} h={6} mr={2.5} />}
        w="100%"
        position="relative"
      >
        <Button
          position="absolute"
          w="fit-content"
          size="lg"
          top={6}
          right={6}
          onClick={onOpenModal}
        >
          {t("bnpl.update")}
        </Button>
        <Flex>
          <Flex direction="column" mr={5}>
            <Text textStyle="bodyL" mb={2.5}>
              {t("bnpl.available")}
            </Text>
            <Text textStyle="bodyL" mb={2.5}>
              {t("bnpl.approved")}
            </Text>
            <Text textStyle="bodyL">{t("bnpl.used")}</Text>
          </Flex>
          <Flex direction="column">
            <Text fontWeight="bold" mb={2}>
              {paymentTerms.available < 0
                ? Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: currency,
                  }).format(0)
                : Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: currency,
                  }).format(paymentTerms.available)}
            </Text>
            <Text fontWeight="bold" mb={2}>
              {Intl.NumberFormat("en-US", {
                style: "currency",
                currency: currency,
              }).format(paymentTerms.approved)}
            </Text>
            <Text fontWeight="bold">
              {Intl.NumberFormat("en-US", {
                style: "currency",
                currency: currency,
              }).format(paymentTerms.used)}
            </Text>
          </Flex>
        </Flex>
      </PanelForm>
      <Flex mt={8} flexDir="column">
        <Flex justify="end">
          <Link href={`/bnpl-application-history/${retailerId}`}>
            <Text color="#1C7EC6" mr={9} cursor="pointer">
              {t("bnpl.see_application_history")}
            </Text>
          </Link>
        </Flex>
        {termRows.length > 0 && (
          <>
            <Flex flexDir="column" mt={8}>
              <Text textStyle="bodyL" fontWeight="semibold" mb={4}>
                {t("bnpl.usage")}
              </Text>
            </Flex>
            <TableWrapper
              columns={termColumns}
              tableData={termRows}
              tableProps={{ variant: "order", size: "sm" }}
              showFooter
            />
          </>
        )}
      </Flex>
      <ModalWrapper
        isOpen={isOpenModal}
        onClose={onCloseModal}
        showHeaderDivider
        modalHeader={t("bnpl.update")}
        modalBody={
          <>
            <Flex mb={5}>
              <InputField
                errors={errors}
                label={t("bnpl.enter_amount")}
                placeholder={"0"}
                rightAddon={currency}
                type="number"
                {...register("amount", { required: t("bnpl.required") })}
              />
            </Flex>
            <Text textStyle="bodyM" color="black" mb={1}>
              {t("bnpl.add_comment")}
            </Text>
            <TextAreaField
              errors={errors}
              height="125px"
              resize="none"
              placeholder={t("bnpl.please_reason")}
              {...register("note", { required: t("bnpl.required") })}
            />
          </>
        }
        modalFooter={
          <Button
            w="100%"
            size="xl"
            variant="solid"
            isLoading={isLoading}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleUpdateBnpl}
          >
            {t("bnpl.apply")}
          </Button>
        }
      />
    </Flex>
  );
};
