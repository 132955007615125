/* eslint-disable @typescript-eslint/no-misused-promises */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { usePostAdminImpersonate } from "../utils/usePostAdminImpersonate";
import { BuyNowPayLater } from "./BuyNowPayLater";
import { RetailerDetails } from "./RetailerDetails";
import { RetailerSettings } from "./RetailerSettings";
import { WalletCredit } from "./WalletCredit";
import { useActivateRetailerAccountById } from "./hooks/useActivateRetailerAccountById";
import { useDeactivateForm } from "./hooks/useDeactivateForm";
import { useGetAllRetailerById } from "./hooks/useGetRetailerById";
import { usePutDeactivateRetailerAccount } from "./hooks/usePutDeactivateRetailerAccount";
import { Button } from "components/Button/Button";
import { ModalWrapper } from "components/Modal/ModalWrapper";
import { Status } from "components/Status/Status";
import { TabData, TabWrapper } from "components/Tabs/TabWrapper";
import {
  capitalizeFirstLetter,
  convertToStatusVariant,
} from "features/UserManagement/utils";
import { useToast } from "hooks/useToast";
import { MARKATO_DEFAULT_DOMAIN, getErrorCodes } from "services/httpClient";
import {
  SolidArrowChevronDown,
  SolidArrowChevronLeft,
} from "styles/icons/solid";
import { isAbleToImpersonate } from "utils/functions";

type RetailerAccountParam = {
  retailerId: string;
};

export const RetailerOverview = () => {
  const { t } = useTranslation();
  const toast = useToast();

  const { mutateAsync: postAdminImpersonate, isLoading: isLoadingImpersonate } =
    usePostAdminImpersonate();

  const { retailerId } = useParams<
    keyof RetailerAccountParam
  >() as RetailerAccountParam;

  const {
    isOpen: isOpenDactivateRetailer,
    onOpen: onOpenDeactivateRetailer,
    onClose: onCloseDeactivateRetailer,
  } = useDisclosure();
  const {
    isOpen: isOpenActivateRetailer,
    onOpen: onOpenActivateRetailer,
    onClose: onCloseActivateRetailer,
  } = useDisclosure();

  const { register, isFormValid, getValues } = useDeactivateForm();

  const {
    data: retailerAccount,
    isLoading,
    isFetching,
    refetch,
  } = useGetAllRetailerById(retailerId);

  const { mutateAsync: activateRetailerAccount, isLoading: isLoadingActivate } =
    useActivateRetailerAccountById(retailerId);

  const {
    mutateAsync: deactivateRetailerAccount,
    isLoading: isLoadingDeactivate,
  } = usePutDeactivateRetailerAccount(retailerId);

  if (isFetching || isLoading) {
    return <Spinner position="absolute" top="50%" left="50%" />;
  }

  const tabsData: TabData[] = [
    {
      title: t("Details"),
      content: retailerAccount && (
        <RetailerDetails
          retailerAccount={retailerAccount}
          retailerAccountRefetch={refetch}
        />
      ),
    },
    {
      title: t("wallet_credit.title"),
      content: retailerAccount && <WalletCredit id={retailerAccount.id} />,
    },
    {
      title: t("bnpl.title"),
      content: retailerAccount && (
        <BuyNowPayLater retailerId={retailerAccount.id} />
      ),
    },
    {
      title: t("Settings"),
      content: retailerAccount && (
        <RetailerSettings retailerId={retailerAccount.id} />
      ),
    },
  ];

  const handleDeactivateRetailer = async () => {
    await deactivateRetailerAccount(
      {
        id: retailerId,
        reason: getValues().reason,
      },
      {
        onSuccess: () => {
          let retailerName: string;
          if (retailerAccount) {
            retailerName = `${retailerAccount.firstName} ${retailerAccount.lastName}`;
          } else {
            retailerName = "Retailer";
          }
          toast({
            description: `${retailerName} has been deactivated successfully`,
          });
        },
        onError: (error) => {
          const errorCodes = getErrorCodes(error);
          toast({ status: "error", description: t(errorCodes[0]) });
        },
      },
    );
    onCloseDeactivateRetailer();
  };

  const handleActivateRetailer = async () => {
    await activateRetailerAccount(
      {
        id: retailerId,
      },
      {
        onSuccess: () => {
          if (retailerAccount)
            toast({
              description: `${retailerAccount.firstName} ${retailerAccount.lastName} has been activated successfully`,
            });
        },
        onError: (error) => {
          const errorCodes = getErrorCodes(error);
          toast({ status: "error", description: t(errorCodes[0]) });
        },
      },
    );
    onCloseActivateRetailer();
  };

  const handleImpersonate = async () => {
    if (!retailerAccount) {
      return;
    }
    await postAdminImpersonate({
      id: retailerAccount.cognitoUserId,
      group: "Retailer",
      name: `${retailerAccount.firstName} ${retailerAccount.lastName}`,
    });

    window.location.replace(
      process.env.REACT_APP_MARKATO_URL ?? MARKATO_DEFAULT_DOMAIN,
    );
  };

  return (
    <Flex display="column">
      <Flex mb={2} mt={5} ml={10} mr={10} justifyContent="space-between">
        <Flex>
          <Button
            variant="outline"
            colorScheme="grey"
            mainIcon={SolidArrowChevronLeft}
            aria-label="backButton"
            data-test-id="backToRetailersList"
            alignSelf="center"
            mr={4}
            onClick={() => window.history.back()}
          />
          <Heading as="h1" size="h1" fontWeight="bold">
            {retailerAccount?.firstName} {retailerAccount?.lastName}
          </Heading>
          <Status
            display="flex"
            variant={convertToStatusVariant(
              retailerAccount?.status ? "INACTIVE" : "ACTIVE",
            )}
            text={capitalizeFirstLetter(
              retailerAccount?.status ? "INACTIVE" : "ACTIVE",
            )}
            ml={3.5}
            h="22px"
            alignSelf="center"
          />
        </Flex>
        <Flex>
          <Menu placement="bottom-end">
            <MenuButton
              as={Button}
              variant="outline"
              rightIcon={SolidArrowChevronDown}
              data-test-id="threeDotsMenuButton"
              isLoading={isLoadingImpersonate}
            >
              {t("impersonate.manage_profile")}
            </MenuButton>
            <MenuList minW={48}>
              {isAbleToImpersonate() && (
                <MenuItem
                  w={48}
                  onClick={handleImpersonate}
                  mx={2}
                  isDisabled={!!retailerAccount?.status}
                >
                  {t("impersonate.impersonate_user")}
                </MenuItem>
              )}
              {retailerAccount?.status ? (
                <MenuItem
                  color="blue.600"
                  w={48}
                  onClick={onOpenActivateRetailer}
                  mx={2}
                >
                  {t("retailer_details.activate_account")}
                </MenuItem>
              ) : (
                <MenuItem
                  color="red"
                  w={48}
                  onClick={onOpenDeactivateRetailer}
                  mx={2}
                >
                  {t("retailer_details.deactivate_account")}
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
      <TabWrapper
        tabListProps={{ pl: 10 }}
        tabPanelsProps={{ pl: 2 }}
        tabsData={tabsData}
      />
      <ModalWrapper
        isOpen={isOpenActivateRetailer}
        onClose={onCloseActivateRetailer}
        showHeaderDivider
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-base-to-string
        modalHeader={`${t("retailer_details.activate")} "${
          retailerAccount?.firstName ?? ""
        } ${retailerAccount?.lastName ?? ""}"`}
        modalBody={
          <Text textStyle="bodyM" color="black">
            {t("retailer_details.modal_activate")} {retailerAccount?.firstName}{" "}
            {retailerAccount?.lastName}?
          </Text>
        }
        modalFooter={
          <>
            <Button
              variant="outline"
              colorScheme="grey"
              fontWeight="600"
              color="grey.black"
              onClick={onCloseActivateRetailer}
              data-test-id="cancelActivateRetailerButton"
            >
              {t("retailer_details.cancel")}
            </Button>
            <Button
              variant="solid"
              colorScheme="blue"
              isLoading={isLoadingActivate}
              onClick={() => void handleActivateRetailer()}
              data-test-id="activateRetailerButton"
            >
              {t("retailer_details.activate")}
            </Button>
          </>
        }
      />
      <ModalWrapper
        isOpen={isOpenDactivateRetailer}
        onClose={onCloseDeactivateRetailer}
        showHeaderDivider
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-base-to-string
        modalHeader={`${t("retailer_details.deactivate")} ${
          retailerAccount?.firstName ?? ""
        } ${retailerAccount?.lastName ?? ""}`}
        modalBody={
          <>
            <Text textStyle="bodyM" color="black" mb={1}>
              {t("Add Comment")}
            </Text>
            <Textarea
              height="125px"
              resize="none"
              placeholder={t("retailer_details.reason_msg")}
              {...register("reason", {
                required: true,
                validate: (value) => !!value.trim(),
              })}
            ></Textarea>
          </>
        }
        modalFooter={
          <>
            <Button
              variant="outline"
              colorScheme="grey"
              fontWeight="600"
              color="grey.black"
              onClick={onCloseDeactivateRetailer}
              data-test-id="cancelDeactivateRetailerButton"
            >
              {t("retailer_details.cancel")}
            </Button>
            <Button
              variant="solid"
              colorScheme="red"
              isLoading={isLoadingDeactivate}
              disabled={isFormValid}
              onClick={() => void handleDeactivateRetailer()}
              data-test-id="deactivateRetailerButton"
            >
              {t("retailer_details.deactivate")}
            </Button>
          </>
        }
      />
    </Flex>
  );
};
