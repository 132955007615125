import { Button, Flex, Text } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { format, parseISO } from "date-fns";

import { Transaction } from "./useGetFinancialDetails";
import { ColorCircle } from "components/Color/ColorCircle";

export const useSetupDetailsTable = (openForcePushModal: () => void) => {
  const columnHelper = createColumnHelper<Transaction>();

  const handleForcePush = () => openForcePushModal();

  const convertTypeToReadableType = (type: string) => {
    switch (type) {
      case "PAYMENT_TERM":
        return "Buy Now Pay Later";
      case "PAYMENT_TERM_CANCEL":
        return "Buy Now Pay Later - adjustment";
      case "WALLET_CREDIT":
        return "Wallet Credit";
      case "WALLET_CREDIT_REFUND":
        return "Wallet Credit Refund";
      case "BANK_TRANSFER":
        return "Bank Transfer";
      case "CREDIT_CARD":
        return "Credit Card";
      case "FORCE_PUSH":
        return "Force Pushed";
      case "OVERPAY":
        return "Overpay";
      case "VOUCHER":
        return "Voucher";
      default:
        return "";
    }
  };
  const detailsColumns = [
    columnHelper.accessor("type", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700" fontWeight="bold">
          {convertTypeToReadableType(row.original.type)}
        </Text>
      ),
      header: "Method",
      size: 20,
      enableSorting: false,
    }),
    columnHelper.accessor("transactionReference", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {row.original.type === "BANK_TRANSFER" &&
          !row.original.transactionReference ? (
            <Flex align="center">
              <ColorCircle
                color="red.500"
                borderColor="red.500"
                width={3}
                height={3}
                mr={1}
              />
              Waiting
              <Button variant="outline" ml={4} onClick={handleForcePush}>
                Force Push Payment
              </Button>
            </Flex>
          ) : (
            row.original.transactionReference
          )}
        </Text>
      ),
      header: "Payment ID",
      size: 60,
      enableSorting: false,
    }),
    columnHelper.accessor("creationDate", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {format(parseISO(row.original.creationDate), "dd-MM-yyyy HH:mm:ss")}
        </Text>
      ),
      header: "Date & Time",
      size: 20,
      enableSorting: false,
    }),
    columnHelper.accessor("amount", {
      cell: ({ row }) => (
        <Text
          textStyle="bodyS"
          color={
            row.original.type === "WALLET_CREDIT_REFUND"
              ? "green.500"
              : "grey.700"
          }
        >
          {Intl.NumberFormat("en-US", {
            style: "currency",
            currency: row.original.currency,
          }).format(Number(row.original.amount))}
        </Text>
      ),
      header: "Amount",
      size: 10,
      enableSorting: false,
    }),
  ];

  return {
    detailsColumns,
  };
};
