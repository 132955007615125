/* eslint-disable @tanstack/query/exhaustive-deps */
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { Retailer } from "features/UserManagement/types";
import { RETAILERS_KEY } from "services/ReactQueryKeys";
import httpClient from "services/httpClient";

export const GET_ALL_RETAILERS_URL = "/admin/retailer";

export const useGetAllRetailers = (): UseQueryResult<Retailer[]> =>
  useQuery({
    queryKey: [RETAILERS_KEY],
    queryFn: async () => {
      const { data: allRetailers } = await httpClient.get<Retailer[]>(
        GET_ALL_RETAILERS_URL,
      );
      return allRetailers.map((r) => {
        return {
          ...r,
          name: `${r.firstName} ${r.lastName}`,
          brStatus: r.brStatus,
        };
      });
    },
  });
