/* eslint-disable @typescript-eslint/no-unnecessary-type-arguments */

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-misused-promises */
import { Flex, Text, Textarea } from "@chakra-ui/react";
import { BaseSyntheticEvent, useEffect } from "react";
import {
  FieldErrorsImpl,
  UseFormRegister,
  UseFormReset,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { EditReturnFields } from "./hooks/useReturnRequestDetails";
import { Button } from "components/Button/Button";
import { InputField } from "components/InputField/InputField";
import { ModalWrapper } from "components/Modal/ModalWrapper";

type EditReturnQuantityModalProps = {
  isOpenModal: boolean;
  onCloseModal: () => void;
  sku: string;
  register: UseFormRegister<EditReturnFields>;
  errors: Partial<
    FieldErrorsImpl<{
      id: string;
      quantity: number;
      caseQty: number;
      sku: string;
      comment: string;
    }>
  >;
  handleApply: (
    event?: BaseSyntheticEvent<object, any, any> | undefined,
  ) => Promise<void>;
  resetModal: UseFormReset<EditReturnFields>;
  maxProductQty: number;
};

export const EditReturnQuantityModal = ({
  isOpenModal,
  onCloseModal,
  sku,
  register,
  errors,
  handleApply,
  resetModal,
  maxProductQty,
}: EditReturnQuantityModalProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!isOpenModal) {
      resetModal({});
    }

    resetModal({
      sku: sku,
      approvedUnitQuantity: undefined,
      adminComment: undefined,
    });
  }, [sku, resetModal, isOpenModal]);

  return (
    <ModalWrapper
      isOpen={isOpenModal}
      onClose={onCloseModal}
      showHeaderDivider
      modalHeader={t("return_requests.edit_return_quantity")}
      modalBody={
        <>
          <Flex mb={8}>
            <InputField
              errors={errors}
              label={t("return_requests.edit_quantity")}
              placeholder={"0"}
              type="number"
              {...register("approvedUnitQuantity", {
                required: t("return_requests.required_field"),
                validate: (value) => {
                  if (Number(value) > maxProductQty) {
                    return t("return_requests.max_return_quantity");
                  }

                  if (Number(value) < 0) {
                    return t("return_requests.return_quantity_less_0");
                  }

                  return undefined;
                },
              })}
            />
          </Flex>

          <Text textStyle="bodyM" color="black" mb={1}>
            {t("return_requests.add_comment")}
          </Text>
          <Textarea
            height="125px"
            resize="none"
            placeholder={t("return_requests.provide_reason")}
            {...register("adminComment")}
          ></Textarea>
        </>
      }
      modalFooter={
        <Button w="100%" size="lg" onClick={() => handleApply()}>
          {t("return_requests.apply_changes")}
        </Button>
      }
    />
  );
};
