import { useMemo } from "react";
import { useForm } from "react-hook-form";

import { GET_FINANCIAL_BREAKDOWN_QK } from "../useGetFinancialBreakdown";
import { FinancialOption } from "./AddOptionForm";
import { usePostFinancialOption } from "./usePostFinancialOption";
import { queryClient } from "services/queryClient";

export type AddOptionFormFields = {
  id: string;
  type: FinancialOption;
  amount: string;
  comment: string;
};

type UseAddOptionFormProps = {
  orderId: string;
  onCloseModal: () => void;
};
export const useAddOptionForm = ({
  orderId,
  onCloseModal,
}: UseAddOptionFormProps) => {
  const {
    control,
    register,
    formState: { errors, isDirty, isValid },
    handleSubmit,
    reset,
    getValues,
  } = useForm<AddOptionFormFields>({
    mode: "onChange",
    defaultValues: {
      id: orderId,
    },
  });

  const onSuccess = () => {
    void queryClient.invalidateQueries({
      queryKey: [GET_FINANCIAL_BREAKDOWN_QK],
    });
    onCloseModal();
  };
  const { mutateAsync: postFinancialOption, isLoading } =
    usePostFinancialOption(onSuccess);

  const isFormValid = useMemo(() => {
    return !isDirty || !isValid;
  }, [isDirty, isValid]);

  const onSubmit = handleSubmit(async () => {
    await postFinancialOption(getValues());
  });

  return {
    control,
    register,
    errors,
    onSubmit,
    reset,
    isFormValid,
    isLoading,
  };
};
