import { useMutation } from "@tanstack/react-query";

import httpClient from "services/httpClient";

export const POST_FORGOT_PASSWORD = "/admin/auth/forgot-password";

type ForgotPasswordRequest = {
  email: string;
};

const postForgotPassword = async (form: ForgotPasswordRequest) => {
  return await httpClient.post(`${POST_FORGOT_PASSWORD}`, form);
};

export const usePostForgotPassword = () => useMutation(postForgotPassword);
