export const ADMINS_KEY = "admins";
export const BRAND_REGISTRATION_REQUESTS_KEY = "brands_registration_requests";
export const BRAND_UPDATE_PAGE_REQUESTS_KEY = "brands_update_page_requests";
export const BRANDS_KEY = "brands";
export const BRAND_FINANCIAL_KEY = "brand_financial";

export const RETAILERS_KEY = "retailers";
export const ACTIVE_ORDERS_KEY = "active_orders";
export const ORDER_HISTORY_KEY = "order_history";
export const VOUCHER_KEY = "voucher";
export const VOUCHER_EXPIRED = "voucher_expired";
export const SETTINGS_KEY = "settings";
export const TOP_BANNERS_KEY = "top_banners";
export const CAROUSEL_BANNERS_KEY = "carousel_banners";
export const UPCOMING_PAYOUTS_KEY = "upcoming_payouts";
export const PAYOUT_DETAILS_KEY = "payout_details";
export const PAYOUTS_EXPORT_KEY = "payouts_export";
export const PAYOUT_DETAILS_EXPORT_KEY = "payout_details_export";
export const SUBMITTED_REQUESTS_KEY = "submitted_requests";
export const RETURN_IN_PROGRESS_REQUESTS_KEY = "return_in_progress_requests";
export const READY_FOR_REVIEW_REQUESTS_KEY = "ready_for_review_requests";
export const HISTORY_REQUESTS_KEY = "return_requests_history_requests";
export const RETURN_REQUEST_DETAILS_KEY = "return_requests_details_requests";
export const RETURN_REQUEST_BY_ORDER_KEY = "return_requests_details_requests";
export const NOTES = "notes";
