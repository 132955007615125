import { useMutation } from "@tanstack/react-query";

import { DeactivateBrandFormFields } from "./useDeactivateForm";
import { BRANDS_KEY } from "services/ReactQueryKeys";
import httpClient from "services/httpClient";
import { queryClient } from "services/queryClient";

export const DEACTIVATE_BRAND_BY_ID_URL = "/admin/brands";

const deactivateBrand = async (form: DeactivateBrandFormFields) => {
  return await httpClient.put(
    `${DEACTIVATE_BRAND_BY_ID_URL}/${form.id}/block`,
    {
      blockReason: form.reason,
    },
  );
};

export const usePutDeactivateBrandAccount = (id: string) =>
  useMutation(deactivateBrand, {
    onSuccess: () => {
      void queryClient.fetchQuery({ queryKey: [BRANDS_KEY, id] });
    },
  });
