import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { usePostAdminAccount } from "./usePostAdminAccount";
import { convertToAccessFormat } from "features/UserManagement/utils";
import { ErrorCode, ErrorResponse } from "services/ErrorResponse";

export type AddAdminFormFields = {
  email: string;
  account: string | null;
  settings: string | null;
  users: string | null;
  financials: string | null;
  promotions: string | null;
};

type UseAddAdminFormProps = {
  onCloseModal: () => void;
};
export const useAddAdminForm = ({ onCloseModal }: UseAddAdminFormProps) => {
  const { t } = useTranslation();
  const {
    control,
    register,
    formState: { errors, isDirty, isValid },
    handleSubmit,
    getValues,
    watch,
    setError,
    reset,
  } = useForm<AddAdminFormFields>({
    mode: "onChange",
    defaultValues: {
      account: "none",
      settings: "none",
      users: "none",
      financials: "none",
      promotions: "none",
    },
  });

  const onError = (error: ErrorResponse) => {
    setError("email", { message: t(error.response.data.code[0] as ErrorCode) });
  };
  const { mutateAsync: postAdminAccount, isLoading } =
    usePostAdminAccount(onError);

  const watchRadioFields = watch([
    "account",
    "financials",
    "promotions",
    "settings",
    "users",
  ]);

  const isFormValid = useMemo(() => {
    return (
      !isDirty || !isValid || !watchRadioFields.some((item) => item !== "none")
    );
  }, [isDirty, isValid, watchRadioFields]);

  const onSubmit = handleSubmit(async () => {
    await postAdminAccount({
      email: getValues().email.trim(),
      account: convertToAccessFormat("Account", getValues().account),
      promotions: convertToAccessFormat("Promotions", getValues().promotions),
      settings: convertToAccessFormat("Settings", getValues().settings),
      users: convertToAccessFormat("Users", getValues().users),
      financials: convertToAccessFormat("Financials", getValues().financials),
    });

    onCloseModal();
  });

  return {
    control,
    register,
    errors,
    onSubmit,
    reset,
    isFormValid,
    isLoading,
  };
};
