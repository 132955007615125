import { Box, Button, Flex, Heading } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { StandardDeck } from "./StandardDeck/";
import "./print.scss";
import { TabData, TabWrapper } from "components/Tabs/TabWrapper";
import { SolidDownload } from "styles/icons/solid";

export const Pdfs = () => {
  const [tabIndex, setTabIndex] = useState<number>(
    parseInt(window.location.hash.replace("#", "") || "0"),
  );

  const { t } = useTranslation();

  const tabsData: TabData[] = [
    {
      title: t("pdf.tab1"),
      content: <StandardDeck />,
    },
  ];

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
    window.location.hash = `${index}`;
  };

  return (
    <Box>
      <Flex display="column">
        <Flex justifyContent="space-between" className="hidePrint">
          <Heading as="h1" size="h1" fontWeight="bold" mb={2} mt={5} ml={10}>
            {t("pdf.menu_title")}
          </Heading>
          <Button
            size="lg"
            mt={15}
            mr={8}
            leftIcon={<SolidDownload fontSize={24} />}
            fontWeight="semibold"
            onClick={() => {
              document.title = `${Date.now()}`;
              window.print();
            }}
            data-test-id="download"
          >
            Download
          </Button>
        </Flex>
        <TabWrapper
          tabListProps={{ pl: 10 }}
          tabPanelsProps={{ pl: 2 }}
          tabsData={tabsData}
          tabIndex={tabIndex}
          handleTabsChange={handleTabsChange}
        />
      </Flex>
    </Box>
  );
};
