import {
  BoxProps,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  Flex,
} from "@chakra-ui/react";
import { ReactNode } from "react";

export type DrawerWrapperProps = {
  drawerProps: DrawerProps & BoxProps;
  drawerHeader?: ReactNode;
  drawerBody?: ReactNode;
  drawerFooter?: ReactNode;
  showHeaderDivider?: boolean;
};
export const DrawerWrapper = ({
  drawerHeader,
  drawerProps,
  drawerBody,
  drawerFooter,
  showHeaderDivider = true,
}: DrawerWrapperProps) => {
  return (
    <Drawer {...drawerProps}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>{drawerHeader}</DrawerHeader>
        {showHeaderDivider && (
          <Flex px={8}>
            <Divider />
          </Flex>
        )}
        <DrawerBody>{drawerBody}</DrawerBody>
        <DrawerFooter>{drawerFooter}</DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
