/* eslint-disable @tanstack/query/exhaustive-deps */
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import httpClient from "services/httpClient";

export const GET_FINANCIAL_BREAKDOWN = "/admin/orders/";
export const GET_FINANCIAL_BREAKDOWN_QK = "FINANCIAL_BREAKDOWN_QK";

export type Resolutions = {
  type: string;
  description: string;
  currency: string;
  adminEmail: string;
  total: string;
  creationDate: string;
  name: string;
  brandTotal: number;
};

export type FinancialBreakdown = {
  name: string;
  currency: string;
  brandCurrency: string;
  storeName: string;
  id: string;
  orderId: string;
  orderNumber: string;
  total: number;
  resolutions: Resolutions[];
  brandTotal: number;
};

export const useGetFinancialBreakdown = (
  id: string,
): UseQueryResult<FinancialBreakdown> =>
  useQuery({
    queryKey: [GET_FINANCIAL_BREAKDOWN_QK],
    queryFn: async () => {
      const { data: financials } = await httpClient.get<FinancialBreakdown>(
        `${GET_FINANCIAL_BREAKDOWN}${id}/resolutions`,
      );
      return financials;
    },
  });
