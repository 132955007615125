/* eslint-disable @typescript-eslint/no-misused-promises */

/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Divider, Flex, Radio, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { VoucherAppliedTo } from "../../UserManagement/types";
import { useCreateVouchersForm } from "../hooks/useCreateVouchersForm";
import { isBrandSpecific } from "./VoucherUtils";
import { AutocompleteField } from "components/AutocompleteField/AutocompleteField";
import { Button } from "components/Button/Button";
import { DrawerWrapper } from "components/DrawerWrapper/DrawerWrapper";
import { InputField } from "components/InputField/InputField";
import { RadioField } from "components/RadioField/RadioField";
import { SingleDatePickerField } from "components/SingleDatePickerField/SingleDatePickerField";
import { useGetAllBrands } from "features/UserManagement/Brands/hooks/useGetAllBrands";
import { useGetCurrencies } from "hooks/useGetCurrencies";

type CreateVouchersProps = {
  isOpen: boolean;
  onClose: () => void;
};
export const CreateVouchers = ({ isOpen, onClose }: CreateVouchersProps) => {
  const { t } = useTranslation();
  const requiredError = "This field is required";

  const {
    register,
    control,
    errors,
    discountTypeOptions,
    discountApplyOptions,
    onSubmit,
    generateCode,
    isLoading,
    discountType,
    setValue,
    watch,
  } = useCreateVouchersForm({ isOpen, onClose });

  const { data: fetchCurrencies } = useGetCurrencies();
  const { data: fetchBrands } = useGetAllBrands();

  const selectedDiscountType = watch("discountAppliedTo");

  useEffect(() => {
    if (!isBrandSpecific(selectedDiscountType?.value as VoucherAppliedTo)) {
      setValue("applicableBrands", null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDiscountType, setValue]);

  const getExpiryDate = () => {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return date;
  };

  const currencies = fetchCurrencies?.map((currency) => ({
    label: `${currency.name} (${currency.currencySign})`,
    value: currency.currencyCode,
  })) ?? [{ label: "", value: "" }];

  const brands = fetchBrands?.map((brand) => ({
    label: `${brand.storeName}`,
    value: `${brand.id}`,
  })) ?? [{ label: "", value: "" }];

  const selectedCurrency = watch("voucherCurrency") ?? currencies[0];

  const selectedCurrencySign =
    fetchCurrencies?.find(
      (currency) => currency.currencyCode === selectedCurrency.value,
    )?.currencySign ?? "";

  const radioOptions = (
    <Flex w="400px" gap={5} textStyle="bodyM" color="grey.700">
      <Flex>
        <Radio value="ALL_ORDERS">
          <Text>{t("vouchers.all_order")}</Text>
        </Radio>
      </Flex>
      <Flex>
        <Radio value="FIRST_ORDER">
          <Text>{t("vouchers.first_order")}</Text>
        </Radio>
      </Flex>
    </Flex>
  );

  return (
    <DrawerWrapper
      drawerProps={{ isOpen, onClose, children: undefined, size: "lg" }}
      drawerHeader={
        <Text textStyle="h3" fontWeight="bold" paddingTop={2} px={2}>
          {t("vouchers.create_voucher")}
        </Text>
      }
      drawerBody={
        <Flex flexDirection="column">
          <Flex flexDirection="column" gap={5} mb={5}>
            <AutocompleteField
              label={t("vouchers.voucher_currency")}
              isClearable={false}
              options={currencies}
              closeMenuOnSelect
              control={control as unknown as Control}
              customSize="md"
              name="voucherCurrency"
              defaultValueOption={
                currencies.find((currency) => currency.value === "HKD") ??
                currencies[0]
              }
            />
            <AutocompleteField
              label={"Applies to"}
              isClearable={false}
              options={discountApplyOptions}
              rules={{ required: requiredError }}
              closeMenuOnSelect
              control={control as unknown as Control}
              customSize="md"
              name="discountAppliedTo"
              isDisabled={selectedDiscountType === discountApplyOptions[1]}
              defaultValueOption={discountApplyOptions[0]}
            />

            <AutocompleteField
              label={t("vouchers.discount_type")}
              isClearable={false}
              options={discountTypeOptions}
              closeMenuOnSelect
              control={control as unknown as Control}
              rules={{ required: requiredError }}
              customSize="md"
              name="discountType"
              defaultValueOption={discountTypeOptions[0]}
            />
          </Flex>
          <Flex gap={5} mb={5}>
            <InputField
              type="number"
              errors={errors}
              label={t("vouchers.discount_value")}
              placeholder={"0"}
              rightAddon={discountType.value === "percentage" ? "%" : ""}
              {...register("discountValue", {
                min: { value: 1, message: t("vouchers.min_value_message") },
                required: requiredError,
              })}
            />
            {discountType.value === "percentage" && (
              <InputField
                type="number"
                errors={errors}
                label={t("vouchers.max_amount")}
                placeholder={"0"}
                rightAddon={selectedCurrencySign}
                {...register("maxAmount", {
                  min: { value: 1, message: t("vouchers.min_value_message") },
                  required: requiredError,
                })}
              />
            )}
          </Flex>
          <Flex flexDirection="column" gap={5}>
            <div>
              <Text textStyle="bodyS" fontWeight="medium" marginBottom={2}>
                {t("vouchers.select_order_type")}
              </Text>
              <RadioField
                id="type"
                control={control as unknown as Control}
                name="firstOrder"
                radios={radioOptions}
                rules={{ required: requiredError }}
              />
            </div>
            <AutocompleteField
              closeMenuOnSelect
              customSize="md"
              control={control as unknown as Control}
              name="applicableBrands"
              label={t("vouchers.bespoke_brands")}
              options={brands}
              placeholder={t("vouchers.bespoke_brands_placeholder")}
            />
          </Flex>
          <Divider my={5} />

          <Flex gap={5} mb={5}>
            <InputField
              errors={errors}
              label={t("vouchers.voucher_code")}
              placeholder={t("vouchers.generate_code")}
              {...register("code", { required: requiredError })}
            />
            <Button size="lg" variant="outline" mt={5} onClick={generateCode}>
              {t("vouchers.generate")}
            </Button>
          </Flex>

          <SingleDatePickerField
            name="expiryDate"
            label={t("vouchers.expiry_date")}
            control={control as unknown as Control}
            minDate={getExpiryDate()}
            inputFormat="dd/MM/yyyy HH:mm"
            rules={{ required: requiredError }}
          />
          <Divider my={5} />

          <InputField
            errors={errors}
            label={t("vouchers.usage_quantity")}
            placeholder={"0"}
            {...register("quantity", { required: requiredError })}
          />
          <Text textStyle="bodyS" color="#9999A0" mt={3} mb={5}>
            {t("vouchers.unlimited_usage")}
          </Text>

          <InputField
            errors={errors}
            label={t("vouchers.max_usage")}
            placeholder={"0"}
            {...register("maxUsage", { required: requiredError })}
          />
          <Text textStyle="bodyS" color="#9999A0" mt={3}>
            {t("vouchers.unlimited_usage")}
          </Text>

          <Divider my={5} />

          <InputField
            errors={errors}
            label={t("vouchers.min_amount_voucher")}
            placeholder={"0"}
            rightAddon={selectedCurrencySign}
            {...register("minAmount")}
          />
          <Text textStyle="bodyS" color="#9999A0" mt={3} mb={5}>
            {t("vouchers.min_amount_description")}
          </Text>
        </Flex>
      }
      drawerFooter={
        <>
          <Button size="lg" variant="outline" marginRight={5} onClick={onClose}>
            {t("Cancel")}
          </Button>
          <Button size="lg" onClick={onSubmit} isLoading={isLoading}>
            {t("vouchers.save_voucher")}
          </Button>
        </>
      }
    ></DrawerWrapper>
  );
};
