import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { SetStateAction } from "react";
import { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  buttonStyle,
  thankYouTitleStyle,
  thankYouWrapperStyle,
} from "../ForgotPassword.style";

type ThankYouProps = {
  setIsOpen: () => void;
  setStep: Dispatch<SetStateAction<number>>;
};

export const ThankYou = ({ setIsOpen, setStep }: ThankYouProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Flex {...thankYouWrapperStyle}>
      <Box mb={8}>
        <Image src="/images/check.svg" />
      </Box>
      <Text {...thankYouTitleStyle}>{t("Password changed")}</Text>
      <Text>{t("Please login with the new password")}</Text>
      <Button
        onClick={() => {
          navigate("/login");
          setStep(0);
          setIsOpen();
        }}
        {...buttonStyle}
      >
        {t("Login")}
      </Button>
    </Flex>
  );
};
