import { useMutation } from "@tanstack/react-query";

import { BRANDS_KEY } from "services/ReactQueryKeys";
import httpClient from "services/httpClient";
import { queryClient } from "services/queryClient";

export const ACTIVATE_BRAND_ACCOUNT_BY_ID = "/admin/brands";

const activateBrandAccount = async ({ id }: { id: string }) => {
  return await httpClient.put(`${ACTIVATE_BRAND_ACCOUNT_BY_ID}/${id}/unblock`);
};
export const useActivateBrandAccountById = (id: string) =>
  useMutation(activateBrandAccount, {
    onSuccess: () =>
      void queryClient.fetchQuery({ queryKey: [BRANDS_KEY, id] }),
  });
