import config from "../aws-exports";
import configProd from "../aws-exports-prod";
import configQA from "../aws-exports-qa";
import configStage from "../aws-exports-stage";

export const useEnvironmentConfig = () => {
  const environment = process.env.REACT_APP_ENVIRONMENT;

  let awsConfig = config;
  switch (environment) {
    case "qa":
      awsConfig = configQA;
      break;
    case "stage":
      awsConfig = configStage;
      break;
    case "prod":
      awsConfig = configProd;
      break;
    default:
      awsConfig = config;
  }
  return { awsConfig };
};
