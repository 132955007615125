/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Box, Button, Flex, Link, Text } from "@chakra-ui/react";
import { NEW_RELIC_ACCOUNT_ID } from "env";

import {
  createNewRelicLinkTemplate,
  generateNewRelicLauncherParam,
} from "../utils/newRelic";

export type ThirdPartyPanelProps = {
  cognitoId: string;
  email: string;
};

export const ThirdPartyPanel = ({ cognitoId, email }: ThirdPartyPanelProps) => {
  const nrLinkTemplate = createNewRelicLinkTemplate(NEW_RELIC_ACCOUNT_ID!);
  const launcherData = generateNewRelicLauncherParam({
    cognitoId: cognitoId,
  });
  const nrLink = nrLinkTemplate.replace("[LAUNCHER_DATA]", launcherData);
  return (
    <Box border="1px solid" borderColor="grey.400" p={5} borderRadius={"base"}>
      <Text mb={2} fontWeight={"semibold"} textStyle={"bodyM"}>
        3rd Party Tools
      </Text>
      <Flex gap={2}>
        <Link
          href={`https://app.hubspot.com/contacts/40039512/objects/0-1/views/all/list?query=${encodeURIComponent(
            email,
          )}`}
          isExternal
          w="fit-content"
        >
          <Button>HubSpot</Button>
        </Link>
        <Link
          href={`https://mixpanel.com/project/3218100/view/3728164/app/profile#distinct_id=${encodeURIComponent(
            email,
          )}`}
          isExternal
          w="fit-content"
        >
          <Button>Mixpanel</Button>
        </Link>
        <Link href={nrLink} isExternal w="fit-content">
          <Button>New Relic</Button>
        </Link>
      </Flex>
    </Box>
  );
};
