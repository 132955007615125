import { useMutation } from "@tanstack/react-query";

import { BRANDS_KEY } from "services/ReactQueryKeys";
import httpClient from "services/httpClient";
import { queryClient } from "services/queryClient";

export const POST_APPROVE_BRAND_REQUEST = "/admin/brand-requests/";

const approveBrandRequest = async (brandRequestId: string) => {
  return await httpClient.post(
    `${POST_APPROVE_BRAND_REQUEST}${brandRequestId}/approve`,
  );
};

export const usePostApproveBrandRequest = () =>
  useMutation(approveBrandRequest, {
    onSuccess: () => {
      void queryClient.fetchQuery({ queryKey: [BRANDS_KEY] });
    },
  });
