/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Button, Flex, Text } from "@chakra-ui/react";
import { BaseSyntheticEvent } from "react";
import {
  FieldArrayWithId,
  FieldErrorsImpl,
  UseFormGetValues,
  UseFormRegister,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MSRPMultiplierType } from "./hooks/useGetMSRPMultipliers";
import { MultiplierFormFields } from "./hooks/useMultiplierForm";
import { InputField } from "components/InputField/InputField";
import { disableInputScroll } from "utils/formUtils";

type MultiplierCountryProps = {
  field: FieldArrayWithId<MultiplierFormFields, "multipliers">;
  getValues: UseFormGetValues<MultiplierFormFields>;
  index: number;
  register: UseFormRegister<MultiplierFormFields>;
  countryName: string;
  updateOne: (index: number) => (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unnecessary-type-arguments
    event?: BaseSyntheticEvent<object, any, any> | undefined,
  ) => Promise<void>;
  errors: Partial<
    FieldErrorsImpl<{
      multipliers: {
        multiplier: number;
        type: MSRPMultiplierType;
        countryCode: string;
        categoryId: string;
        brandId: string;
      }[];
    }>
  >;
};

const requiredMessage = "This field is required";

export const RetailerCountryMultiplier = ({
  field,
  index,
  register,
  countryName,
  errors,
  updateOne,
}: MultiplierCountryProps) => {
  const { t } = useTranslation();

  return (
    <Flex
      h="56px"
      p={2}
      bg="light.lightGrey"
      alignItems="baseline"
      justifyContent="space-between"
    >
      <Text textStyle="bodyM" fontWeight="medium" w="200px" pl={1}>
        {` ${countryName} (${field.countryCode ?? ""})`}
      </Text>
      <Flex w="260px">
        <InputField
          errors={errors}
          placeholder={"0"}
          defaultValue={field.multiplier ?? 1}
          type="number"
          {...register(`multipliers.${index}.multiplier`, {
            required: requiredMessage,
            validate: (value) => Number(value ?? 0) >= 0,
            valueAsNumber: true,
          })}
          mb={5}
          onWheel={disableInputScroll}
        />
      </Flex>
      <Flex w="120px">
        <Button
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={updateOne(index)}
        >
          {t("settings.msrp_settings.single_update")}
        </Button>
      </Flex>
    </Flex>
  );
};
