import { Box, Flex, Heading } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { History } from "./History/History";
import { ReadyForReview } from "./ReadyForReview/ReadyForReview";
import { TabData, TabWrapper } from "components/Tabs/TabWrapper";

export const ReturnRequests = () => {
  const [tabIndex, setTabIndex] = useState<number>(
    parseInt(window.location.hash.replace("#", "") || "0"),
  );

  const { t } = useTranslation();

  const tabsData: TabData[] = [
    {
      title: t("return_requests.ready_for_review"),
      content: <ReadyForReview />,
    },
    {
      title: t("return_requests.history"),
      content: <History />,
    },
  ];

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
    window.location.hash = `${index}`;
  };

  return (
    <Box margin="auto">
      <Flex display="column">
        <Heading as="h1" size="h1" fontWeight="bold" mb={2} mt={5} ml={10}>
          {t("return_requests.return_requests")}
        </Heading>
        <TabWrapper
          tabListProps={{ pl: 10 }}
          tabPanelsProps={{ pl: 2 }}
          tabsData={tabsData}
          tabIndex={tabIndex}
          handleTabsChange={handleTabsChange}
        />
      </Flex>
    </Box>
  );
};
