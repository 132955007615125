import { SVGProps } from "react";

const SvgSolidBuilding = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5 21C4.45 21 3.979 20.8043 3.587 20.413C3.19567 20.021 3 19.55 3 19V9C3 8.45 3.19567 7.979 3.587 7.587C3.979 7.19567 4.45 7 5 7H7V5C7 4.45 7.196 3.979 7.588 3.587C7.97933 3.19567 8.45 3 9 3H15C15.55 3 16.021 3.19567 16.413 3.587C16.8043 3.979 17 4.45 17 5V11H19C19.55 11 20.021 11.1957 20.413 11.587C20.8043 11.979 21 12.45 21 13V19C21 19.55 20.8043 20.021 20.413 20.413C20.021 20.8043 19.55 21 19 21H13V17H11V21H5ZM5 19H7V17H5V19ZM5 15H7V13H5V15ZM5 11H7V9H5V11ZM9 15H11V13H9V15ZM9 11H11V9H9V11ZM9 7H11V5H9V7ZM13 15H15V13H13V15ZM13 11H15V9H13V11ZM13 7H15V5H13V7ZM17 19H19V17H17V19ZM17 15H19V13H17V15Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgSolidBuilding;
