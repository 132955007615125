import { SVGProps } from "react";

const SvgRegularLocation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9 9C9.4125 9 9.76575 8.853 10.0598 8.559C10.3533 8.2655 10.5 7.9125 10.5 7.5C10.5 7.0875 10.3533 6.73425 10.0598 6.44025C9.76575 6.14675 9.4125 6 9 6C8.5875 6 8.2345 6.14675 7.941 6.44025C7.647 6.73425 7.5 7.0875 7.5 7.5C7.5 7.9125 7.647 8.2655 7.941 8.559C8.2345 8.853 8.5875 9 9 9ZM9 16.2188C8.9 16.2188 8.8 16.2 8.7 16.1625C8.6 16.125 8.5125 16.075 8.4375 16.0125C6.6125 14.4 5.25 12.9032 4.35 11.5223C3.45 10.1407 3 8.85 3 7.65C3 5.775 3.60325 4.28125 4.80975 3.16875C6.01575 2.05625 7.4125 1.5 9 1.5C10.5875 1.5 11.9843 2.05625 13.1903 3.16875C14.3967 4.28125 15 5.775 15 7.65C15 8.85 14.55 10.1407 13.65 11.5223C12.75 12.9032 11.3875 14.4 9.5625 16.0125C9.4875 16.075 9.4 16.125 9.3 16.1625C9.2 16.2 9.1 16.2188 9 16.2188Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgRegularLocation;
