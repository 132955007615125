/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/restrict-template-expressions */

/* eslint-disable @typescript-eslint/no-unsafe-argument */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Spinner } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";

import { ProductData, Suggestion, skuType } from "./Suggestion";
import httpClient from "services/httpClient";

const KeywordParser: React.FC<{ query: string }> = ({ query }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>();

  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://mr1ovrw2sh-3.algolianet.com/1/indexes/*/queries?x-algolia-agent=Algolia%20for%20JavaScript%20(4.20.0)%3B%20Browser%3B%20instantsearch.js%20(4.60.0)%3B%20react%20(18.2.0)%3B%20react-instantsearch%20(7.3.0)%3B%20react-instantsearch-core%20(7.3.0)%3B%20next.js%20(13.5.4)%3B%20JS%20Helper%20(3.15.0)%3B%20autocomplete-core%20(1.12.1)%3B%20autocomplete-js%20(1.12.1)",
        `{"requests":[{"indexName":"variants-from-connector","params":"analytics=true&clickAnalytics=true&facets=%5B%22attributes.brand-location%22%2C%22attributes.brand-name%22%2C%22attributes.made-in%22%2C%22attributes.production-lead-time%22%2C%22attributes.universal-price%22%2C%22categories.en-HK.lvl0%22%5D&filters=&highlightPostTag=__%2Fais-highlight__&highlightPreTag=__ais-highlight__&hitsPerPage=48&maxValuesPerFacet=99999&page=0&query=${query}&tagFilters=&userToken=9f624c5a-da04-4187-8a02-653668f81af8"}]}`,
        {
          headers: {
            "x-algolia-api-key": "287e14375a4ea464991d55a040f5699c",
            "x-algolia-application-id": "MR1OVRW2SH",
          },
        },
      );

      const hits = response.data.results[0].hits;
      const skuNames = await hits
        .filter((hit?: ProductData) => hit !== undefined)
        .map((hit: ProductData) => hit.sku);

      const payload = JSON.stringify({
        skus: skuNames,
      });

      const productPrices = `https://api.markato.com/products/prices`;

      const pricesResponse = await httpClient.post(
        `${productPrices}?ts=${Date.now()}`,
        payload,
        {
          headers: {
            sessionId: "UI-ADMIN",
            "x-locale": "en-hk",
          },
        },
      );

      const data: JSX.Element[] = hits.map((data: ProductData) => {
        const sku = pricesResponse.data[
          data.sku as unknown as string
        ] as skuType;
        return (
          <Suggestion
            key={data.name["en-US"]}
            name={data.name}
            attributes={data.attributes}
            images={data.images}
            sku={sku}
          />
        );
      });
      setData(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    void fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return <div>{isLoading ? <Spinner /> : data}</div>;
};

export default KeywordParser;
