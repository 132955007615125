/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/restrict-template-expressions */

/* eslint-disable @typescript-eslint/no-unsafe-argument */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Spinner } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { ReactI18NextChild } from "react-i18next";

const extractUrls = (text: string): string[] => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const urls = text.match(urlRegex) ?? [];
  return urls.map((url) => url.replace(/',/g, ""));
};

const SqlParser: React.FC<{ query: string }> = ({ query }) => {
  const [data, setData] = useState<JSX.Element>();
  const [isLoading, setIsLoading] = useState<boolean>();

  useEffect(() => {
    setIsLoading(true);

    const fetchData = async () => {
      try {
        const headersList = {
          Accept: "*/*",
          "User-Agent": "Thunder Client (https://www.thunderclient.com)",
          "Content-Type": "text/plain",
        };
        const reqOptions = {
          url: "https://ip0wkdi66k.execute-api.ap-southeast-1.amazonaws.com/default/sqlProductSearch",
          method: "POST",
          headers: headersList,
          data: query,
        };
        const response = await axios.request(reqOptions);
        console.log(response.data);
        const data = response.data.map(
          (
            data: Record<
              string,
              | string
              | number
              | boolean
              | React.ReactElement
              | React.ReactFragment
              | React.ReactPortal
              | Iterable<ReactI18NextChild>
              | null
              | undefined
            >,
          ) => (
            <h6
              key={JSON.stringify(data["Product Name"])}
              style={{ display: "flex", alignItems: "center", gap: "10px" }}
            >
              <a
                href={`https://www.markato.com/product/${data["Product Key"]}`}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  style={{ height: "50px" }}
                  src={extractUrls(`${data["Product Images"]}`)[0]}
                  alt=""
                />
              </a>
              {data["Brand Name"]}:{" "}
              <a
                href={`https://www.markato.com/product/${data["Product Key"]}`}
                target="_blank"
                rel="noreferrer"
              >
                {data["Product Name"]}
              </a>
            </h6>
          ),
        );
        setData(data);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    void fetchData();
  }, [query]);

  return <div>{isLoading ? <Spinner /> : data}</div>;
};

export default SqlParser;
