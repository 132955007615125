/* eslint-disable @tanstack/query/exhaustive-deps */
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { AdminAccount } from "../types";
import { ADMINS_KEY } from "services/ReactQueryKeys";
import httpClient from "services/httpClient";

export const GET_ALL_ADMIN_URL = "/admin/accounts";

export const useGetAllAdmins = (): UseQueryResult<AdminAccount[]> =>
  useQuery({
    queryKey: [ADMINS_KEY],
    queryFn: async () => {
      const { data: adminAccounts } = await httpClient.get<AdminAccount>(
        GET_ALL_ADMIN_URL,
      );
      return adminAccounts;
    },
  });
