import { Box, Button, Flex, Heading } from "@chakra-ui/react";
import { isPast } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { EditPromotion, PromotionForm } from "./EditPromotion";
import { PromotionList } from "./PromotionList";
import { PromotionResponse } from "./usePromotions";
import { useGetPromotions } from "./usePromotions";
import { TabData, TabWrapper } from "components/Tabs/TabWrapper";
import { SolidPlus } from "styles/icons/solid";

export const Promotions = () => {
  const { t } = useTranslation();

  const [tabIndex, setTabIndex] = useState<number>(
    parseInt(window.location.hash.replace("#", "") || "0"),
  );

  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [promotion, setPromotion] = useState<PromotionForm>();

  const { data: promotions, isLoading } = useGetPromotions();

  const pastPromotions = [];
  const currentPromotions = [];

  if (promotions && promotions.length > 0) {
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < promotions.length; i++) {
      if (isPast(new Date(promotions[i].untilDate))) {
        pastPromotions.push(promotions[i]);
      } else {
        currentPromotions.push(promotions[i]);
      }
    }
  }

  const handlePromotionClick = (promotion: PromotionResponse) => {
    setPromotion({
      ...promotion,
      discount: promotion.discount.value[0].amount,
    });
    setIsEditOpen(true);
  };

  const tabsData: TabData[] = [
    {
      title: t("promotions.current"),
      content: (
        <PromotionList
          promotions={currentPromotions}
          isLoading={isLoading}
          onPromotionClick={handlePromotionClick}
        />
      ),
    },
    {
      title: t("promotions.history"),
      content: (
        <PromotionList
          promotions={pastPromotions}
          isLoading={isLoading}
          onPromotionClick={handlePromotionClick}
        />
      ),
    },
  ];

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
    window.location.hash = `${index}`;
  };

  const handleAddPromotionClick = () => {
    setIsCreateOpen(true);
  };

  return (
    <>
      <Box margin="auto">
        <Flex display="column">
          <Flex
            width="100%"
            justify="space-between"
            align="center"
            paddingRight={10}
          >
            <Heading as="h1" size="h1" fontWeight="bold" mb={4} mt={5} ml={10}>
              {t("promotions.title")}
            </Heading>
            <Button
              size="lg"
              leftIcon={<SolidPlus fontSize={24} />}
              fontWeight="semibold"
              marginTop={10}
              onClick={handleAddPromotionClick}
              data-test-id="addPromotion"
            >
              {t("promotions.new")}
            </Button>
          </Flex>
          <TabWrapper
            tabListProps={{ pl: 10 }}
            tabPanelsProps={{ pl: 2 }}
            tabsData={tabsData}
            tabIndex={tabIndex}
            handleTabsChange={handleTabsChange}
          />
        </Flex>
      </Box>
      <EditPromotion
        isOpen={isCreateOpen}
        onClose={() => setIsCreateOpen(false)}
      />
      {promotion && isEditOpen && (
        <EditPromotion
          isOpen={isEditOpen}
          onClose={() => setIsEditOpen(false)}
          promotion={promotion}
        />
      )}
    </>
  );
};
