/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Flex, Link, Text } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { format, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";

import { Transaction, convertWalletType } from "./useGetWalletCredit";

type UseSetupTransactionsTable = {
  currency: string;
};
export const useSetupTransactionsTable = ({
  currency,
}: UseSetupTransactionsTable) => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<Transaction>();

  const columns = [
    columnHelper.accessor("orderNumber", {
      cell: ({ row }) => (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {row.original.orderId ? (
            <Link href={`/orders/${row.original.orderId}`}>
              <Text textStyle="bodyS" color="#1C7EC6" fontWeight="medium">
                {`#${row.original.orderNumber}`}
              </Text>
            </Link>
          ) : (
            <Text textStyle="bodyS" fontWeight="medium">
              {row.original.orderNumber ? `#${row.original.orderNumber}` : ""}
            </Text>
          )}
        </>
      ),
      header: t("wallet_credit.order_header"),
      size: 10,
      enableSorting: false,
    }),
    columnHelper.accessor("transactionReference", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {row.original.manuallyAddedCredit
            ? ""
            : row.original.transactionReference}
        </Text>
      ),
      header: t("wallet_credit.payment_header"),
      size: 30,
      enableSorting: false,
    }),
    columnHelper.accessor("creationDate", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {format(parseISO(row.original.creationDate), "dd-MM-yyyy HH:mmaaa")}
        </Text>
      ),
      header: t("wallet_credit.date_header"),
      size: 10,
      enableSorting: false,
    }),
    columnHelper.accessor("type", {
      cell: ({ row }) => (
        <Flex flexDir="column" textStyle="bodyS">
          <Text fontWeight="medium">
            {convertWalletType(row.original.type)}
            {row.original.manuallyAddedCredit
              ? ` - ${row.original.email!}`
              : ""}
          </Text>
          <Text>{row.original.description}</Text>
        </Flex>
      ),
      header: t("wallet_credit.type_header"),
      size: 30,
      enableSorting: false,
    }),
    columnHelper.accessor("amount", {
      cell: ({ row }) => (
        <Text
          textStyle="bodyS"
          color={row.original.amount > 0 ? "green.500" : "red.500"}
        >
          {Intl.NumberFormat("en-US", {
            style: "currency",
            currency: currency,
            signDisplay: "always",
          }).format(Number(row.original.amount))}
        </Text>
      ),
      header: t("wallet_credit.amount_header"),
      size: 10,
      enableSorting: false,
    }),
  ];

  return {
    columns,
  };
};
