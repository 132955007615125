import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from "@chakra-ui/react";
import { Locale, format, isValid, parse } from "date-fns";
import enUS from "date-fns/locale/en-US";
import {
  ChangeEvent,
  Dispatch,
  FocusEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { Calendar, CalendarProps } from "../Calendar/Calendar";
import { calendarIconStyle } from "./SingleDatePicker.styles";
import { useClickOutside } from "hooks/useClickOutside";
import { SolidCalendarEvent } from "styles/icons/solid";
import { now } from "utils/dateUtils";

export type SingleDatePickerProps = {
  onValueChange: (value: Date, event?: ChangeEvent<HTMLInputElement>) => void;
  value?: Date;
  inputLocale?: Locale;
  inputFormat?: string;
  isInvalidField?: boolean;
  isInitiallyOpen?: boolean;
  setInvalidField?: Dispatch<SetStateAction<boolean | undefined>>;
  disabled?: boolean;
  inputProps?: InputProps;
  height?: string | number;
  disableExcludedTagNames?: boolean;
} & CalendarProps;

export const SingleDatePicker = ({
  value,
  onValueChange,
  inputLocale = enUS,
  inputFormat = "P",
  isInvalidField,
  isInitiallyOpen = false,
  setInvalidField,
  disabled,
  inputProps,
  height,
  disableExcludedTagNames = false,
  ...rest
}: SingleDatePickerProps) => {
  const [open, setOpen] = useState(isInitiallyOpen);
  const [date, setDate] = useState("");
  const wrapperRef = useRef(null);

  useClickOutside(
    wrapperRef,
    () => {
      setOpen(false);
    },
    disableExcludedTagNames,
  );

  useEffect(() => {
    if (value === undefined) {
      return;
    }

    setDate(format(value, inputFormat, { locale: inputLocale }));
    setInvalidField?.(false);
  }, [inputFormat, inputLocale, setInvalidField, value]);

  const handleOnBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      const parsedDate = parse(event.target.value, inputFormat, now(), {
        locale: inputLocale,
      });
      const isValidDate = isValid(parsedDate);
      setInvalidField?.(!isValidDate);
      isValidDate && onValueChange(parsedDate);
    },
    [inputFormat, inputLocale, onValueChange, setInvalidField],
  );

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value);
  };

  return (
    <>
      <InputGroup cursor="pointer">
        <Input
          value={date}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          isInvalid={isInvalidField}
          placeholder="Select date"
          paddingY={2.5}
          height={height ?? 10}
          disabled={disabled}
          {...inputProps}
        />
        <InputRightElement
          zIndex={0}
          height="100%"
          cursor={disabled ? "not-allowed" : "pointer"}
        >
          <Icon
            as={SolidCalendarEvent}
            {...calendarIconStyle(disabled)}
            onClick={() =>
              !disabled && setOpen((currentIsOpen) => !currentIsOpen)
            }
          />
        </InputRightElement>
      </InputGroup>

      {open ? (
        <Box ref={wrapperRef}>
          <Calendar onChange={onValueChange} value={value} {...rest} />
        </Box>
      ) : null}
    </>
  );
};
