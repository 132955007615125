import { useMutation } from "@tanstack/react-query";

import { ErrorResponse } from "services/ErrorResponse";
import httpClient from "services/httpClient";

export const FORCE_CANCEL_SUBORDER = "/admin/orders/";

const forceCancelSubOrder = async (subOrderId: string) => {
  return await httpClient.put(
    `${FORCE_CANCEL_SUBORDER}${subOrderId}/force-cancel`,
    {},
  );
};

export const useForceCancelSubOrder = (
  onSuccess: () => void,
  onError: (data: ErrorResponse) => void,
) =>
  useMutation(forceCancelSubOrder, {
    onSuccess,
    onError,
  });
