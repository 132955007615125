import { useMutation } from "@tanstack/react-query";

import {
  ExportProductFormFields,
  ExportProductJSONReturnType,
} from "./usePostExportProductsForm";
import { ErrorResponse } from "services/ErrorResponse";
import httpClient from "services/httpClient";

export const JSON_POST_PRODUCTS = "/admin/support/export-products";

const postProductJSON = async (form: ExportProductFormFields) => {
  const { data: products } = await httpClient.post<ExportProductJSONReturnType>(
    JSON_POST_PRODUCTS,
    {
      brands: form.brands,
      categories: form.categories,
      keywords: form.keywords,
      minWholesalePrice: Number(form.minWholesalePrice),
      maxWholesalePrice: Number(form.maxWholesalePrice),
      minRetailPrice: Number(form.minRetailPrice),
      maxRetailPrice: Number(form.maxRetailPrice),
      margin: Number(form.margin),
    },
  );
  return products;
};

export const usePostProductJSON = (
  onSuccess: (response: ExportProductJSONReturnType) => void,
  onError: (data: ErrorResponse) => void,
) =>
  useMutation(postProductJSON, {
    onSuccess,
    onError,
  });
