/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Button, Flex } from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import {
  Control,
  UseFormGetValues,
  UseFormSetValue,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { SettingsFormFields } from "./hooks/useSettingsForm";
import { AutocompleteField } from "components/AutocompleteField/AutocompleteField";
import { InputField } from "components/InputField/InputField";
import { ModalWrapper } from "components/Modal/ModalWrapper";
import { Option } from "components/Select/Select";

type AddBankDetailsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  setValue: UseFormSetValue<SettingsFormFields>;
  getMainFormValues: UseFormGetValues<SettingsFormFields>;
  bankDetailsExist: boolean;
};

type AddBankDetailsFields = {
  name: string;
  country: Option;
  address: string;
};

export const AddBankDetailsModal = ({
  isOpen,
  onClose,
  setValue,
  getMainFormValues,
  bankDetailsExist,
}: AddBankDetailsModalProps) => {
  const { t } = useTranslation();
  const {
    control,
    register,
    formState: { errors, isDirty, isValid },
    getValues,
    setValue: setValueLocal,
    reset,
  } = useForm<AddBankDetailsFields>();

  const isFormValid = useMemo(() => {
    return !isDirty || !isValid;
  }, [isDirty, isValid]);

  const requiredMessage = "This field is required";

  const handleOnAddBankDetails = () => {
    setValue("companyName", getValues("name"), { shouldDirty: true });
    setValue("companyCountry", getValues("country.label"), {
      shouldDirty: true,
    });
    setValue("companyAddress", getValues("address"), { shouldDirty: true });
    onClose();
    reset();
  };

  useEffect(() => {
    if (bankDetailsExist) {
      setValueLocal("country", {
        label: getMainFormValues("companyCountry"),
        value: getMainFormValues("companyCountry"),
      });
    }
  }, [bankDetailsExist, getMainFormValues, setValueLocal]);
  return (
    <ModalWrapper
      modalProps={{ isOpen, onClose, children: undefined }}
      isOpen={isOpen}
      onClose={onClose}
      modalHeader={t("settings.bank_transfer_company_details_header")}
      showHeaderDivider
      modalBody={
        <Flex flexDir="column" textStyle="bodyS" gap={5}>
          <InputField
            errors={errors}
            label={t("settings.company_name_modal")}
            placeholder={t("settings.enter_your_company_name")}
            {...register("name", { required: requiredMessage })}
            defaultValue={getMainFormValues("companyName")}
          />
          <AutocompleteField
            label={t("settings.country_region")}
            placeholder={t("settings.select_your_country")}
            options={[{ value: "Hong Kong", label: "Hong Kong" }]}
            closeMenuOnSelect
            name="country"
            control={control as unknown as Control}
            rules={{ required: requiredMessage }}
            w="2xs"
          />
          <InputField
            errors={errors}
            label={t("settings.address_modal")}
            placeholder={t("settings.enter_street_number")}
            {...register("address", { required: requiredMessage })}
            mb={4}
            defaultValue={getMainFormValues("companyAddress")}
          />
        </Flex>
      }
      modalFooter={
        <Button
          size="xl"
          disabled={isFormValid}
          onClick={handleOnAddBankDetails}
          data-test-id="addBankDetails"
          w="100%"
        >
          {bankDetailsExist
            ? t("settings.change_address")
            : t("settings.add_address")}
        </Button>
      }
    />
  );
};
