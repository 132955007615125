/* eslint-disable @tanstack/query/exhaustive-deps */
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import httpClient from "services/httpClient";

export const GET_RETAILER_REQEUSTS =
  "/admin/business-registration-requests/history/{id}";

export type BrHistoryRequest = {
  history: {
    adminId: string;
    creationDate: string;
    id: string;
    note: string;
    retailer: string;
    retailerId: string;
    type: string;
  }[];
  documents: {
    documentRef: string;
    id: string;
    name: string;
    requestId: string;
    retailerId: string;
  }[];
};

export const useGetBrRequestsHistory = (
  id: string,
): UseQueryResult<BrHistoryRequest> =>
  useQuery({
    queryKey: [GET_RETAILER_REQEUSTS, id],
    queryFn: async () => {
      const { data } = await httpClient.get<BrHistoryRequest>(
        GET_RETAILER_REQEUSTS.replace("{id}", id),
      );
      return data;
    },
  });
