import { useMutation } from "@tanstack/react-query";

import { BRAND_UPDATE_PAGE_REQUESTS_KEY } from "services/ReactQueryKeys";
import httpClient from "services/httpClient";
import { queryClient } from "services/queryClient";

export const POST_APPROVE_BRAND_PAGE_UPDATE_REQUEST =
  "/admin/brand-page-requests/";

const approveBrandPageUpdateRequest = async (
  brandPageUpdateRequestId: string,
) => {
  return await httpClient.post(
    `${POST_APPROVE_BRAND_PAGE_UPDATE_REQUEST}${brandPageUpdateRequestId}/approve`,
  );
};

export const usePostApproveBrandPageUpdateRequest = () =>
  useMutation(approveBrandPageUpdateRequest, {
    onSuccess: () => {
      void queryClient.fetchQuery({
        queryKey: [BRAND_UPDATE_PAGE_REQUESTS_KEY],
      });
    },
  });
