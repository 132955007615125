import { UseQueryResult, useQuery } from "@tanstack/react-query";

import httpClient from "services/httpClient";

export const GET_CURRENCIES = "/admin/vouchers/currencies";

export type Currency = {
  name: string;
  currencyCode: string;
  currencySign: string;
};

export const useGetCurrencies = (): UseQueryResult<Currency[]> =>
  useQuery({
    queryKey: [GET_CURRENCIES],
    queryFn: async () => {
      const { data: currencies } = await httpClient.get<Currency>(
        GET_CURRENCIES,
      );
      return currencies;
    },
  });
