import { Option, SelectFilter } from "chakra-multiselect";

// Copy from https://github.com/bmartel/chakra-multiselect/blob/565df3c2a78e1f094a0c34b8d9cffe77db1c6b38/src/use-select.ts#L152
export const filterOnLabelAndValue: SelectFilter = (
  options,
  searchValue,
  getOption,
) => {
  return options
    .filter(
      (option) =>
        getOption(option)
          .label.toString()
          .toLowerCase()
          .includes(searchValue.toString().toLowerCase()) ||
        (getOption(option) as Option).value
          .toString()
          .toLowerCase()
          .includes(searchValue.toString().toLowerCase()),
    )
    .sort((a) => {
      return (getOption(a) as Option).label
        .toString()
        .toLowerCase()
        .indexOf(searchValue.toString().toLowerCase());
    });
};
