/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Box,
  Flex,
  Heading,
  Spinner,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { format, isFuture, parseISO } from "date-fns";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Actions } from "./Actions/Actions";
import { OrderDetails } from "./Details/OrderDetails";
import { useGetOrderById } from "./Details/hooks/useGetOrderById";
import { FinancialDetails } from "./FinancialDetails/FinancialDetails";
import { RetailerDetails } from "./RetailerDetails/RetailerDetails";
import { ReturnDetails } from "./ReturnDetails/ReturnDetails";
import { OrderSettings } from "./Settings/Settings";
import { usePutCancelOrder } from "./hooks/usePutCancelOrderById";
import { useRejectOrderForm } from "./hooks/useRejectOrderForm";
import { Button } from "components/Button/Button";
import { ModalWrapper } from "components/Modal/ModalWrapper";
import { Status } from "components/Status/Status";
import { TabData, TabWrapper } from "components/Tabs/TabWrapper";
import {
  capitalizeFirstLetter,
  convertToStatusVariant,
} from "features/UserManagement/utils";
import { getErrorCodes } from "services/httpClient";
import { SolidArrowChevronLeft } from "styles/icons/solid";

export const SingleOrder = () => {
  const [tabIndex, setTabIndex] = useState<number>(
    parseInt(window.location.hash.replace("#", "") || "0"),
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { orderId } = useParams();

  const ORDERS_REQUEST_URL = "/orders";

  const {
    isOpen: isOpenReject,
    onOpen: onOpenReject,
    onClose: onCloseReject,
  } = useDisclosure();

  const toast = useToast();

  const { register, isFormValid, getValues } = useRejectOrderForm();

  const { mutateAsync: rejectOrder, isLoading: isLoadingReject } =
    usePutCancelOrder();

  const { data: order, isLoading } = useGetOrderById(orderId ?? "");

  const tabsData: TabData[] = useMemo(() => {
    const tabs = [
      {
        title: t("order.order_details"),
        content: order ? <OrderDetails order={order} /> : <Box />,
      },
      {
        title: t("order.retailer_details"),
        content: order ? <RetailerDetails order={order} /> : <Box />,
      },
      {
        title: t("order.payment_details"),
        content: order ? (
          <FinancialDetails
            orderId={order.id}
            orderNumber={order.orderNumber}
          />
        ) : (
          <Box />
        ),
      },
      {
        title: t("order.actions"),
        content: order ? <Actions orderId={order.id} /> : <Box />,
      },
    ];

    if (
      order &&
      order.status === "PENDING" &&
      isFuture(parseISO(order.cancelDueDate))
    ) {
      tabs.push({
        title: t("order.settings"),
        content: (
          <OrderSettings
            orderId={order.id}
            cancelDueDate={order.cancelDueDate}
          />
        ),
      });
    }

    tabs.push({
      title: t("order.return_details"),
      content: order ? <ReturnDetails orderId={order.id} /> : <Box />,
    });

    return tabs;
  }, [order, t]);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
    window.location.hash = `${index}`;
  };

  const handleCancelOrder = async () => {
    await rejectOrder(
      {
        id: orderId!,
        reason: getValues().reason,
      },
      {
        onSuccess: () => {
          toast({
            description: `Order ${orderId!} has been rejected`,
          });
        },
        onError: (error) => {
          const errorCodes = getErrorCodes(error);
          toast({ status: "error", description: t(errorCodes[0]) });
        },
      },
    );
    navigate(ORDERS_REQUEST_URL);
  };

  if (isLoading) {
    return <Spinner position="absolute" top="50%" left="50%" />;
  }

  return (
    <Box margin="auto">
      <Flex display="column">
        <Flex mb={2} mt={5} ml={10} mr={10} justifyContent="space-between">
          <Flex>
            <Button
              variant="outline"
              colorScheme="grey"
              mainIcon={SolidArrowChevronLeft}
              aria-label="backButton"
              data-test-id="backToAdminList"
              alignSelf="center"
              mr={4}
              onClick={() => window.history.back()}
            />
            <Heading as="h1" size="h1" fontWeight="bold">
              {order && `#${order.orderNumber}`}
            </Heading>
            <Text
              textStyle="bodyS"
              color="gey.700"
              fontWeight="medium"
              mx={5}
              alignSelf="center"
            >
              {order?.creationDate &&
                `${t("order.order_date").toString()} ${format(
                  parseISO(order.creationDate),
                  "dd.MM.yyyy",
                )}`}
            </Text>
            <Status
              display="flex"
              variant={convertToStatusVariant(order?.status ?? "done")}
              text={capitalizeFirstLetter(order?.status ?? t("order.active"))}
              ml={3.5}
              h="22px"
              alignSelf="center"
            />
          </Flex>
          {order && order.status === "PENDING" ? (
            <Flex direction="column" align="flex-end">
              <Flex textStyle="bodyS">
                <Text fontWeight="bold" mr={2} mb={3}>
                  {t("order.auto_conf_due")}
                </Text>
                <Text>
                  {format(parseISO(order.cancelDueDate), "dd-MM-yyyy HH:mm:ss")}
                </Text>
              </Flex>
              <Button
                variant="solid"
                colorScheme="red"
                size="md"
                width="110px"
                onClick={onOpenReject}
              >
                {t("order.cancel_order")}
              </Button>
            </Flex>
          ) : null}
        </Flex>
        <TabWrapper
          tabListProps={{ pl: 10 }}
          tabPanelsProps={{ pl: 2 }}
          tabsData={tabsData}
          tabIndex={tabIndex}
          handleTabsChange={handleTabsChange}
        />
      </Flex>

      <ModalWrapper
        isOpen={isOpenReject}
        onClose={onCloseReject}
        modalProps={{
          size: "sm",
          children: undefined,
          isOpen: isOpenReject,
          onClose: onCloseReject,
        }}
        showHeaderDivider
        modalHeader={`${t("order.reject_order")} #${order?.orderNumber ?? ""}`}
        modalBody={
          <>
            <Text textStyle="bodyM" color="black" mb={1}>
              {t("Add Comment")}
            </Text>
            <Textarea
              height="125px"
              resize="none"
              placeholder={t("order.reason_rejecting_order")}
              {...register("reason", {
                required: true,
                validate: (value) => !!value.trim(),
              })}
            />
          </>
        }
        modalFooter={
          <>
            <Button
              variant="outline"
              colorScheme="grey"
              fontWeight="600"
              color="grey.black"
              onClick={onCloseReject}
              data-test-id="cancelAddAdminButton"
            >
              {t("Cancel")}
            </Button>
            <Button
              variant="solid"
              colorScheme="red"
              isLoading={isLoadingReject}
              disabled={isFormValid || isLoadingReject}
              onClick={() => void handleCancelOrder()}
            >
              {t("Reject")}
            </Button>
          </>
        }
      />
    </Box>
  );
};
