/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  Box,
  Button,
  Flex,
  Input,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Row } from "@tanstack/react-table";
import { isUndefined } from "lodash";
import { useEffect, useState } from "react";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ConfirmEditOrderModal } from "../ConfirmEditOrderModal/ConfirmEditOrderModal";
import { SubOrderTable } from "../SubOrder";
import {
  EditSuborderPreviewFields,
  useEditOrderPreviewForm,
} from "../hooks/useEditOrderPreviewForm";
import { AdminSuborderProductDTO } from "../hooks/useGetBrandProductsBySuborderId";
import { SubOrder } from "../hooks/useGetOrderById";
import { AutocompleteField } from "components/AutocompleteField/AutocompleteField";
import { InputField } from "components/InputField/InputField";
import { ModalWrapper } from "components/Modal/ModalWrapper";
import { Option } from "components/Select/Select";
import { TableWrapper } from "components/Table/TableWrapper";
import { TextWithIcon } from "components/TextWithIcon/TextWithIcon";
import { bodyM } from "styles/foundations";
import { SolidClose, SolidPlus } from "styles/icons/solid";
import { roundPriceDecimal } from "utils/priceUtils";

export type EditOrderPreviewModalProps = {
  isOpen: boolean;
  onClose: () => void;
  subOrder: SubOrder;
  currency: string;
  brandProducts?: AdminSuborderProductDTO;
  isProductsLoading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: any;
  originalOrderPrice: number;
};

export type ProductOption = Option & {
  sku: string;
  variantKey: string;
  unitPrice: number;
  caseQuantity: number;
  productKey: string;
};

export const EditOrderPreviewModal = ({
  isOpen,
  onClose,
  subOrder,
  currency,
  brandProducts,
  isProductsLoading,
  columns,
  originalOrderPrice,
}: EditOrderPreviewModalProps) => {
  const brandCurrency = subOrder.products[0].currency.brand;
  const brandCurrecyMultiplier = 1 / subOrder.brandToRetailerConversionRate;
  const { t } = useTranslation();
  const [addProductBar, setAddProductBar] = useState<number>(0);

  const {
    isOpen: isOpenEditConfirmationModal,
    onOpen: onOpenEditConfirmationModal,
    onClose: onCloseEditConfirmationModal,
  } = useDisclosure();

  const {
    onSubmit,
    register,
    getValues,
    watch,
    control,
    errors,
    unregister,
    reset,
    setValue,
    isPostPreviewLoading,
  } = useEditOrderPreviewForm({
    suborder: subOrder,
    openModal: onOpenEditConfirmationModal,
  });
  const handleDecimalChange = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedCharacters = [
      ..."0123456789",
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
    ];

    if (!allowedCharacters.includes(ev.key)) {
      ev.preventDefault();
    }
  };

  const handlePriceChange = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedCharacters = [
      ...".0123456789",
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
    ];

    if (!allowedCharacters.includes(ev.key)) {
      ev.preventDefault();
    }
  };

  const getAdjustedPrice = (cellIndex: number, originalPrice: number) => {
    const adjustedBrandPrice = watch(`products.${cellIndex}.adjustedPrice`);
    let price;
    if (adjustedBrandPrice) {
      price = adjustedBrandPrice * subOrder.brandToRetailerConversionRate;
    } else {
      price = adjustedBrandPrice === 0 ? 0 : originalPrice;
    }
    return roundPriceDecimal(price);
  };

  const createProductLevelInputField = (
    registeredName: string,
    index: number,
  ) => {
    const registered = `products.${index}.${registeredName}`;
    return (
      <Input
        placeholder={subOrder.products[index]?.unitQuantity?.toString() ?? "0"}
        onKeyDown={handleDecimalChange}
        type="number"
        {...register(registered as keyof EditSuborderPreviewFields, {
          valueAsNumber: true,
          pattern: /^[0-9]+$/,
        })}
      />
    );
  };

  const createTextField = (currency: string, value: number) => {
    return (
      <Text>
        {Intl.NumberFormat("en-US", {
          style: "currency",
          currency: currency,
        }).format(value)}
      </Text>
    );
  };

  const transformColumns = () => {
    const [RETAILER_PRICE_INDEX, QUANTITY_INDEX, SUBTOTAL_INDEX] = [2, 3, 4];
    columns[QUANTITY_INDEX].header = t("order.quantity_of_unit");
    columns[QUANTITY_INDEX].cell = (cell: { row: Row<SubOrderTable> }) => {
      return createProductLevelInputField("unitQuantity", cell.row.index);
    };

    columns[SUBTOTAL_INDEX].cell = (cell: { row: Row<SubOrderTable> }) => {
      const dirtyUnitQuantity = getValues(
        `products.${cell.row.index}.unitQuantity`,
      );

      const unitQuantity = Number.isNaN(dirtyUnitQuantity)
        ? 0
        : dirtyUnitQuantity;

      const price = getAdjustedPrice(
        cell.row.index,
        Number(cell.row.original.price),
      );

      return createTextField(currency, Number(price) * unitQuantity);
    };

    columns[RETAILER_PRICE_INDEX].header = "Original Price (Retailer Currency)";
    columns[RETAILER_PRICE_INDEX].cell = (cell: {
      row: Row<SubOrderTable>;
    }) => {
      const price = getAdjustedPrice(
        cell.row.index,
        Number(cell.row.original.price),
      );
      return createTextField(currency, Number(price));
    };
  };

  useEffect(() => {
    transformColumns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // New Product Dropdown
  const productOptions: ProductOption[] = [];
  if (brandProducts) {
    const { data: addProducts } = brandProducts;

    addProducts.forEach((product) => {
      productOptions.push({
        label: `${product.productName} (${
          product.variantName ?? "Master"
        }) - ${Intl.NumberFormat("en-US", {
          style: "currency",
          currency: brandCurrency,
        }).format(Number(product.unitPrice) * brandCurrecyMultiplier)}`,
        value: `${product.productKey}-${product.variantKey}`,
        ...product,
      });
    });
  }

  const addAddedProduct = () => {
    const addedProductsArray = getValues("addedProducts");
    if (addedProductsArray) {
      setValue(`addedProducts`, [
        ...addedProductsArray,
        {
          key: productOptions[0],
          unitQuantity: 0,
          isExistingItem: false,
        },
      ]);
    } else {
      setValue(`addedProducts`, [
        {
          key: productOptions[0],
          unitQuantity: 0,
          isExistingItem: false,
        },
      ]);
    }
    setAddProductBar((n) => n + 1);
  };

  const deleteAddedProduct = () => {
    if (addProductBar !== 0) {
      const addedProductsArray = getValues("addedProducts");
      addedProductsArray?.pop();
      unregister(`addedProducts.${addProductBar - 1}`);
      setValue(`addedProducts`, addedProductsArray);
      setAddProductBar((n) => n - 1);
    }
  };

  const calculateNewOrderTotal = () => {
    let oldProductsTotal = 0;
    const oldProducts = watch("products");
    for (let i = 0; i < oldProducts.length; i++) {
      const price = getAdjustedPrice(i, Number(subOrder.products[i].price));
      oldProductsTotal +=
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        Number(price) * Number(oldProducts[i].unitQuantity);
    }
    const orderDetails = getValues();

    // get new products
    let newProductsTotal = 0;
    const newProductDetails = orderDetails.addedProducts;
    if (newProductDetails?.[newProductDetails.length - 1]?.key) {
      newProductDetails.forEach((newProduct) => {
        let currentProductPrice;
        if (
          typeof newProduct.adjustedBrandPrice === "number" &&
          newProduct.adjustedBrandPrice >= 0 &&
          !isNaN(newProduct.adjustedBrandPrice)
        ) {
          currentProductPrice =
            roundPriceDecimal(
              newProduct.adjustedBrandPrice *
                subOrder.brandToRetailerConversionRate,
            ) * newProduct.unitQuantity;
        } else {
          currentProductPrice =
            newProduct.key.unitPrice * newProduct.unitQuantity;
        }
        isNaN(currentProductPrice)
          ? (newProductsTotal += 0)
          : (newProductsTotal += currentProductPrice);
      });
    }
    const total = oldProductsTotal + newProductsTotal;
    if (isNaN(total)) return 0;
    return oldProductsTotal + newProductsTotal;
  };

  return (
    <>
      <ModalWrapper
        modalProps={{ size: "full", isOpen, onClose, children: undefined }}
        isOpen={isOpen}
        onClose={onClose}
        modalHeader={
          <Flex flexDir="column" textStyle="h3" fontWeight="bold">
            <Text>{t("order.edit_suborder")}</Text>
            <Text>{`#${subOrder.orderNumber}`}</Text>
          </Flex>
        }
        modalBody={
          <>
            <TableWrapper
              tableData={subOrder.products}
              columns={columns as unknown}
              disableColumnClick
              disablePagination
            />
            <Box pb={3}>
              <Text>
                {t("order.previous_suborder_total")}
                {` `}
                <Text as={"b"}>
                  {Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: currency,
                  }).format(Number(originalOrderPrice))}
                </Text>
              </Text>
              <Text>
                {t("order.current_suborder_total")}
                {` `}
                <Text as={"b"}>
                  {Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: currency,
                  }).format(Number(calculateNewOrderTotal()))}
                </Text>
              </Text>
            </Box>
            {[...Array(addProductBar)].map((_, i) => {
              const productUnitPrice = watch(
                `addedProducts.${i}.key.unitPrice`,
              );
              const unitQuantity = watch(`addedProducts.${i}.unitQuantity`);
              const adjustedBrandPrice = watch(
                `addedProducts.${i}.adjustedBrandPrice`,
              );

              const adjustedPriceRetailerPerUnit = Number(
                (!isUndefined(adjustedBrandPrice) && !isNaN(adjustedBrandPrice)
                  ? adjustedBrandPrice * subOrder.brandToRetailerConversionRate
                  : productUnitPrice
                ).toFixed(2),
              );

              const retailerProductSubtotal =
                adjustedPriceRetailerPerUnit * unitQuantity;

              return (
                <Flex key={i} gap={15} pr={15} mb={5} alignItems={"center"}>
                  <Box width={500}>
                    <AutocompleteField
                      name={`addedProducts.${i}.key`}
                      label={t("order.sku_name")}
                      isClearable={false}
                      options={productOptions}
                      closeMenuOnSelect
                      control={control as unknown as Control}
                      customSize="md"
                      defaultValueOption={productOptions[0]}
                    />
                  </Box>
                  <Box width={150}>
                    <Text as={"b"} size={bodyM}>
                      {t("order.adjusted_price_brand")}
                    </Text>
                    <InputField
                      {...register(`addedProducts.${i}.adjustedBrandPrice`, {
                        valueAsNumber: true,
                      })}
                      onKeyDown={handlePriceChange}
                      errors={errors}
                    />
                  </Box>
                  <Box
                    width={150}
                    display="flex"
                    gap={2}
                    flexDir="column"
                    justifyContent={"center"}
                    alignItems=""
                  >
                    <Box>
                      <Text as={"b"} size={bodyM}>
                        {t("order.adjusted_price_retailer")}
                      </Text>
                    </Box>
                    <Box height={41.5}>
                      {Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: currency,
                      }).format(adjustedPriceRetailerPerUnit)}
                    </Box>
                  </Box>
                  <Box width={200}>
                    <InputField
                      {...register(`addedProducts.${i}.unitQuantity`)}
                      onKeyDown={handleDecimalChange}
                      errors={errors}
                      label={t("order.quantity_of_unit")}
                    />
                  </Box>
                  <Box
                    width={200}
                    display="flex"
                    gap={2}
                    flexDir="column"
                    justifyContent={"center"}
                    alignItems=""
                  >
                    <Box>
                      <Text as={"b"} size={bodyM}>
                        {t("order.product_price_retailer")}
                      </Text>
                    </Box>
                    <Box height={41.5}>
                      {Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: currency,
                      }).format(retailerProductSubtotal)}
                    </Box>
                  </Box>
                </Flex>
              );
            })}
            {isProductsLoading ? (
              <Spinner />
            ) : (
              <Box display={"flex"} gap={8}>
                {!!productOptions.length && (
                  <Button
                    onClick={() => {
                      addAddedProduct();
                    }}
                  >
                    <TextWithIcon
                      icon={SolidPlus}
                      iconProps={{ color: "#fff" }}
                      text={t("order.add_product")}
                      textProps={{ color: "#fff" }}
                      flexProps={{ mr: 0 }}
                    />
                  </Button>
                )}
                {addProductBar !== 0 && (
                  <Button onClick={deleteAddedProduct}>
                    <TextWithIcon
                      icon={SolidClose}
                      iconProps={{ color: "#fff" }}
                      text={t("order.delete_product")}
                      textProps={{ color: "#fff" }}
                      flexProps={{ mr: 0 }}
                    />
                  </Button>
                )}
              </Box>
            )}
          </>
        }
        modalFooter={
          <>
            <Button
              variant="outline"
              fontWeight="600"
              size="lg"
              onClick={() => {
                onClose();
                reset();
              }}
              data-test-id="close-edit-order-preview-modal"
            >
              {t("Cancel")}
            </Button>
            <Button
              type="submit"
              form="editOrderPreview"
              variant="solid"
              size="lg"
              ml={3}
              data-test-id="put-edit-order-preview-button"
              isLoading={isPostPreviewLoading}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={onSubmit}
            >
              {t("order.edit_preview_button")}
            </Button>
          </>
        }
      />
      {isOpenEditConfirmationModal && (
        <ConfirmEditOrderModal
          isOpen={isOpenEditConfirmationModal}
          onClose={onCloseEditConfirmationModal}
          suborder={subOrder}
          currency={currency}
        />
      )}
    </>
  );
};
