import { RefObject, useEffect } from "react";

export const useClickOutside = (
  ref: RefObject<HTMLDivElement>,
  onClickOutside: () => void,
  disableExcludedTagNames = false,
) => {
  useEffect(() => {
    const excludedTagNames = ["svg", "path", "INPUT"];
    const handleClickOutside = (event: MouseEvent) => {
      const { target } = event;
      const tagName = (target as HTMLDivElement).tagName;
      if (
        ref.current &&
        !ref.current.contains(target as HTMLDivElement) &&
        (disableExcludedTagNames || !excludedTagNames.includes(tagName))
      ) {
        onClickOutside();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [ref, onClickOutside, disableExcludedTagNames]);
};
