import { useMutation } from "@tanstack/react-query";

import { AddSuborderNoteFields } from "./useNoteModalForm";
import { ErrorResponse } from "services/ErrorResponse";
import httpClient from "services/httpClient";

const POST_ADDITIONAL_NOTES = "admin/orders/";
const postSuborderNote = async (form: AddSuborderNoteFields) => {
  return await httpClient.put(
    `${POST_ADDITIONAL_NOTES}${form.subOrderId}/additional-notes`,
    {
      additionalNotes: form.note,
    },
  );
};

export const usePostSuborderNote = (
  onSuccess: () => Promise<void>,
  onError: (data: ErrorResponse) => void,
) =>
  useMutation(postSuborderNote, {
    onSuccess,
    onError,
  });
