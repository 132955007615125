/* eslint-disable @tanstack/query/exhaustive-deps */
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { ZendeskTicket } from "../ZendeskTicketTable";
import httpClient from "services/httpClient";

export const GET_ORDER_DETAILS = "/admin/orders/";
export const GET_ORDER_DETAILS_QK = "GET_ORDER_DETAILS_QK";
export type Brand = {
  email: string;
  id: string;
  phoneNumber: string;
  storeName: string;
  countryName: string;
  image: string;
  brandKey: string;
  creationDate: string;
};
export type Total = {
  brand: number;
  retailer: number;
};
export type Product = {
  key: string;
  currency: {
    brand: string;
    retailer: string;
  };
  id: string;
  imageUrl: string;
  price: number;
  sku: string;
  name: string;
  total: Total;
  variantKey?: string;
  variantName?: string;
  caseQty: number;
  qty: number;
  unitQuantity: number;
  adjustedPrice: number;
  brandPrice: number;
};

type Label = {
  labelStatus: string;
  labelUrl: string;
};
type Document = {
  category: string;
  url: string;
};

export type PickupTime = {
  date: string;
  fromTime: string;
  id: string;
  toTime: string;
};

export type ShippingDetails = {
  courierId: string;
  courierName: string;
  shipmentId: string;
  totalCharge: string;
  trackingUrl?: string;
  insuranceIncluded: boolean;
  label?: Label;
  pickupTime?: PickupTime;
  documents?: Document[];
  selfShipInvoiceUrl?: string;
  invoiceAmount: number;
};
export type SubOrder = {
  id: string;
  orderNumber: string;
  status?: string;
  total: number;
  tax: number;
  brand: Brand;
  products: Product[];
  brandToRetailerConversionRate: number;
  returnable: boolean;
  shippingDetails: ShippingDetails;
  subtotal: number;
  additionalNotes?: string;
  isEdited?: boolean;
  destinationCountry?: string;
  returnSurchargeApplied: number;
  zendeskTickets?: ZendeskTicket[];
};
export type Address = {
  address: string;
  address2?: string | null;
  companyName?: string | null;
  state: string;
  city: string;
  postCode: string;
};
export type RetailerDetails = {
  businessPhoneNumber: string;
  email: string;
  firstName: string;
  lastName: string;
  website: string;
  billingAddress: Address;
  deliveryAddress: Address;
};
export type Order = {
  id: string;
  orderNumber: string;
  creationDate: string;
  cancelDueDate: string;
  status: string;
  total: number;
  currency: string;
  subOrders: SubOrder[];
  retailerDetails: RetailerDetails;
  retailerId: string;
};

export const getOrderById = async (id: string) => {
  const { data: order } = await httpClient.get<Order>(
    `${GET_ORDER_DETAILS}${id}`,
  );
  return order;
};

export const useGetOrderById = (id: string): UseQueryResult<Order> =>
  useQuery({
    queryKey: [GET_ORDER_DETAILS_QK, id],
    queryFn: async () => await getOrderById(id),
  });
