/* eslint-disable @typescript-eslint/no-misused-promises */

/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-unsafe-return */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { usePostAdminImpersonate } from "../utils/usePostAdminImpersonate";
import { BrandDetails } from "./BrandDetails";
import { BrandSettings } from "./BrandSettings";
import { useActivateBrandAccountById } from "./hooks/useActivateBrandAccountById";
import { useDeactivateForm } from "./hooks/useDeactivateForm";
import { useExportBrandOrderHistory } from "./hooks/useExportBrandOrderHistory";
import { useGetBrandById } from "./hooks/useGetBrandById";
import { usePutDeactivateBrandAccount } from "./hooks/usePutDeactivateBrandAccount";
import { Button } from "components/Button/Button";
import { ModalWrapper } from "components/Modal/ModalWrapper";
import { Status } from "components/Status/Status";
import { TabData, TabWrapper } from "components/Tabs/TabWrapper";
import {
  capitalizeFirstLetter,
  convertToStatusVariant,
} from "features/UserManagement/utils";
import { useToast } from "hooks/useToast";
import { MARKATO_DEFAULT_DOMAIN, getErrorCodes } from "services/httpClient";
import {
  SolidArrowChevronDown,
  SolidArrowChevronLeft,
} from "styles/icons/solid";
import { downloadCSV, isAbleToImpersonate } from "utils/functions";

type BrandAccountParam = {
  brandId: string;
};

export const BrandOverview = () => {
  const { t } = useTranslation();
  const toast = useToast();

  const { brandId } = useParams<keyof BrandAccountParam>() as BrandAccountParam;
  const { mutateAsync: postAdminImpersonate, isLoading: isLoadingImpersonate } =
    usePostAdminImpersonate();
  const { mutateAsync: exportOrderHistory } = useExportBrandOrderHistory();
  const {
    isOpen: isOpenDactivateBrand,
    onOpen: onOpenDeactivateBrand,
    onClose: onCloseDeactivateBrand,
  } = useDisclosure();
  const {
    isOpen: isOpenActivateBrand,
    onOpen: onOpenActivateBrand,
    onClose: onCloseActivateBrand,
  } = useDisclosure();

  const { register, isFormValid, getValues } = useDeactivateForm();

  const { mutateAsync: activateBrandAccount, isLoading: isLoadingActivate } =
    useActivateBrandAccountById(brandId);

  const {
    mutateAsync: deactivateBrandAccount,
    isLoading: isLoadingDeactivate,
  } = usePutDeactivateBrandAccount(brandId);

  const {
    data: brandAccount,
    isLoading,
    isFetching,
  } = useGetBrandById(brandId);

  if (isFetching || isLoading) {
    return <Spinner position="absolute" top="50%" left="50%" />;
  }

  const tabsData: TabData[] = [
    {
      title: t("Details"),
      content: brandAccount && <BrandDetails brandAccount={brandAccount} />,
    },
    {
      title: t("Settings"),
      content: brandAccount && <BrandSettings brandAccount={brandAccount} />,
    },
  ];

  const handleExport = async () =>
    downloadCSV(await exportOrderHistory(brandId));

  const handleDeactivateBrand = async () => {
    await deactivateBrandAccount(
      {
        id: brandId,
        reason: getValues().reason,
      },
      {
        onSuccess: () => {
          let brandName: string;
          if (brandAccount) {
            brandName = brandAccount.storeName;
          } else {
            brandName = "Brand";
          }
          toast({
            description: `${brandName} has been deactivated successfully`,
          });
        },
        onError: (error) => {
          const errorCodes = getErrorCodes(error);
          toast({ status: "error", description: t(errorCodes[0]) });
        },
      },
    );
    onCloseDeactivateBrand();
  };

  const handleActivateBrand = async () => {
    await activateBrandAccount(
      {
        id: brandId,
      },
      {
        onSuccess: () => {
          if (brandAccount)
            toast({
              description: `${brandAccount.storeName} has been activated successfully`,
            });
        },
        onError: (error) => {
          const errorCodes = getErrorCodes(error);
          toast({ status: "error", description: t(errorCodes[0]) });
        },
      },
    );
    onCloseActivateBrand();
  };

  const handleImpersonate = async () => {
    if (!brandAccount) {
      return;
    }
    await postAdminImpersonate({
      id: brandAccount.cognitoUserId,
      group: "Brand",
      name: brandAccount.storeName,
    });
    window.location.replace(
      process.env.REACT_APP_MARKATO_URL ?? MARKATO_DEFAULT_DOMAIN,
    );
  };

  return (
    <Flex display="column">
      <Flex mb={2} mt={5} ml={10} mr={10} justifyContent="space-between">
        <Flex>
          <Button
            variant="outline"
            colorScheme="grey"
            mainIcon={SolidArrowChevronLeft}
            aria-label="backButton"
            data-test-id="backToBrandsList"
            alignSelf="center"
            mr={4}
            onClick={() => window.history.back()}
          />
          <Heading as="h1" size="h1" fontWeight="bold">
            {brandAccount?.storeName}
          </Heading>
          <Status
            display="flex"
            variant={convertToStatusVariant(
              brandAccount?.status ? "INACTIVE" : "ACTIVE",
            )}
            text={capitalizeFirstLetter(
              brandAccount?.status ? "INACTIVE" : "ACTIVE",
            )}
            ml={3.5}
            h="22px"
            alignSelf="center"
          />
        </Flex>
        <Menu placement="bottom-end">
          <MenuButton
            as={Button}
            variant="outline"
            rightIcon={SolidArrowChevronDown}
            data-test-id="threeDotsMenuButton"
            isLoading={isLoadingImpersonate}
          >
            {t("impersonate.manage_profile")}
          </MenuButton>
          <MenuList minW={48}>
            <MenuItem w={48} onClick={handleExport} mx={2}>
              {t("brand_details.export_order_history")}
            </MenuItem>
            {isAbleToImpersonate() && (
              <MenuItem
                w={48}
                onClick={handleImpersonate}
                mx={2}
                isDisabled={!!brandAccount?.status}
              >
                {t("impersonate.impersonate_user")}
              </MenuItem>
            )}
            {brandAccount?.status ? (
              <MenuItem
                color="blue.600"
                w={48}
                onClick={onOpenActivateBrand}
                mx={2}
              >
                {t("brand_details.activate_account")}
              </MenuItem>
            ) : (
              <MenuItem
                color="red"
                w={48}
                onClick={onOpenDeactivateBrand}
                mx={2}
              >
                {t("brand_details.deactivate_account")}
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      </Flex>
      <TabWrapper
        tabListProps={{ pl: 10 }}
        tabPanelsProps={{ pl: 2 }}
        tabsData={tabsData}
      />

      <ModalWrapper
        isOpen={isOpenActivateBrand}
        onClose={onCloseActivateBrand}
        showHeaderDivider
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-base-to-string
        modalHeader={`${t("brand_details.activate")} "${
          brandAccount?.storeName ?? ""
        }`}
        modalBody={
          <Text textStyle="bodyM" color="black">
            {t("brand_details.modal_activate")} {brandAccount?.storeName}
          </Text>
        }
        modalFooter={
          <>
            <Button
              variant="outline"
              colorScheme="grey"
              fontWeight="600"
              color="grey.black"
              onClick={onCloseActivateBrand}
              data-test-id="cancelActivateBrandButton"
            >
              {t("brand_details.cancel")}
            </Button>
            <Button
              variant="solid"
              colorScheme="blue"
              isLoading={isLoadingActivate}
              onClick={() => void handleActivateBrand()}
              data-test-id="activateBrandButton"
            >
              {t("brand_details.activate")}
            </Button>
          </>
        }
      />
      <ModalWrapper
        isOpen={isOpenDactivateBrand}
        onClose={onCloseDeactivateBrand}
        showHeaderDivider
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-base-to-string
        modalHeader={`${t("brand_details.deactivate")} ${
          brandAccount?.storeName ?? ""
        }`}
        modalBody={
          <>
            <Text textStyle="bodyM" color="black" mb={1}>
              {t("Add Comment")}
            </Text>
            <Textarea
              height="125px"
              resize="none"
              placeholder={t("brand_details.reason_msg")}
              {...register("reason", {
                required: true,
                validate: (value) => !!value.trim(),
              })}
            ></Textarea>
          </>
        }
        modalFooter={
          <>
            <Button
              variant="outline"
              colorScheme="grey"
              fontWeight="600"
              color="grey.black"
              onClick={onCloseDeactivateBrand}
              data-test-id="cancelDeactivateBrandButton"
            >
              {t("brand_details.cancel")}
            </Button>
            <Button
              variant="solid"
              colorScheme="red"
              isLoading={isLoadingDeactivate}
              disabled={isFormValid}
              data-test-id="deactivateBrandButton"
              onClick={() => void handleDeactivateBrand()}
            >
              {t("brand_details.deactivate")}
            </Button>
          </>
        }
      />
    </Flex>
  );
};
