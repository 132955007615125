/* eslint-disable id-denylist, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { addSeconds, formatISO } from "date-fns";
import { API_BASE_URL } from "env";
import { isEmpty } from "lodash";
import uuid from "react-uuid";

import { LoginResponse } from "features/Login/useLogin";

export const MARKATO_DEFAULT_DOMAIN = "https://markato.dev.markato.cloud";
const ACCESS_TOKEN = "access-token";
const TOKEN_EXPIRATION = "token-expiration";
const SESSION_ID = "session-id";

const headers = {
  accept: "application/json",
  "Content-type": "application/json",
};

export type AccessToken = {
  username: string;
  "cognito:groups": string[];
};

const httpClient = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
  headers,
});

export const setSessionId = () => sessionStorage.setItem(SESSION_ID, uuid());
const getSessionId = () => {
  let sessionId = sessionStorage.getItem(SESSION_ID);
  if (!sessionId) {
    sessionId = uuid();
    sessionStorage.setItem(SESSION_ID, sessionId);
  }
  return sessionId;
};

export const removeCookies = () => {
  document.cookie.split(";").forEach(function (c) {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
  document.cookie = "";
};

export const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN);
export const setAccessToken = (accessToken: string, expiresIn: number) => {
  localStorage.setItem(ACCESS_TOKEN, accessToken);
  localStorage.setItem(
    TOKEN_EXPIRATION,
    formatISO(addSeconds(new Date(), expiresIn - 20)),
  );
  // "Refresh" request interceptor to update it with a new token
  createRequestInterceptor();
};
export const deleteAccessToken = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(TOKEN_EXPIRATION);
  // Clear request interceptor on logout
  httpClient.interceptors.request.clear();
  createRequestInterceptor();
};

export const getTokenExpirationTime = () => {
  const result = localStorage.getItem(TOKEN_EXPIRATION);
  if (!result) {
    const newExiprationDate = formatISO(new Date());
    localStorage.setItem(TOKEN_EXPIRATION, newExiprationDate);
    return newExiprationDate;
  }
  return result;
};

const createRequestInterceptor = () => {
  // request interceptor for adding token to header
  httpClient.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const token = getAccessToken();
      config.headers = {
        ...config.headers,
        sessionId: getSessionId(),
      };

      if (token) {
        config.headers.Authorization = "Bearer " + token;
      }
      return config;
    },

    (error: any) => {
      return Promise.reject(error);
    },
  );
};

createRequestInterceptor();

httpClient.interceptors.response.use(
  // Any status code that lie within the range of 2xx cause this function to trigger
  (res: AxiosResponse) => {
    return res;
  },
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  async (err: any) => {
    console.error(err);
    const originalConfig = err?.config as AxiosRequestConfig;

    if (err?.response?.status === 401) {
      try {
        const result = await axios.post<LoginResponse>(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          API_BASE_URL! + "/admin/auth/refresh-token",
          {},
          {
            withCredentials: true,
            headers: {
              sessionId: getSessionId(),
            },
          },
        );
        setAccessToken(result.data.accessToken, result.data.expiresIn);
        axios.defaults.headers.common.Authorization = `Bearer ${result.data.accessToken}`;
        return httpClient(originalConfig);
      } catch (error) {
        console.error(error);
        deleteAccessToken();
        window.location.href = `${
          window.location.origin
        }/login?source=interceptors&error=${JSON.stringify(error)}`;
        return Promise.reject(error);
      }
    }

    return Promise.reject(err);
  },
);

export const getErrorCodes = (error: any): ErrorCode[] => {
  const errorCodes = error?.response?.data?.code as ErrorCode[];
  // message is a string, here by pass useTranslation() t() function checking to display the message
  const errorMsg = error?.response?.data?.message as ErrorCode | null;

  if (!isEmpty(errorCodes)) {
    return errorCodes;
  } else if (errorMsg) {
    return [errorMsg];
  } else {
    return ["ERR_000"];
  }
};

type ErrorCode =
  | "AUTH_003"
  | "AUTH_004"
  | "AUTH_005"
  | "AUTH_006"
  | "AUTH_008"
  | "AUTH_009"
  | "ADMIN_004"
  | "COUNTRY_001"
  | "ERR_000";

export default httpClient;
