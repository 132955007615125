import { isUndefined } from "lodash";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ProductOption } from "../EditOrderPreviewModal/EditOrderPreviewModal";
import { Product, SubOrder } from "./useGetOrderById";
import {
  ItemPreviewDTO,
  usePostEditOrderPreview,
} from "./usePostEditOrderPreview";
import { useSimpleToast } from "hooks/useSimpleToast";
import { ErrorResponse } from "services/ErrorResponse";
import { getErrorCodes } from "services/httpClient";

type AddProductType = {
  unitQuantity: number;
  key: ProductOption;
  adjustedBrandPrice?: number;
  isExistingItem: boolean;
};

export type EditSuborderPreviewFields = {
  subOrderId: string;
  products: Product[];
  addedProducts?: AddProductType[];
};

export type EditOrderPreviewFormProps = {
  suborder: SubOrder;
  openModal: () => void;
};

export const useEditOrderPreviewForm = ({
  suborder,
  openModal,
}: EditOrderPreviewFormProps) => {
  const {
    control,
    register,
    getValues,
    handleSubmit,
    reset,
    watch,
    unregister,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm<EditSuborderPreviewFields>({
    defaultValues: {
      subOrderId: suborder.id,
      products: suborder.products,
    },
  });

  const { t } = useTranslation();

  const toast = useSimpleToast();

  const onSuccess = () => {
    openModal();
  };

  const onError = (error: ErrorResponse) => {
    const errorCodes = getErrorCodes(error);
    toast({
      status: "error",
      position: "top",
      description: t(errorCodes[0]),
    });
  };

  // Workaround to re-render every product change to update price.
  const addedProducts = watch();
  useEffect(() => {
    return;
  }, [addedProducts]);

  const { mutateAsync: postEditOrderPreview, isLoading: isPostPreviewLoading } =
    usePostEditOrderPreview(onSuccess, onError);

  const onSubmit = handleSubmit(async () => {
    const items: ItemPreviewDTO[] = [];
    const products = getValues("products");
    const addedProducts = getValues("addedProducts");

    products
      .filter((product) => {
        if (!product.unitQuantity) return false;
        return true;
      })
      .forEach((product) => {
        const hasAdjustedPrice = product.adjustedPrice;
        let itemObject: ItemPreviewDTO = {
          key: product.key,
          sku: product.sku,
          unitQuantity: Number(product.unitQuantity),
          isEditedPrice: false,
          isExistingItem: true,
        };
        if (hasAdjustedPrice >= 0) {
          itemObject = {
            ...itemObject,
            isEditedPrice: true,
            editedBrandPrice: hasAdjustedPrice,
            isExistingItem: true,
          };
        }
        items.push(itemObject);
      });

    if (addedProducts) {
      addedProducts
        .filter((product) => {
          if (!Number(product.unitQuantity)) return false;
          return true;
        })
        .forEach((addedProduct) => {
          const hasAdjustedPrice = addedProduct.adjustedBrandPrice;
          let itemObject: ItemPreviewDTO = {
            key: addedProduct.key.productKey,
            sku: addedProduct.key.sku,
            unitQuantity: Number(addedProduct.unitQuantity),
            isEditedPrice: false,
            isExistingItem: false,
          };
          if (!isUndefined(hasAdjustedPrice) && hasAdjustedPrice >= 0) {
            itemObject = {
              ...itemObject,
              isEditedPrice: true,
              editedBrandPrice: hasAdjustedPrice,
              isExistingItem: false,
            };
          }
          items.push(itemObject);
        });
    }
    await postEditOrderPreview({
      items: items,
      subOrderId: getValues("subOrderId"),
    });
  });

  return {
    control,
    register,
    getValues,
    reset,
    errors,
    isDirty,
    isValid,
    onSubmit,
    watch,
    unregister,
    setValue,
    isPostPreviewLoading,
  };
};
