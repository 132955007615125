import { BoxProps, Flex, Text } from "@chakra-ui/react";

export type PageDetailsProps = {
  heading: string;
} & BoxProps;
export const PageDetails = ({
  heading,
  children,
  ...rest
}: PageDetailsProps) => {
  return (
    <Flex
      w="480px"
      p={5}
      flexDir="column"
      borderRadius="base"
      boxShadow={1}
      border="1px solid"
      borderColor="grey.400"
      {...rest}
    >
      <Text textStyle="bodyM" color="grey.700" fontWeight="semibold">
        {heading}
      </Text>

      <Flex flexDir="column" mt={4}>
        {children}
      </Flex>
    </Flex>
  );
};
