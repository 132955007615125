/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Flex, Grid, Image, Link, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { BrandPageUpdateRequestDetails } from "../types";
import { PageDetails } from "components/PageDetails/PageDetails";

export type PageUpdateDetailsProps = {
  brandRequestDetails: BrandPageUpdateRequestDetails;
};
export const PageUpdateDetails = ({
  logoRef,
  coverRef,
  description,
  videoName,
  videoDescription,
  videoRef,
  imgRef,
}: BrandPageUpdateRequestDetails) => {
  const { t } = useTranslation();

  return (
    <Flex
      mt={9}
      ml={10}
      flexDir="column"
      h="calc(100vh - 170px)"
      overflow="auto"
    >
      <PageDetails
        heading={t("page_update_details.logo_and_cover_image")}
        mb={6}
      >
        <Flex>
          <Box mr={3}>
            <Image src={logoRef} w={78} h={78} borderRadius={4} />
          </Box>
          <Image src={coverRef} w={344} h={78} borderRadius={4} />
        </Flex>
      </PageDetails>
      <PageDetails heading={t("page_update_details.brand_story")} mb={6}>
        {description.split("\n").map((paragraph, index) => (
          <Text mb={2} key="index">
            {paragraph}
          </Text>
        ))}
      </PageDetails>
      <PageDetails heading={t("page_update_details.promo_video")} mb={6}>
        <Flex w="100%">
          <Text w="30%">{t("page_update_details.video_link")}</Text>
          <Link w="70%" href={videoRef} color="#1C7EC6">
            {videoRef}
          </Link>
        </Flex>
        <Flex w="100%">
          <Text w="30%">{t("page_update_details.name")}</Text>
          <Text w="70%">{videoName}</Text>
        </Flex>
        <Flex w="100%">
          <Text w="30%">{t("page_update_details.description")}</Text>
          <Text w="70%">{videoDescription}</Text>
        </Flex>
      </PageDetails>
      <PageDetails heading={t("page_update_details.photo_gallery")}>
        <Grid gap={3} templateColumns="repeat(5, 1fr)">
          {imgRef.map((img, index) => {
            return (
              <Box key={index}>
                <Image src={img} w={78} h={78} borderRadius={4} />
              </Box>
            );
          })}
        </Grid>
      </PageDetails>
    </Flex>
  );
};
