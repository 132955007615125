/* eslint-disable @typescript-eslint/no-explicit-any */
import { Control, Controller, RegisterOptions } from "react-hook-form";

import { GroupedOption, Option, Select } from "../Select/Select";
import "../Select/Select.css";

type SelectProps = {
  control: Control<any>;
  rules?: Omit<
    RegisterOptions<any, any>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  name: string;
  id?: string;
  label?: string;
  options: any[];
  placeholder?: string;
  isDisabled?: boolean;
  errorMessage?: string;
  className?: string;
  isMulti?: boolean;
};

export const SelectField = ({
  control,
  rules,
  id,
  name,
  options,
  label,
  placeholder = "Select",
  isDisabled,
  className,
  isMulti = false,
}: SelectProps) => {
  const findSelectedOption = (options: Option[], optionId: string) =>
    options.find((option) => option.value === optionId);
  const setSelectedValues = (value: string | string[]): Option | Option[] => {
    if (!isMulti) {
      return options.find(
        (option: Option) => String(option.value) === String(value),
      ) as Option;
    }
    const selected: Option[] = [];
    if (Array.isArray(value)) {
      for (const option of value) {
        for (const mainOption of options as GroupedOption[]) {
          const selectedOption = findSelectedOption(mainOption.options, option);
          if (selectedOption) {
            selected.push(selectedOption);
          }
        }
      }
    }
    return selected;
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { onChange, value, name },
        fieldState: { invalid, error },
      }) => (
        <Select
          label={label}
          isMulti={isMulti}
          id={id}
          placeholder={placeholder}
          name={name}
          isDisabled={isDisabled}
          className={className}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          options={options}
          // eslint-disable-next-line
          value={setSelectedValues(value)}
          onChange={(val) => {
            onChange(val);
          }}
          errorMessage={invalid ? error?.message : ""}
        />
      )}
    />
  );
};
