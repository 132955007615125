import {
  Box,
  Button,
  Flex,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { parseISO } from "date-fns";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { ThirdPartyPanel } from "../shared/ThirdPartyPanel";
import { BrandDetailsType } from "../types";
import { useGetAllBrandNotesById } from "./hooks/useGetAllBrandNotesById";
import { usePostNewBrandNote } from "./hooks/usePostNewBrandNote";
import AddressDetails from "components/AddressDetails/AddressDetails";
import { InfoDetails } from "components/InfoDetails/InfoDetails";
import {
  AWXKycStatusLabel,
  KycStatusLabel,
} from "components/KYCStatus/KYCStatus";
import { ModalWrapper } from "components/Modal/ModalWrapper";
import { Note } from "components/Note/Note";
import { TextAreaField } from "components/TextAreaField/TextAreaField";
import { useSimpleToast } from "hooks/useSimpleToast";
import { NOTES } from "services/ReactQueryKeys";
import { queryClient } from "services/queryClient";
import { SolidPlus } from "styles/icons/solid";

type BrandDetailsProps = {
  brandAccount: BrandDetailsType;
};

type BrandAccountIdParam = {
  brandId: string;
};

export const BrandDetails = ({
  brandAccount: {
    email,
    storeName,
    phoneNumber,
    website,
    country,
    categoryName,
    wholesaleProduct,
    numberOfStores,
    kycStatus,
    instagram,
    shippingAddress,
    billingAddress,
    cognitoUserId,
    paymentAccountDetails,
  },
}: BrandDetailsProps) => {
  const { t } = useTranslation();

  const toast = useSimpleToast();

  const {
    isOpen: isOpenNewNote,
    onOpen: onOpenNewNote,
    onClose: onCloseNewNote,
  } = useDisclosure();

  const { brandId } = useParams<
    keyof BrandAccountIdParam
  >() as BrandAccountIdParam;

  const { data: notes, isLoading: isLoadingNotes } =
    useGetAllBrandNotesById(brandId);

  const sortedNotes =
    notes?.sort((first, second) => {
      const dateFirst = parseISO(first.creationDate) as unknown as Date;
      const dateSecond = parseISO(second.creationDate) as unknown as Date;
      return dateSecond.getTime() - dateFirst.getTime();
    }) ?? [];

  const {
    register,
    getValues,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onSubmit" });

  const onSuccess = () => {
    reset({ newNote: "" });
    onCloseNewNote();

    toast({
      description: t("brand_details.successfully_created_note"),
    });

    void queryClient.invalidateQueries({
      queryKey: [NOTES, brandId],
    });
  };

  const { mutateAsync: postNewNote, isLoading: isLoadingNewNote } =
    usePostNewBrandNote(onSuccess);

  const handleAddNewNote = handleSubmit(async () => {
    await postNewNote({ id: brandId, content: getValues("newNote") as string });
  });

  return (
    <Flex overflowY="auto">
      <Flex
        pl={5}
        mt={5}
        pr={5}
        gap={5}
        flexDirection="column"
        flexWrap={"wrap"}
      >
        <Box>
          <InfoDetails
            w="554px"
            heading={t("brand_details.basic_information")}
            leftList={[
              t("brand_details.store_name"),
              t("brand_details.business_email"),
              t("brand_details.website_form"),
              t("brand_details.instagram"),
              t("brand_details.country_form"),
              t("brand_details.phone_number"),
            ]}
            rightList={[
              storeName,
              email,
              website,
              instagram,
              country,
              phoneNumber,
            ]}
            mb={5}
          />
          <InfoDetails
            w="554px"
            heading={t("brand_details.brand_information")}
            leftList={[
              t("brand_details.primary_category"),
              t("brand_details.wholesale_products"),
              t("brand_details.number_of_stores"),
            ]}
            rightList={[categoryName, wholesaleProduct, numberOfStores]}
            mb={5}
          />

          <Flex
            w="554px"
            p={5}
            mb={5}
            flexDir="column"
            borderRadius="base"
            boxShadow={1}
            border="1px solid"
            borderColor="grey.400"
          >
            <Flex>
              <Text textStyle="bodyM" color="grey.700" fontWeight="semibold">
                {t("brand_details.airwallex_account")}
              </Text>
            </Flex>
            <Flex flexDir="column" mt={4}>
              <Flex>
                <Text
                  textStyle="bodyS"
                  fontWeight="medium"
                  color="grey.700"
                  p={1}
                  mr={4}
                >
                  {t("brand_details.platform_status")}
                </Text>
                <KycStatusLabel status={kycStatus} />
              </Flex>

              <Flex mt={1}>
                <Text
                  textStyle="bodyS"
                  fontWeight="medium"
                  color="grey.700"
                  p={1}
                  mr={4}
                >
                  {t("brand_details.awx_status")}
                </Text>
                {paymentAccountDetails && (
                  <AWXKycStatusLabel status={paymentAccountDetails.status} />
                )}
              </Flex>

              <Flex mt={1}>
                <Text
                  textStyle="bodyS"
                  fontWeight="medium"
                  color="grey.700"
                  p={1}
                  mr={4}
                >
                  {t("brand_details.awx_id")}:{" "}
                  {paymentAccountDetails?.id ?? "-"}
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <AddressDetails
            heading={t("common.address.delivery_address")}
            address={shippingAddress}
          />
          <AddressDetails
            heading={t("retailer_details.billing_address")}
            address={billingAddress}
          />
        </Box>
        <Box>
          {isLoadingNotes ? (
            <Flex justify="center" w="554px">
              <Spinner mt={5} ml={5} />
            </Flex>
          ) : (
            <Flex
              w="554px"
              maxH={"554px"}
              px={5}
              pt={5}
              flexDir="column"
              borderRadius="base"
              boxShadow={1}
              border="1px solid"
              borderColor="grey.400"
              mb={5}
            >
              <Text textStyle="bodyM" color="grey.700" fontWeight="semibold">
                {t("brand_details.notes")}
              </Text>
              <Button
                size="md"
                w="fit-content"
                mt={5}
                onClick={onOpenNewNote}
                leftIcon={<SolidPlus width={20} height={20} />}
              >
                {t("brand_details.add_note")}
              </Button>
              <Box mt={5}>
                {sortedNotes.map((note) => (
                  <Note key={note.id} note={note} />
                ))}
              </Box>
            </Flex>
          )}
          <ThirdPartyPanel cognitoId={cognitoUserId} email={email} />
        </Box>
      </Flex>

      <ModalWrapper
        isOpen={isOpenNewNote}
        onClose={onCloseNewNote}
        modalProps={{
          size: "sm",
          children: undefined,
          isOpen: isOpenNewNote,
          onClose: onCloseNewNote,
        }}
        showHeaderDivider
        modalHeader={t("brand_details.new_note")}
        modalBody={
          <>
            <Text textStyle="bodyM" color="black" mb={1}>
              {t("brand_details.add_a_note")}
            </Text>
            <TextAreaField
              height="125px"
              resize="none"
              placeholder={t("brand_details.add_note_explanation")}
              errors={errors}
              {...register("newNote", {
                required: t("brand_details.required_field"),
                validate: (value: string) => !!value.trim(),
              })}
            />
          </>
        }
        modalFooter={
          <Button
            onClick={() => void handleAddNewNote()}
            data-test-id="addNoteRequestButton"
            size="lg"
            isLoading={isLoadingNewNote}
            w="100%"
          >
            {t("brand_details.add_note")}
          </Button>
        }
      />
    </Flex>
  );
};
