/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useMutation } from "@tanstack/react-query";

import { AddOptionFormFields } from "./useAddOptionForm";
import httpClient from "services/httpClient";

export const POST_FINANCIAL_OPTION = "/admin/orders/";

const postFinancialOption = async (form: AddOptionFormFields) => {
  return await httpClient.post(
    `${POST_FINANCIAL_OPTION}${form.id}/financial-breakdown/transactions`,
    {
      type: form.type.label,
      amount: Number(form.amount),
      comment: form.comment,
    },
  );
};

export const usePostFinancialOption = (onSuccess: () => void) =>
  useMutation(postFinancialOption, {
    onSuccess,
  });
