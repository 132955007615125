/* eslint-disable @typescript-eslint/no-misused-promises */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-unsafe-return */
import {
  Flex,
  Input,
  Link,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Payout, useGetUpcomingPayouts } from "./hooks/useGetUpcomingPayouts";
import { usePostStartPayout } from "./hooks/useStartPayout";
import { Button } from "components/Button/Button";
import { EmptyList } from "components/EmptyList/EmptyList";
import { ModalWrapper } from "components/Modal/ModalWrapper";
import { Status } from "components/Status/Status";
import { TableWrapper } from "components/Table/TableWrapper";
import { useSimpleToast } from "hooks/useSimpleToast";
import { ErrorResponse } from "services/ErrorResponse";
import { UPCOMING_PAYOUTS_KEY } from "services/ReactQueryKeys";
import { getErrorCodes } from "services/httpClient";
import { queryClient } from "services/queryClient";
import { formatDate } from "utils/functions";

const PAYOUT_PASSPHRASE = "confirm";

export const UpcomingPayouts = () => {
  const { t } = useTranslation();
  const [confirmPayoutPhrase, setConfirmPayoutPhrase] = useState("");
  const toast = useSimpleToast();

  const { data: upcomingPayout, isLoading } = useGetUpcomingPayouts();

  const {
    isOpen: isOpenTriggerPayoutModal,
    onOpen: onOpenTriggerPayoutModal,
    onClose: onCloseTriggerPayoutModal,
  } = useDisclosure();

  const handlePayoutSuccess = () => {
    void queryClient.fetchQuery({
      queryKey: [UPCOMING_PAYOUTS_KEY],
    });
    toast({ description: t("payout.trigger_payouts_success"), duration: 8000 });
    onCloseTriggerPayoutModal();
  };

  const handlePayoutError = (error: ErrorResponse) => {
    toast({
      status: "error",
      description: t(getErrorCodes(error)[0]),
    });
  };

  const { mutateAsync: startPayout, isLoading: isPayingOut } =
    usePostStartPayout(handlePayoutSuccess, handlePayoutError);

  const columnHelper = createColumnHelper<Payout>();

  const columns = [
    columnHelper.accessor("storeName", {
      cell: ({ row }) => (
        <Flex position="relative">
          {row.original.hasHeld ? (
            <Status
              position="absolute"
              display="flex"
              variant="pending"
              left={-6}
              h="22px"
              alignSelf="center"
            />
          ) : null}
          <Link
            href={`/user-management/brand/${row.original.brandId}`}
            textStyle="bodyS"
            color="#1C7EC6"
          >
            {row.original.storeName}
          </Link>
        </Flex>
      ),
      header: t("payout.brand_name"),
      size: 30,
    }),
    columnHelper.accessor("amountInNextPayout", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {row.original.numberOfSuborders}
        </Text>
      ),
      header: t("payout.total_orders"),
      size: 30,
    }),
    columnHelper.accessor("amountToBePaid", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {Intl.NumberFormat("en-US", {
            style: "currency",
            currency: row.original.currency,
          }).format(Number(row.original.amountInNextPayout))}
        </Text>
      ),
      header: t("payout.amount_to_be_paid"),
      size: 30,
    }),
    columnHelper.accessor("action", {
      cell: ({ row }) => (
        <Link href={`/payouts/${row.original.cognitoUserId}`}>
          <Button variant="outline">{t("payout.view_details")}</Button>
        </Link>
      ),
      header: "Action",
      size: 10,
    }),
  ];

  if (isLoading) {
    return <Spinner position="absolute" top="50%" left="50%" />;
  }

  const totalBrandsToBePaid = upcomingPayout?.payouts.filter(
    ({ amountInNextPayout }) => amountInNextPayout > 0,
  ).length;

  return (
    <Flex flexDir="column" pl={2} pr={4} pt={4}>
      <Flex justifyContent="space-between">
        <Flex flexDir="column">
          <Text textStyle="bodyL" textTransform="uppercase" fontWeight="bold">
            {t("payout.list_of_brands")}
          </Text>
          <Text textStyle="bodyS" mb={8}>
            From <b>{formatDate(upcomingPayout?.dateFrom ?? "")}</b> to{" "}
            <b>{formatDate(upcomingPayout?.dateTo ?? "")}</b>
          </Text>
        </Flex>
        <Flex>
          <Button
            variant="outline"
            onClick={onOpenTriggerPayoutModal}
            data-test-id="batch-payout"
          >
            {t("payout.trigger_payouts")}
          </Button>
        </Flex>
      </Flex>
      {upcomingPayout && upcomingPayout.payouts.length > 0 ? (
        <TableWrapper
          tableData={upcomingPayout.payouts}
          columns={columns}
          data-test-id="payout-upcoming-table"
          disablePagination={true}
        />
      ) : (
        <EmptyList />
      )}

      <ModalWrapper
        modalHeader={t("payout.confirm_payout_trigger_for_brands")}
        modalBody={
          <Flex direction="column">
            <Flex direction="column">
              {upcomingPayout?.payouts.map(
                ({ storeName, amountInNextPayout }) => {
                  return amountInNextPayout > 0 ? (
                    <Text fontWeight={600} key={storeName}>
                      {storeName}
                    </Text>
                  ) : null;
                },
              )}
            </Flex>
            <Flex direction="column" mt="8">
              <Text>
                {t("payout.total_brand_to_be_paid")}
                <strong>{totalBrandsToBePaid}</strong>
              </Text>
              <Text mt="2">
                {t("payout.confirm_payout_trigger_message1")}
                <strong> {PAYOUT_PASSPHRASE} </strong>
                {t("payout.confirm_payout_trigger_message2")}
              </Text>
              <Input
                value={confirmPayoutPhrase}
                onChange={(event) => setConfirmPayoutPhrase(event.target.value)}
              />
            </Flex>

            <Flex mt={5} gap={5} alignSelf="flex-end">
              <Button
                w={100}
                onClick={() => {
                  void startPayout(confirmPayoutPhrase);
                }}
                disabled={
                  isPayingOut || confirmPayoutPhrase !== PAYOUT_PASSPHRASE
                }
                isLoading={isPayingOut}
              >
                {t("confirm")}
              </Button>
              <Button
                w={100}
                onClick={onCloseTriggerPayoutModal}
                disabled={isPayingOut}
              >
                {t("cancel")}
              </Button>
            </Flex>
          </Flex>
        }
        isOpen={isOpenTriggerPayoutModal}
        onClose={onCloseTriggerPayoutModal}
      />
    </Flex>
  );
};
