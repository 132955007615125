/* eslint-disable @tanstack/query/exhaustive-deps */
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { Retailer } from "features/UserManagement/types";
import httpClient from "services/httpClient";

export const GET_RETAILER_REQEUSTS = "/admin/business-registration-requests";

export type BrRequest = {
  action: string;
  adminId: string;
  creationDate: string;
  id: string;
  note: string;
  retailerId: string;
  type: string;
  retailer: {
    name: string;
    storeName: string;
    typeOfStore: string;
    country: string;
    countryId: string;
  } & Retailer;
};

export const useGetBrRequests = (): UseQueryResult<BrRequest[]> =>
  useQuery({
    queryKey: [GET_RETAILER_REQEUSTS],
    queryFn: async () => {
      const { data } = await httpClient.get<BrRequest>(GET_RETAILER_REQEUSTS);
      return data;
    },
  });
