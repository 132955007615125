/* eslint-disable @tanstack/query/exhaustive-deps */
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import httpClient from "services/httpClient";

export const GET_APPLICATION_HISTORY = "/admin/payment-terms-requests/history/";
export const GET_APPLICATION_HISTORY_RETAILER = "/admin/retailer/";
export const GET_APPLICATION_HISTORY_QK = "APPLICATION_HISTORY_QK";

export type ApplicationHistory = {
  date: string;
  limit: number;
  rejectReason: string;
  status: string;
  currency: string;
};

export const useGetApplicationHistory = (
  id: string,
): UseQueryResult<ApplicationHistory[]> =>
  useQuery({
    queryKey: [GET_APPLICATION_HISTORY_QK],
    queryFn: async () => {
      const { data: history } = await httpClient.get<ApplicationHistory>(
        GET_APPLICATION_HISTORY + id,
      );
      return history;
    },
  });

export const useGetApplicationHistoryViaRetailerId = (
  retailerId: string,
): UseQueryResult<{ retailerName: string; history: ApplicationHistory[] }> =>
  useQuery({
    queryKey: [GET_APPLICATION_HISTORY_QK],
    queryFn: async () => {
      const { data: history } = await httpClient.get<ApplicationHistory>(
        GET_APPLICATION_HISTORY_RETAILER +
          retailerId +
          "/payment-terms-history",
      );
      return history;
    },
  });
