/*eslint-disable @typescript-eslint/no-non-null-assertion*/
import {
  Flex,
  Heading,
  Icon,
  Link,
  Spinner,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { format, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { TableWrapper } from "components/Table/TableWrapper";
import {
  ApplicationHistory,
  useGetApplicationHistoryViaRetailerId,
} from "features/RetailerRequests/RetailerRequestOverview/hooks/useGetApplicationHistory";
import { RegularClose } from "styles/icons/regular";

export const BnplApplicationHistory = () => {
  const { retailerId } = useParams();
  const { t } = useTranslation();

  const columnHelper = createColumnHelper<ApplicationHistory>();
  const { data: applicationHistory, isLoading } =
    useGetApplicationHistoryViaRetailerId(retailerId!);

  const columns = [
    columnHelper.accessor("date", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {format(parseISO(row.original.date), "dd-MM-yyyy")}
        </Text>
      ),
      header: "Name",
      size: 10,
      enableSorting: false,
    }),
    columnHelper.accessor("limit", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {Intl.NumberFormat("en-US", {
            style: "currency",
            currency: row.original.currency,
          }).format(Number(row.original.limit))}
        </Text>
      ),
      header: "Desired Limit Amount",
      size: 10,
      enableSorting: false,
    }),
    columnHelper.accessor("rejectReason", {
      cell: ({ row }) =>
        row.original.rejectReason.length > 200 ? (
          <Tooltip
            hasArrow
            label={row.original.rejectReason}
            borderRadius="base"
            p={5}
            backgroundColor="white"
            color="grey.700"
          >
            <Text textStyle="bodyS" color="grey.700" overflow="hidden">
              {`${row.original.rejectReason.slice(0, 100)}...`}
            </Text>
          </Tooltip>
        ) : (
          <Text textStyle="bodyS" color="grey.700" overflow="hidden">
            {row.original.rejectReason.slice(0, 100)}
          </Text>
        ),
      header: "Rejection Reason",
      size: 55,
      enableSorting: false,
    }),
    columnHelper.accessor("status", {
      cell: ({ row }) => (
        <Text
          textStyle="bodyS"
          color={row.original.status === "APPROVED" ? "green.500" : "red.500"}
        >
          {row.original.status.charAt(0) +
            row.original.status.substring(1).toLowerCase()}
        </Text>
      ),
      header: "Status",
      size: 10,
      enableSorting: false,
    }),
  ];

  if (isLoading) {
    return <Spinner position="absolute" top="50vh" left="50%" />;
  }

  return (
    <Flex>
      <Flex position="absolute" right={0} p={8}>
        <Link href={`/user-management/retailer/${retailerId!}`}>
          <Icon as={RegularClose} w={6} h={6} cursor="pointer" />
        </Link>
      </Flex>
      <Flex p={16} width="100%" flexDir="column">
        <Heading as="h2" size="h2" fontWeight="bold">
          {t("bnpl.application_history")}
        </Heading>
        <Flex py={9} justifyContent="space-between">
          <Text textStyle="bodyM" fontWeight="bold">
            {applicationHistory?.retailerName}
          </Text>
        </Flex>
        {applicationHistory && applicationHistory.history.length > 0 ? (
          <TableWrapper
            tableData={applicationHistory.history}
            columns={columns}
            data-test-id="application-history-table"
          />
        ) : (
          <Text color="grey.600" textStyle="bodyM">
            {t("bnpl.no_history")}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};
