/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Buffer } from "buffer";
import { NEW_RELIC_URL } from "env";

type QueryObjectType = Record<string, string>;

const generateLauncherParamQuery = (pairs: [string, string][]) => {
  let queryString = '"';
  for (const pair of pairs) {
    const [key, value] = pair;
    queryString += `${key}:\\"${value}\\" `;
  }
  const amendedQS = queryString.slice(0, -1) + '"';
  return amendedQS;
};

export const generateNewRelicLauncherParam = (queryObject: QueryObjectType) => {
  const stringParam = generateLauncherParamQuery(Object.entries(queryObject));
  console.log(stringParam);
  const launcherParam = `{"isEntitled":true,"query":${stringParam},"eventTypes":["Log_Logging"]}`;
  const encodedData = Buffer.from(launcherParam).toString("base64");
  return encodedData;
};

export const generateNewRelicPaneParam = (accountId: string) => {
  const paneParam = `"{"nerdletId":"logger.log-tailer","accountId":${accountId}}"`;
  return Buffer.from(paneParam).toString("base64");
};

export const createNewRelicLinkTemplate = (accountId: string) => {
  const encodedPane = generateNewRelicPaneParam(accountId);
  return `${NEW_RELIC_URL!}?platform[accountId]=${accountId}&launcher=[LAUNCHER_DATA]&pane=${encodedPane}`;
};
