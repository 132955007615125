import { useForm } from "react-hook-form";

import { usePostForgotPassword } from "./usePostForgotPassword";

export type PostForgotPasswordForm = {
  username: string;
};

export const useForgotPasswordForm = (username: string) => {
  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm<PostForgotPasswordForm>({
    mode: "onChange",
    defaultValues: { username },
  });

  const { mutateAsync: postForgotPassword, isLoading } =
    usePostForgotPassword();

  const onSubmit = handleSubmit(async () => {
    await postForgotPassword({
      email: getValues().username.trim(),
    });
  });

  return {
    control,
    register,
    errors,
    onSubmit,
    getValues,
    isValid,
    isLoading,
    username: getValues().username,
  };
};
