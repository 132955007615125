export const buttonStyle = {
  w: "100%",
  justifyContent: "left",
  variant: "ghost",
  colorScheme: "primary",
  borderRadius: "6px",
  color: "#4D4D54",
  py: 2,
  _hover: {
    color: "#3E859D",
    backgroundColor: "#C8E1E9",
  },
};

export const badgeStyle = {
  position: "absolute" as const,
  right: 0,
  mr: 2,
  color: "white",
  background: "#4BA8D0",
  borderRadius: "4px",
  textAlign: "center" as const,
  w: "22px",
};
