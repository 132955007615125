import { SVGProps } from "react";

const SvgRegularPackage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.83082 1.09165C9.05316 0.966345 9.32427 0.969732 9.5435 1.10056L15.3605 4.5718L8.4743 8.38225L6.30355 7.06097L12.1856 3.66657C12.2596 3.62336 12.3048 3.54371 12.3048 3.4573C12.3048 3.37036 12.2596 3.29072 12.1856 3.24751L10.7423 2.48897C10.6683 2.44576 10.5773 2.44576 10.5033 2.48897L4.40315 5.90423L2.42644 4.70106L8.83082 1.09165Z"
      fill="currentColor"
    />
    <path
      d="M2 5.71554V12.6532C2.00001 12.9113 2.13466 13.1501 2.35394 13.2809L7.92312 16.6043V9.32081L5.92033 8.10176V10.07C5.92033 10.1698 5.85414 10.2589 5.75483 10.2932C5.72661 10.3029 5.69785 10.3075 5.66854 10.3075L5.668 10.308C5.59528 10.308 5.52419 10.2778 5.47535 10.223L4.83637 9.38673L4.41437 9.57586C4.34491 9.57945 4.27978 9.55386 4.2304 9.50933C4.18156 9.46481 4.15389 9.40288 4.15389 9.33839L4.15409 7.02669L2 5.71554Z"
      fill="currentColor"
    />
    <path
      d="M9.00004 17C9.05833 16.9847 9.11519 16.9619 9.16918 16.9315L15.6307 13.2898C15.8586 13.1614 16 12.9177 16 12.6532V5.4624L9.00004 9.33578V17Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgRegularPackage;
