import { useMutation } from "@tanstack/react-query";

import { VoucherAppliedTo } from "../../UserManagement/types";
import { isBrandSpecific } from "../CreateVouchers/VoucherUtils";
import { VoucherFormFields } from "./useCreateVouchersForm";
import { ErrorResponse } from "services/ErrorResponse";
import httpClient from "services/httpClient";

export const POST_CREATE_VOUCHER = "/admin/vouchers";

const postCreateVoucher = async (form: VoucherFormFields) => {
  return await httpClient.post(`${POST_CREATE_VOUCHER}`, {
    key: form.code,
    validTo: form.expiryDate,
    minAmount: Number(form.minAmount),
    ...(form.discountType.label === "Fixed Amount" && {
      fixedAmount: Number(form.discountValue),
    }),
    ...(form.discountType.label === "Percentage" && {
      fixedPercent: Number(form.discountValue),
    }),
    appliedTo: form.discountAppliedTo.value,
    maxUsage: Number(form.quantity),
    maxUsagePerUser: Number(form.maxUsage),
    isActive: true,
    maxAmount: Number(form.maxAmount),
    currency: form.voucherCurrency.value,
    firstOrderOnly: form.firstOrder === "FIRST_ORDER",
    brands:
      isBrandSpecific(form.discountAppliedTo.value as VoucherAppliedTo) &&
      form.applicableBrands?.value
        ? [form.applicableBrands.value]
        : [],
  });
};

export const usePostCreateVoucher = (
  onSuccess: () => void,
  onError: (data: ErrorResponse) => void,
) =>
  useMutation(postCreateVoucher, {
    onSuccess,
    onError,
  });
