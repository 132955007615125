/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Button, Flex, Spinner, Text } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import { SubOrder } from "../hooks/useGetOrderById";
import { ProductObject, useGetOrderPreview } from "../hooks/useGetOrderPreview";
import { usePostEditSuborder } from "../hooks/usePostEditSuborder";
import { ModalWrapper } from "components/Modal/ModalWrapper";
import { OrderAvatar } from "components/OrderAvatar/OrderAvatar";
import { TableWrapper } from "components/Table/TableWrapper";
import { useSimpleToast } from "hooks/useSimpleToast";
import { ErrorResponse } from "services/ErrorResponse";
import { getErrorCodes } from "services/httpClient";

export type ConfirmEditOrderModalProps = {
  isOpen: boolean;
  onClose: () => void;
  suborder: SubOrder;
  currency: string;
};

export const ConfirmEditOrderModal = ({
  isOpen,
  onClose,
  suborder,
  currency,
}: ConfirmEditOrderModalProps) => {
  const { t } = useTranslation();
  const { isLoading: isLoadingOrderPreview, data: orderPreview } =
    useGetOrderPreview(suborder.id);

  const toCharge = orderPreview?.toCharge ?? 0;
  const toRefund = orderPreview?.toRefund ?? 0;
  const diffInReturnSurcharge = orderPreview?.diffInReturnSurcharge ?? 0;
  const isCharge = toCharge > 0;
  const isRefund = toRefund > 0;

  const toast = useSimpleToast();

  const onSuccess = () => {
    toast({
      position: "top",
      description: t("order.edit_suborder_success", {
        suborderId: suborder.orderNumber,
      }),
    });
    window.location.reload();
  };

  const onError = (error: ErrorResponse) => {
    const errorCodes = getErrorCodes(error);
    toast({
      status: "error",
      position: "top",
      description: t(errorCodes[0]),
    });
  };
  const { mutateAsync: postEditSuborder, isLoading: isMutating } =
    usePostEditSuborder(onSuccess, onError);

  const onSubmit = async () => {
    await postEditSuborder({
      suborderId: suborder.id,
      // eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
      snapshotId: orderPreview?.snapshot.id as string,
    });
  };

  const columnHelper = createColumnHelper<ProductObject>();

  const confirmColumns = [
    columnHelper.accessor("id", {
      cell: ({ row }) => (
        <OrderAvatar
          name={row.original.name}
          sku={`SKU: ${row.original.sku}`}
          image={row.original.imageUrl}
          variant={row.original.variantName}
        />
      ),
      header: "Product",
      size: 300,
    }),
    columnHelper.accessor("price", {
      size: 120,
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {Intl.NumberFormat("en-US", {
            style: "currency",
            currency: row.original.currency.brand,
          }).format(
            Number(
              isNaN(row.original.price)
                ? 0
                : (
                    row.original.price / suborder.brandToRetailerConversionRate
                  ).toFixed(2),
            ),
          )}
        </Text>
      ),
      header: t("order.adjusted_price_brand"),
    }),
    columnHelper.accessor("price", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {Intl.NumberFormat("en-US", {
            style: "currency",
            currency: row.original.currency.retailer,
          }).format(row.original.price)}
        </Text>
      ),
      header: t("order.adjusted_price_retailer"),
    }),
    columnHelper.accessor("unitQuantity", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {row.original.unitQuantity}
        </Text>
      ),
      header: "Unit Quantity",
    }),
    columnHelper.accessor("total", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700">
          {Intl.NumberFormat("en-US", {
            style: "currency",
            currency: currency,
          }).format(row.original.total.retailer)}
        </Text>
      ),
      header: "Subtotal",
    }),
  ];

  const getSnapshotTotal = () => {
    if (orderPreview) {
      let total = 0;
      orderPreview.snapshot.products.forEach((product) => {
        total += product.total.retailer;
      });
      return Number(total.toFixed(2));
    }
    return null;
  };
  return (
    <ModalWrapper
      modalProps={{ size: "l", isOpen, onClose, children: undefined }}
      isOpen={isOpen}
      onClose={onClose}
      modalHeader={
        <Flex flexDir="column" textStyle="h3" fontWeight="bold">
          <Text>{t("order.confirm_order")}</Text>
          <Text>{`#${suborder.orderNumber}`}</Text>
        </Flex>
      }
      modalBody={
        isLoadingOrderPreview ? (
          <Spinner />
        ) : (
          <>
            <TableWrapper
              textWrap={true}
              columns={confirmColumns}
              tableData={orderPreview?.snapshot.products}
              disablePagination
            />
            <Flex justifyContent={"right"} flexDir={"column"} mb={5}>
              <Text align={"right"}>{t("order.new_total")}</Text>
              <Text as={"b"} align={"right"} textStyle="bodyL" color="grey.700">
                {Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: currency,
                }).format(Number(getSnapshotTotal()))}
              </Text>
            </Flex>
            {(isCharge || isRefund) && (
              <Flex justifyContent={"right"} flexDir={"column"}>
                <Text
                  align={"right"}
                  color={isRefund ? "green.400" : "red.400"}
                >
                  {isRefund ? t("order.to_refund") : t("order.to_charge")}
                </Text>
                <Text
                  as={"b"}
                  align={"right"}
                  textStyle="bodyL"
                  color={isRefund ? "green.400" : "red.400"}
                >
                  {Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: currency,
                  }).format(Number(Math.abs(isRefund ? toRefund : toCharge)))}
                </Text>
              </Flex>
            )}
            {(isCharge || isRefund) && (
              <Flex justifyContent={"right"} flexDir={"column"} mt={5}>
                <Text
                  align={"right"}
                  color={isRefund ? "green.400" : "red.400"}
                >
                  {isRefund ? t("order.to_refund") : t("order.to_charge")}{" "}
                  {t("order.to_surcharge")}
                </Text>
                <Text
                  as={"b"}
                  align={"right"}
                  textStyle="bodyL"
                  color={isRefund ? "green.400" : "red.400"}
                >
                  {Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: currency,
                  }).format(Number(Math.abs(diffInReturnSurcharge)))}
                </Text>
              </Flex>
            )}
          </>
        )
      }
      modalFooter={
        <>
          <Button
            variant="outline"
            fontWeight="600"
            size="lg"
            onClick={() => {
              onClose();
            }}
            data-test-id="close-confirm-preview-modal"
          >
            {t("Cancel")}
          </Button>
          <Button
            type="submit"
            form="confirmEditOrder"
            variant="solid"
            size="lg"
            ml={3}
            data-test-id="post-edit-order"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={onSubmit}
            disabled={isMutating}
          >
            {t("order.edit_suborder")}
          </Button>
        </>
      }
    />
  );
};
