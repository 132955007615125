/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import { format, parseISO } from "date-fns";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";

import { PickupOption } from "../PickupModal/PickupModal";
import { GET_ORDER_DETAILS_QK } from "./useGetOrderById";
import { DateSlot } from "./useGetSchedulePickup";
import { usePostSchedulePickup } from "./usePostSchedulePickup";
import { useSimpleToast } from "hooks/useSimpleToast";
import { queryClient } from "services/queryClient";

export type PickupFormFields = {
  id: string;
  pickupDate: PickupOption;
  pickupTime?: PickupOption;
};

type UsechedulePickupFormProps = {
  onClose: () => void;
  suborderId: string;
  dateSlots: DateSlot[];
};
export const useSchedulePickupForm = ({
  onClose,
  suborderId,
  dateSlots,
}: UsechedulePickupFormProps) => {
  const {
    control,
    register,
    formState: { errors, isDirty, isValid },
    getValues,
    setValue,
    handleSubmit,
    reset,
    watch,
  } = useForm<PickupFormFields>({
    defaultValues: {
      id: suborderId,
    },
  });

  const toast = useSimpleToast();

  const onSuccess = () => {
    void queryClient.invalidateQueries({
      queryKey: [GET_ORDER_DETAILS_QK],
    });
    toast({
      position: "top",
      description: "A pickup has been scheduled successfully",
    });
    onClose();
  };

  const watchPickupDate = watch("pickupDate");

  useEffect(() => {
    setValue("pickupTime", undefined);
  }, [setValue, watchPickupDate]);

  const { mutateAsync: postSchedulePickup } = usePostSchedulePickup(onSuccess);

  const onSubmit = handleSubmit(async () => {
    await postSchedulePickup(getValues());
  });

  const dateOptions = dateSlots.map((item) => {
    return {
      label: format(parseISO(item.date), "EEEE, MMMM dd, yyyy"),
      value: item.date,
    } as PickupOption;
  });

  const timeOptions = useMemo(() => {
    const singleDate = dateSlots.find(
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (item) => item.date === (getValues("pickupDate") as PickupOption)?.value,
    );

    return singleDate
      ? singleDate.timeSlots.map((time) => {
          return {
            label: `${time.fromTime} - ${time.toTime}`,
            value: time.id,
          } as PickupOption;
        })
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateSlots, watchPickupDate, getValues]);

  const isFormValid = useMemo(() => {
    return !isDirty || !isValid;
  }, [isDirty, isValid]);

  return {
    control,
    register,
    errors,
    isFormValid,
    getValues,
    onSubmit,
    reset,
    dateOptions,
    timeOptions,
  };
};
