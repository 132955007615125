/* eslint-disable @tanstack/query/exhaustive-deps */
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import httpClient from "services/httpClient";

export const GET_PAYMENT_TERMS_USAGE = "/admin/payment-terms-requests/usage/";
export const GET_PAYMENT_TERMS_USAGE_RETAILER = "/admin/retailer/";
export const GET_PAYMENT_TERMS_USAGE_QK = "PAYMENT_TERMS_USAGE_QK";

type PaymentTerms = {
  approved: number;
  used: number;
  available: number;
};

export type Usage = {
  creationDate: string;
  dueDate: string;
  payIn: number;
  payOut: number;
  transactionReference: string;
};

export type PaymentTermUsage = {
  currency: string;
  paymentTerms: PaymentTerms;
  usage: Usage[];
  outstanding: number | null;
};

const getPaymentTermsUsage = async (id: string) => {
  const { data: usage } = await httpClient.get<PaymentTermUsage>(
    GET_PAYMENT_TERMS_USAGE + id,
  );
  return usage;
};

export const useGetPaymentTermsUsage = (
  id: string,
): UseQueryResult<PaymentTermUsage> =>
  useQuery({
    queryKey: [GET_PAYMENT_TERMS_USAGE_QK],
    queryFn: async () => await getPaymentTermsUsage(id),
  });

export const getPaymentTermsUsageViaRetailerId = async (retailerId: string) => {
  const { data: usage } = await httpClient.get<PaymentTermUsage>(
    GET_PAYMENT_TERMS_USAGE_RETAILER + retailerId + "/payment-terms-usage",
  );
  return usage;
};

export const useGetPaymentTermsUsageViaRetailerId = (
  retailerId: string,
): UseQueryResult<PaymentTermUsage> =>
  useQuery({
    queryKey: [GET_PAYMENT_TERMS_USAGE_QK],
    queryFn: async () => await getPaymentTermsUsageViaRetailerId(retailerId),
  });
