import { bodyL, bodyM, bodyS, h6 } from "./textStyles";

export const defaultButtonStyle = {
  borderRadius: "base",
  fontWeight: 500,
  color: "primary.500",
  _disabled: {
    bg: "grey.200",
    color: "grey.500",
    fontWeight: "semibold",
    pointerEvents: "none",
  },
};

export const smButtonStyle = {
  ...bodyS,
  h: 6,
  px: 2,
  py: 0.5,
};

export const mdButtonStyle = {
  ...bodyM,
  h: 8,
  px: 3,
  py: 1.5,
};

export const lgButtonStyle = {
  ...bodyL,
  height: 10,
  px: 4,
  py: 2.5,
};

export const xlButtonStyle = {
  ...h6,
  height: 12,
  px: 4,
  py: 2.5,
};

export const greyStyle = {
  bg: "grey.800",
  _hover: {
    bg: "grey.900",
  },
  _active: {
    bg: "grey.black",
  },
};

export const greyAlphaStyle = {
  color: "grey.800",
  borderColor: "greyAlpha.300",
  _hover: {
    bg: "greyAlpha.100",
  },
  _active: {
    bg: "greyAlpha.200",
  },
};
