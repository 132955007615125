import { BoxProps } from "@chakra-ui/react";

import "./calendar.css";

export const boxWrapperStyle: BoxProps = {
  position: "absolute",
  zIndex: "docked",
  borderRadius: "lg",
  boxShadow: 6,
  border: "none",
};
