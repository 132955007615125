import {
  Badge,
  Box,
  Button,
  Flex,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { parseISO } from "date-fns";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { ThirdPartyPanel } from "../shared/ThirdPartyPanel";
import { RetailerDetailsType } from "../types";
import { capitalizeFirstLetter } from "../utils";
import { useGetAllRetailerNotesById } from "./hooks/useGetAllRetailerNotesById";
import { usePostNewRetailerNote } from "./hooks/usePostNewRetailerNote";
import AddressDetails from "components/AddressDetails/AddressDetails";
import { InfoDetails } from "components/InfoDetails/InfoDetails";
import { ModalWrapper } from "components/Modal/ModalWrapper";
import { Note } from "components/Note/Note";
import { TextAreaField } from "components/TextAreaField/TextAreaField";
import { useGetBrRequestsHistory } from "features/RetailerRequests/hooks/useGetBrRequestsHistory";
import {
  usePostApproveBr,
  usePostRejectBr,
} from "features/RetailerRequests/hooks/usePostApproveRejectBr";
import { useSimpleToast } from "hooks/useSimpleToast";
import { ErrorResponse } from "services/ErrorResponse";
import { NOTES } from "services/ReactQueryKeys";
import { queryClient } from "services/queryClient";
import { SolidCheck, SolidClose, SolidPlus } from "styles/icons/solid";

type RetailerDetailsProps = {
  retailerAccount: RetailerDetailsType;
  retailerAccountRefetch: () => Promise<unknown>;
};

type RetailerAccountIdParam = {
  retailerId: string;
};

export const RetailerDetails = ({
  retailerAccount,
  retailerAccountRefetch,
}: RetailerDetailsProps) => {
  const { t } = useTranslation();

  const {
    id,
    email,
    firstName,
    lastName,
    phoneNumber,
    country,
    storeName,
    typeOfStore,
    website,
    yearsInBusiness,
    annualSales,
    productPreferences,
    shippingAddress,
    billingAddress,
    brStatus,
  } = retailerAccount;

  const toast = useSimpleToast();

  const {
    isOpen: isOpenNewNote,
    onOpen: onOpenNewNote,
    onClose: onCloseNewNote,
  } = useDisclosure();

  const { retailerId } = useParams<
    keyof RetailerAccountIdParam
  >() as RetailerAccountIdParam;
  const { data: notes, isLoading: isLoadingNotes } =
    useGetAllRetailerNotesById(retailerId);

  const sortedNotes =
    notes?.sort((first, second) => {
      const dateFirst = parseISO(first.creationDate) as unknown as Date;
      const dateSecond = parseISO(second.creationDate) as unknown as Date;
      return dateSecond.getTime() - dateFirst.getTime();
    }) ?? [];

  const {
    register,
    getValues,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onSubmit" });

  const onSuccess = () => {
    reset({ newNote: "" });
    onCloseNewNote();

    toast({
      description: t("retailer_details.successfully_created_note"),
    });

    void queryClient.invalidateQueries({
      queryKey: [NOTES, retailerId],
    });
  };

  const { mutateAsync: postNewNote, isLoading: isLoadingNewNote } =
    usePostNewRetailerNote(onSuccess);

  const handleAddNewNote = handleSubmit(async () => {
    await postNewNote({
      id: retailerId,
      content: getValues("newNote") as string,
    });
  });

  const categories: string[] = [];
  const subCategories: string[][] = [];

  productPreferences.forEach((item) => {
    categories.push(`${item.name}:`);
    subCategories.push(item.subCategories.map((subItem) => subItem.name));
  });

  const transformedSubCategories = subCategories.map((subCategoryArr) => {
    const transformedSubCategory = subCategoryArr.map((subCategory, i) => {
      return (
        <Badge key={i} colorScheme="grey" fontWeight={400} mr={1} my={1} p={1}>
          {subCategory}
        </Badge>
      );
    });
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{transformedSubCategory}</>;
  });

  const { data: brData, refetch } = useGetBrRequestsHistory(id);
  const onBrSuccess = () => {
    reset({ newApproveNote: "", newRejectNote: "" });
    void refetch();
    void retailerAccountRefetch();
    toast({ status: "success", description: "Success" });
  };
  const onBrError = (error: ErrorResponse) => {
    reset({ newApproveNote: "", newRejectNote: "" });
    void refetch();
    void retailerAccountRefetch();
    toast({ status: "success", description: "Success" });
    toast({ status: "error", description: "Error" });
  };
  const { mutateAsync: postApproveBr } = usePostApproveBr(
    onBrSuccess,
    onBrError,
  );
  const { mutateAsync: postRejectBr } = usePostRejectBr(onBrSuccess, onBrError);
  const {
    isOpen: isOpenBrApproveNote,
    onOpen: onOpenBrApproveNote,
    onClose: onCloseBrApproveNote,
  } = useDisclosure();
  const {
    isOpen: isOpenBrRejectNote,
    onOpen: onOpenBrRejectNote,
    onClose: onCloseBrRejectNote,
  } = useDisclosure();
  const getBrColorScheme = (status: string) => {
    switch (status) {
      case "APPROVED":
        return "green";
      case "REJECTED":
        return "red";
      default:
        return "grey";
    }
  };

  return (
    <Flex overflowY="auto">
      <Flex
        pl={5}
        mt={5}
        pr={5}
        flexDirection="column"
        flexWrap={"wrap"}
        gap={5}
      >
        <Box>
          <InfoDetails
            w="554px"
            leftTextProps={{ width: "40%" }}
            heading={t("retailer_details.basic_information")}
            leftList={[
              t("retailer_details.first_name"),
              t("retailer_details.last_name"),
              t("retailer_details.email_address"),
              t("retailer_details.phone_number"),
              t("retailer_details.country"),
            ]}
            rightList={[firstName, lastName, email, phoneNumber, country]}
            mb={5}
          />
          <InfoDetails
            w="554px"
            leftTextProps={{ width: "40%" }}
            heading={t("retailer_details.business_preferences")}
            leftList={[
              t("retailer_details.store_name"),
              t("retailer_details.type_of_store"),
              t("retailer_details.website"),
              t("retailer_details.years_in_business"),
              t("retailer_details.annual_sales"),
            ]}
            rightList={[
              storeName,
              typeOfStore,
              website,
              yearsInBusiness,
              annualSales,
            ]}
            mb={5}
          />

          <InfoDetails
            w="554px"
            leftTextProps={{ width: "40%" }}
            heading={t("retailer_details.business_preferences")}
            leftList={categories}
            rightList={transformedSubCategories}
            mb={5}
          />
          <AddressDetails
            heading={t("common.address.delivery_address")}
            address={shippingAddress}
          />
          <AddressDetails
            heading={t("retailer_details.billing_address")}
            address={billingAddress}
          />
        </Box>

        <Box>
          {isLoadingNotes ? (
            <Flex justify="center" w="554px" mb={5}>
              <Spinner mt={5} ml={5} />
            </Flex>
          ) : (
            <Flex
              w="554px"
              maxH={554}
              px={5}
              pt={5}
              flexDir="column"
              borderRadius="base"
              boxShadow={1}
              border="1px solid"
              borderColor="grey.400"
              mb={5}
            >
              <Text textStyle="bodyM" color="grey.700" fontWeight="semibold">
                {t("retailer_details.notes")}
              </Text>
              <Button
                size="md"
                w="fit-content"
                mt={5}
                onClick={onOpenNewNote}
                leftIcon={<SolidPlus width={20} height={20} />}
              >
                {t("retailer_details.add_note")}
              </Button>
              <Box mt={5}>
                {sortedNotes.map((note) => (
                  <Note key={note.id} note={note} />
                ))}
              </Box>
            </Flex>
          )}

          <Flex
            w="554px"
            maxH={400}
            px={5}
            pt={5}
            flexDir="column"
            borderRadius="base"
            boxShadow={1}
            border="1px solid"
            borderColor="grey.400"
            mb={5}
          >
            <Text textStyle="bodyM" color="grey.700" fontWeight="semibold">
              Business Registration
            </Text>

            <Flex flexDir="row" gap={2} alignItems={"center"} mt={3}>
              <Text textStyle="body" color="grey.900">
                Current Status:
                <Badge
                  colorScheme={getBrColorScheme(brStatus)}
                  fontWeight="semibold"
                  ml={2}
                  my={1}
                  p={2}
                  fontSize={14}
                >
                  {capitalizeFirstLetter(brStatus)}
                </Badge>
              </Text>
            </Flex>

            <Flex flexDir="row" gap={2} alignItems={"center"} mt={3}>
              <Button
                size="md"
                w="fit-content"
                onClick={() => {
                  onOpenBrApproveNote();
                }}
                leftIcon={<SolidCheck width={20} height={20} />}
                // backgroundColor={"green.500"}
                // disabled={brStatus === 'APPROVED'}
              >
                Approve
              </Button>

              <Button
                size="md"
                w="fit-content"
                onClick={() => {
                  onOpenBrRejectNote();
                }}
                leftIcon={<SolidClose width={20} height={20} />}
              >
                Reject
              </Button>
            </Flex>

            <Box mt={5} mb={5} pr={5} overflow={"auto"}>
              {brData?.history.map((history) => {
                const files = brData.documents.filter(
                  (x) => x.requestId === history.id,
                );
                const links = files.map((file) => {
                  return `<a key="${file.id}" href="${file.documentRef}" target="_blank" rel="noreferrer">${file.name}</a>`;
                });
                const getContent = () => {
                  let content = "";
                  if (history.note) {
                    content += `${history.note} <br/>`;
                  }
                  if (links.length > 0) {
                    content += `Files: [${links.join(", ")}]<br/>`;
                  }
                  return content;
                };
                return (
                  <Note
                    key={history.id}
                    note={{
                      createdBy: capitalizeFirstLetter(history.type),
                      content: getContent(),
                      creationDate: history.creationDate,
                      isRichText: true,
                    }}
                  />
                );
              })}
            </Box>
          </Flex>
          <ThirdPartyPanel
            cognitoId={retailerAccount.cognitoUserId}
            email={email}
          />
        </Box>
      </Flex>

      <ModalWrapper
        isOpen={isOpenNewNote}
        onClose={onCloseNewNote}
        modalProps={{
          size: "sm",
          children: undefined,
          isOpen: isOpenNewNote,
          onClose: onCloseNewNote,
        }}
        showHeaderDivider
        modalHeader={t("retailer_details.new_note")}
        modalBody={
          <>
            <Text textStyle="bodyM" color="black" mb={1}>
              {t("retailer_details.add_a_note")}
            </Text>
            <TextAreaField
              height="125px"
              resize="none"
              placeholder={t("retailer_details.add_note_explanation")}
              errors={errors}
              {...register("newNote", {
                required: t("retailer_details.required_field"),
                validate: (value: string) => !!value.trim(),
              })}
            />
          </>
        }
        modalFooter={
          <Button
            onClick={() => void handleAddNewNote()}
            data-test-id="addNoteRequestButton"
            size="lg"
            isLoading={isLoadingNewNote}
            w="100%"
          >
            {t("retailer_details.add_note")}
          </Button>
        }
      />

      <ModalWrapper
        isOpen={isOpenBrApproveNote}
        onClose={onCloseBrApproveNote}
        modalProps={{
          size: "sm",
          children: undefined,
          isOpen: isOpenBrApproveNote,
          onClose: onCloseNewNote,
        }}
        showHeaderDivider
        modalHeader="Approve"
        modalBody={
          <>
            <Text textStyle="bodyM" color="black" mb={1}>
              {t("retailer_details.add_a_note")}
            </Text>
            <TextAreaField
              height="125px"
              resize="none"
              placeholder={t("retailer_details.add_note_explanation")}
              errors={errors}
              {...register("newApproveNote", {
                required: t("retailer_details.required_field"),
                validate: (value: string) => !!value.trim(),
              })}
              defaultValue={`Approved by ${
                localStorage.getItem("username") ?? ""
              }`}
            />
          </>
        }
        modalFooter={
          <Button
            onClick={() => {
              return void postApproveBr({
                id: retailerId,
                note: getValues("newApproveNote") as string,
              });
            }}
            data-test-id="addNoteRequestButton"
            size="lg"
            w="100%"
            backgroundColor={"green.500"}
            disabled={brStatus === "APPROVED"}
          >
            Approve
          </Button>
        }
      />

      <ModalWrapper
        isOpen={isOpenBrRejectNote}
        onClose={onCloseBrRejectNote}
        modalProps={{
          size: "sm",
          children: undefined,
          isOpen: isOpenBrRejectNote,
          onClose: onCloseNewNote,
        }}
        showHeaderDivider
        modalHeader="Reject"
        modalBody={
          <>
            <Text textStyle="bodyM" color="black" mb={1}>
              {t("retailer_details.add_a_note")}
            </Text>
            <TextAreaField
              height="125px"
              resize="none"
              placeholder={t("retailer_details.add_note_explanation")}
              errors={errors}
              {...register("newRejectNote", {
                required: t("retailer_details.required_field"),
                validate: (value: string) => !!value.trim(),
              })}
              defaultValue={`Rejected by ${
                localStorage.getItem("username") ?? ""
              }`}
            />
          </>
        }
        modalFooter={
          <Button
            onClick={() => {
              return void postRejectBr({
                id: retailerId,
                note: getValues("newRejectNote") as string,
              });
            }}
            data-test-id="addNoteRequestButton"
            size="lg"
            w="100%"
            backgroundColor={"red.500"}
            disabled={brStatus === "REJECTED"}
          >
            Reject
          </Button>
        }
      />
    </Flex>
  );
};
