/* eslint-disable @tanstack/query/exhaustive-deps */
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { ACTIVE_ORDERS_KEY, ORDER_HISTORY_KEY } from "services/ReactQueryKeys";
import httpClient from "services/httpClient";

const GET_ACTIVE_ORDERS_URL = "/admin/orders";
const GET_ORDER_HISTORY_URL = "/admin/orders/history";

export type OrderSuborder = {
  orderNumber: number;
  brandName: string;
};

export type SuborderDetails = {
  orderNumber: string;
  brandName: string;
};

export type Order = {
  creationDate: string;
  toDate: string;
  fromDate: string;
  currency: string;
  deliveryCountry: string;
  id: string;
  orderNumber: string;
  retailerName: string;
  total: number;
  status: OrderStatus;
  suborderNumbers?: number[];
  retailerStoreName?: string;
  brandStoreNames?: string[];
  retailerBrStatus: string;
};

type OrderStatus = "PENDING" | "COMPLETED" | "CANCELLED";

export const useGetActiveOrders = (): UseQueryResult<Order[]> =>
  useQuery({
    queryKey: [ACTIVE_ORDERS_KEY],
    queryFn: async () => {
      const { data: activeOrders } = await httpClient.get<Order>(
        GET_ACTIVE_ORDERS_URL,
      );
      return activeOrders;
    },
  });

export const useGetOrderHistory = (): UseQueryResult<Order[]> =>
  useQuery({
    queryKey: [ORDER_HISTORY_KEY],
    queryFn: async () => {
      const { data: orderHistory } = await httpClient.get<Order[]>(
        GET_ORDER_HISTORY_URL,
      );
      return orderHistory;
    },
  });
