import { radioAnatomy as parts } from "@chakra-ui/anatomy";
import { defineStyle } from "@chakra-ui/react";
import {
  StyleFunctionProps,
  createMultiStyleConfigHelpers,
} from "@chakra-ui/styled-system";

// eslint-disable-next-line @typescript-eslint/unbound-method
const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const grey500 = "grey.500";

const baseStyleControl = defineStyle(({ isDisabled }) => {
  return {
    borderRadius: "full",
    border: "1px solid",
    _checked: {
      _before: {
        content: `""`,
        display: "inline-block",
        pos: "relative",
        w: "50%",
        h: "50%",
        borderRadius: "50%",
        bg: isDisabled ? "white" : "currentColor",
      },
      _disabled: {
        backgroundColor: grey500,
      },
    },
    _hover: {
      boxShadow: 5,
      borderColor: isDisabled ? grey500 : "primary.500 ",
      _disabled: {
        boxShadow: "none",
        borderColor: "grey.400 ",
      },
      _checked: {
        borderColor: isDisabled ? grey500 : "primary.500 ",
      },
    },
    _disabled: {
      backgroundColor: "grey.200",
      borderColor: "grey.400",
    },
    _focusVisible: {
      outline: 0,
      boxShadow: 5,
    },
    _invalid: {
      borderColor: "none",
    },
  };
});

const baseStyle = definePartsStyle((props: StyleFunctionProps) => ({
  control: baseStyleControl(props),
}));

const sizes = {
  md: definePartsStyle({
    control: { w: "4", h: "4" },
    label: { textStyle: "bodyM" },
  }),
  lg: definePartsStyle({
    control: { w: "5", h: "5" },
    label: { textStyle: "bodyM" },
  }),
};

export const Radio = defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: "md",
    colorScheme: "primary",
  },
});
