import { useMutation } from "@tanstack/react-query";

import { PAYOUT_DETAILS_KEY } from "services/ReactQueryKeys";
import httpClient from "services/httpClient";
import { queryClient } from "services/queryClient";

export const PUT_HOLD = "/admin/payouts/suborder";
type HoldProps = {
  id: string;
  type: string;
};
const hold = async ({ id, type }: HoldProps) => {
  const route =
    type === "hold" ? `${PUT_HOLD}/${id}/unhold` : `${PUT_HOLD}/${id}/hold`;
  return await httpClient.put(route);
};

export const usePutHoldUnhold = () =>
  useMutation(hold, {
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [PAYOUT_DETAILS_KEY] });
    },
  });
