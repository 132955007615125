import { Text } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { format, parseISO } from "date-fns";

import { Transaction, TransactionTerm } from "./useGetFinancialDetails";

type UseSetupTermsTableProps = {
  termsData: TransactionTerm[];
  outstanding: number | null;
};
export const useSetupTermsTable = ({
  termsData,
  outstanding,
}: UseSetupTermsTableProps) => {
  const columnHelper = createColumnHelper<Transaction>();

  const convertTypeToReadableType = (type: string) => {
    switch (type) {
      case "PAYMENT_TERM":
        return "Payment Terms";
      case "WALLET_CREDIT":
        return "Wallet";
      case "BANK_TRANSFER":
        return "Bank Transfer";
      case "CREDIT_CARD":
        return "Credit Card";
      case "PAY_IN":
        return "Pay In";
      case "PAY_OUT":
        return "Payment Terms Used";
      case "FORCE_PUSH":
        return "Force Pushed";
      case "OVERPAY":
        return "Overpay";

      default:
        return "Voucher";
    }
  };

  const termColumns = [
    columnHelper.accessor("type", {
      cell: ({ row }) => (
        <Text textStyle="bodyS" color="grey.700" fontWeight="bold">
          {convertTypeToReadableType(row.original.type)}
        </Text>
      ),
      header: "Method",
      size: 20,
      enableSorting: false,
    }),
    columnHelper.accessor("transactionReference", {
      cell: ({ row }) =>
        row.original.transactionReference && (
          <Text textStyle="bodyS" color="grey.700">
            {row.original.transactionReference}
          </Text>
        ),
      header: "Payment ID",
      size: 60,
      enableSorting: false,
    }),
    columnHelper.accessor("creationDate", {
      cell: ({ row }) =>
        row.original.creationDate && (
          <Text textStyle="bodyS" color="grey.700">
            {format(parseISO(row.original.creationDate), "dd-MM-yyyy HH:mm:ss")}
          </Text>
        ),
      header: "Date & Time",
      size: 20,
      enableSorting: false,
    }),
    columnHelper.accessor("expirationDate", {
      cell: ({ row }) =>
        row.original.creationDate && (
          <Text textStyle="bodyS" color="grey.700">
            {row.original.expirationDate &&
              format(parseISO(row.original.expirationDate), "dd-MM-yyyy")}
          </Text>
        ),
      header: "Due Date",
      size: 20,
      enableSorting: false,
      footer: () => (
        <Text textStyle="bodyS" fontWeight="bold" float="right">
          Outstanding:
        </Text>
      ),
    }),
    columnHelper.accessor("amount", {
      cell: ({ row }) =>
        row.original.amount && (
          <Text
            textStyle="bodyS"
            color={row.original.type === "PAY_IN" ? "green.500" : "red.500"}
          >
            {Intl.NumberFormat("en-US", {
              style: "currency",
              currency: row.original.currency,
              signDisplay: "always",
            }).format(Number(row.original.amount))}
          </Text>
        ),
      header: "Amount",
      size: 10,
      enableSorting: false,
      footer: () => (
        <Text textStyle="bodyS" fontWeight="bold" float="left" pl={4}>
          {Intl.NumberFormat("en-US", {
            style: "currency",
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            currency: termsData[0]?.currency ?? "EUR",
          }).format(outstanding ?? 0)}
        </Text>
      ),
    }),
  ];
  const termRows = termsData.map(
    ({
      transactionReference,
      creationDate,
      expirationDate,
      payIn,
      payOut,
      currency,
    }) => ({
      type: Number(payIn) !== 0 ? "PAY_IN" : "PAY_OUT",
      transactionReference,
      creationDate,
      expirationDate,
      amount: payIn + payOut,
      currency,
    }),
  );

  return {
    termColumns,
    termRows,
  };
};
