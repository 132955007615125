/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Avatar,
  Divider,
  Flex,
  Heading,
  Spinner,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { PageUpdateDetails } from "./PageUpdateDetails";
import { useGetBrandPageUpdateRequestById } from "./hooks/useGetBrandPageUpdateRequestById";
import { usePostApproveBrandPageUpdateRequest } from "./hooks/usePostApproveBrandPageUpdateRequest";
import { usePostRejectBrandPageUpdateRequest } from "./hooks/usePostRejectBrandPageUpdateRequest";
import { useRejectForm } from "./hooks/useRejectForm";
import { Button } from "components/Button/Button";
import { ModalWrapper } from "components/Modal/ModalWrapper";
import { useToast } from "hooks/useToast";
import { getErrorCodes } from "services/httpClient";
import { LightArrowLeft } from "styles/icons/light";

type BrandRequestParam = {
  pageUpdateRequestId: string;
};
export const BrandPageUpdateRequestOverview = () => {
  const { pageUpdateRequestId } = useParams<
    keyof BrandRequestParam
  >() as BrandRequestParam;

  const {
    isOpen: isOpenReject,
    onOpen: onOpenReject,
    onClose: onCloseReject,
  } = useDisclosure();

  const { t } = useTranslation();
  const toast = useToast();
  const BRAND_REQUEST_URL = "/brand-requests";
  const {
    data: brandRequestDetails,
    isLoading,
    isFetching,
  } = useGetBrandPageUpdateRequestById(pageUpdateRequestId);

  const navigate = useNavigate();

  const {
    mutateAsync: approveBrandPageUpdateRequest,
    isLoading: isLoadingApprove,
  } = usePostApproveBrandPageUpdateRequest();
  const { mutateAsync: rejectBrandRequest, isLoading: isLoadingReject } =
    usePostRejectBrandPageUpdateRequest();

  const { register, isFormValid, getValues } = useRejectForm();

  if (isFetching || isLoading) {
    return <Spinner position="absolute" top="50%" left="50%" />;
  }

  const handleOnClickApprove = async () => {
    await approveBrandPageUpdateRequest(brandRequestDetails?.id ?? "", {
      onSuccess: () => {
        let brandName: string;
        if (brandRequestDetails) {
          brandName = brandRequestDetails.name;
        } else {
          brandName = "Brand";
        }
        toast({
          description: `Request for ${brandName} has been accepted`,
        });
      },
      onError: (error) => {
        const errorCodes = getErrorCodes(error);
        toast({ status: "error", description: t(errorCodes[0]) });
      },
    });

    navigate(`${BRAND_REQUEST_URL}#1`);
  };

  const handleRejectAdmin = async () => {
    await rejectBrandRequest(
      {
        id: pageUpdateRequestId,
        reason: getValues().reason,
      },
      {
        onSuccess: () => {
          let brandName: string;
          if (brandRequestDetails) {
            brandName = brandRequestDetails.name;
          } else {
            brandName = "Brand";
          }
          toast({
            description: `Request for ${brandName} has been rejected`,
          });
        },
        onError: (error) => {
          const errorCodes = getErrorCodes(error);
          toast({ status: "error", description: t(errorCodes[0]) });
        },
      },
    );
    navigate(`${BRAND_REQUEST_URL}#1`);
  };

  return (
    <Flex display="column" width="1200px">
      <Flex mb={2} mt={12} ml={10} justifyContent="space-between">
        <Flex>
          <Button
            variant="ghost"
            colorScheme="grey"
            mainIcon={LightArrowLeft}
            aria-label="backButton"
            data-test-id="backToAdminList"
            alignSelf="center"
            mr={4}
            onClick={() => window.history.back()}
          />
          <Avatar
            name={brandRequestDetails?.name}
            alignSelf="center"
            color="white"
            bg="#DF9797"
            mr={2}
          />
          <Heading as="h1" size="h1" fontWeight="bold">
            {brandRequestDetails?.name}
          </Heading>
        </Flex>
        <Flex>
          <Button
            colorScheme="red"
            variant="outline"
            size="lg"
            mr={2.5}
            onClick={onOpenReject}
          >
            {t("Reject")}
          </Button>
          <Button
            colorScheme="primary"
            size="lg"
            isLoading={isLoadingApprove}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleOnClickApprove}
          >
            {t("Accept")}
          </Button>
        </Flex>
      </Flex>
      <Divider h="2px" />

      {brandRequestDetails && <PageUpdateDetails {...brandRequestDetails} />}

      <ModalWrapper
        isOpen={isOpenReject}
        onClose={onCloseReject}
        modalProps={{
          size: "sm",
          children: undefined,
          isOpen: isOpenReject,
          onClose: onCloseReject,
        }}
        showHeaderDivider
        modalHeader={`${t("Reject")} ${brandRequestDetails?.name ?? ""}`}
        modalBody={
          <>
            <Text textStyle="bodyM" color="black" mb={1}>
              {t("Add Comment")}
            </Text>
            <Textarea
              height="125px"
              resize="none"
              placeholder={t("Add an explanation for rejecting a brand")}
              {...register("reason", {
                required: true,
                validate: (value) => !!value.trim(),
              })}
            ></Textarea>
          </>
        }
        modalFooter={
          <>
            <Button
              variant="outline"
              colorScheme="grey"
              fontWeight="600"
              color="grey.black"
              onClick={onCloseReject}
              data-test-id="cancelAddAdminButton"
            >
              {t("Cancel")}
            </Button>
            <Button
              variant="solid"
              colorScheme="red"
              isLoading={isLoadingReject}
              disabled={isFormValid}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={() => handleRejectAdmin()}
            >
              {t("Reject")}
            </Button>
          </>
        }
      />
    </Flex>
  );
};
