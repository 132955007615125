/* eslint-disable @tanstack/query/exhaustive-deps */
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { UPCOMING_PAYOUTS_KEY } from "services/ReactQueryKeys";
import httpClient from "services/httpClient";

export const GET_UPCOMING_PAYOUTS = "/admin/payouts/incoming";

export type Payout = {
  id: string;
  cognitoUserId: string;
  brandId: string;
  storeName: string;
  amountInNextPayout: number;
  amountToBePaid: string;
  numberOfSuborders: number;
  action: string;
  hasHeld: boolean;
  currency: string;
};
export type UpcomingPayout = {
  dateFrom: string;
  dateTo: string;
  currency: string;
  payouts: Payout[];
};
export const useGetUpcomingPayouts = (): UseQueryResult<UpcomingPayout> =>
  useQuery({
    queryKey: [UPCOMING_PAYOUTS_KEY],
    queryFn: async () => {
      const { data: payouts } = await httpClient.get<UpcomingPayout>(
        GET_UPCOMING_PAYOUTS,
      );
      return payouts;
    },
  });
